import { v4 as uuidv4 } from 'uuid'
import { ActivePlan } from './ActivePlan'
import { ActivePlanCustom } from './ActivePlanCustom'

export interface ActivePlanDTO {
  id: string
  userCircleID: string
  userProfessionalID: string
  professionalMessage: string
  status: string
  startDate: Date
  finishDate: Date
  reason: string
  observation: string
  protocol: string
  rcv: number
}

export interface ActivePlanCustomDTO {
  id: string
  userCircleID: string
  userProfessionalID: string
  professionalMessage: string
  status: string
  startDate: Date
  finishDate: Date
  reason: string
  observation: string
  protocol: string
  completeName: string
  dni: string
  cip: string
  centerName: string
  birthDate: Date
  groupName: string
  petitionDate: Date
  attendance: number
  intensity: number
  physicalCondition: number
}

export function emptyActivePlanDTO() {
  return {
    id: uuidv4(),
    userCircleID: '',
    userProfessionalID: '',
    professionalMessage: '',
    status: '',
    startDate: new Date(),
    finishDate: new Date(),
    reason: '',
    observation: '',
    protocol: '',
    rcv: 1,
  }
}

export function fromModel(e: ActivePlan): ActivePlanDTO {
  return {
    id: e.id,
    userCircleID: e.userCircleID,
    userProfessionalID: e.userProfessionalID,
    professionalMessage: e.professionalMessage,
    status: e.status,
    startDate: e.startDate,
    finishDate: e.finishDate,
    reason: e.reason,
    observation: e.observation,
    protocol: e.protocol,
    rcv: e.rcv,
  }
}

export function fromCustomModel(e: ActivePlanCustom): ActivePlanCustomDTO {
  return {
    id: e.id,
    userCircleID: e.userCircleID,
    userProfessionalID: e.userProfessionalID,
    professionalMessage: e.professionalMessage,
    status: e.status,
    startDate: e.startDate,
    finishDate: e.finishDate,
    reason: e.reason,
    observation: e.observation,
    protocol: e.protocol,
    completeName: e.completeName,
    dni: e.dni,
    cip: e.cip,
    centerName: e.centerName,
    birthDate: e.birthDate,
    groupName: e.groupName,
    petitionDate: e.petitionDate,
    attendance: e.attendance,
    intensity: e.intensity,
    physicalCondition: e.physicalCondition,
  }
}

export function toModel(d: ActivePlanDTO): ActivePlan {
  return new ActivePlan(d)
}

export function toCustomModel(d: ActivePlanCustomDTO): ActivePlanCustom {
  return new ActivePlanCustom(d)
}

export function toModelArray(activePlanArr: ActivePlanDTO[]): ActivePlan[] {
  const auxArray: ActivePlan[] = []
  activePlanArr.forEach((value) => auxArray.push(toModel(value)))
  return auxArray
}
