import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Editable, Slate, withReact } from 'slate-react'
import { createEditor, Descendant, Range, Transforms } from 'slate'
import { Editor } from './Types'
import handleHotKeys from './HandleHotKeys'
import { Leaf } from './Leaf'
import { Element } from './Element'
import withShortcuts from './WithShortcuts'
import FormatToolbar from './FormatToolbar'
import { Box } from '@mui/material'
import { withCorrectVoidBehavior } from './InsertImageButton'
import { withHistory } from 'slate-history'
import { withInlines } from './Inlines'
import { isKeyHotkey } from 'is-hotkey'

const initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

type RichTextProps = {
  content: string
  handleChange?: (e: string) => void
  edit?: boolean
  maxLength?: number
  view?: boolean
}

function onKeyDown(event: KeyboardEvent, editor: Editor) {
  const handled = handleHotKeys(event, editor)
  const { selection } = editor
  if (handled) {
    event.preventDefault()
  }

  if (selection && Range.isCollapsed(selection)) {
    const { nativeEvent } = event
    if (isKeyHotkey('left', nativeEvent)) {
      event.preventDefault()
      Transforms.move(editor, { unit: 'offset', reverse: true })
      return
    }
    if (isKeyHotkey('right', nativeEvent)) {
      event.preventDefault()
      Transforms.move(editor, { unit: 'offset' })
    }
  }
}

export const RichText = (props: RichTextProps) => {
  const [value, setValue] = useState<Descendant[]>(
    props.edit ? JSON.parse(props.content) : initialValue
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const renderElement = useCallback((props) => <Element {...props} edit={props.view} />, [])
  const editor = useMemo(
    () =>
      withCorrectVoidBehavior(withInlines(withShortcuts(withHistory(withReact(createEditor()))))),
    []
  )

  useEffect(() => {
    if (!props.handleChange) return
    props.handleChange(JSON.stringify(value))
  }, [value])

  return (
    <>
      <Slate editor={editor} value={value} onChange={(value) => setValue(value)}>
        {!props.view && (
          <Box style={{ backgroundColor: '#f5f5f5', padding: 10 }}>
            <FormatToolbar />
          </Box>
        )}
        <Editable
          readOnly={!!props.view}
          style={{ paddingTop: 8 }}
          maxLength={props.maxLength}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Descripcion..."
          spellCheck
          // autoFocus
          onKeyDown={(event) => onKeyDown(event, editor)}
        />
      </Slate>
    </>
  )
}
