import { RouteProps } from '../../routes/AppRouter'
import { useTranslation } from 'react-i18next'
import { CityHallTable as CityHall } from '../../features/cityHall-config'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

export function CityHallConfig(props: RouteProps) {
  const title = props.title ?? ''
  const { t } = useTranslation()

  return (
    <>
      <CurrentNavHeaderEsfera
        title={t(title)}
        subtitle={t('cityHalls')}
        headerTitle={''}
        noCircleName={true}
        divider={true}
      />
      <CityHall />
    </>
  )
}
