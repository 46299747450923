import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_MESSENGER } from 'routes/routes-constants'
import { Query, QueryParam } from 'common/api/Query'
import { Conversation } from 'modules/messenger/models/Conversation'
import { getMessengerContainer } from 'container/messenger-module'
import { ConversationService } from 'modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from 'modules/messenger'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip,
} from '@mui/material'
import { listItemTextStyle } from '../notification-card-patient/NotificationsCardStyleMui'
import style from './ChatsCard.module.css'
import maleIcon from '@assets/chatIcons/male.svg'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { ConversationDTO } from 'modules/messenger/models/ConversationDTO'
import { reduceString } from 'common/utils/strings'
import { useProfCircle } from 'common/utils/prof-circle-context/ProfCircleContext'

type ChatsCardProps = {
  setNumberPendingChats: (number: number) => void
  showMore?: boolean
  professionalDashboard?: boolean
}

type Message = {
  _author: string
  _conversationID: string
}

const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const chatsPerPage = 7
const chatsPage = 0

export const ChatsCard: React.FC<ChatsCardProps> = (props) => {
  const loggedUser = loggedUserService.get()
  const [conversationCollection, setConversationCollection] = useState<ConversationDTO[]>([])
  const [conversationCollectionOriginal, setConversationCollectionOriginal] = useState<
    ConversationDTO[]
  >([])
  const { selectedUserCircle, setSelectedUserCircle, userCircles } = useCircleConfiguration()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { selectedProfCircle } = useProfCircle()

  useEffect(() => {
    if (!loggedUser) return

    conversationsService
      .getFilteredItems(
        new Query({
          pager: { offset: chatsPage * chatsPage, limit: chatsPerPage },
          query: [
            new QueryParam<Conversation>('userID', loggedUser.id),
            new QueryParam<Conversation>(
              'userCircleID',
              props.professionalDashboard ? '' : (selectedUserCircle?.id ?? '')
            ),
          ],
        })
      )
      .subscribe((res) => {
        const isLastMessageMyOwn = (chats: any, loggedUserId: string) => {
          return chats.map((chat: string | Message[]) => {
            const lastMsg = Array.isArray(chat) ? chat[chat.length - 1] : null
            return {
              isLastMsg: lastMsg && lastMsg._author === loggedUserId,
              converID: lastMsg && lastMsg._conversationID,
            }
          })
        }

        const lastMessagesOwnership = isLastMessageMyOwn(
          res.map((chat) => chat.messages),
          loggedUser.id
        )

        const numberOfChats =
          res.length -
          lastMessagesOwnership.map((msg: { isLastMsg: boolean }) => msg.isLastMsg).filter(Boolean)
            .length

        // Filtar los chats que isLastMsg sea false
        const filteredChatsBasedOnMsgRead = res.filter((chat, index) => {
          return !lastMessagesOwnership[index].isLastMsg
        })
        setConversationCollection(filteredChatsBasedOnMsgRead)
        setConversationCollectionOriginal(filteredChatsBasedOnMsgRead)

        props.setNumberPendingChats(numberOfChats)
        setIsLoading(false)
      })

    // llamada principal para llamar a los userCircles
  }, [selectedUserCircle, isLoading])

  useEffect(() => {
    if (!selectedProfCircle?.id) {
      setIsLoading(true)
    }

    // filter the conversations based on the selectedProfCircle ID
    const filteredConversations = conversationCollectionOriginal.filter((conversation) => {
      return conversation.circleID === selectedProfCircle?.id
    })

    setConversationCollection(filteredConversations)
    props.setNumberPendingChats(filteredConversations.length)
  }, [selectedProfCircle])

  const handlerGotToChats = (conversation: Conversation) => {
    const { userCircleID, id } = conversation
    const filterUser = userCircles.items.filter((u) => u.id === userCircleID)[0]

    try {
      setSelectedUserCircle(filterUser)
      navigate(ROUTE_MESSENGER, { state: { conversationId: id } })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <DashboardSectionCard
      label={'pendingChats'}
      showMore={props.showMore ? Boolean(selectedUserCircle?.circle) : false}
      onClick={() => navigate(ROUTE_MESSENGER)}
    >
      <List className={style.containerList}>
        {isLoading &&
          Array.from({ length: 5 }).map((n, i) => {
            return (
              <ListItem className={style.notificationContainer} key={i}>
                <ListItemIcon>
                  <Skeleton
                    width={34}
                    height={34}
                    style={{ borderRadius: '100%' }}
                    variant="rounded"
                  />
                </ListItemIcon>
                <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                  <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: '5px' }} />
                </ListItemText>
              </ListItem>
            )
          })}
        {!isLoading &&
          conversationCollection.map((conversation) => (
            <ListItem
              sx={{ paddingLeft: 0, paddingRight: 0 }}
              key={conversation.id}
              onClick={() => handlerGotToChats(conversation as Conversation)}
              className={style.chatContainer}
            >
              <ListItemAvatar>
                <Avatar
                  src={maleIcon}
                  style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
                />
              </ListItemAvatar>
              <Tooltip title={conversation.name}>
                <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                  {reduceString(conversation.name, 30)}
                </ListItemText>
              </Tooltip>
            </ListItem>
          ))}
      </List>
    </DashboardSectionCard>
  )
}
