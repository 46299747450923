import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormCard } from '../../components/form-card/FormCard'
import { Alert, Box, Chip, MenuItem, Select, TextField } from '@mui/material'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATIENT_CALENDAR_CONFIG } from '../../routes/routes-constants'
import {
  emptyPatientCalendarDTO,
  fromModel,
  PatientCalendarDTO,
} from '../../modules/patient-calendar-config/models/PatientCalendarDTO'
import { EventCategory, eventCategoryTypes } from '../../modules/calendar/enums/EventCategory'
import { Modal } from './Modal'
import styles from './Editor.module.css'
import genericStyle from '../../common/utils/generic.module.css'
import { reduceString } from '../../common/utils/strings'
import { getPatientCalendarContainer } from '../../container/patient-calendar-module'
import { PatientCalendarService } from '../../modules/patient-calendar-config/services/PatientCalendarService'
import { PATIENT_CALENDAR_SERVICE_KEY } from '../../modules/patient-calendar-config'
import { Query } from '../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'
import { VaccinesService } from '../../modules/vaccines/services/VaccinesService'
import { VACCINES_SERVICE_KEY } from '../../modules/vaccines'
import { getVaccinesContainer } from '../../container/vaccine-module'
import { ItemList } from '../../common/models/ItemList'
import { Vaccine } from '../../modules/vaccines/models/Vaccines'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { Cancel } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'
import { getCircleContainer } from '../../container/circle-module'
import CustomToolbar from '../calendar/CustomToolbar'
import usePrevious from 'hooks/accessOldState/usePreviousState'

type PatientCalendarConfigEditorProps = {
  id?: string
}

type PatientCalendarConfigEvent = {
  id: string
  month: number
  title: string
}

type OpenPatientCalendarConfigModal = {
  open: boolean
  patientCalendarConfigEvent?: PatientCalendarConfigEvent
}

const categoryTypes = eventCategoryTypes()

const forbiddenCategories = [
  categoryTypes[EventCategory.Medication],
  categoryTypes[EventCategory.Appointment],
  categoryTypes[EventCategory.Others],
  categoryTypes[EventCategory.TrainingPending],
  categoryTypes[EventCategory.TrainingAbsent],
  categoryTypes[EventCategory.TrainingAssisted],
  categoryTypes[EventCategory.EpilepticCrisis],
  categoryTypes[EventCategory.Symptoms],
  categoryTypes[EventCategory.PersonalEvent],
  categoryTypes[EventCategory.Treatments],
  categoryTypes[EventCategory.Teleconsultation],
]

type CircleInterface = {
  key: string
  value: string
}

enum PatientCalendarError {
  NO_EVENTS = 'calendarShouldHaveEvents',
}

const patientCalendarService = getPatientCalendarContainer().get<PatientCalendarService>(
  PATIENT_CALENDAR_SERVICE_KEY
)

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export const Editor = (props: PatientCalendarConfigEditorProps) => {
  const { t } = useTranslation()
  const [vaccines, setVaccines] = useState<ItemList<Vaccine>>()
  const [calendar, setCalendar] = useState<PatientCalendarDTO>(emptyPatientCalendarDTO())
  const [openModal, setOpenModal] = useState<OpenPatientCalendarConfigModal>({ open: false })
  const [events, setEvents] = useState<PatientCalendarConfigEvent[]>([])
  const [date, setDate] = useState<Date>(new Date())
  const [selectedPathology, setSelectedPathology] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<number>(EventCategory.Landmarks)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [circles, setCircles] = useState<CircleInterface[]>([])
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const navigate = useNavigate()

  const vaccinesContainer = getVaccinesContainer()
  const vaccinesService = vaccinesContainer.get<VaccinesService>(VACCINES_SERVICE_KEY)
  const prevCategory = usePrevious(selectedCategory)

  useEffect(() => {
    setIsEditing(!!props.id)
    circleService
      .getFilteredList(
        new Query({
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        const pl = res.items.map((p) => ({ key: p.name, value: p.id || '' }))
        setCircles(pl)
        if (!props.id) setSelectedPathology(pl[0].value)
      })

    if (!props.id) {
      setCalendar(Object.assign({ ...calendar }, { category: EventCategory.Landmarks }))
      setIsLoading(false)
      return
    }
    patientCalendarService.getByID(props.id).subscribe((res) => {
      if (!res) {
        return
      }
      const c = fromModel(res)
      setDate(new Date(c.year, 1, 1))
      setSelectedPathology(c.pathologyID)
      setSelectedCategory(c.category)
      setCalendar({
        ...calendar,
        title: c.title,
        pathologyID: c.pathologyID,
      })
      setEvents(c.events.map((e) => ({ id: uuidv4(), month: e.month, title: e.title })))
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    setCalendar(Object.assign({ ...calendar }, { year: date.getFullYear() }))
  }, [date])

  useEffect(() => {
    if (selectedPathology === calendar.pathologyID) {
      return
    }
    setCalendar(Object.assign({ ...calendar }, { pathologyID: selectedPathology }))
  }, [selectedPathology])

  useEffect(() => {
    if (selectedCategory !== calendar.category) {
      setCalendar((prevCalendar) => ({
        ...prevCalendar,
        category: selectedCategory,
      }))
    }

    if (prevCategory && prevCategory !== selectedCategory) {
      if (props.id) return
      // volvemos los eventos a  [] para poder setearlos de nuevo si hemos cambiaod de categoria
      setEvents([])
    }
  }, [selectedCategory, calendar.category])

  useEffect(() => {
    setCalendar(Object.assign({ ...calendar }, { events }))
    setErrorMessage('')
  }, [events])

  const goBack = () => navigate(ROUTE_PATIENT_CALENDAR_CONFIG)

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setCalendar(Object.assign({ ...calendar }, { [e.target.name]: e.target.value }))

  const handleDate = (e: any) => {
    if (e) {
      setDate(e.toDate())
    }
  }

  const validateCalendar = () => {
    if (!events.length) {
      setErrorMessage(PatientCalendarError.NO_EVENTS)
      return false
    }

    return true
  }

  const prepareVaccinesModal = () => {
    vaccinesService
      .getFilteredList(
        new Query({
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setVaccines(res)
      })
  }

  const saveCalendar = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateCalendar()) {
      return
    }

    patientCalendarService.add(calendar).subscribe(() => goBack())
  }

  const deleteEvent = (id: string) => setEvents(events.filter((e) => e.id !== id))

  const addOrEditEvent = (pcce: PatientCalendarConfigEvent) => {
    const eventIndex = events.map((e) => e.id).indexOf(pcce.id)

    if (eventIndex > 0) {
      const tmpEvents = events.slice()
      tmpEvents[eventIndex] = pcce
      setEvents(tmpEvents)
    } else {
      setEvents([...events, pcce])
    }
  }

  const onClick = (id: string, month: number, title: string) => {
    setOpenModal({
      open: true,
      patientCalendarConfigEvent: { id, month, title },
    })
  }

  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        <>
          <FormCard>
            <Box>
              <form onSubmit={saveCalendar}>
                <Box mb={3}>
                  <p style={{ marginLeft: '-2px' }}>{t('title')}</p>
                  <TextField
                    style={{ width: '18%' }}
                    key={'title'}
                    id={'title'}
                    name={'title'}
                    value={calendar.title}
                    onChange={handleInput}
                    rows={undefined}
                    required={true}
                    variant={'outlined'}
                    disabled={isEditing}
                    inputProps={{
                      maxLength: 120,
                    }}
                  />
                </Box>
                <Box mb={3}>
                  <p style={{ marginLeft: '-2px' }}>{t('year')}</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DatePicker
                      views={['year']}
                      PopperProps={{
                        sx: {
                          '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize',
                          },
                          '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                            fontFamily: 'Poppins',
                          },
                          '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                            fontFamily: 'Poppins',
                          },
                          '& .css-3eghsz-PrivatePickersYear-button': {
                            fontFamily: 'Poppins',
                          },
                        },
                      }}
                      onError={(reason, value) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      renderInput={(props) => (
                        <TextField
                          key={'year'}
                          name={'year'}
                          id={'year'}
                          sx={{
                            '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': {
                              paddingRight: '17px',
                            },
                            '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                              fontFamily: 'Poppins',
                            },
                          }}
                          variant={'standard'}
                          helperText={props.error && DateTimePickerError}
                          {...props}
                        />
                      )}
                      inputFormat="YYYY"
                      value={date}
                      onChange={handleDate}
                      disabled={isEditing}
                      ToolbarComponent={CustomToolbar}
                    />
                  </LocalizationProvider>
                </Box>
                <Box mb={3}>
                  <p style={{ marginLeft: '-2px' }}>{t('selectPathology')}</p>
                  <Select
                    style={{ width: '18%' }}
                    id={'pathologyID'}
                    key={'pathologyID'}
                    name={'pathologyID'}
                    variant={'outlined'}
                    value={selectedPathology}
                    onChange={(e) => setSelectedPathology(e.target.value + '')}
                    disabled={isEditing}
                    required
                  >
                    {circles.map((k) => (
                      <MenuItem key={k.key} value={k.value}>
                        {k.key}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box mb={3}>
                  <p style={{ marginLeft: '-2px' }}>{t('selectCategory')}</p>
                  <Select
                    style={{ width: '18%' }}
                    labelId="demo-simple-select-filled-label"
                    id={'category'}
                    key={'category'}
                    name={'category'}
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(+(e.target.value as number))
                      if (+(e.target.value as number) === 4) {
                        prepareVaccinesModal()
                      }
                    }}
                    variant={'outlined'}
                    disabled={isEditing}
                    required
                  >
                    {Object.keys(categoryTypes)
                      .filter(
                        (c) =>
                          !forbiddenCategories.includes(
                            categoryTypes[c as unknown as EventCategory]
                          )
                      )
                      .map((k) => (
                        <MenuItem key={k} value={k}>
                          {t(categoryTypes[k as unknown as EventCategory])}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
                {!isEditing && (
                  <Box component={'div'} mb={3}>
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      type={'button'}
                      label={t('add')}
                      handler={() => {
                        if (selectedCategory !== 4) {
                          setVaccines(undefined)
                        }
                        setOpenModal({ open: true })
                      }}
                    />
                  </Box>
                )}
                <Box component={'div'} mb={3}>
                  {Array.from(new Set(events.map((e) => e.month)))
                    .sort((a, b) => a - b)
                    .map((m) => {
                      return (
                        <ChipsToMonth
                          key={m}
                          events={events}
                          month={m}
                          handleOnDelete={deleteEvent}
                          handleOnClick={onClick}
                          isEditing={isEditing}
                        />
                      )
                    })}
                </Box>
                {errorMessage && (
                  <Box mb={3}>
                    <Alert severity="warning" key="errorMessage" id="errorMessage">
                      {t(errorMessage)}
                    </Alert>
                  </Box>
                )}
                <Box display={'flex'} justifyContent={'space-between'}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('cancel')}
                    handler={goBack}
                  />
                  {!isEditing && (
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      type={'submit'}
                      label={t('save')}
                      handler={() => {}}
                    />
                  )}
                </Box>
              </form>
              {openModal.open && (
                <Modal
                  openCalendarConfigEvent={openModal}
                  handleOnClose={() => {
                    setOpenModal({ open: false })
                  }}
                  setEvent={addOrEditEvent}
                  vaccines={vaccines}
                />
              )}
            </Box>
          </FormCard>
        </>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </Box>
  )
}

type ChipsToMonthProps = {
  events: PatientCalendarConfigEvent[]
  month: number
  handleOnDelete: (id: string) => void
  handleOnClick: (id: string, month: number, title: string) => void
  isEditing: boolean
}

const ChipsToMonth = (props: ChipsToMonthProps) => {
  const { t } = useTranslation()

  return (
    <Box component={'div'} className={styles.month}>
      <p>
        {t('month')} {props.month}
      </p>
      {props.events.map((pcce) => {
        if (pcce.month === props.month) {
          return (
            <Chip
              key={pcce.id}
              label={reduceString(pcce.title, 25)}
              onDelete={() => !props.isEditing && props.handleOnDelete(pcce.id)}
              onClick={() =>
                !props.isEditing && props.handleOnClick(pcce.id, pcce.month, pcce.title)
              }
              className={styles.chip}
              style={{ margin: '0 15px 15px 0' }}
              deleteIcon={props.isEditing ? <></> : <Cancel />}
            />
          )
        }
        return null
      })}
    </Box>
  )
}
