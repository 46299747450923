let basePath = process.env.REACT_APP_BASE_PATH
const rolePath = process.env.REACT_APP_ROLE_PATH
if (basePath && rolePath) basePath = basePath + rolePath
if (!basePath) basePath = rolePath

export const ROUTE_HOME = `${basePath}/`
export const ROUTE_CREATE = `create`
export const ROUTE_TEMPLATE = `template`
export const ROUTE_CIRCLES_BASE = `${basePath}`
export const ROUTE_CIRCLES = `${basePath}/`
export const ROUTE_USERS = `${basePath}/users`
export const ROUTE_CIRCLE_USERS = `${basePath}/circles/:id/users`
export const ROUTE_USER_ID = `${basePath}/users/:userID`
export const ROUTE_USER_PROFILE = `${basePath}/user-profile`
export const ROUTE_USER_PROFILE_EDIT = `${basePath}/user-profile-edit`
export const ROUTE_NOTIFICATIONS = `${basePath}/newsWall`

export const ROUTE_DASHBOARD = `${basePath}/dashboard`

export const ROUTE_DASHBOARD_PROFESSIONAL = `${basePath}/dashboard-profesional`
export const ROUTE_DASHBOARD_CASE_MANAGER = `${basePath}/dashboard-case-manager`
export const ROUTE_PATIENT_ACTIVE_PLAN = `${basePath}/dashboard/active-plan`
export const ROUTE_FORM_GENERATOR = `${basePath}/form-generator`
export const ROUTE_FORM_GENERATOR_FORM = `${basePath}/form-generator/form`
export const ROUTE_FORM_GENERATOR_FORM_EDIT = `${basePath}/form-generator/form/:id`
export const ROUTE_FORM_GENERATOR_FORM_REMOVE = `${basePath}/form-generator/remove`
export const ROUTE_FORM_GENERATOR_FORM_RESULTS = `${basePath}/form-generator/form/:id/results`
export const ROUTE_TEMPLATE_FORM_GENERATOR = `${basePath}/form-generator/form/create`
export const ROUTE_TEMPLATE_FORM_GENERATOR_EDIT = `${basePath}/form-generator/form/create/:id`
export const ROUTE_FORM_GENERATOR_CIRCLES = `${basePath}/form-generator/:id/circles`
export const ROUTE_PATIENT_FORMS = `${basePath}/forms`
export const ROUTE_PATIENT_FORMS_VIEW = `${basePath}/forms/:id`
export const ROUTE_MESSENGER = `${basePath}/messenger`
export const ROUTE_CALENDAR = `${basePath}/calendar`
export const ROUTE_CALENDAR_FORM = `${basePath}/calendar/form`
export const ROUTE_CALENDAR_FORM_EDIT = `${basePath}/calendar/form/:id`
export const ROUTE_ARTICLES = `${basePath}/articles`
export const ROUTE_SEGMENTED_NOTIFICATION = `${basePath}/segmented-notifications/:id`
export const ROUTE_SEGMENTED_NOTIFICATIONS = `${basePath}/segmented-notifications`
export const ROUTE_SEGMENTED_NOTIFICATIONS_FORM_EDIT = `${basePath}/segmented-notifications/form`
export const ROUTE_ADMIN_TUTORIAL_TEMPLATES = `${basePath}/admin-tutorial-templates`
export const ROUTE_ARTICLES_HISTORY = `${basePath}/articles-history`
export const ROUTE_TUTORIAL_CONTENT = `${basePath}/tutorial-content`
export const ROUTE_TUTORIALS_FORM = `${basePath}/tutorial-content/form`
export const ROUTE_TUTORIALS_FORM_EDIT = `${basePath}/tutorial-content/form/:id`
export const ROUTE_MY_POSTS_CONTENT = `${basePath}/my-posts`
export const ROUTE_ARTICLES_VALIDATE = `${basePath}/article-validate`
export const ROUTE_TEMPLATES_PRO_CONTENT = `${basePath}/templates-pro`
export const ROUTE_TEMPLATES_FORM = `${basePath}/templates-pro/form`
export const ROUTE_TEMPLATES_FORM_EDIT = `${basePath}/templates-pro/form/:id`
export const ROUTE_ADD_NEW_CIRCLE = `${basePath}/circle-configurator/add-new-circle`
export const ROUTE_ADD_NEW_CIRCLE_CIRCLE_CONFIGURATOR = `${basePath}/circle-configurator/add-new-circle-configurator`
export const ROUTE_ADD_NEW_CIRCLE_RESOURCES_CONFIGURATOR = `${basePath}/circle-configurator/add-new-circle-resources-configurator`
export const ROUTE_CIRCLE_CONFIGURATOR = `${basePath}/circle-configurator`
export const ROUTE_ADD_NEW_CIRCLE_FORM_CONFIGURATOR = `${basePath}/circle-configurator/add-new-circle-form-configurator`
export const ROUTE_ADD_NEW_CIRCLE_SYMPTOMS_CONFIGURATOR = `${basePath}/circle-configurator/add-new-circle-symptoms-configurator`
export const ROUTE_ARTICLE = `${basePath}/articles/:id`
export const ROUTE_ARTICLES_FORM = `${basePath}/articles/form`
export const ROUTE_ARTICLES_FORM_EDIT = `${basePath}/articles/form/:id`
export const ROUTE_TAG_LIST = `${basePath}/tags`
export const ROUTE_TAG_FORM = `${basePath}/tags/form`
export const ROUTE_TAG_EDIT = `${basePath}/tags/form/:id`
export const ROUTE_TAG_ARTICLES = `${basePath}/tag-articles`
export const ROUTE_CONFIGURATION = `${basePath}/configuration`
export const ROUTE_RESOURCES = `${basePath}/resources`
export const ROUTE_RESOURCES_ID = `${basePath}/resources/:id`
export const ROUTE_RESOURCES_SHARED_WITH_ME = `${basePath}/resources/shared`
export const ROUTE_RESOURCES_SHARED_WITH_ME_ID = `${basePath}/resources/shared/:id`
export const ROUTE_RESOURCES_FORM = `${basePath}/resources/form`
export const ROUTE_RESOURCES_FORM_EDIT = `${basePath}/resources/form/:id`
export const ROUTE_NOTIFICATIONS_CONFIG = `${basePath}/notifications-config`
export const ROUTE_SYMPTOMS_CONFIG = `${basePath}/symptoms-config`
export const ROUTE_DENTIST_CONFIG = `${basePath}/dentist-config`
export const ROUTE_ROLE_PERMISSIONS = `${basePath}/role-permissions`
export const ROUTE_PATIENT_DATA = `${basePath}/patient-data`
export const ROUTE_PATIENT_SYMPTOMS = `${basePath}/patient-data/symptoms`
export const ROUTE_PATIENT_SYMPTOMS_FORM = `${basePath}/patient-data/symptoms/form`
export const ROUTE_PATIENT_SYMPTOMS_FORM_EDIT = `${basePath}/patient-data/symptoms/form/:id`
export const ROUTE_PATHOLOGY_SYMPTOMS_FORM = `${basePath}/patient-data/pathology-symptoms/form`
export const ROUTE_PATHOLOGY_SYMPTOMS_FORM_EDIT = `${basePath}/patient-data/pathology-symptoms/form/:id`
export const ROUTE_TREATMENTS = `${basePath}/patient-data/treatments`
export const ROUTE_TREATMENTS_FORM = `${basePath}/patient-data/treatments/form`
export const ROUTE_TREATMENTS_FORM_EDIT = `${basePath}/patient-data/treatments/form/:id`
export const ROUTE_VACCINATION_SCHEDULE = `${basePath}/patient-data/vaccinationSchedule`
export const ROUTE_EVOLUTION_CONTROL = `${basePath}/patient-data/evolutionControl`
export const ROUTE_DENTIST_FORM = `${basePath}/patient-data/dentists/form`
export const ROUTE_DENTIST_FORM_EDIT = `${basePath}/patient-data/dentists/form/:id`
export const ROUTE_DENTIST_MAP = `${basePath}/patient-data/oralHealth`
export const ROUTE_DENTISTS_LIST = `${basePath}/dentists-list`
export const ROUTE_PATIENT_CALENDAR_CONFIG = `${basePath}/patient-calendar-config`
export const ROUTE_PATIENT_CALENDAR_CONFIG_FORM = `${basePath}/patient-calendar-config/form`
export const ROUTE_PATIENT_CALENDAR_CONFIG_FORM_EDIT = `${basePath}/patient-calendar-config/form/:id`
export const ROUTE_MEDICAL_DATA = `${basePath}/patient-data/medicalData`
export const ROUTE_FAMILIAR_DATA = `${basePath}/patient-data/familiar-data`
export const ROUTE_FAMILIAR_DATA_FORM = `${basePath}/patient-data/familiar-data/form`
export const ROUTE_FAMILIAR_DATA_FORM_EDIT = `${basePath}/patient-data/familiar-data/form/:id`
export const ROUTE_LANDMARK = `${basePath}/patient-data/landmarks`
export const ROUTE_LANDMARK_FORM = `${basePath}/patient-data/landmarks/form`
export const ROUTE_LANDMARK_FORM_EDIT = `${basePath}/patient-data/landmarks/form/:id`
export const ROUTE_GROWTH_MONITORING = `${basePath}/patient-data/growth-monitoring`
export const ROUTE_GROWTH_MONITORING_FORM = `${basePath}/patient-data/growth-monitoring/form`
export const ROUTE_GROWTH_MONITORING_FORM_EDIT = `${basePath}/patient-data/growth-monitoring/form/:id`
export const ROUTE_MOBILE_MENU = `${basePath}/mobile-menu`
export const ROUTE_WEARABLES = `${basePath}/patient-data/wearables`
export const ROUTE_GOALS_FORM = `${basePath}/patient-data/wearables/goals-form`
export const ROUTE_VACCINES = `${basePath}/vaccines`
export const ROUTE_VACCINES_CREATE = `${basePath}/create-vaccines`
export const ROUTE_EDIT_VACCINE = `${basePath}/vaccines/:id`
export const ROUTE_LINK_WEARABLE = `${basePath}/patient-data/wearables/link`
export const ROUTE_USERS_CREATE = `${basePath}/users/createParticipant`
export const ROUTE_CIRCLE_REGISTER = `${basePath}/register`
export const ROUTE_CHANGE_PASSWORD = `${basePath}/configuration/password-config`
export const ROUTE_SHARE_RESOURCES = `${basePath}/shareResources`
export const ROUTE_SHARE_RESOURCES_ID = `${basePath}/shareResources/:id`
export const ROUTE_SHARE_RESOURCES_CREATE = `${basePath}/shareResources/create`
export const ROUTE_SHARE_RESOURCES_FORM = `${basePath}/shareResources/form`
export const ROUTE_SHARE_RESOURCES_FORM_EDIT = `${basePath}/shareResources/form/:id`
export const ROUTE_SHARE_RESOURCES_ASSIGN = `${basePath}/shareResources/:id/circle`
export const ROUTE_PROFESSIONAL_CONFIG = `${basePath}/professionalType-config`
export const ROUTE_PROMS_PREMS = `${basePath}/prems-proms`
export const ROUTE_PROMS_PREMS_GENERATOR = `${basePath}/proms-prems-generator/form`
export const ROUTE_PROMS_PREMS_GENERATOR_FORM_EDIT = `${basePath}/prems-proms/form/:id`
export const ROUTE_PROMS_PREMS_GENERATOR_FORM = `${basePath}/prems-proms/form`
export const ROUTE_PROMS_PREMS_GENERATOR_FORM_REMOVE = `${basePath}/prems-proms/remove`
export const ROUTE_QUERY_CONFIG = `${basePath}/query-config`
export const ROUTE_QUERY = `${basePath}/query`
export const ROUTE_CIRCLE_CONFIG = `${basePath}/circle-config`
export const ROUTE_CIRCLE_FORM = `${basePath}/patient-data/circle/form`
export const ROUTE_CIRCLE_FORM_EDIT = `${basePath}/patient-data/circle/form/:id`
export const ROUTE_SPORTCENTRE_CONFIG = `${basePath}/sportCenter-config`
export const ROUTE_CITY_HALL_CONFIG = `${basePath}/cityHall-config`
export const ROUTE_EDIT_ACTIVE_CIRCLE_ADD_NEW_CIRCLE = `${basePath}/circle-configurator-edit-active-circle/add-new-circle`
export const ROUTE_EDIT_ACTIVE_CIRCLE_CIRCLE_CONFIGURATOR = `${basePath}/circle-configurator-edit-active-circle/add-new-circle-configurator`
export const ROUTE_EDIT_ACTIVE_CIRCLE_SYMPTOMS_CONFIGURATOR = `${basePath}/circle-configurator-edit-active-circle/add-new-circle-symptoms-configurator`
export const ROUTE_ACTIVE_PLAN_DATA = `${basePath}/patient-data/activePlan`
export const ROUTE_LEGAL_ADVICE = `${basePath}/legal-advice`
export const ROUTE_TELECONSULTATION = `${basePath}/teleconsultation`
