import { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { ShortDescription } from './ShortDescription'
import { Message } from 'modules/messenger_consulta/models/Message'
import { Searcher } from './Searcher'
import { Messages } from './Messages'
import { Header } from './Header'
import { Sender } from './Sender'
import 'dayjs'
import style from './Messenger.module.css'
import { CustomModal } from 'components/modal/CustomModal'
import { File } from 'modules/files/models/File'
import { reduceString } from 'common/utils/strings'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { getMessengerContainer } from 'container/messenger-module'
import { ConversationService } from 'modules/messenger/services/ConversationService'
import { CONVERSATION_SERVICE_KEY } from 'modules/messenger'
import { Query, QueryParam } from 'common/api/Query'
import { ConversationEditor } from './ConversationEditor'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getUserContainer } from 'container/user-module'
import { SwitchCustom } from './SwitchStyle'
import { getMessengerConsultaContainer } from 'container/messenger-consulta-module'
import { CONSULTA_SERVICE_KEY, PROFESSIONAL_QUERY_SERVICE_KEY } from 'modules/messenger_consulta'
import { ConsultaService } from 'modules/messenger_consulta/services/ConsultaService'
import { Consulta } from 'modules/messenger_consulta/models/Consulta'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { QueryUserGender } from 'modules/messenger_consulta/models/ConsultaUser'
import womanIcon from 'assets/chatIcons/woman.svg'
import maleIcon from 'assets/chatIcons/male.svg'
import {
  fromModel,
  ProfessionalQueryDTO,
} from 'modules/messenger_consulta/models/ProfessionalQueryDTO'
import { ProfessionalQueryService } from 'modules/messenger_consulta/services/ProfessionalQueryService'
import open_message_icon from 'assets/query/open_message.svg'
import { ShortDescriptionSkeleton } from './ShortDescriptionSkeleton'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { finalize } from 'rxjs/operators'
import { useLocation } from 'react-router-dom'

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const messengerContainer = getMessengerContainer()
const conversationsService = messengerContainer.get<ConversationService>(CONVERSATION_SERVICE_KEY)
const queryService = getMessengerConsultaContainer().get<ConsultaService>(CONSULTA_SERVICE_KEY)

export type QueryProps = {
  conversationID?: string
}

const professionalQueryService = getMessengerConsultaContainer().get<ProfessionalQueryService>(
  PROFESSIONAL_QUERY_SERVICE_KEY
)

export function QueryView(props: QueryProps): JSX.Element {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()
  const [currentConversation, setCurrentConversation] = useState<Consulta>()
  const [conversationCollection, setConversationCollection] = useState<Consulta[]>([])
  const [originalConversations, setOriginalConversations] = useState<Consulta[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [showClosedQuery, setShowClosedQuery] = useState<boolean>(false)
  const [professionalQuery, setProfessionalQuery] = useState<ProfessionalQueryDTO>()
  const { state } = useLocation()

  useEffect(() => {
    // si desde cuadro de manods seleccionamos "Consultas activas"
    // cuando lleguemos a esta pantalla , el chat se abrira automaticamente
    if (!state?.conversationId) return
    handleConversationIDPass(state.conversationId)
  }, [conversationCollection])

  useEffect(() => {
    if (!loggedUser) return
    professionalQueryService.getByUserID(loggedUser.id).subscribe((res) => {
      if (res) {
        setProfessionalQuery(fromModel(res))
      }

      initMessages()
    })
  }, [])

  const initMessages = () => {
    // Si el usuario logeado es profesionalSMS y no acepta consultas
    if (
      loggedUser?.roles.includes('professionalSMS') &&
      professionalQuery &&
      professionalQuery?.acceptConsulta === 0
    ) {
      return
    }
    setIsLoading(true)
    queryService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<Consulta>('userID', loggedUser?.id ?? ''),
            new QueryParam<Consulta>('circleID', selectedUserCircle?.circle?.id ?? ''),
            new QueryParam<Consulta>('userCircleID', selectedUserCircle?.id ?? ''),
          ],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setConversationCollection(res)
        setOriginalConversations(res)
        if (props.conversationID) {
          queryService
            .getByID(props.conversationID)
            .pipe(finalize(() => setIsLoading(false)))
            .subscribe((res) => {
              if (res) {
                setCurrentConversation(res)
              }
            })
        }
      })
  }

  const orderMessagesByCreationDate = (messages: Message[]): Message[] => {
    return [...messages].sort((m1, m2) => m1.createdAt.getTime() - m2.createdAt.getTime())
  }

  const handleConversationIDPass = (conversationID: string) => {
    const conversation = conversationCollection.find((c) => c.id === conversationID)
    if (conversation) {
      setCurrentConversation(conversation)
    }
  }

  const handleShortDescription = (conversationID: string | undefined) => {
    const conversation = conversationCollection.find((c) => c.id === conversationID)
    if (conversation) {
      conversation.unreadMessages = 0
      const newVal = currentConversation?.id !== conversationID ? conversation : undefined
      setCurrentConversation(newVal)
    }
  }

  const handleSearch = (s: string) => {
    const filteredConversations = originalConversations?.filter((c) => {
      const name = c.name.toLowerCase()
      return name.includes(s.toLowerCase())
    })
    if (filteredConversations) {
      setConversationCollection(filteredConversations)
    }
  }

  const handleSender = (m: Message, f: File[]) => {
    if (currentConversation) {
      const textMessage = cloneDeep<Message>(m)

      if (f.length === 0) {
        currentConversation.addMessage(textMessage)
      }

      f.forEach((file) => {
        const fileMessage = new Message({
          author: m.author,
          consultaID: m.consultaID,
          createdAt: new Date(),
          isPinned: m.isPinned,
          message2Users: m.message2Users,
          parentID: m.parentID,
          reactions: m.reactions ?? [],
          rootID: m.rootID,
          text: m.text,
          files: [file.id ?? ''],
        })
        currentConversation.addMessage(fileMessage)
        fileMessage.addFile(file.id ?? '')
      })

      const conversation = cloneDeep<Consulta>(currentConversation)

      setCurrentConversation(conversation)
      replaceConversationCollection(conversation)

      queryService.addMessage(m).subscribe()
    }
  }

  function replaceConversationCollection(conversation: Consulta) {
    const conversations = [...conversationCollection]

    const index = conversations.findIndex((c) => c.id === conversation.id)
    conversations.splice(index, 1, conversation)

    setConversationCollection(conversations)
  }

  const reverseConversationCollection = () => {
    setConversationCollection([...conversationCollection.reverse()])
  }

  const handleNewConversation = (): void => {
    setCurrentConversation(undefined)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSaveModal = (c: Consulta) => {
    setOpenModal(false)
    setCurrentConversation(undefined)
    queryService.add(c).subscribe((res) => {
      if (res) {
        setOriginalConversations([...originalConversations, res])
        setConversationCollection([...conversationCollection, res])
        setCurrentConversation(res)
      }
    })
  }

  const handleCloseQuery = () => {
    if (currentConversation) {
      currentConversation.closed = !currentConversation.closed
      setIsLoading(true)
      queryService.update(currentConversation).subscribe((res) => {
        setIsLoading(false)
        setCurrentConversation(undefined)
      })
    }
  }

  const handleEditConversation = () => {
    setOpenModal(true)
  }

  const handleDeleteConversation = () => {
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteEvent = () => {
    if (!currentConversation?.id) {
      return
    }
    conversationsService.delete(currentConversation.id).subscribe((_) => {
      setIsLoading(true)
      setOriginalConversations(
        originalConversations?.filter((c) => c.id !== currentConversation.id)
      )
      setConversationCollection(
        conversationCollection.filter((c) => c.id !== currentConversation.id)
      )
      setCurrentConversation(undefined)
    })
    setOpenModal(false)
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  const handlerBacktoList = () => {
    setCurrentConversation(undefined)
  }

  const isProfessional = (): boolean => {
    if (
      typeof selectedUserCircle?.profSmsID === 'string' &&
      selectedUserCircle.profSmsID === loggedUser?.id
    ) {
      return true
    }
    if (loggedUser?.roles.some((role) => role.startsWith('manager'))) {
      return true
    }
    return selectedUserCircle?.profSmsID.includes(loggedUser?.id ?? '') ?? false
  }

  const handleSwitchChange = () => {
    setShowClosedQuery(!showClosedQuery)
    setCurrentConversation(undefined)
    initMessages()
  }

  return (
    <>
      {isLoading && (
        <div className={style.messengerContainer}>
          <Box className={style.conversationWidget}>
            <Box
              component="section"
              className={
                window.innerWidth > 599 || !currentConversation
                  ? style.conversationWidget
                  : style.displayNone
              }
            >
              <Box
                className={style.searcherContainer}
                component="section"
                display="flex"
                flexDirection={'column'}
                justifyContent="space-between"
              >
                <Searcher
                  disabled
                  handler={handleSearch}
                  reverse={reverseConversationCollection}
                  handleNewConversation={handleNewConversation}
                />
                <Box className={style.switchButtonContainer}>
                  <p>{t('showQueriesClosed')}</p>
                  <SwitchCustom disabled checked={showClosedQuery} onClick={handleSwitchChange} />
                </Box>
                <Box
                  className={style.newConversationBox}
                  visibility={!isProfessional() ? 'visible' : 'hidden'}
                >
                  <AppButton
                    disabled
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    label={t('newQuery')}
                    handler={handleNewConversation}
                  />
                </Box>

                <Box className={style.chatList} component="section">
                  <ShortDescriptionSkeleton />
                  <ShortDescriptionSkeleton />
                  <ShortDescriptionSkeleton />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={style.emptyConversation}>
            <img
              className={style.imgCreateOrSelectConversation}
              src={open_message_icon}
              width={34}
              height={34}
              alt="Open conversation image"
            />
            <p className={style.labelCreateOrSelectConversation}>{t('loadingQueries')}</p>
            <div className={style.orangeUnderscorePulse}></div>
          </Box>
        </div>
      )}
      {!isLoading && (
        <div className={style.messengerContainer}>
          <Box
            className={
              window.innerWidth < 599 && currentConversation
                ? style.backButtonContaienr
                : style.displayNone
            }
          >
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('back')}
              handler={handlerBacktoList}
            />
          </Box>
          <Box
            component="section"
            className={
              window.innerWidth > 599 || !currentConversation
                ? style.conversationWidget
                : style.displayNone
            }
          >
            <Box
              className={style.searcherContainer}
              component="section"
              display="flex"
              flexDirection={'column'}
              justifyContent="space-between"
            >
              <Searcher
                handler={handleSearch}
                reverse={reverseConversationCollection}
                handleNewConversation={handleNewConversation}
              />
              <Box className={style.switchButtonContainer}>
                <p>{t('showQueriesClosed')}</p>
                <SwitchCustom checked={showClosedQuery} onClick={handleSwitchChange} />
              </Box>
              <Box
                className={style.newConversationBox}
                visibility={!isProfessional() ? 'visible' : 'hidden'}
              >
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('newQuery')}
                  handler={handleNewConversation}
                />
              </Box>
            </Box>

            <Box className={style.chatList} component="section">
              {conversationCollection.map((c) => {
                const messages = orderMessagesByCreationDate(c.messages)
                const lengthLimit = 70
                const lastMessage =
                  c.messages.length === 0
                    ? ''
                    : reduceString(messages[messages.length - 1].text, lengthLimit)
                const conversationName = reduceString(c.name, lengthLimit)
                if ((showClosedQuery && !c.closed) || (!showClosedQuery && c.closed)) {
                  return <></>
                } else {
                  return (
                    <ShortDescription
                      key={c.id}
                      conversationID={c.id}
                      conversationName={conversationName}
                      conversationDescription={c.description}
                      avatarUrl={
                        c.users[0].userGender === QueryUserGender.female ? womanIcon : maleIcon
                      }
                      description={c.description}
                      lastMessage={lastMessage}
                      lastMessageDate={
                        messages.length > 0 ? messages[messages.length - 1].createdAt : undefined
                      }
                      notReadMessages={c.unreadMessages}
                      handlerConversation={handleShortDescription}
                      isCurrentConversation={c.id === currentConversation?.id}
                    />
                  )
                }
              })}
            </Box>
          </Box>
          {currentConversation && (
            <>
              <Box className={style.messengerWidget}>
                <Box className={style.headerContainer}>
                  <Header
                    headerName={currentConversation.name || ''}
                    avatarUrl={
                      currentConversation.users[0].userGender === QueryUserGender.female
                        ? womanIcon
                        : maleIcon
                    }
                    creatorID={currentConversation.users.find((u) => !u.isAdmin)?.userID ?? ''}
                    description={currentConversation.description || ''}
                    handleEdit={handleEditConversation}
                    handleDelete={handleDeleteConversation}
                    handleCloseQuery={handleCloseQuery}
                    currentConversation={currentConversation}
                    conversationUsers={currentConversation.users.map((cu) => cu.userID)}
                  />
                </Box>

                <Box className={style.messagesContainer} flexGrow={1}>
                  <Messages
                    messages={currentConversation.messages || []}
                    ConsultaUsers={currentConversation.users}
                  />
                </Box>

                {window.innerWidth > 599 && (
                  <Box>
                    <Sender
                      handler={handleSender}
                      conversationId={currentConversation.id ?? ''}
                      conversationClosed={currentConversation.closed}
                    />
                  </Box>
                )}
              </Box>
              {window.innerWidth < 599 && (
                <Box className={style.senderMobile}>
                  <Sender
                    handler={handleSender}
                    conversationId={currentConversation.id ?? ''}
                    conversationClosed={currentConversation.closed}
                  />
                </Box>
              )}
            </>
          )}
          {!currentConversation && (
            <Box className={style.emptyConversation}>
              <img
                className={style.imgCreateOrSelectConversation}
                src={open_message_icon}
                width={34}
                height={34}
                alt="Open conversation image"
              />
              <p className={style.labelCreateOrSelectConversation}>{t('createOrSelectQuery')}</p>
              <div className={style.orangeUnderscore}></div>
            </Box>
          )}
          <Modal
            open={openDeleteModal}
            className={style.eventModal}
            onClose={handleCloseDeleteModal}
          >
            <CustomModal
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteEvent}
              title={t('deleteConversation')}
              warningText={t('irreversibleConversationAction')}
            />
          </Modal>
        </div>
      )}
      <Modal
        className={style.modalNewConversation}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConversationEditor
          query={currentConversation}
          handleClose={handleCloseModal}
          handleSave={handleSaveModal}
        />
      </Modal>
    </>
  )
}
