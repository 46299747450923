import {
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  startOfMonth,
  differenceInDays,
  parseISO,
  addDays,
} from 'date-fns'
import { es } from 'date-fns/locale'
import { EventCategory } from 'modules/calendar/enums/EventCategory'
import { getIconFromSymptomIDIcon } from './SpecificSymptomIconsEnum'
import generalSymptom from 'assets/calendar/general_symptom.svg'
import othersIcon from 'assets/calendar/Otros.svg'
import teleconsultationIcon from 'assets/events_icons/bola-teleconsulta.svg'
import sportGray from 'assets/calendar/sport_gray.svg'
import sportGreen from 'assets/calendar/sport_green.svg'
import sportRed from 'assets/calendar/sport_red.svg'
import foodIcon from 'assets/calendar/Comida.svg'
import landmarks from 'assets/calendar/Icono_hitos.svg'
import bucodental from 'assets/calendar/bucodental.svg'
import advices from 'assets/calendar/Consejos.svg'
import vaccines from 'assets/calendar/Vacunas.svg'
import treatments from 'assets/calendar/treatments.svg'
import epilecticCrisis from 'assets/calendar/epileptic_crisis.svg'
import diaBueno from '../../assets/neuropediatria_icon/dia-bueno.svg'
import diaRegular from '../../assets/neuropediatria_icon/dia-regular.svg'
import diaMalo from '../../assets/neuropediatria_icon/dia-malo.svg'
import medicationIcon from '../../assets/neuropediatria_icon/medicacion.svg'
import personalEvent from 'assets/calendar/personalEvent.svg'
import appointmentIcon from 'assets/events_icons/ico-appointment.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useMemo, useState, useEffect } from 'react'
import { AllCalendarEventDTO } from 'modules/calendar/models/AllCalendarEventDTO'
import { useTranslation } from 'react-i18next'
import styles from './MonthView.module.css'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  useMediaQuery,
  Modal,
} from '@mui/material'
import { CalendarTooltip } from './CalendarTooltip'
import { CalendarViewProps } from './types'
import { WEEK_DAYS } from 'common/const'
import { useCalendarEvents } from 'hooks/calendarEvents/useCalendarEvents'
import { CalendarListModal } from './CalendarModalList'
import { LoadingMonthView } from './LoadingMonthView'

const MAXNUMBEREVENTS = 8

export function MonthView(props: CalendarViewProps) {
  const [currentDate, setCurrentDate] = useState(props.selectedDate)
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 599px)')
  const firstDayOfMonth = startOfMonth(currentDate)
  const lastDayOfMonth = endOfMonth(currentDate)
  const daysInMonth = eachDayOfInterval({ start: firstDayOfMonth, end: lastDayOfMonth })
  const startingDayIndex = (getDay(firstDayOfMonth) + 6) % 7
  const [, setTrigger] = useState(0)
  const { calendarEvents, refreshCalendarEvents, setRefreshCalendarEvents, isLoading } =
    useCalendarEvents()
  // const [filteredEvents, setFilteredEvents] = useState<AllCalendarEventDTO[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedDayEvents, setSelectedDayEvents] = useState<AllCalendarEventDTO[] | undefined>(
    undefined
  )

  const rowsCalendar: Number[] = Array.from({
    length: Math.ceil((daysInMonth.length + startingDayIndex) / 7),
  })
  const colsCalendar: Number[] = Array.from({ length: 7 })
  // const { selectedUserCircle } = useCircleConfiguration()
  // const [loading, setIsLoading] = useState(false)

  useEffect(() => {
    setCurrentDate(props.selectedDate)
  }, [props.selectedDate])

  useEffect(() => {
    if (refreshCalendarEvents) {
      setTrigger((prev) => prev + 1) // forzamos re-render
      setRefreshCalendarEvents(false)
    }
  }, [refreshCalendarEvents])

  const handlePreviousMonth = () => {
    props.handlePreviousMonth()
  }
  const handleNextMonth = () => {
    props.handleNextMonth()
  }

  const wDays = WEEK_DAYS.map((d) => t(d))

  const upperCaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  type RelevantEventCategory = Exclude<
    EventCategory,
    EventCategory.Medication | EventCategory.Reviews
  >

  type IconMap = { [key in RelevantEventCategory]: (idIcon: string) => string | undefined }

  const iconMap: IconMap = {
    [EventCategory.Symptoms]: (idIcon: string) =>
      idIcon !== '' ? getIconFromSymptomIDIcon(idIcon) : generalSymptom,
    [EventCategory.EpilepticCrisis]: (idIcon: string) =>
      idIcon !== '' ? getIconFromSymptomIDIcon(idIcon) : undefined,
    [EventCategory.Treatments]: () => treatments,
    [EventCategory.PersonalEvent]: () => personalEvent,
    [EventCategory.Appointment]: () => appointmentIcon,
    [EventCategory.Others]: () => othersIcon,
    [EventCategory.TrainingPending]: () => sportGray,
    [EventCategory.TrainingAbsent]: () => sportRed,
    [EventCategory.TrainingAssisted]: () => sportGreen,
    [EventCategory.Food]: () => foodIcon,
    [EventCategory.Advices]: () => advices,
    [EventCategory.Landmarks]: () => landmarks,
    [EventCategory.OralHealth]: () => bucodental,
    [EventCategory.Vaccines]: () => vaccines,
    [EventCategory.Teleconsultation]: () => teleconsultationIcon,
  }

  const drawIcons = (event: AllCalendarEventDTO) => {
    const getImage = iconMap[event.eventCategory as RelevantEventCategory]
    const image = getImage ? getImage(event.idIcon) : othersIcon
    return (
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <img src={image} alt="Event Icon" className={styles.iconDay} />
        <div className={styles.iconDayBall} />
      </div>
    )
  }

  const getCrisisIcon = (crisisEvents: AllCalendarEventDTO[]) => {
    if (!crisisEvents.length) return null

    const moodIcons = {
      1: diaBueno,
      2: diaRegular,
      3: diaMalo,
    }

    const validMoodEvent = crisisEvents.find(
      (event) =>
        event.crisisDetails?.epilepticalMood !== null &&
        event.crisisDetails?.epilepticalMood !== undefined
    )

    if (!validMoodEvent) {
      return null
    }

    const moodValue = validMoodEvent.crisisDetails.epilepticalMood as keyof typeof moodIcons

    return moodIcons[moodValue] || null
  }

  const hasMedication = (crisisEvents: AllCalendarEventDTO[]) => {
    if (!crisisEvents.length) return null

    const hasMedicationEvent = crisisEvents.some((event) => event.crisisDetails?.medication > 0)

    return hasMedicationEvent ? medicationIcon : null
  }

  const eventsByDate = useMemo(() => {
    if (!calendarEvents || calendarEvents.length === 0) return {}

    return calendarEvents.reduce(
      (acc: Record<string, AllCalendarEventDTO[]>, event) => {
        const startDate =
          typeof event.startDate === 'string' ? parseISO(event.startDate) : event.startDate
        const endDate =
          typeof event.finishDate === 'string' ? parseISO(event.finishDate) : event.finishDate

        const daysDifference = differenceInDays(endDate, startDate)

        for (let i = 0; i <= daysDifference; i++) {
          const dateKey = format(addDays(startDate, i), 'yyyy-MM-dd', { locale: es })

          if (!acc[dateKey]) {
            acc[dateKey] = []
          }

          acc[dateKey].push(event)
        }

        return acc
      },
      {} satisfies Record<string, AllCalendarEventDTO[]>
    )
  }, [calendarEvents])

  if (isLoading) return <LoadingMonthView />

  const handleOpenModal = (events: AllCalendarEventDTO[]) => {
    if (isMobile && events.length > 0) {
      setSelectedDayEvents(events)
      setOpenModal(true)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setSelectedDayEvents(undefined)
  }

  return (
    <>
      <Modal open={openModal} className={styles.eventModal} onClose={handleCloseModal}>
        <CalendarListModal events={selectedDayEvents} handleClose={handleCloseModal} />
      </Modal>
      <div className={styles.arrowsMonth}>
        {props.canChangeMonth && (
          <button className={styles.styleArrow} onClick={handlePreviousMonth}>
            <ArrowBackIosIcon />
          </button>
        )}
        {upperCaseFirstLetter(format(currentDate, 'MMMM yyyy', { locale: es }))}

        {props.canChangeMonth && (
          <button className={styles.styleArrow} onClick={handleNextMonth}>
            <ArrowForwardIosIcon />
          </button>
        )}
      </div>

      <TableContainer className={styles.tabRow}>
        <Table>
          <TableHead className={styles.tabHeader}>
            <TableRow className={styles.tableRowMobile}>
              {wDays.map((day, i) => {
                const showDay = isMobile ? day.slice(0, 3) : day
                return (
                  <TableCell className={styles.dateName} key={day + i}>
                    <p className={styles.weekDay}>{showDay}</p>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsCalendar.map((row, rowIndex) => (
              <TableRow key={`row-${rowIndex}`}>
                {/*  celdas para cada día */}
                {colsCalendar.map((col, cellIndex) => {
                  const dayIndex = rowIndex * 7 + cellIndex - startingDayIndex
                  if (dayIndex >= 0 && dayIndex < daysInMonth.length) {
                    const day = daysInMonth[dayIndex]
                    const dateKey = format(day, 'yyyy-MM-dd')
                    const todaysEvents = eventsByDate[dateKey] || []

                    const eventsToRender =
                      todaysEvents.length < MAXNUMBEREVENTS
                        ? todaysEvents
                        : todaysEvents.slice(0, 4)

                    const crisisEvents = todaysEvents.filter(
                      (ev) => ev.eventCategory === EventCategory.EpilepticCrisis
                    )
                    const crisisCount = crisisEvents.length
                    const nonCrisisEventsToRender = eventsToRender.filter(
                      (event) => event.eventCategory !== EventCategory.EpilepticCrisis
                    )
                    const crisisIconForDay = getCrisisIcon(crisisEvents)
                    const medicationIconToShow = hasMedication(crisisEvents)

                    return (
                      <Tooltip
                        key={day.toString()}
                        title={!isMobile ? <CalendarTooltip events={todaysEvents} /> : <></>}
                        arrow={true}
                        placement={
                          cellIndex === 5 ||
                          cellIndex === 12 ||
                          cellIndex === 19 ||
                          cellIndex === 26 ||
                          cellIndex === 33
                            ? 'left'
                            : 'right'
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: 'white',
                              boxShadow: '1px 1px 5px 3px transparent',
                              '& .MuiTooltip-arrow': { color: 'white' },
                            },
                          },
                        }}
                      >
                        <TableCell
                          className={styles.tabCell}
                          onClick={() => handleOpenModal(eventsToRender)}
                        >
                          {/* Mostrar el número del día */}
                          <Box className={styles.date}>
                            <div className={styles.dataDivNumber}>{format(day, 'd')}</div>
                          </Box>
                          {eventsToRender.length > 0 && (
                            <Box className={styles.containerRow}>
                              {isMobile ? (
                                <div>{drawIcons(eventsToRender[0])}</div>
                              ) : (
                                <>
                                  {/* Ícono de crisis con contador */}
                                  {crisisCount > 0 && (
                                    <div className={styles.crisisContainer}>
                                      <span className={styles.crisisCount}>{crisisCount}</span>
                                      <img
                                        src={epilecticCrisis} // Ícono por defecto para crisis
                                        alt="Crisis Icon"
                                        className={styles.iconDay}
                                      />
                                      {crisisIconForDay && (
                                        <img src={crisisIconForDay} alt="Crisis Severity Icon" />
                                      )}
                                      {medicationIconToShow && (
                                        <img src={medicationIcon} alt="Medication Icon" />
                                      )}
                                    </div>
                                  )}
                                  {/* Resto de los eventos */}
                                  {nonCrisisEventsToRender.map((event) => (
                                    <div key={event.id}>{drawIcons(event)}</div>
                                  ))}
                                  {todaysEvents.length >= MAXNUMBEREVENTS && (
                                    <p className={styles.puntitos}>...</p>
                                  )}
                                </>
                              )}
                            </Box>
                          )}
                        </TableCell>
                      </Tooltip>
                    )
                  }
                  return <TableCell key={`empty-${cellIndex}`} className={styles.tabCell} />
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
