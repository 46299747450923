import { Box, Modal, Typography, Checkbox, FormControlLabel, TextField } from '@mui/material'
import React, { useState } from 'react'
import style from './RefuseUserModal.module.css'
import { AppButton, ButtonTheme } from '../../../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import { Header } from '../../../../components/header/Header'
import iconClose from '../../../../assets/dashboard/closeIcon.svg'
import checkboxCuadrado from 'assets/newCircle/checkbox-cuadrado.svg'
import checkBoxChecked from 'assets/newCircle/checkbox-cuadrado-ok.svg'

interface SelectedItem {
  id: string
  status: string
  reason: string
}

interface RefuseUserModalProps {
  open: boolean
  onClose: () => void

  setSelectedItem: React.Dispatch<
    React.SetStateAction<
      {
        id: string
        status: string
        reason: string
      }[]
    >
  >

  selectedItem: SelectedItem[]
}

export const RefuseUserModal: React.FC<RefuseUserModalProps> = ({
  open,
  onClose,
  setSelectedItem,
  selectedItem,
}) => {
  const { t } = useTranslation()

  const [selectedReason, setSelectedReason] = useState<string>('')
  const [otherReason, setOtherReason] = useState<string>('')

  const handleReasonChange = (reason: string) => {
    setSelectedReason(reason)
    if (reason !== 'other') setOtherReason('')
  }

  return (
    <Modal
      className={style.modalContainer}
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: { backgroundColor: 'transparent' },
      }}
    >
      <Box className={style.content}>
        <Box className={style.iconContainer}>
          <img className={style.icon} src={iconClose} alt={'icon close'} onClick={onClose} />
        </Box>
        <Header label={t('disableUser')} />
        <Typography className={style.description}>{t('selectReason')}</Typography>

        <Box className={style.checkboxContainer}>
          <FormControlLabel
            className={style.checkbox}
            control={
              <Checkbox
                checked={selectedReason === 'programFinished'}
                onChange={() => handleReasonChange('programFinished')}
                icon={<img src={checkboxCuadrado} />}
                checkedIcon={<img src={checkBoxChecked} />}
              />
            }
            label={t('programFinished')}
          />
          <FormControlLabel
            className={style.checkbox}
            control={
              <Checkbox
                checked={selectedReason === 'lackOfAssistance'}
                onChange={() => handleReasonChange('lackOfAssistance')}
                icon={<img src={checkboxCuadrado} />}
                checkedIcon={<img src={checkBoxChecked} />}
              />
            }
            label={t('lackOfAssistance')}
          />
          <FormControlLabel
            className={style.checkbox}
            control={
              <Checkbox
                checked={selectedReason === 'others'}
                onChange={() => handleReasonChange('others')}
                icon={<img src={checkboxCuadrado} />}
                checkedIcon={<img src={checkBoxChecked} />}
              />
            }
            label={t('others')}
          />
        </Box>

        {selectedReason === 'others' && (
          <TextField
            className={style.otherInput}
            placeholder={t('indicateReason')}
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            fullWidth
          />
        )}

        <Box className={style.buttonContainer}>
          <AppButton
            label={t('save')}
            type={'button'}
            theme={ButtonTheme.NewPrimary}
            handler={() => {
              const updatedItems = selectedItem.map((item) => {
                return {
                  ...item,
                  reason: selectedReason !== 'others' ? selectedReason : otherReason,
                }
              })
              setSelectedItem(updatedItems)
              onClose()
            }}
          />
        </Box>
      </Box>
    </Modal>
  )
}
