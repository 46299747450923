import { Report } from './Report'

export interface ReportDTO {
  id?: string
  createdByUserID: string
  userCircleID: string
  createdAt: Date
  startDate: Date
  finishDate: Date
  generalInformation: boolean
  specificInformation: boolean
  rcvValoration: boolean
  sessionsAttendance: boolean
  avgAttendanceMonth: boolean
  avgIntensityMonth: boolean
  anthropometricData: boolean
  physicalCondition: boolean
  healthFormulary: boolean
  efdComments: boolean
  previsualization: boolean
  createdBy: string
}

export function emptyReportDTO() {
  return {
    id: '',
    createdByUserID: '',
    userCircleID: '',
    createdAt: new Date(),
    startDate: new Date(),
    finishDate: new Date(),
    generalInformation: '',
    specificInformation: '',
    rcvValoration: '',
    sessionsAttendance: '',
    avgAttendanceMonth: '',
    avgIntensityMonth: '',
    anthropometricData: '',
    physicalCondition: '',
    healthFormulary: '',
    efdComments: '',
    previsualization: '',
    createdBy: '',
  }
}

export function fromModel(e: Report): ReportDTO {
  return {
    id: e.id,
    createdByUserID: e.createdByUserID,
    userCircleID: e.userCircleID,
    createdAt: e.createdAt,
    startDate: e.startDate,
    finishDate: e.finishDate,
    generalInformation: e.generalInformation,
    specificInformation: e.specificInformation,
    rcvValoration: e.rcvValoration,
    sessionsAttendance: e.sessionsAttendance,
    avgAttendanceMonth: e.avgAttendanceMonth,
    avgIntensityMonth: e.avgIntensityMonth,
    anthropometricData: e.anthropometricData,
    physicalCondition: e.physicalCondition,
    healthFormulary: e.healthFormulary,
    efdComments: e.efdComments,
    previsualization: true,
    createdBy: e.createdBy,
  }
}

export function toModel(d: ReportDTO): Report {
  return new Report(d)
}

export function toModelArray(activePlanArr: ReportDTO[]): Report[] {
  const auxArray: Report[] = []
  activePlanArr.forEach((value) => auxArray.push(toModel(value)))
  return auxArray
}
