import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Alert,
  InputLabel,
} from '@mui/material'
import styles from './ModalNewCrisis.module.css'
import icoDelete from 'assets/resource_icons/ico-eliminar.svg'
import React, { Dispatch, FormEvent, SetStateAction } from 'react'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { UserEpilepticalConfigDTO } from 'modules/epileptical/models/UserEpilepticalConfigDTO'
import allBody from 'assets/neuropediatria_icon/01-cuerpo-todo.svg'
import rightBody from 'assets/neuropediatria_icon/03-cuerpo-derecha.svg'
import leftBody from 'assets/neuropediatria_icon/02-cuerpo-izquierda.svg'
import extremityBody from 'assets/neuropediatria_icon/04-cuerpo-extremidad.svg'
import headBody from 'assets/neuropediatria_icon/05-cuerpo-cabeza.svg'
import undefinedBody from 'assets/neuropediatria_icon/06-cuerpo-sindefinir.svg'
import eyesUpIcon from 'assets/neuropediatria_icon/ojo1.svg'
import eyesCloseIcon from 'assets/neuropediatria_icon/ojo2.svg'
import eyesRightIcon from 'assets/neuropediatria_icon/ojo3.svg'
import eyesLeftIcon from 'assets/neuropediatria_icon/ojo4.svg'
import eyesStareIcon from 'assets/neuropediatria_icon/ojo6.svg'
import eyesBlinkIcon from 'assets/neuropediatria_icon/ojo5.svg'
import { durationTypes } from 'modules/epileptical/enums/Duration'
import { crisisTypeTypes } from 'modules/epileptical/enums/CrisisType'
import { ItemList } from 'common/models/ItemList'
import { UserEpilepticalConfig } from 'modules/epileptical/models/UserEpilepticalConfig'
import { awarenessTypes } from 'modules/epileptical/enums/Awareness'
import { movementTypes } from 'modules/epileptical/enums/Movement'
import { zoneTypes } from 'modules/epileptical/enums/Zone'
import { visionTypes } from 'modules/epileptical/enums/Vision'
import { skinColorTypes } from 'modules/epileptical/enums/SkinColor'
import { symptomTypes } from 'modules/epileptical/enums/Symptom'
import { mouthTypes } from 'modules/epileptical/enums/Mouth'
import { afterDurationTypes } from 'modules/epileptical/enums/AfterDuration'

export type ModalNewCrisisProps = {
  readonly open: boolean
  readonly handleClose: () => void
  readonly innerWidth: number
  color: string | undefined
  durationNewCrisisType: string
  commentNewCrisisType: string
  selectedColor: string[]
  crisisZone: string
  intensityRadioButton: string | undefined
  newType: UserEpilepticalConfigDTO
  index: number
  handleTypeCrisis: (value: string, index: number) => void
  handleIntensityCrisisType: (value: string) => void
  handleCommentNewCrisisType: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleDurationNewCrisisType: (value: string) => void
  handleCrisisZone: (value: string) => void
  handlerMovement: (value: string) => void
  handlerVision: (value: string) => void
  handlerSkinColor: (value: string) => void
  handlerSymptom: (value: string) => void
  handlerMouth: (value: string) => void
  handlerAfterDuration: (value: string) => void
  handlerAfterSymptom: (index: number) => void
  handlerAwareness: (value: string) => void
  otherSymptoms: string[]
  checkedState: any[]
  saveNewCrisisType: (e: FormEvent<HTMLFormElement>) => void
  handleColorTypeCrisis: () => void
  errorMessage: string
  handleEdit: (color: string) => void // este prop no se usa relamente
  epilepticConfig: ItemList<UserEpilepticalConfig>
  colorSelected: string
  setColorSelected: Dispatch<SetStateAction<string>>
}

export function ModalNewCrisis(props: ModalNewCrisisProps) {
  const { t } = useTranslation()

  function ShowRadios(color: string) {
    const colorPresent = props.epilepticConfig.items.some((item) => item.color === color)
    if (colorPresent) {
      return null // If color is present in items, return null to skip rendering
    }
    return (
      <FormControlLabel
        sx={{ fontFamily: 'Poppins' }}
        value={color}
        control={<Radio />}
        label={
          <Box
            style={{
              backgroundColor: color,
            }}
            className={styles.boxLevel}
          />
        }
      />
    )
  }

  // FIXME unmounted
  if (props.color) {
    props.newType.color = props.color
  }

  return (
    <Modal open={props.open} onClose={props.handleClose} className={styles.modal}>
      <Box className={styles.modalContainer}>
        <Box className={styles.formModal}>
          <Box className={styles.contentHeader}>
            <h1 className={styles.titleNewCrisis}>{t('newCrisisType')}</h1>
            <img style={{ cursor: 'pointer' }} onClick={props.handleClose} src={icoDelete} />
          </Box>
          <Box className={styles.containerRadioButton}>
            <Box>
              <h2>{t('crisisIntensity')}</h2>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={crisisTypeTypes()[props.newType.crisisType] ?? ' '}
                onChange={(event) => {
                  props.handleIntensityCrisisType(event.target.value)
                }}
              >
                <FormControlLabel value={t('low')} control={<Radio />} label={t('low')} />
                <FormControlLabel value={t('medium')} control={<Radio />} label={t('medium')} />
                <FormControlLabel value={t('strong')} control={<Radio />} label={t('strong')} />
              </RadioGroup>
            </Box>
            <Box>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={props.newType.color ?? ' '}
                onChange={(event) => {
                  props.handleTypeCrisis(event.target.value, props.index)
                }}
              >
                {props.newType.crisisType !== -1 && props.selectedColor.length > 0 && (
                  <>
                    <h2>{t('selectColor')}</h2>
                    {props.selectedColor
                      .filter(
                        (color) => !props.epilepticConfig.items.some((item) => item.color === color)
                      )
                      .map((color) => ShowRadios(color))}
                  </>
                )}
              </RadioGroup>
            </Box>
          </Box>
          <Box>
            <h2>{t('beforeTheCrisis')}</h2>
            <h3>{t('childCrisisComment')}</h3>
            <Box mb={3}>
              <TextFieldItem
                field="comments"
                value={props.newType.comment ?? ' '}
                label={''}
                type={'text'}
                handleChange={(e) => {
                  props.handleCommentNewCrisisType(e)
                }}
                rows={2}
                required={false}
                maxLength={255}
              />
            </Box>
          </Box>
          <Divider className={styles.divider} />
          <h2>{t('duringTheCrisis')}</h2>
          <Box className={styles.containerWithSelect}>
            <Box>
              <h3>{t('crisisDuration')}</h3>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={durationTypes()[props.newType.duration] ?? ' '}
                onChange={(event) => {
                  props.handleDurationNewCrisisType(event.target.value)
                }}
              >
                <FormControlLabel value={t('oneMin')} control={<Radio />} label={t('oneMin')} />
                <FormControlLabel
                  value={t('oneToFiveMin')}
                  control={<Radio />}
                  label={t('oneToFiveMin')}
                />
                <FormControlLabel value={t('FiveMin')} control={<Radio />} label={t('FiveMin')} />
              </RadioGroup>
            </Box>
            <Box>
              <FormControl fullWidth style={{ marginTop: '1em' }}>
                <InputLabel shrink className={styles.textSelect} htmlFor="select">
                  {t('awareness')}
                </InputLabel>
                <Select
                  className={styles.inputSelect}
                  defaultValue={''}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={awarenessTypes()[props.newType.awareness] ?? ' '}
                  label="awareness"
                  onChange={(e) => {
                    props.handlerAwareness(e.target.value)
                  }}
                >
                  <MenuItem value={t('fully')}>{t('fully')}</MenuItem>
                  <MenuItem value={t('confused')}>{t('confused')}</MenuItem>
                  <MenuItem value={t('respondsVoice')}>{t('respondsVoice')}</MenuItem>
                  <MenuItem value={t('unconscious')}>{t('unconscious')}</MenuItem>
                  <MenuItem value={t('undefined')}>{t('undefined')}</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box>
              <FormControl fullWidth style={{ marginTop: '1em' }}>
                <InputLabel shrink className={styles.textSelect} htmlFor="select">
                  {t('movement')}
                </InputLabel>
                <Select
                  className={styles.inputSelect}
                  defaultValue={''}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={movementTypes()[props.newType.movement] ?? ' '}
                  label="movement"
                  onChange={(e) => {
                    props.handlerMovement(e.target.value)
                  }}
                >
                  <MenuItem value={t('jolting')}>{t('jolting')}</MenuItem>
                  <MenuItem value={t('rigidity')}>{t('rigidity')}</MenuItem>
                  <MenuItem value={t('rigidityJolting')}>{t('rigidityJolting')}</MenuItem>
                  <MenuItem value={t('automatism')}>{t('automatism')}</MenuItem>
                  <MenuItem value={t('hypotonia')}>{t('hypotonia')}</MenuItem>
                  <MenuItem value={t('undefined')}>{t('undefined')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            className={
              props.innerWidth > 598
                ? styles.containerRadioButton
                : styles.containerRadioButtonMobile
            }
          >
            <Box>
              <h3>{t('bodyZone')}</h3>
              <FormControl component="fieldset">
                <RadioGroup
                  row={window.innerWidth > 490}
                  style={{ columnGap: '2em' }}
                  aria-label="gender"
                  name="gender1"
                  value={zoneTypes()[props.newType.zone] ?? ' '}
                  onChange={(event) => props.handleCrisisZone(event.target.value)}
                >
                  <Box className={styles.textImage}>
                    <FormControlLabel
                      value={t('allBody')}
                      control={<Radio />}
                      label={t('allBody')}
                    />
                    <Box>
                      <img src={allBody} alt={` ${t('allBody')}`} title={` ${t('allBody')}`} />
                    </Box>
                  </Box>
                  <Box className={styles.textImage}>
                    <FormControlLabel value={t('right')} control={<Radio />} label={t('right')} />
                    <Box>
                      <img src={rightBody} alt={` ${t('right')}`} title={` ${t('right')}`} />
                    </Box>
                  </Box>

                  <Box className={styles.textImage}>
                    <FormControlLabel value={t('left')} control={<Radio />} label={t('left')} />
                    <Box>
                      <img src={leftBody} alt={` ${t('left')}`} title={` ${t('left')}`} />
                    </Box>
                  </Box>
                  <Box className={styles.textImage}>
                    <FormControlLabel
                      value={t('extremity')}
                      control={<Radio />}
                      label={t('extremity')}
                    />
                    <Box>
                      <img
                        src={extremityBody}
                        alt={` ${t('extremity')}`}
                        title={` ${t('extremity')}`}
                      />
                    </Box>
                  </Box>
                  <Box className={styles.textImage}>
                    <FormControlLabel value={t('head')} control={<Radio />} label={t('head')} />
                    <Box>
                      <img src={headBody} alt={` ${t('head')}`} title={` ${t('head')}`} />
                    </Box>
                  </Box>
                  <Box className={styles.textImage}>
                    <FormControlLabel
                      value={t('undefined')}
                      control={<Radio />}
                      label={t('undefined')}
                    />
                    <Box>
                      <img
                        src={undefinedBody}
                        alt={` ${t('undefined')}`}
                        title={` ${t('undefined')}`}
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box
            className={
              props.innerWidth > 598
                ? styles.containerRadioButton
                : styles.containerRadioButtonMobile
            }
          >
            <Box>
              <h3>{t('vision')}</h3>
              <RadioGroup
                row={window.innerWidth > 490}
                aria-label="gender"
                name="gender1"
                value={visionTypes()[props.newType.vision] ?? ' '}
                onChange={(e) => {
                  props.handlerVision(e.target.value)
                }}
                style={{ columnGap: '2em' }}
              >
                <Box className={styles.textImage}>
                  <FormControlLabel value={t('eyesUp')} control={<Radio />} label={t('eyesUp')} />
                  <Box>
                    <img src={eyesUpIcon} alt={` ${t('eyesUp')}`} title={` ${t('eyesUp')}`} />
                  </Box>
                </Box>
                <Box className={styles.textImage}>
                  <FormControlLabel
                    value={t('eyesClose')}
                    control={<Radio />}
                    label={t('eyesClose')}
                  />
                  <Box>
                    <img
                      src={eyesCloseIcon}
                      alt={` ${t('eyesClose')}`}
                      title={` ${t('eyesClose')}`}
                    />
                  </Box>
                </Box>
                <Box className={styles.textImage}>
                  <FormControlLabel
                    value={t('eyesRight')}
                    control={<Radio />}
                    label={t('eyesRight')}
                  />
                  <Box>
                    <img
                      src={eyesRightIcon}
                      alt={` ${t('eyesRight')}`}
                      title={` ${t('eyesRight')}`}
                    />
                  </Box>
                </Box>
                <Box className={styles.textImage}>
                  <FormControlLabel
                    value={t('eyesLeft')}
                    control={<Radio />}
                    label={t('eyesLeft')}
                  />
                  <Box>
                    <img src={eyesLeftIcon} alt={` ${t('eyesLeft')}`} title={` ${t('eyesLeft')}`} />
                  </Box>
                </Box>
                <Box className={styles.textImage}>
                  <FormControlLabel value={t('stare')} control={<Radio />} label={t('stare')} />
                  <Box>
                    <img src={eyesStareIcon} alt={` ${t('stare')}`} title={` ${t('stare')}`} />
                  </Box>
                </Box>
                <Box className={styles.textImage}>
                  <FormControlLabel value={t('blink')} control={<Radio />} label={t('blink')} />
                  <Box>
                    <img src={eyesBlinkIcon} alt={` ${t('blink')}`} title={` ${t('blink')}`} />
                  </Box>
                </Box>
                <Box className={styles.textImage}>
                  <FormControlLabel
                    value={t('undefined')}
                    control={<Radio />}
                    label={t('undefined')}
                  />
                </Box>
              </RadioGroup>
            </Box>
          </Box>
          <Box className={styles.containerWithSelect}>
            <Box>
              <FormControl fullWidth>
                <InputLabel shrink className={styles.textSelect} htmlFor="select">
                  {t('skinColor')}
                </InputLabel>
                <Select
                  defaultValue={''}
                  className={styles.inputSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={skinColorTypes()[props.newType.skinColor] ?? ' '}
                  label="skinColor"
                  onChange={(e) => {
                    props.handlerSkinColor(e.target.value)
                  }}
                >
                  <MenuItem value={t('blue')}>{t('blue')}</MenuItem>
                  <MenuItem value={t('pale')}>{t('pale')}</MenuItem>
                  <MenuItem value={t('noChanges')}>{t('noChanges')}</MenuItem>
                  <MenuItem value={t('undefined')}>{t('undefined')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel shrink className={styles.textSelect} htmlFor="select">
                  {t('associatedSymptoms')}
                </InputLabel>
                <Select
                  defaultValue={''}
                  className={styles.inputSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={symptomTypes()[props.newType.symptom] ?? ' '}
                  label="symptom"
                  onChange={(e) => {
                    props.handlerSymptom(e.target.value)
                  }}
                >
                  <MenuItem value={t('urineControlLoss')}>{t('urineControlLoss')}</MenuItem>
                  <MenuItem value={t('deposition')}>{t('deposition')}</MenuItem>
                  <MenuItem value={t('vomiting')}>{t('vomiting')}</MenuItem>
                  <MenuItem value={t('undefined')}>{t('undefined')}</MenuItem>
                  <MenuItem value={t('others')}>{t('others')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel className={styles.textSelect} shrink htmlFor="select">
                  {t('mouth')}
                </InputLabel>
                <Select
                  defaultValue={''}
                  className={styles.inputSelect}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mouthTypes()[props.newType.mouth] ?? ' '}
                  label="mouth"
                  onChange={(e) => {
                    props.handlerMouth(e.target.value)
                  }}
                >
                  <MenuItem value={t('dry')}>{t('dry')}</MenuItem>
                  <MenuItem value={t('drooling')}>{t('drooling')}</MenuItem>
                  <MenuItem value={t('foam')}>{t('foam')}</MenuItem>
                  <MenuItem value={t('tongueBiting')}>{t('tongueBiting')}</MenuItem>
                  <MenuItem value={t('undefined')}>{t('undefined')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Divider className={styles.divider} />
          <h2>{t('afterTheCrisis')}</h2>
          <Box
            className={
              props.innerWidth > 598
                ? styles.containerRadioButton
                : styles.containerRadioButtonMobile
            }
          >
            <Box className={styles.boxAfterCrisis}>
              <Box>
                <h3>{t('eventDuration')}</h3>
                <RadioGroup
                  style={{
                    marginRight: props.innerWidth < 598 ? '' : '92px',
                  }}
                  className={styles.radioGroup}
                  aria-label="gender"
                  name="gender1"
                  value={afterDurationTypes()[props.newType.afterDuration] ?? ' '}
                  onChange={(e) => {
                    props.handlerAfterDuration(e.target.value)
                  }}
                >
                  <FormControlLabel
                    value={t('lessThanThirtyMin')}
                    control={<Radio />}
                    label={t('lessThanThirtyMin')}
                  />
                  <FormControlLabel
                    value={t('betweenThirtySixty')}
                    control={<Radio />}
                    label={t('betweenThirtySixty')}
                  />
                  <FormControlLabel
                    value={t('OverSixty')}
                    control={<Radio />}
                    label={t('OverSixty')}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <h3>{t('otherSymptom')}</h3>
                <Box className={styles.otherSympthoms}>
                  {props.otherSymptoms.map((os, index) => {
                    return (
                      <Box key={index} style={{ width: window.innerWidth > 490 ? 300 : 'auto' }}>
                        <Checkbox
                          title={t(os)}
                          value={t(os)}
                          checked={props.checkedState[index]}
                          onChange={() => props.handlerAfterSymptom(index)}
                        />
                        {t(os)}
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={styles.buttonSaveContainer}>
            <Box mr={2}>
              {props.errorMessage && (
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t('intensityColorMandatory')}
                </Alert>
              )}
            </Box>
            <Box className={styles.buttonSaveNewtypeCrisis}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                handler={(e) => {
                  props.saveNewCrisisType(e)
                }}
                label={t('save')}
                type={'button'}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
