// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Chip, TextField, InputAdornment } from '@mui/material'
import styles from '../user-profile/Editor.module.css'
import React, { ChangeEvent } from 'react'
import { Alert, Grid, Card } from '@mui/material'
import { UserDTO } from '../../modules/users/models/User'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import style from './CircleRegister.module.css'
import accederParticipanteIcon from '../../assets/newCircle/acceder-como-participante.svg'
import accederConsultaIcon from '../../assets/newCircle/acceder-modo-consulta.svg'
import crearCirculoIcon from '../../assets/newCircle/crear-circulo.svg'
import { Circle } from '../../modules/circle/models/Circle'
import { Searcher } from '../../components/table/Searcher'
import SearchIcon from '@mui/icons-material/Search' // Importar el ícono de Material-UI

type PathologyInterface = {
  key: string
  value: string
}
type CircleRegisterHomeProps = {
  circleToAdd: UserDTO
  userToAdd: UserDTO
  handleInputCircle: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    user: UserDTO,
    setState: (user: UserDTO) => void
  ) => void
  setCircleToAdd: (user: UserDTO) => void
  selectedPathology: string
  setSelectedPathology: (selectedPathology: string) => void
  pathologies: PathologyInterface[]
  circleToAddPathologies: Circle[]
  cipaError: boolean
  cipaErrorText: string
  getCitizenData: (id: string, type: string) => void
  handleAddCircle: () => void
  handleVisit: () => void
  handleAccessParticipant: () => void
  handleSearchWithCip: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    user: UserDTO,
    setState: (user: UserDTO) => void
  ) => void
}

export function CircleRegisterHome(props: CircleRegisterHomeProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Box bgcolor="white" m="1rem">
          <Box className={style.title}>{t('searchCircle')}</Box>
          <Box mb={3} className={style.textFieldBox}>
            <Box mb={3} className={style.cipContainer}>
              <p className={style.homeLabel}>{t('searchByCIP')}</p>
              <Searcher<UserDTO>
                search={{
                  searchValues: [
                    {
                      name: 'cip',
                      value: props.circleToAdd.cip || props.circleToAdd.dni,
                      label: '',
                      type: 'text',
                    },
                  ],
                  handleSearch: (values) => {
                    const searchValue = values[0].value
                    if (searchValue) {
                      props.getCitizenData(
                        searchValue,
                        searchValue.substring(0, 4) === 'CARM' ? 'cim' : 'nif'
                      )
                    }
                  },
                }}
                isPreventDefault={true}
              >
                <TextField
                  id="cip-search"
                  placeholder={t('Enter CIP or DNI')} // Agrega un placeholder si es necesario
                  value={props.circleToAdd.cip || props.circleToAdd.dni}
                  onChange={(e) =>
                    props.handleSearchWithCip(e, props.circleToAdd, props.setCircleToAdd)
                  }
                  variant="outlined"
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Searcher>
            </Box>
            <Box
              mb={3}
              className={style.cipContainer}
              display={'flex'}
              justifyContent={'space-between'}
            >
              <p className={style.homeLabel}>{t('patientName')}</p>
              <TextField
                id={'name'}
                key={'name'}
                name="name"
                className={styles.textField}
                style={{ backgroundColor: '#f5f3f3' }}
                value={props.circleToAdd.name + ' ' + props.circleToAdd.lastname}
                type="name"
                // onChange={(e) => props.handleInputCircle(e, props.circleToAdd, props.setCircleToAdd)}
                required={true}
                variant={'outlined'}
                size={'small'}
                disabled={true}
              />
            </Box>
          </Box>
          {props.cipaError && (
            <Box mb={3}>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <Alert severity="error">"{props.cipaErrorText}"</Alert>
            </Box>
          )}
          <Box mb={3} className={styles.cipContainer} display={'flex'}>
            {props.circleToAddPathologies.length > 0 ? (
              <>
                <p className={styles.label}>{t('Circulos a los que pertenece')}</p>
                <Box display={'flex'}>
                  {props.circleToAddPathologies.map((circle) => (
                    /* <TextField
                    id={'pathology'}
                    key={'pathology'}
                    name="pathology"
                    className={styles.textField}
                    value={circle.name}
                    type="text"
                    required={true}
                    variant={'outlined'}
                    size={'small'}
                    disabled={true}
                  /> */
                    <Chip key={circle.id} className={style.chip} label={circle.name} />
                  ))}
                </Box>
              </>
            ) : (
              ''
            )}
          </Box>
        </Box>
        <Box className={style.buttonsContainer}>
          <Box style={{ display: 'flex', visibility: 'hidden' }}>
            <Grid item xs={6} md={3}>
              <Card
                className={style.hoverCard}
                variant="outlined"
                /* onClick={() => handleOnClick(item.name)}
                onMouseEnter={() => setActiveItemName(item.name)}
                onMouseLeave={() => setActiveItemName(null)} */
              >
                <div className={style.iconBlock}>
                  <img src={accederConsultaIcon} alt={accederConsultaIcon} />
                </div>
                <div>
                  <h2 className={style.textBlock} style={{ whiteSpace: 'pre-line' }}>
                    {t('accessInConsultationMode')}
                    <hr className={style.horizontalLine} />
                  </h2>
                </div>
              </Card>
            </Grid>
          </Box>
          <Box style={{ display: 'flex', visibility: 'hidden' }}>
            <Grid item xs={6} md={3}>
              <Card
                className={style.hoverCard}
                variant="outlined"
                /* onClick={() => handleOnClick(item.name)}
                onMouseEnter={() => setActiveItemName(item.name)}
                onMouseLeave={() => setActiveItemName(null)} */
              >
                <div className={style.iconBlock}>
                  <img src={accederParticipanteIcon} alt={accederParticipanteIcon} />
                </div>
                <div>
                  <h2 className={style.textBlock} style={{ whiteSpace: 'pre-line' }}>
                    {t('accessAsParticipant')}
                    <hr className={style.horizontalLine} />
                  </h2>
                </div>
              </Card>
            </Grid>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Grid item xs={6} md={3}>
              <Card
                className={style.hoverCard}
                variant="outlined"
                onClick={() => {
                  if (props.circleToAdd.name !== '') {
                    props.handleAddCircle()
                  }
                }}
              >
                <div className={style.iconBlock}>
                  <img src={crearCirculoIcon} alt="crear circulo" />
                </div>
                <div>
                  <h2 className={style.textBlock} style={{ whiteSpace: 'pre-line' }}>
                    {t('createAddNewCircle')}
                    <hr className={style.horizontalLine} />
                  </h2>
                </div>
              </Card>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}
