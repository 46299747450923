import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './EpilepsyCard'
import { UserForm, UserFormQuery } from '../../../../modules/forms/models/UserForm'
import { MenuItem, Select, Skeleton } from '@mui/material'
import { getFormContainer } from 'container/form-module'
import { USER_FORM_SERVICE_KEY } from 'modules/forms'
import { UserFormService } from 'modules/forms/services/UserFormService'
import { Query, QueryParam } from 'common/api/Query'

type PsychologicalHealthCardProps = {
  userForms: UserForm[]
}

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)

export const PsychologicalHealth: React.FC<PsychologicalHealthCardProps> = ({ userForms }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [monthLabel, setMonthLabel] = useState<string[]>([])
  const [selectedItem, setSelectedItem] = useState<string>('totalScore')
  const [chartData, setChartData] = useState<number[]>([])
  const memoizedUserForms = useMemo(() => userForms, [JSON.stringify(userForms)])

  const items = [
    'totalScore',
    'sadness',
    'pessimism',
    'failure',
    'lossOfPleasure',
    'guilt',
    'punishment',
    'disconformityWithSelf',
    'selfCriticism',
    'suicidalThoughts',
    'crying',
    'agitation',
    'lossOfInterest',
    'indecision',
    'devaluation',
    'lossOfEnergy',
    'sleepHabits',
    'irritability',
    'appetite',
    'difficultyConcentrating',
    'fatigue',
    'lossOfInterestInSex',
  ]

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]
  /* eslint-disable @typescript-eslint/promise-function-async */
  useEffect(() => {
    if (userForms.length > 0) {
      setIsLoading(true)

      // Inicializar estructuras de datos
      const monthlyData: Record<string, number[]> = {}
      const questionData: Record<string, number> = Object.fromEntries(items.map((key) => [key, 0]))

      // Convertir cada solicitud a una Promise
      const fetchPromises = userForms.map((form) =>
        userFormService
          .getPromsBeckItems(
            new Query<UserFormQuery>({
              query: [new QueryParam('ids', [form.id!])],
            })
          )
          .toPromise()
          .then((res) => {
            setIsLoading(false)
            if (res) {
              res.forEach((values) => {
                questionData.totalScore = form.totalScore ?? 0
                questionData.sadness = values.sadness
                questionData.pessimism = values.pessimism
                questionData.failure = values.failure
                questionData.lossOfPleasure = values.lossOfPleasure
                questionData.guilt = values.guilt
                questionData.punishment = values.punishment
                questionData.disconformityWithSelf = values.disconformityWithSelf
                questionData.selfCriticism = values.selfCriticism
                questionData.suicidalThoughts = values.suicidalThoughts
                questionData.crying = values.crying
                questionData.agitation = values.agitation
                questionData.lossOfInterest = values.lossOfInterest
                questionData.indecision = values.indecision
                questionData.devaluation = values.devaluation
                questionData.lossOfEnergy = values.lossOfEnergy
                questionData.sleepHabits = values.sleepHabits
                questionData.irritability = values.irritability
                questionData.appetite = values.appetite
                questionData.difficultyConcentrating = values.difficultyConcentrating
                questionData.fatigue = values.fatigue
                questionData.lossOfInterestInSex = values.lossOfInterestInSex
              })
            }

            // Obtener la fecha del formulario y organizar los datos mensuales
            const date = new Date(form.userFormValues[0].date)
            const monthYear = `${labels[date.getMonth()]} ${date.getFullYear()}`

            if (!monthlyData[monthYear]) {
              monthlyData[monthYear] = []
            }
            monthlyData[monthYear].push(questionData[selectedItem] || 0)
          })
      )

      // Esperar a que todas las promesas terminen antes de actualizar el estado
      Promise.all(fetchPromises)
        .then(() => {
          const sortedMonths = Object.keys(monthlyData).sort((a, b) => {
            const dateA = new Date(a.split(' ')[1] + '-' + (labels.indexOf(a.split(' ')[0]) + 1))
            const dateB = new Date(b.split(' ')[1] + '-' + (labels.indexOf(b.split(' ')[0]) + 1))
            return dateA.getTime() - dateB.getTime()
          })

          const averages = sortedMonths.map((month) => {
            const values = monthlyData[month]
            return values.reduce((sum, val) => sum + val, 0) / values.length
          })

          setMonthLabel(sortedMonths)
          setChartData(averages)
        })
        .catch((error) => {
          console.error('Error al obtener Fdatos:', error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [memoizedUserForms, selectedItem])

  const datasets = [
    {
      label: t(selectedItem),
      data: chartData,
      borderColor: 'blue',
    },
  ]

  return (
    <CardComponent title={t('PsychologicalHealth')}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={200} />
      ) : (
        <>
          <GenericChartDashboardComponent
            datasets={datasets}
            labels={monthLabel}
            title={''}
            typeChart={'bar'}
            legendPosition={'bottom'}
            yAxisConfig={{
              min: 0,
              max: selectedItem === 'totalScore' ? 63 : 3,
              stepSize: selectedItem === 'totalScore' ? 9 : 1,
            }}
          />

          <Select
            MenuProps={{
              MenuListProps: {
                sx: {
                  'li.MuiButtonBase-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontFamily: 'Poppins',
                    paddingLeft: '10px',
                  },
                },
              },
            }}
            SelectDisplayProps={{
              style: {
                paddingLeft: '10px',
                fontFamily: 'Poppins',
                fontSize: '14px',
              },
            }}
            fullWidth
            variant="outlined"
            style={{
              backgroundColor: '#f5f2f2',
              width: '50%',
              marginLeft: '50%',
              marginTop: '20px',
              height: '40px',
            }}
            value={selectedItem}
            onChange={(e) => setSelectedItem(e.target.value)}
          >
            {items.map((item: string) => (
              <MenuItem key={item} value={item}>
                {t(item)}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </CardComponent>
  )
}
