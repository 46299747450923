import { SxProps } from '@mui/material'

export const dividerStyle: SxProps = {
  width: '100%',
  marginBottom: 3,
  borderColor: '#68B3E0',
  bgcolor: '#68B3E0',
  borderWidth: '2px',
}

export const TypographyStyle: SxProps = {
  fontWeight: 'bold',
  fontSize: '1.2rem',
  fontFamily: 'Montserrat, sans-serif',
  textAlign: 'left',
}
