/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTE_SEGMENTED_NOTIFICATIONS } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import styles from './List.module.css'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import Button from '@mui/material/Button'
import rightArrow from '../../assets/calendar/arrow.svg'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { NotificationCard } from './NotificationCard'
import { Searcher } from './Searcher'
import { GroupModal } from './NewGroup'
import { NotificationData } from './NotificationData'
import { SegmentedNotificationService } from '../../modules/notifications/services/SegmentedNotificationService'
import { SEGMENTED_NOTIFICATION_API_KEY } from '../../modules/notifications'
import { getNotificationContainer } from '../../container/notification-module'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { SegmentedNotification } from '../../modules/notifications/models/SegmentedNotification'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { UserCircleWithCircle } from '../../modules/user-circle/models/UserCircleWithCircle'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import { Permission } from '../../common/permission'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const notificationContainer = getNotificationContainer()
const segmentedNotificationService = notificationContainer.get<SegmentedNotificationService>(
  SEGMENTED_NOTIFICATION_API_KEY
)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)

type EditorProps = {
  id?: string
}

function Screen() {
  return (
    <div className={styles.screen}>
      <ForwardToInboxIcon
        fontSize={'large'}
        style={{ color: 'rgb(104, 179, 224)', fontSize: '150px' }}
      />
      <p>Crea un grupo nuevo o</p>
      <p>selecciona un grupo ya existente</p>
    </div>
  )
}

function ScreenPatient() {
  return (
    <div className={styles.screen}>
      <ForwardToInboxIcon
        fontSize={'large'}
        style={{ color: 'rgb(104, 179, 224)', fontSize: '150px' }}
      />
      <p>Selecciona un grupo ya existente</p>
    </div>
  )
}

export function View(props: EditorProps) {
  const loggedUser = loggedUserService.get()

  const { t } = useTranslation()

  const [circleUser, setCircleUser] = useState<UserCircleWithCircle>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [resetSearcher, setResetSearcher] = useState<boolean>(false)
  const [notificationGroups, setNotificationGroups] =
    useState<ItemList<SegmentedNotification>>(emptyList<SegmentedNotification>())
  const [notificationGroupsOriginal, setNotificationGroupsOriginal] =
    useState<ItemList<SegmentedNotification>>(emptyList<SegmentedNotification>())
  const [groupSelected, setGroupSelected] = useState<any>('')
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false)
  const [isPatient, setPatient] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleCloseGroupModal = () => {
    getSegmentedNotifications()
    setGroupSelected('')
    setOpenGroupModal(false)
  }

  const handleOpenGroupModal = () => {
    setOpenGroupModal(true)
  }

  const getSegmentedNotifications = () => {
    const circles: any[] = []
    if (loggedUser)
      userCircleService
        .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
        .subscribe((res) => {
          if (res) {
            const uniqueCircles: CircleDTO[] = []
            res.forEach((userCircle) => {
              if (!uniqueCircles.find((circle) => circle.id === userCircle.circle.id)) {
                uniqueCircles.push(userCircle.circle)
                circles.push(userCircle.circle.id)
              }
            })

            const articlesQuery = [new QueryParam<any>('circlesIds', circles)]
            segmentedNotificationService
              .getFilteredList(
                new Query({
                  query: articlesQuery,
                })
              )
              .subscribe((res) => {
                const notifications = emptyList<SegmentedNotification>()

                notifications.items = res.items.sort((a, b) => {
                  return b.updatedAt.getTime() - a.updatedAt.getTime()
                })
                setNotificationGroups(notifications)
                setNotificationGroupsOriginal(notifications)
              })
          }
        })
  }

  // const getSegmentedNotifications = () => {
  //   const circles: any[] = []
  //   userCircleService.getUserCircleByUserIDWithCircle(loggedUser.id).subscribe((res) => {
  //     if (res && res?.length > 0) {
  //       res.forEach((obj) => circles.push(obj.circle.id))
  //     } else {
  //       userCircleService.getUserCirclesByUserId(loggedUser.id).subscribe((res) => {
  //         if (res && res?.length > 0) {
  //           res.forEach((obj) => circles.push(obj.circle.id.toString()))
  //         }
  //       })
  //     }
  //   })
  //   if(loggedUser)
  //   userCircleService.getUserCirclesByUserId(loggedUser.id).subscribe((res) => {
  //     if (res && res?.length > 0) {
  //       res.forEach((obj) => circles.push(obj.circle.id.toString()))
  //     }
  //
  //     if (loggedUser && loggedUser.roles.find((item) => item === 'patient')) {
  //       // @ts-expect-error get items
  //       const id = JSON.parse(localStorage.getItem('selected user circle' || 'null')).circle.id
  //       circles.push(id)
  //     }
  //     const articlesQuery = [new QueryParam<any>('circlesIds', circles)]
  //     segmentedNotificationService
  //       .getFilteredList(
  //         new Query({
  //           query: articlesQuery,
  //         })
  //       )
  //       .subscribe((res) => {
  //         const notifications = emptyList<SegmentedNotification>()
  //
  //         notifications.items = res.items.sort((a, b) => {
  //           return b.updatedAt.getTime() - a.updatedAt.getTime()
  //         })
  //         setNotificationGroups(notifications)
  //         setNotificationGroupsOriginal(notifications)
  //       })
  //   })
  // }

  useEffect(() => {
    getSegmentedNotifications()

    if (loggedUser) {
      userCircleService.getUserCircleByUserIDWithCircle(loggedUser.id).subscribe((res) => {
        const userCircleSelected = res?.find((userCircle) => {
          return userCircle.userID === loggedUser.id
        })
        if (userCircleSelected !== null) {
          setCircleUser(userCircleSelected)
        }
      })
      if (loggedUser.roles.find((item) => item === 'patient')) {
        setPatient(true)
      }
    }
  }, [openGroupModal])

  useEffect(() => {
    if (props.id && notificationGroupsOriginal.items.length !== 0) {
      const group = notificationGroups.items.find((obj) => {
        return obj.id === props.id
      })
      const data = {
        data: group,
        id: group?.id,
        title: group?.name,
      }
      group && setGroupSelected(data)
    }
  }, [props.id, notificationGroupsOriginal])

  const uploadComment = () => {
    // setGroupSelected({...groupSelected, title: "test" })
    // setOpenGroupModal(false)
  }

  const handleSearch = (data: any) => {
    if (data.length !== 0) setNotificationGroups(data)
    setNotificationGroups(Object.assign({ ...notificationGroups }, { items: data }))
  }

  const handleSelectNotification = (data: any) => {
    setResetSearcher(!resetSearcher)
    setGroupSelected(data)
    navigate(`${ROUTE_SEGMENTED_NOTIFICATIONS}/${data.id}`)
  }

  return (
    <>
      <Modal open={openGroupModal} onClose={handleCloseGroupModal}>
        <GroupModal
          handleClose={handleCloseGroupModal}
          handleSave={uploadComment}
          deleted={() => {}}
          segmentedId={groupSelected.id}
          userId={loggedUser?.id}
          userCircleId={circleUser?.id}
        />
      </Modal>
      {!isLoading ? (
        <Box className={styles.wallContainer} style={{ height: '80%' }}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            className={styles.chatWidgetContainer}
            style={{ maxHeight: '93%', overflowY: 'auto' }}
          >
            <div
              style={{ position: 'sticky', zIndex: 10, top: '-24px', backgroundColor: '#ffffff' }}
            >
              <Box
                className={style.searcherContainer}
                component="section"
                display="flex"
                justifyContent="space-between"
              >
                <Searcher
                  handler={handleSearch}
                  reset={resetSearcher}
                  data={notificationGroupsOriginal}
                />
              </Box>
              {loggedUserService.userCan(Permission.createSegmentedNotifications) && (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flex: 1,
                  }}
                >
                  <Button
                    onClick={handleOpenGroupModal}
                    variant="contained"
                    fullWidth={true}
                    endIcon={<img src={rightArrow} alt="right arrow" />}
                    style={{
                      backgroundColor: '#68b3e0',
                      marginTop: '10px',
                      float: 'right',
                      color: 'white',
                    }}
                  >
                    {t('newGroup')}
                  </Button>
                </Box>
              )}
            </div>
            <Box>
              <Box className={style.blueLine} />
            </Box>
            <Box>
              {notificationGroupsOriginal &&
                notificationGroups.items.map((obj, index) => {
                  return (
                    <NotificationCard
                      key={index}
                      id={obj.id}
                      clickEvent={handleSelectNotification}
                      title={obj.name}
                      data={obj}
                      avatarUrl={'AvatarUrl'}
                    />
                  )
                })}
            </Box>
          </Box>
          {groupSelected !== '' ? (
            <NotificationData
              title={groupSelected.title}
              description={groupSelected.description}
              data={groupSelected}
              segmentedId={groupSelected.id}
              userId={loggedUser?.id}
              userCircleId={circleUser?.id}
              handleClose={handleCloseGroupModal}
              segmentedGroup={groupSelected}
              updateGroups={getSegmentedNotifications}
              isPatient={isPatient}
            />
          ) : isPatient ? (
            <ScreenPatient />
          ) : (
            <Screen />
          )}
        </Box>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </>
  )
}
