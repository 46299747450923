import React, { ReactNode, useEffect, useState } from 'react'
import { AllCalendarEventDTO } from 'modules/calendar/models/AllCalendarEventDTO'
import { getCalendarContainer } from 'container/calendar-module'
import { EventService } from 'modules/calendar/services/EventService'
import { EVENT_SERVICE_KEY } from 'modules/calendar'
import { Query, QueryParam } from '../../api/Query'
import { Event } from 'modules/calendar/models/Event'
import dayjs from 'dayjs'
import { fromArrayModel } from 'modules/calendar/models/AllCalendarEventDTO'
import { EventCategory, EventCategoryArray } from 'modules/calendar/enums/EventCategory'
import { TODAY } from 'common/const'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'

import { finalize } from 'rxjs/operators'

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const emptyCalendarEvents = (calendarEvents: AllCalendarEventDTO[]) => {
  return {
    calendarEvents,
    selectedDate: new Date(TODAY.getFullYear(), TODAY.getMonth(), 1),
    handlerEdit: () => {},
    handlerRemove: () => {},
    handlerAdd: () => {},
    handleNextMonth: () => {},
    handlePreviousMonth: () => {},
    canChangeMonth: false,
  }
}

type CalendarEventsContextProviderProps = {
  children: ReactNode
}
export type CalendarEventsContextType = {
  calendarEvents: AllCalendarEventDTO[]
  filterEvents: EventCategory[]
  setfilterEvents: (calendarEvents: EventCategory[]) => void
  refreshCalendarEvents: boolean
  setRefreshCalendarEvents: (refresh: boolean) => void
  eventsMonthYear: {
    mes: number
    ano: number
  }
  setEventsMonthYear: (monthYear: { mes: number; ano: number }) => void
  isLoading: boolean
}

export const CalendarEventsContext = React.createContext<CalendarEventsContextType>({
  calendarEvents: [],
  filterEvents: [],
  setfilterEvents: () => {},
  refreshCalendarEvents: false,
  setRefreshCalendarEvents: () => {},
  eventsMonthYear: {
    mes: 0,
    ano: 0,
  },
  setEventsMonthYear: (monthYear: { mes: number; ano: number }) => {},
  isLoading: true,
})
export const CalendarEventsContextProvider: React.FC<CalendarEventsContextProviderProps> = ({
  children,
}) => {
  const [allCalendarEvents, setAllCalendarEvents] = useState<AllCalendarEventDTO[]>([])
  const [filterEvents, setfilterEvents] = useState<EventCategory[]>(EventCategoryArray)
  const [refreshCalendarEvents, setRefreshCalendarEvents] = useState(false)
  const today = dayjs()
  const [eventsMonthYear, setEventsMonthYear] = useState({
    mes: today.month() + 1, // dayjs month is 0 based
    ano: today.year(),
  })

  const loggedUser = loggedUserService.get()
  const [eventCache, setEventCache] = useState<Record<string, AllCalendarEventDTO[]>>({})
  const [isLoading, setIsLoading] = useState(true)
  const [prevEventsMonthYear, setPrevEventsMonthYear] = useState(eventsMonthYear)
  const [prevRefreshCalendarEvents, setPrevRefreshCalendarEvents] = useState(refreshCalendarEvents)
  const [prevFilterEvents, setPrevFilterEvents] = useState<EventCategory[]>()

  useEffect(() => {
    if (!loggedUser) return

    const today = new Date()
    const startDate = dayjs(`${today.getFullYear() - 1}-${today.getMonth() + 1}-01`).startOf(
      'month'
    )
    const endDate = startDate.clone().add(100, 'month').endOf('month')
    const lastPreviousMonth = startDate
      .clone()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    const firstNextMonth = endDate
      .clone()
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

    const cacheKey = `${lastPreviousMonth}-${firstNextMonth}-${filterEvents.join(',')}-${refreshCalendarEvents}`

    if (eventCache[cacheKey]) {
      setAllCalendarEvents(eventCache[cacheKey])
    }

    if (
      prevEventsMonthYear.mes !== eventsMonthYear.mes ||
      prevEventsMonthYear.ano !== eventsMonthYear.ano ||
      prevRefreshCalendarEvents !== refreshCalendarEvents ||
      JSON.stringify(prevFilterEvents) !== JSON.stringify(filterEvents)
    ) {
      setIsLoading(true)

      setEventsMonthYear({
        mes: today.getMonth() + 1,
        ano: today.getFullYear(),
      })

      eventService
        .getAllCalendarEvents(
          new Query<Event>({
            query: [
              new QueryParam<Event>('startDate', lastPreviousMonth),
              new QueryParam<Event>('finishDate', firstNextMonth),
            ],
          }),
          loggedUser.id ?? ''
        )
        .pipe(
          finalize(() => {
            setTimeout(() => {
              setIsLoading(false)
            }, 500)
          })
        )
        .toPromise()
        .then((res) => {
          let filteredEvents = res || []

          if (filterEvents.length > 0) {
            filteredEvents = filteredEvents.filter((event) =>
              filterEvents.includes(event.eventCategory)
            )
          }

          const eventModels = fromArrayModel(filteredEvents)
          setAllCalendarEvents(eventModels)

          setEventCache((prevCache) => ({
            ...prevCache,
            [cacheKey]: eventModels,
          }))
        })
        .catch((error) => {
          console.error('Error al obtener eventos:', error)
          setIsLoading(false)
        })

      setPrevEventsMonthYear(eventsMonthYear)
      setPrevRefreshCalendarEvents(refreshCalendarEvents)
      setPrevFilterEvents([...filterEvents])
    }
  }, [eventsMonthYear, refreshCalendarEvents, filterEvents, eventCache, loggedUser])

  return (
    <CalendarEventsContext.Provider
      value={{
        calendarEvents: allCalendarEvents,
        filterEvents,
        setfilterEvents,
        refreshCalendarEvents,
        setRefreshCalendarEvents,
        eventsMonthYear,
        setEventsMonthYear,
        isLoading,
      }}
    >
      {children}
    </CalendarEventsContext.Provider>
  )
}
