import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../generic/GenericTable.module.css'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_CREATE,
  ROUTE_FORM_GENERATOR,
  ROUTE_FORM_GENERATOR_FORM,
  ROUTE_TEMPLATE,
  ROUTE_TEMPLATE_FORM_GENERATOR,
} from '../../routes/routes-constants'
import { getFormContainer } from '../../container/form-module'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { FormService } from '../../modules/forms/services/FormService'
import { Form, FormQuery } from '../../modules/forms/models/Form'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { AppTable } from '../../components/table'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { UserForm, UserFormQuery } from '../../modules/forms/models/UserForm'
import genericStyle from '../../common/utils/generic.module.css'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import ShareIcon from '../../assets/resource_icons/ico-share.svg'
import unAssignIcon from '../../assets/table_icons/ico-desvincular-gris.svg'
import { forkJoin, Observable } from 'rxjs'
import { Box, createTheme, Modal, Typography } from '@mui/material'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, PROFESSIONALTYPE_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { ModalTemplate } from './modal-template/ModalTemplate'
import { FormType } from '../../modules/forms/enums/FormType'
import { ProfessionalTypesService } from '../../modules/users/services/ProfessionalTypeService'
import { ProfessionalType } from '../../modules/users/models/ProfessionalType'
import { PreviewForm } from './PreviewForm'
import { FormFieldDTO, fromModel } from '../../modules/forms/models/FormDTO'
import { CustomSwitch } from './modal-template/CustomSwitch'
import { CheckboxAssignType } from '../../modules/forms/enums/CheckboxAssignType'
import { UserFormWithCheckboxDTO } from '../../modules/forms/models/UserFormWithCheckboxDTO'
import { CustomModal } from '../../components/modal/CustomModal'
import { useSnackbar } from 'notistack'
import { ChoiceFields } from './ChoiceFields'
import { ItemList } from 'common/models/ItemList'

const formContainer = getFormContainer()
const userContainer = getUserContainer()
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const professionalTypeService = userContainer.get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

interface TableForms extends Form {
  specialty?: string | undefined
  pathology?: string[] | undefined
}

export function Table() {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const { enqueueSnackbar } = useSnackbar()
  const [forms, setForms] = useState<TableForms[]>([])
  const data = useMemo(() => forms, [forms])
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [formsPerPage, setFormsPerPage] = useState<number>(10)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userForms, setUserForms] = useState<string[]>([])
  const isManager = loggedUser?.roles?.some((role) => role.startsWith('manager'))
  const isExtern = loggedUser?.roles.some((r) => r === 'externProfessional')
  const isProfessional = loggedUser?.roles.some((r) => r === 'professionalSMS')
  const isProfessionalOrExtern = !!isExtern || !!isProfessional
  const [isViewForm, setIsViewForm] = useState<boolean>(false)
  const [currentForm, setCurrentForm] = useState<Form>()
  const [openModalTemplate, setOpenModalTemplate] = useState<boolean>(false)
  const [templates, setTemplates] = useState<Form[]>([])
  const [professionalType, setProfessionalType] = useState<ProfessionalType>()
  const [professionalSpecialities, setProfessionalSpecialities] = useState<ProfessionalType[]>([])
  const [showAllTemplates, setShowAllTemplates] = useState<boolean>(false)
  const [professionalTypeSelected, setProfessionalTypeSelected] = useState<ProfessionalType>()
  const [showTemplatesInTable, setShowTemplatesInTable] = useState<boolean>(false)
  const [showOtherProfessionalTemplates, setShowOtherProfessionalTemplates] =
    useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtering, setFiltering] = useState<boolean>(false)
  const [openModalAssign, setOpenModalAssign] = useState<boolean>(false)
  const [searcher, setSearcher] = useState<SearchValue<FormQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
      value: '',
    },
  ])
  const [openModalStopCopying, setOpenModalStopCopying] = useState<boolean>(false)
  const [formsSubscriber, setFormSubscriber] = useState<Form[]>([])
  const navigate = useNavigate()

  const handlerShowAllTemplates = () => {
    // setTemplates([]) commented out since im not sure what this really does apart from empty the state and make a new re-render
    setShowAllTemplates(!showAllTemplates)
  }

  const handlerShowTemplatesInTable = () => {
    setShowTemplatesInTable(!showTemplatesInTable)
    if (!showTemplatesInTable) {
      setShowOtherProfessionalTemplates(false)
    }
    setPage(0)
  }

  const handlerShowOtherProfessionalTemplates = () => {
    setShowOtherProfessionalTemplates(!showOtherProfessionalTemplates)
    if (!showOtherProfessionalTemplates) {
      setShowTemplatesInTable(false)
    }
    setPage(0)
  }

  const handlerProfessionalTypeSelected = (professionalType: ProfessionalType) => {
    setProfessionalTypeSelected(professionalType)
  }

  const handlerOpenModalAssign = () => {
    setCurrentForm(undefined)
    setOpenModalAssign(false)
  }

  const handlerOpenModalStopCopying = () => {
    setCurrentForm(undefined)
    setOpenModalStopCopying(false)
  }

  const getUserForms = (ids: string[]): Observable<UserForm[]> =>
    forkJoin(
      ids.map((id) =>
        userFormService.getFilteredList(
          new Query({
            query: [new QueryParam<UserFormQuery>('formID', id)],
          })
        )
      )
    ) as unknown as Observable<UserForm[]>

  useEffect(() => {
    if (searcher[0].value !== '') {
      formService
        .getFilteredList(
          new Query({
            pager: { offset: page * formsPerPage, limit: formsPerPage },
            sort: [{ field: 'created', desc: true }],
            query: [...searcherQuery(searcher)],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setForms(res.items)
          setCount(res.count)
          getUserForms(res.items.map((f) => f.id ?? '')).subscribe((res) =>
            setUserForms(res.map((uf) => uf.id ?? ''))
          )
        })
    }
  }, [searcher, pager])

  useEffect(() => {
    if (!loggedUser) return
    const listAux: ProfessionalType[] = []
    if (showAllTemplates || isExtern) {
      professionalTypeService
        .getAll(
          new Query({
            sort: [{ field: 'name' }],
            pager: { offset: 0, limit: -1 },
          })
        )
        .subscribe((res) => {
          if (!res) return
          setProfessionalSpecialities(res)
        })
    } else {
      professionalTypeService.getProfessionalTypeByUserID(loggedUser?.id).subscribe((res) => {
        if (!res) return
        listAux.push(res)
        setProfessionalSpecialities(listAux)
        setProfessionalType(listAux[0])
      })
    }
  }, [loggedUser, showAllTemplates, showOtherProfessionalTemplates])

  useEffect(() => {
    if (searcher[0].value !== '') return
    const fetchFormData = async (formType: FormType) => {
      if (!loggedUser) {
        return
      }

      try {
        const res = await formService
          .getListByUserPathology(
            new Query<FormQuery>({
              pager: { offset: page * formsPerPage, limit: formsPerPage },
              query: [
                new QueryParam<FormQuery>('userID', loggedUser?.id),
                new QueryParam<FormQuery>('creator', loggedUser?.id),
                new QueryParam<FormQuery>('isTemplate', FormType.Form.toString()),
              ],
              sort: [{ field: 'TITLE', desc: false }],
            })
          )
          .toPromise()
        const resWithoutPage = await formService
          .getListByUserPathology(
            new Query<FormQuery>({
              query: [
                new QueryParam<FormQuery>('userID', loggedUser?.id),
                new QueryParam<FormQuery>('creator', loggedUser?.id),
                new QueryParam<FormQuery>('isTemplate', FormType.Template.toString()),
              ],
              sort: [{ field: 'TITLE', desc: false }],
            })
          )
          .toPromise()

        if (!res) return
        // const filterForm = res.items.filter((f) => f.isTemplate === FormType.Form)
        if (!showTemplatesInTable) {
          setForms(res.items)
          setCount(res.count)
        } else {
          setForms([])
        }
        if (showAllTemplates || showTemplatesInTable || showOtherProfessionalTemplates) return
        if (!resWithoutPage) return
        if (resWithoutPage.items.length > 0) {
          setTemplates(resWithoutPage.items)
        }

        const userFormsRes = await getUserForms(res.items.map((f) => f.id ?? '')).toPromise()
        if (!userFormsRes) return
        if (userFormsRes.length <= 0) return
        setUserForms(userFormsRes.map((uf) => uf.id ?? ''))
      } catch (error) {
        console.error(`Error fetching form data for type ${formType}:`, error)
      }
    }

    const fetchFormDataByCreatorID = async (formType: FormType) => {
      if (!loggedUser) {
        return
      }

      try {
        const res = await formService
          .getListByCreatorID(
            new Query<FormQuery>({
              pager: { offset: page * formsPerPage, limit: formsPerPage },
              query: [new QueryParam<FormQuery>('creator', loggedUser?.id)],
              sort: [{ field: 'TITLE', desc: false }],
            })
          )
          .toPromise()
        const filterForm = res.items.filter((f) => f.isTemplate === FormType.Form)

        /* const filterTemplate = await formService
          .getFilteredList(
            new Query({
              pager: { offset: page * formsPerPage, limit: formsPerPage },
              query: [new QueryParam<FormQuery>('isTemplate', FormType.Template.toString())],
            })
          )
          .toPromise() */
        if (filterForm.length > 0) {
          setForms(filterForm)
          setCount(res.count)
        } else {
          setForms([])
        }
        const userFormsRes = await getUserForms(res.items.map((f) => f.id ?? '')).toPromise()
        setUserForms(userFormsRes.map((uf) => uf.id ?? ''))
      } catch (error) {
        console.error(`Error fetching form data for type ${formType}:`, error)
      }
    }

    const fetchTemplateData = async () => {
      if (!loggedUser) {
        return
      }

      try {
        const res = await formService
          .getFilteredList(
            new Query({
              pager: { offset: page * formsPerPage, limit: formsPerPage },
              query: [new QueryParam<FormQuery>('isTemplate', FormType.Template.toString())],
              sort: [{ field: 'TITLE', desc: false }],
            })
          )
          .toPromise()
        setTemplates(res.items)
        setForms(res.items)
        setCount(res.count)

        const userFormsRes = await getUserForms(res.items.map((f) => f.id ?? '')).toPromise()
        setUserForms(userFormsRes.map((uf) => uf.id ?? ''))
      } catch (error) {
        console.error('Error fetching template data:', error)
      }
    }

    const fetchFormOtherProfessional = async () => {
      if (!loggedUser) {
        return
      }

      try {
        const res = await formService
          .getListByUserPathology(
            new Query<FormQuery>({
              pager: { offset: page * formsPerPage, limit: formsPerPage },
              query: [
                new QueryParam<FormQuery>('userID', loggedUser?.id),
                new QueryParam<FormQuery>('creator', loggedUser?.id),
                new QueryParam<FormQuery>('others', 1),
                new QueryParam<FormQuery>('isTemplate', FormType.Form.toString()),
              ],
              sort: [{ field: 'TITLE', desc: false }],
            })
          )
          .toPromise()
        if (!res) return
        // const filterForm = res.items.filter((f) => f.isTemplate === FormType.Form)
        if (!showTemplatesInTable) {
          setForms(res.items)
          setCount(res.count)
        } else {
          setForms([])
        }

        const userFormsRes = await getUserForms(res.items.map((f) => f.id ?? '')).toPromise()
        if (!userFormsRes) return
        if (userFormsRes.length <= 0) return
        setUserForms(userFormsRes.map((uf) => uf.id ?? ''))
      } catch (error) {
        console.error(`Error fetching form data for type :`, error)
      }
    }

    if (!isExtern && !isManager) {
      if (showOtherProfessionalTemplates) {
        fetchFormOtherProfessional().then()
      } else {
        fetchFormData(FormType.Form).then()
        // fetchFormData(FormType.Template).then()
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (isManager && loggedUser?.roles.length > 1) {
        if (showOtherProfessionalTemplates) {
          fetchFormOtherProfessional().then()
          return
        }
        if (showTemplatesInTable) {
          fetchTemplateData().then()
        } else {
          fetchFormData(FormType.Form).then()
          fetchFormData(FormType.Template).then()
        }
      } else if (isManager && loggedUser?.roles.length === 1) {
        fetchTemplateData().then()
      } else if (isExtern) {
        fetchFormDataByCreatorID(FormType.Form).then()
      }
    }
  }, [
    formsPerPage,
    page,
    searcher,
    loggedUser,
    isExtern,
    isManager,
    // showAllTemplates,
    openModalTemplate,
    showTemplatesInTable,
    showOtherProfessionalTemplates,
  ])

  useEffect(() => {
    forms.forEach((form) => {
      if (form.specialities !== ' ') {
        form.specialty = form.specialities
      }
      if (form.circles.length > 1) {
        form.pathology = form.circles.split(',')
      }
    })
    // const interval = setInterval(() => setIsLoading(false), 1000)
    // return () => clearInterval(interval)
  }, [forms, searcher])

  useEffect(() => {
    if (!professionalTypeSelected) {
      // asserting the type (as string ) since we know this would't be undefined , since Profesional del SMS always will have a "especialidad"
      // ask in order to know if this will be always be true
      // asserted in order to get ride of TS error of possible undefined
      formService.getListByPathology(professionalType?.name as string).subscribe((res) => {
        handleResponse(res)
      })
    } else {
      formService.getListByPathology(professionalTypeSelected?.name).subscribe((res) => {
        handleResponse(res)
      })
    }
  }, [templates.length, professionalTypeSelected])

  // aux function to handleResponse from formService

  const handleResponse = (res: ItemList<Form>) => {
    const filterTemplate = res.items.filter((f) => f.isTemplate === FormType.Template)
    if (res.count === 0 || filterTemplate.length === 0) {
      setTemplates([])
      return
    }
    if (filterTemplate.length > 0) {
      setTemplates(filterTemplate)
    }
  }

  const createForm = () => navigate(`${ROUTE_FORM_GENERATOR_FORM}/${ROUTE_CREATE}`)
  const createTemplate = () => navigate(`${ROUTE_TEMPLATE_FORM_GENERATOR}/${ROUTE_TEMPLATE}`)

  const editForm = (form: Form) => {
    if (form.isTemplate === FormType.Template) {
      navigate(`${ROUTE_TEMPLATE_FORM_GENERATOR}/${form.id}`)
      return
    }
    navigate(`${ROUTE_FORM_GENERATOR_FORM}/${form.id}`)
  }

  const editTemplate = (id: string) => {
    setOpenModalTemplate(false)
    navigate(`${ROUTE_FORM_GENERATOR_FORM}/${id}`)
  }

  const removeForm = (form: Form) => {
    if (!form?.id) {
      return
    }
    navigate(`${ROUTE_FORM_GENERATOR}/remove`, { state: { formID: form.id } })
  }

  const goBack = () => navigate(ROUTE_FORM_GENERATOR)

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setFormsPerPage(10)
      return
    }
    setFormsPerPage(Number.parseInt(event.target.value))
  }

  const viewForm = (form: Form) => {
    if (!loggedUser) return
    if (form.creator !== loggedUser?.id && !isManager) {
      if (form.isTemplate === FormType.Template) {
        navigate(`${ROUTE_TEMPLATE_FORM_GENERATOR}/${form.id}`)
        return
      }
      navigate(`${ROUTE_FORM_GENERATOR_FORM}/${form.id}`)
    } else {
      if (
        form.creator !== loggedUser?.id &&
        isManager &&
        loggedUser?.roles.length > 1 &&
        form.isTemplate === FormType.Form
      ) {
        navigate(`${ROUTE_FORM_GENERATOR_FORM}/${form.id}`)
      } else {
        setCurrentForm(form)
        setIsViewForm(true)
      }
    }
  }

  const hideForm = (form: Form) => {
    if (!loggedUser) return false
    const isCreator = form.creator === loggedUser?.id
    const isManagerWithMultipleRoles = isManager && loggedUser?.roles.length > 1
    const isManagerAndIsTemplate = isManager
    const isTemplate = form.isTemplate === FormType.Template

    return (
      !isCreator &&
      !(isManagerWithMultipleRoles && isTemplate) &&
      !(isManagerAndIsTemplate && isTemplate)
    )
  }

  useEffect(() => {
    if (!loggedUser) return
    const auxForms: Form[] = []
    forms.forEach((form) => {
      if (form.subscribers?.includes(loggedUser?.id)) {
        auxForms.push(form)
      }
    })
    setFormSubscriber(auxForms)

    return () => {
      setFormSubscriber([])
    }
  }, [forms])

  const handlerAssign = (form: Form) => {
    setCurrentForm(form)
    setOpenModalAssign(true)
  }

  const handlerStopCopying = (form: Form) => {
    setCurrentForm(form)
    setOpenModalStopCopying(true)
  }

  const handleOpenModalTemplate = () => {
    setOpenModalTemplate(true)
  }

  const handleCloseModalTemplate = () => {
    setOpenModalTemplate(false)
  }

  const assignForm = () => {
    setOpenModalAssign(false)
    if (!currentForm?.id) {
      return
    }
    const arrayCheckBoxes: string[] = currentForm.checkBoxesSelected?.split(',') ?? []
    const parse = arrayCheckBoxes.map((str) => parseInt(str))
    const userFormWithSubscribers: UserFormWithCheckboxDTO = {
      id: loggedUser?.id ?? '',
      formID: currentForm.id || '',
      createCheckBoxes: parse as CheckboxAssignType[],
      pathologies: currentForm.circles.split(',') ?? [],
    }
    userFormService.addWithSubscribers(userFormWithSubscribers).subscribe((res) => {
      if (!res) return
      enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
      setFormSubscriber([...formsSubscriber, currentForm])
      goBack()
    })
  }

  const stopCopying = () => {
    setOpenModalStopCopying(false)
    if (!currentForm) return
    const formDTO = fromModel(currentForm)
    const arraySubscribers = currentForm?.subscribers?.split(',') ?? []
    const newArray = [...arraySubscribers]
    const index = newArray.indexOf(loggedUser?.id ?? '')
    if (index !== -1) {
      newArray.splice(index, 1)
    }
    const updateForm = Object.assign({
      ...formDTO,
      subscribers: newArray.join(','),
    })
    formService.update(updateForm).subscribe((res) => {
      if (!res) return
      enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
      setFormSubscriber((prevFormSubscriber) => {
        return prevFormSubscriber.filter((form) => form.id !== currentForm.id)
      })
      goBack()
    })
  }

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: formsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, formsPerPage])

  const search: Search<FormQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<FormQuery>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
      setPage(0)
    },
  }

  const actions: Actions<Form> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewForm,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: editForm,
        icon: editIcon,
        label: 'edit',
        hidden: hideForm,
      },
      {
        handler: handlerAssign,
        icon: ShareIcon,
        label: 'assign',
        hidden: (form) => {
          if (!loggedUser) return false
          const isFormCreator = form?.creator === loggedUser.id
          const hasCheckBoxesSelected = !form?.checkBoxesSelected

          if (isFormCreator || hasCheckBoxesSelected) {
            return true
          }

          return formsSubscriber.some((f) => f.id === form.id)
        },
      },
      {
        handler: handlerStopCopying,
        icon: unAssignIcon,
        label: 'stopCopying',
        hidden: (form) => {
          if (!loggedUser) return false
          const isFormCreator = form?.creator === loggedUser.id
          const hasCheckBoxesSelected = !form?.checkBoxesSelected
          if (isFormCreator || hasCheckBoxesSelected) {
            return true
          }

          return !formsSubscriber.some((f) => f.id === form.id)
        },
      },
      {
        handler: removeForm,
        icon: deleteIcon,
        label: 'delete',
        hidden: hideForm,
      },
    ],
  }

  return (
    <Box className={genericStyle.pageContainer}>
      {isViewForm && currentForm ? (
        <>
          <PreviewForm
            title={currentForm?.title}
            description={currentForm?.description}
            created={currentForm?.created}
            formFields={currentForm?.formFields as unknown as FormFieldDTO[]}
            repeating={currentForm?.repeating}
            startCron={currentForm?.startCron}
            finishCron={currentForm?.finishCron}
            isTemplate={currentForm?.isTemplate}
            creator={currentForm?.creator}
            fieldWithoutAnswer={currentForm?.fieldWithoutAnswer}
            id=""
            circles={currentForm?.circles}
            specialities={currentForm?.specialities}
            monthRecurrence={currentForm?.monthRecurrence}
            checkBoxesSelected={currentForm?.checkBoxesSelected}
          />
          <Box display="flex" justifyContent="flex-end">
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type="button"
              label={t('close')}
              handler={() => {
                setIsViewForm(false)
              }}
            />
          </Box>
        </>
      ) : (
        <>
          <Typography
            style={{
              fontFamily: 'Poppins',
              color: '#599ad6',
              fontWeight: 'bold',
              marginBottom: 20,
            }}
          >
            {t('createForm')}
          </Typography>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box mb={3} className={style.buttonContainer} display="flex">
              {isProfessionalOrExtern && (
                <>
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    label={t('whiteForm')}
                    handler={createForm}
                  />
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    type={'button'}
                    label={t('useTemplate')}
                    handler={handleOpenModalTemplate}
                  />
                </>
              )}
              {isManager && (
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('createTemplate')}
                  handler={createTemplate}
                />
              )}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {loggedUser && loggedUser?.roles.length >= 1 && isManager && (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ fontFamily: 'Poppins' }}>{t('templatesView')}</Typography>
                  <CustomSwitch
                    checked={showTemplatesInTable}
                    onChange={handlerShowTemplatesInTable}
                    theme={createTheme()}
                  />
                </Box>
              )}
              {!isExtern && (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ fontFamily: 'Poppins' }}>{t('otherForms')}</Typography>
                  <CustomSwitch
                    theme={createTheme()}
                    checked={showOtherProfessionalTemplates}
                    onChange={handlerShowOtherProfessionalTemplates}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <AppTable
            items={data}
            rowKeyField="id"
            fields={ChoiceFields(
              loggedUser,
              isExtern,
              formsSubscriber,
              isManager,
              showTemplatesInTable,
              t
            )}
            actions={actions}
            search={search}
            pager={pager}
            isPreventDefault={true}
          />
        </>
      )}
      {openModalTemplate ? (
        <ModalTemplate
          open={openModalTemplate}
          templates={templates}
          professionalType={professionalType}
          professionalSpecialities={professionalSpecialities}
          handlerShowAllTemplates={handlerShowAllTemplates}
          handlerProfessionalTypeSelected={handlerProfessionalTypeSelected}
          showAllTemplates={showAllTemplates}
          professionalTypeSelected={professionalTypeSelected}
          handleClose={handleCloseModalTemplate}
          editTemplate={editTemplate}
        />
      ) : null}
      <Modal open={openModalAssign} className={style.eventModal} onClose={handlerOpenModalAssign}>
        <>
          <CustomModal
            handleClose={handlerOpenModalAssign}
            handleSave={assignForm}
            title={t('assignForm')}
            warningText={t('irreversibleAssignFormAction')}
          />
        </>
      </Modal>
      <Modal
        open={openModalStopCopying}
        className={style.eventModal}
        onClose={handlerOpenModalStopCopying}
      >
        <>
          <CustomModal
            handleClose={handlerOpenModalStopCopying}
            handleSave={stopCopying}
            title={t('stopCopying')}
            warningText={t('irreversibleStopCopyingFormAction')}
          />
        </>
      </Modal>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<FormQuery>[]
): QueryParam<FormQuery>[] | QueryParamN<FormQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
