/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { Report, ReportQuery } from '../models/Report'
import { ReportDTO, toModelArray } from '../models/ReportDTO'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ReportContainerConfig } from '../container'
import { toModel } from '../models/ReportDTO'
import { GeneralInfo } from '../services/ReportService'
import { emptyList, ItemList } from 'common/models/ItemList'

export interface IReportApi extends IInit {
  getByID(id: string): Observable<Report | undefined>

  add(e: ReportDTO): Observable<Report | undefined>

  update(e: ReportDTO): Observable<Report | undefined>

  delete(id: string): Observable<boolean>

  getByUserCircleID(userCircleID: string): Observable<Report[] | undefined>

  getFilteredList(q: Query<ReportQuery>): Observable<ItemList<Report>> // Report? o Report[] ¿¿?¿

  getGeneralInformationbyId(reportId: string): Observable<GeneralInfo | undefined>

  getSpecificInformationbyId(reportId: string): Observable<any | undefined>

  getTrainingSchedulebyId(reportId: string): Observable<any | undefined>

  getSessionsAttendancebyId(reportId: string): Observable<any | undefined>

  getAvgAttendanceMonthById(reportId: string): Observable<any | undefined>

  getRcvValorationById(reportId: string): Observable<any | undefined>

  getAnthropometricById(reportId: string): Observable<any | undefined>

  getAvgIntensityMonthById(reportId: string): Observable<any | undefined>

  getAvgConditionMonthById(reportId: string): Observable<any | undefined>

  getConditionById(reportId: string): Observable<any | undefined>

  getHealthReportById(reportId: string): Observable<any | undefined>

  getEFDCommentsById(reportId: string): Observable<any | undefined>
}

export class ReportApi implements IReportApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ReportContainerConfig).moduleFullUrl
  }

  add(e: ReportDTO): Observable<Report | undefined> {
    return this._httpClient.post<Report>({ url: this._url + '/', body: e }).pipe(
      map<ReportDTO, Report>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Report | undefined> {
    return this._httpClient.get<Report>({ url: `${this._url}/${id}` }).pipe(
      map<ReportDTO, Report>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<ReportQuery>): Observable<ItemList<Report>> {
    return this._httpClient
      .get<ItemList<Report>>({ url: prepareURL(`${this._url}/reports-search`, q) })
      .pipe(
        map<ItemList<ReportDTO>, ItemList<Report>>((dto) => {
          const itemList = emptyList<Report>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => {
            return toModel(d)
          })
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Report>())
        })
      )
  }

  update(e: ReportDTO): Observable<Report | undefined> {
    return this._httpClient.put<Report>({ url: this._url, body: e }).pipe(
      map<ReportDTO, Report>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByUserCircleID(userCircleID: string): Observable<Report[] | undefined> {
    return this._httpClient
      .get<Report[]>({ url: `${this._url}/by-user-circle-id/${userCircleID}` })
      .pipe(
        // @ts-ignore
        map<ReportDTO[], Report[]>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByUserID(userCircleID: string): Observable<Report[] | undefined> {
    return this._httpClient.get<Report[]>({ url: `${this._url}/by-user-id/${userCircleID}` }).pipe(
      // @ts-ignore
      map<ReportDTO[], Report[]>((d) => {
        return d
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getGeneralInformationbyId(reportId: string): Observable<GeneralInfo | undefined> {
    return this._httpClient
      .get<GeneralInfo>({ url: `${this._url}/general-information-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<GeneralInfo>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getSpecificInformationbyId(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/specific-information-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getTrainingSchedulebyId(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/training-schedule-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getSessionsAttendancebyId(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/sessions-attendance-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getRcvValorationById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/rcv-valoration-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getAnthropometricById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/anthropometric-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getAvgAttendanceMonthById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/average-attendance-per-month-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getAvgIntensityMonthById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/average-intensity-per-month-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getAvgConditionMonthById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/average-condition-per-month-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getConditionById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/physical-condition-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getHealthReportById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/health-formulary-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getEFDCommentsById(reportId: string): Observable<any | undefined> {
    return this._httpClient
      .get<any>({ url: `${this._url}/edf-comments-by-report-id/${reportId}` })
      .pipe(
        // @ts-ignore
        map<any>((d) => {
          return d
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
