import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { useEffect, useState } from 'react'
import { getUserCircleContainer } from 'container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getUserContainer } from 'container/user-module'
import { CircleDTO } from 'modules/circle/models/CircleDTO'
import { Box, ListItem, ListItemText, Skeleton } from '@mui/material'
import style from '../../patient/chats-card/ChatsCard.module.css'
import { listItemTextStyle } from '../../patient/notification-card-patient/NotificationsCardStyleMui'
import { useProfCircle } from 'common/utils/prof-circle-context/ProfCircleContext'
import { finalize } from 'rxjs/operators'
import { useGlobalContext } from '../../../../common/utils/GlobalRoleContext'
import { Roles } from '../../../../modules/users/enums/Roles'
import {
  ACTIVE_PLAN_CIRCLE_ID,
  EPILEPSY_CIRCLE_ID,
  HEALTH_CHILD_CIRCLE_ID,
} from '../../../../common/const'
import { getCircleContainer } from '../../../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../../../modules/circle'
import { Query } from '../../../../common/api/Query'
import { CircleQuery } from '../../../../modules/circle/models/Circle'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)

export const ProfessionalCirclesCard = () => {
  const { role } = useGlobalContext()
  const isManager = role.some((role) => role.name.startsWith('manager'))
  const [circles, setCircles] = useState<CircleDTO[]>([])
  const loggedUser = loggedUserService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { selectedProfCircle, setSelectedProfCircle } = useProfCircle()
  const isEpilepticManager = role.some((role) => role.name === Roles.ManagerEpileptic)
  const isHealthChildManager = role.some((role) => role.name === Roles.ManagerHealthChild)
  const isPlanActiveManager = role.some((role) => role.name === Roles.ManagerActivePlan)

  const { selectedUserCircle } = useCircleConfiguration()

  useEffect(() => {
    setSelectedProfCircle(selectedUserCircle?.circle)
  }, [])

  useEffect(() => {
    setIsLoading(true)

    if (isManager) {
      circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
        if (res) {
          if (isEpilepticManager) {
            const epilepsyCircle = res.items.find((res) => res.id === EPILEPSY_CIRCLE_ID)
            setCircles(epilepsyCircle ? [epilepsyCircle] : [])
            setSelectedProfCircle(epilepsyCircle)
            setIsLoading(false)
            return
          }
          if (isHealthChildManager) {
            const healthChildCircle = res.items.find((res) => res.id === HEALTH_CHILD_CIRCLE_ID)
            setCircles(healthChildCircle ? [healthChildCircle] : [])
            setSelectedProfCircle(healthChildCircle)
            setIsLoading(false)
            return
          }
          if (isPlanActiveManager) {
            const activePlanCircle = res.items.find((res) => res.id === ACTIVE_PLAN_CIRCLE_ID)
            setCircles(activePlanCircle ? [activePlanCircle] : [])
            setSelectedProfCircle(activePlanCircle)
            setIsLoading(false)
            return
          }
          setIsLoading(false)
        }
      })
      return
    }

    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        if (res) {
          // obtenemos un array unico de circulos
          const uniqueCircles: CircleDTO[] = []
          const allCirclesAux: CircleDTO[] = []
          res.forEach((userCircle) => {
            allCirclesAux.push(userCircle.circle)
            if (!uniqueCircles.find((circle) => circle.id === userCircle.circle.id)) {
              uniqueCircles.push(userCircle.circle)
            }
          })
          setCircles(uniqueCircles)
        }
      })
  }, [])

  return (
    <DashboardSectionCard label={'myCircles'}>
      <Box
        sx={{
          maxHeight: '295px',
          overflowY: 'auto',
        }}
      >
        {isLoading &&
          Array.from({ length: 3 }).map((n, i) => {
            return (
              <ListItem className={style.notificationContainer} key={i}>
                <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                  <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: '5px' }} />
                </ListItemText>
              </ListItem>
            )
          })}

        {!isLoading &&
          circles.map((circle) => (
            <ListItem
              key={circle.id}
              onClick={(event) => {
                setSelectedProfCircle(selectedProfCircle === circle ? undefined : circle)
              }}
              sx={{
                hover: 'none',
                cursor: 'pointer',
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Box width={'100%'}>
                <Box
                  sx={{
                    backgroundColor:
                      selectedProfCircle?.id === circle.id ? 'rgb(241, 139, 36, 0.15)' : 'white',
                    borderColor:
                      selectedProfCircle?.id === circle.id ? 'rgb(241, 139, 36)' : '#68B3E0',
                  }}
                  display={'flex'}
                  alignItems={'center'}
                  textAlign={'center'}
                  mr={1}
                  ml={1}
                  className={style.chatContainer}
                >
                  <ListItemText sx={listItemTextStyle}>{circle.name}</ListItemText>
                </Box>
              </Box>
            </ListItem>
          ))}
      </Box>
    </DashboardSectionCard>
  )
}
