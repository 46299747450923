import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Query, QueryParam } from 'common/api/Query'
import { TransportType } from 'common/enums/TransportType'
import { getNotificationContainer } from 'container/notification-module'
import { NotificationService } from 'modules/notifications/services/NotificationService'
// import { MESSAGES_SERVICE_KEY, NOTIFICATION_SERVICE_KEY } from 'modules/notifications'
import { Message } from 'modules/notifications/models/Message'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
// import { forkJoin, Observable } from 'rxjs'
// import { MessageService } from 'modules/notifications/services/MessageService'
import { SourceType } from 'modules/notifications/enums/SourceType'
import trainingIcon from 'assets/dashboard/trainingIcon.svg'
import formIcon from 'assets/dashboard/formIcon.svg'
import resourcesIcon from 'assets/dashboard/resourceIcon.svg'
import calendarIcon from 'assets/dashboard/calendarIcon.svg'
import chatIcon from 'assets/dashboard/chatIcon.svg'
import libraryIcon from 'assets/dashboard/libraryIcon.svg'
import symptomIcon from 'assets/dashboard/symptomIcon.svg'
import reportIcon from 'assets/dashboard/reportIcon.png'
import treatmentIcon from 'assets/dashboard/treatmentIcon.svg'
import style from './NotificationCard.module.scss'
import { listItemTextStyle } from './NotificationsCardStyleMui'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_CALENDAR,
  ROUTE_MESSENGER,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_SYMPTOMS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_TREATMENTS,
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_FORM,
  ROUTE_SEGMENTED_NOTIFICATIONS,
  ROUTE_USERS,
  ROUTE_ACTIVE_PLAN_DATA,
  ROUTE_TELECONSULTATION,
} from 'routes/routes-constants'
import { DeliveryStatus } from 'modules/notifications/enums/DeliveryStatus'
import { useProfCircle } from 'common/utils/prof-circle-context/ProfCircleContext'
import { finalize } from 'rxjs/operators'
// juan
// import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { NotificationWithMessage } from 'modules/notifications/models/NotificationWithMessage'
import { NOTIFICATION_SERVICE_KEY } from 'modules/notifications'
import { useCircleConfiguration } from '../../../../common/utils/circle-config-context/CircleConfigContext'
import { UserCircleWithCircleAndUser } from '../../../../modules/user-circle/models/UserCircleWithCircleAndUser'
import { useGlobalContext } from '../../../../common/utils/GlobalRoleContext'
//

type NotificationsCardProps = {
  ProfessionalNotifications: boolean
  SetNumberNewResources: (number: number) => void
}
// const notificationsPerPage = 7
const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
// const messageService = notificationContainer.get<MessageService>(MESSAGES_SERVICE_KEY)

export const NotificationsCard: React.FC<NotificationsCardProps> = (props): JSX.Element => {
  const loggedUser = loggedUserService.get()
  const { role } = useGlobalContext()
  const isManager = role.some((role) => role.name.startsWith('manager'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [notifications, setNotifications] = useState<N[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notificationMessages, setNotificationMessages] = useState<Map<string, Message>>(new Map())
  const { selectedProfCircle } = useProfCircle()
  const navigate = useNavigate()
  const { userCircles, setUserCircleID } = useCircleConfiguration()

  const [notifications, setNotifications] = useState<NotificationWithMessage[]>([])
  const [notificationsOriginal, setNotificationsOriginal] = useState<NotificationWithMessage[]>([])

  // const { selectedUserCircle } = useCircleConfiguration()

  const FILTER_SOURCE_TYPES = [
    SourceType.CalendarEventCreated,
    SourceType.FilesNewCreated,
    SourceType.ContentNewArticle,
    SourceType.ContentArticleRejected,
    SourceType.ContentArticleValidated,
    SourceType.FormsNewUserForm,
    SourceType.PatientSymptomCreated,
    SourceType.TreatmentCreated,
    SourceType.NewRegisteredUser,
    SourceType.NewSegmentedNotification,
    SourceType.NewReport,
    SourceType.QueryMessageCreated,
    SourceType.NewOnlineConsultation,
  ]

  useEffect(() => {
    // filter notifications by circleID find in userCircles
    const circleID = selectedProfCircle?.id
    if (!circleID) {
      setNotifications(notificationsOriginal)
      return
    }
    if (isManager) return
    const userCirclesFiltered: UserCircleWithCircleAndUser[] = []
    userCircles.items.forEach((userCircle) => {
      if (userCircle.circle.id === circleID) {
        userCirclesFiltered.push(userCircle)
      }
    })
    // filter notifications by userCircleID
    const userCircleIDs = userCirclesFiltered.map((userCircle) => userCircle.id)
    const notificationsFiltered = notificationsOriginal.filter((notification) =>
      userCircleIDs.includes(notification.userCircleID)
    )
    setNotifications(notificationsFiltered)
    const newFiles = notificationsFiltered.filter(
      (n) => n.sourceType === SourceType.FilesNewCreated
    )
    props.SetNumberNewResources(newFiles.length)
  }, [selectedProfCircle])

  // NEW EFFECT
  useEffect(() => {
    if (!loggedUser) return

    setIsLoading(true)
    // Si no hay un selectedUserCircle, no haces nada o puedes usar un valor por defecto
    // const recipientID = selectedUserCircle ? selectedUserCircle.user.id : loggedUser.id

    const query = [
      new QueryParam<NotificationWithMessage>('recipientID', loggedUser.id),
      new QueryParam<NotificationWithMessage>('transportType', TransportType.App),
      new QueryParam<NotificationWithMessage>('sourcesType', FILTER_SOURCE_TYPES),
      // filtro adicional-- enviar cirlce ID--back --filtrar userCircleID
    ]
    notificationService
      .getFilteredListWithMessage(
        new Query({
          // pager: { offset: notificationsPage * notificationsPerPage, limit: notificationsPerPage },
          query,
          sort: [{ field: 'sendAt', desc: true }],
        })
      )
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        // Filtrar notificaciones de nuevos recursos

        const newFiles = res.items.filter((n) => n.sourceType === SourceType.FilesNewCreated)
        props.SetNumberNewResources(newFiles.length)
        setNotifications(res.items)
        setNotificationsOriginal(res.items)
      })
  }, []) // dep: selectedUserCircle --> cambiar por el circle name selected or somehtign like that

  const handleOnClick = (notification: NotificationWithMessage) => {
    if (notification && notification.deliveryStatus !== DeliveryStatus.Viewed) {
      notificationService.updateStatus(notification.id as string, DeliveryStatus.Viewed).subscribe()
      setUserCircleID(notification.userCircleID)
    }
    setIsLoading(true)
    setTimeout(() => {
      switch (notification.sourceType) {
        case SourceType.CalendarEventCreated:
        case SourceType.CalendarEventUpdated:
          navigate(ROUTE_CALENDAR)
          break
        case SourceType.FilesNewCreated:
          navigate(ROUTE_RESOURCES)
          break

        case SourceType.ContentNewArticle:
          if (!notification.segmentedID) {
            navigate(`${ROUTE_ARTICLES}/${notification.message.url}`)
          } else {
            navigate(`${ROUTE_SEGMENTED_NOTIFICATIONS}/${notification.segmentedID}`)
          }
          break
        case SourceType.FormsNewUserForm:
          navigate(ROUTE_PATIENT_FORMS)
          break
        case SourceType.FormsUserFormFilled:
          navigate(ROUTE_PATIENT_FORMS)
          break
        case SourceType.PatientSymptomCreated:
          navigate(ROUTE_PATIENT_SYMPTOMS)
          break
        case SourceType.TreatmentCreated:
          navigate(ROUTE_TREATMENTS)
          break
        case SourceType.TreatmentReminder:
          navigate(ROUTE_TREATMENTS)
          break
        case SourceType.NewRegisteredUser:
          navigate(ROUTE_USERS)
          break
        case SourceType.NewReport:
          // cuando se clica en la notification del cuadro de mandos seteamos la ventana 2 de local que es informes
          localStorage.setItem('selectedTab', '2')
          navigate(ROUTE_ACTIVE_PLAN_DATA)
          break
        case SourceType.QueryMessageCreated:
          navigate(ROUTE_QUERY)
          break
        case SourceType.MessengerMessageCreated:
          navigate(ROUTE_MESSENGER)
          break
        case SourceType.NewOnlineConsultation:
          navigate(ROUTE_TELECONSULTATION)
          break
        case SourceType.NewSegmentedNotification:
          navigate(ROUTE_SEGMENTED_NOTIFICATIONS)
          break
        case SourceType.ContentArticleRejected:
          // navigate(ROUTE_MY_POSTS_CONTENT)
          navigate(`${ROUTE_ARTICLES_FORM}/${notification.message.url}`)
          break
        case SourceType.ContentArticleValidated:
          // navigate(ROUTE_MY_POSTS_CONTENT)
          navigate(`${ROUTE_ARTICLES}/${notification.message.url}`)
          break
      }
      setIsLoading(false)
    }, 500)
  }

  const mappedNotifications = () =>
    notifications.map((n) => {
      if (!n.id) {
        return null
      }

      const message = n.message

      if (!message || message.sourceType === SourceType.MessengerMessageCreated) {
        return null
      }
      const handleClick = () => {
        handleOnClick(n)
      }

      return (
        <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }} key={message.id} onClick={handleClick}>
          <Box width={'100%'}>
            <Box display={'flex'} mr={1} ml={1}>
              <ListItemIcon>{getIcon(message.sourceType, message.title)}</ListItemIcon>
              <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                {message.title}
              </ListItemText>
            </Box>
            <Divider className={style.divider} />
          </Box>
        </ListItem>
      )
    })

  const getIcon = (type: SourceType, id: string): JSX.Element => {
    switch (type) {
      case SourceType.CalendarEventCreated:
      case SourceType.CalendarEventUpdated:
        return <img className={style.icon} src={calendarIcon} alt={calendarIcon} />
      case SourceType.FilesNewCreated:
        return <img className={style.icon} src={resourcesIcon} alt={resourcesIcon} />
      case SourceType.ContentNewArticle:
        if (!id.includes('Una nueva notificación')) {
          return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
        } else {
          return <img className={style.icon} src={chatIcon} alt={chatIcon} />
        }
      case SourceType.ContentArticleRejected:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.ContentArticleValidated:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.FormsNewUserForm:
        return <img className={style.icon} src={formIcon} alt={formIcon} />
      case SourceType.FormsUserFormFilled:
        return <img className={style.icon} src={formIcon} alt={formIcon} />
      case SourceType.PatientSymptomCreated:
        return <img className={style.icon} src={symptomIcon} alt={symptomIcon} />
      case SourceType.TreatmentCreated:
        return <img className={style.icon} src={treatmentIcon} alt={treatmentIcon} />
      case SourceType.TreatmentReminder:
        return <img className={style.icon} src={treatmentIcon} alt={treatmentIcon} />
      case SourceType.NewRegisteredUser:
        return <img className={style.icon} src={libraryIcon} alt={libraryIcon} />
      case SourceType.QueryMessageCreated:
        return <img className={style.icon} src={chatIcon} alt={chatIcon} />
      case SourceType.NewSegmentedNotification:
        return <img className={style.icon} src={chatIcon} alt={chatIcon} />
      case SourceType.ActivePlanCreated:
        return <img className={style.icon} src={trainingIcon} alt={trainingIcon} />
      case SourceType.NewReport:
        return <img className={style.icon} src={reportIcon} alt={trainingIcon} />
    }
    return <></>
  }

  return (
    <DashboardSectionCard label={'notifications'}>
      <List className={style.containerList}>
        {isLoading &&
          Array.from({ length: 5 }).map((n, i) => {
            return (
              <ListItem className={style.notificationContainer} key={i}>
                <ListItemIcon>
                  <Skeleton
                    width={24}
                    height={24}
                    style={{ borderRadius: '5px' }}
                    variant="rectangular"
                  />
                </ListItemIcon>
                <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                  <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: '5px' }} />
                </ListItemText>
              </ListItem>
            )
          })}
        {!isLoading && notifications.length > 0 && mappedNotifications()}
      </List>
    </DashboardSectionCard>
  )
}
