export enum SourceType {
  CalendarEventCreated = 1,
  CalendarEventUpdated = 2,
  CalendarNotification = 10,
  MessengerMessageCreated = 51,
  QueryMessageCreated = 52,
  FormsNewUserForm = 101,
  FormsUserFormFilled = 102,
  FilesNewCreated = 301,
  ContentNewArticle = 401,
  ContentArticleRejected = 402,
  ContentArticleValidated = 403,
  PatientSymptomCreated = 501,
  TreatmentCreated = 601,
  TreatmentReminder = 602,
  NewRegisteredUser = 702,
  ActivePlanCreated = 801,
  ActivePlanPending = 802,
  ActivePlanRefused = 803,
  ActivePlanActive = 804,
  ActivePlanFinished = 805,
  NewSegmentedNotification = 901,
  NewReport = 902,
  NewOnlineConsultation = 1001,
}

export const sourceTypes = (): Record<SourceType, string> => ({
  [SourceType.CalendarEventCreated]: 'new event',
  [SourceType.CalendarEventUpdated]: 'event updated',
  [SourceType.CalendarNotification]: 'event notification',
  [SourceType.MessengerMessageCreated]: 'new message',
  [SourceType.QueryMessageCreated]: 'new query message',
  [SourceType.FormsNewUserForm]: 'form pending',
  [SourceType.FormsUserFormFilled]: 'form filled',
  [SourceType.FilesNewCreated]: 'a new resource was created',
  [SourceType.ContentNewArticle]: 'a new article was created',
  [SourceType.ContentArticleRejected]:
    'the article you published was rejected by the content manager',
  [SourceType.ContentArticleValidated]: 'a new article has to be validated',
  [SourceType.PatientSymptomCreated]: 'a new patient symptom was created',
  [SourceType.TreatmentCreated]: 'a new treatment was created',
  [SourceType.TreatmentReminder]: 'treatment reminder',
  [SourceType.NewRegisteredUser]: 'a new user has been registered',
  [SourceType.ActivePlanCreated]: 'new active plan',
  [SourceType.ActivePlanPending]: 'active plan pending',
  [SourceType.ActivePlanRefused]: 'active plan refused',
  [SourceType.ActivePlanActive]: 'active plan active',
  [SourceType.ActivePlanFinished]: 'active plan finished',
  [SourceType.NewSegmentedNotification]: 'a new notification from your segmented group was created',
  [SourceType.NewReport]: 'a new report was created',
  [SourceType.NewOnlineConsultation]: 'new online consultation',
})
