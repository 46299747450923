import { RouteProps } from '../../routes/AppRouter'
import { PromsPremsRemove as FGR } from '../../features/premsProms'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'

type PromsPremsRemoveProps = {
  formID?: string
} & RouteProps

export function PromsPremsRemove(props: PromsPremsRemoveProps) {
  const location = useLocation()
  const title = props.title ?? ''

  const state: PromsPremsRemoveProps = location?.state as PromsPremsRemoveProps

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <FGR formID={state?.formID} />
    </>
  )
}
