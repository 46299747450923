import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { TableHistory } from './TableHistory'
import { Header } from '../../components/header/Header'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)

type SearchProps = {
  tagList: string[]
}

export function SearchHistory(props: SearchProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [circles, setUserCircles] = useState<any>([])

  useEffect(() => {
    const circles: any[] = []

    if (!loggedUser) return
    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          const uniqueCircles: CircleDTO[] = []
          res.forEach((userCircle) => {
            if (!uniqueCircles.find((circle) => circle.id === userCircle.circle.id)) {
              circles.push(userCircle.circle.id)
            }
          })
          setUserCircles(circles)
        }
      })
  }, [])

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Header label={t('historyContent')} />
      </Box>
      <Box mb={3}>
        <TableHistory circles={circles} />
      </Box>
    </Box>
  )
}
