import React, { useEffect, useState } from 'react'
import { Tag } from '../../modules/content/models/Tag'
import { Box, Modal } from '@mui/material'
import { ROUTE_CREATE, ROUTE_MY_POSTS_CONTENT, ROUTE_TAG_FORM } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import style from '../generic/GenericTable.module.css'
import { Query } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { TAG_SERVICE_KEY } from '../../modules/content'
import { TagService } from '../../modules/content/services/TagService'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager } from '../../components/table_type/types'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import { CustomModal } from '../../components/modal/CustomModal'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const tagsService = contentContainer.get<TagService>(TAG_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()
  const [tags, setTags] = useState<ItemList<Tag>>(emptyList<Tag>())
  const [currentTag, setCurrentTag] = useState<Tag>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [tagsPerPage, setTagsPerPage] = useState<number>(10)
  const [totalTags, setTotalTags] = useState<number>(0)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      return
    }
    tagsService
      .getFilteredList(
        new Query({
          pager: { offset: page * tagsPerPage, limit: tagsPerPage },
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setTags(res)
        setTotalTags(res.count)
        setCount(res.count)
      })
  }, [isLoading])

  const createTag = () => navigate(`${ROUTE_TAG_FORM}/${ROUTE_CREATE}`)

  const editTag = (tag: Tag) => navigate(`${ROUTE_TAG_FORM}/${tag.id}`)

  const removeTag = (tag: Tag) => {
    setCurrentTag(tag)
    setOpenDeleteModal(true)
  }

  const hideTag = (_: Tag) => !loggedUserService.userCan(Permission.createContent)

  const goBack = () => navigate(ROUTE_MY_POSTS_CONTENT)

  const lastElementRemoved = () =>
    page === count && Math.round(totalTags / tagsPerPage) === page - 1

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setTagsPerPage(10)
      return
    }
    setTagsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: tagsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, tagsPerPage])

  const fields: Field<Tag>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'description',
      label: t('description'),
    },
  ]

  const actions: Actions<Tag> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editTag,
        icon: editIcon,
        label: 'edit',
        hidden: hideTag,
      },
      {
        handler: removeTag,
        icon: deleteIcon,
        label: 'delete',
        hidden: hideTag,
      },
    ],
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteEvent = () => {
    if (currentTag)
      tagsService.delete(currentTag.id).subscribe((_) => {
        if (lastElementRemoved() && page !== 1) {
          handlePaginationChange(_, page - 1)
        } else {
          setIsLoading(true)
        }
      })
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <>
      {!isLoading ? (
        <Box className={genericStyle.pageContainer}>
          <Box mb={3} className={style.buttonContainer} display="flex">
            {loggedUserService.userCan(Permission.createContent) && (
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('createTag')}
                handler={createTag}
              />
            )}
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('backOneToResources')}
              handler={goBack}
            />
          </Box>
          <Modal
            open={openDeleteModal}
            className={style.eventModal}
            onClose={handleCloseDeleteModal}
          >
            <CustomModal
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteEvent}
              title={t('deleteTag')}
              warningText={t('irreversibleTagAction')}
            />
          </Modal>
          <AppTable
            items={tags.items}
            rowKeyField="id"
            fields={fields}
            actions={actions}
            pager={pager}
          />
        </Box>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </>
  )
}
