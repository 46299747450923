import React from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent } from './ActivePlanCard'
import { GenericChartDashboardComponent } from '../GenericChartDashboardComponent'

type GlobalDailyAttendanceCardProps = {
  attendanceDays: number[]
  isLoading: boolean
}
const colors = ['#0063A6', '#F18B24', '#B6B5B5']

export const GlobalDailyAttendanceCard: React.FC<GlobalDailyAttendanceCardProps> = ({
  attendanceDays,
}) => {
  const { t } = useTranslation()
  const labels = [t('attendance'), t('noAttendance'), t('daysNotReported')]
  const datasets = [
    {
      backgroundColor: colors,
      data: attendanceDays,
    },
  ]

  const drawPercent = (value: number[]): string => {
    let result = ''
    if (!value[0] && !value[1] && !value[2]) return result

    result = ' ' + Math.floor((value[0] / (value[1] + value[2] + value[0])) * 100) + '%'
    return result
  }

  return (
    <CardComponent title={t('globalDailyAttendance')} percent={drawPercent(attendanceDays)}>
      <GenericChartDashboardComponent
        datasets={datasets}
        labels={labels}
        typeChart="pie"
        legendPosition="bottom"
        title={''}
        showXAxis={false}
        showYAxis={false}
      />
    </CardComponent>
  )
}
