import { v4 as uuidv4 } from 'uuid'
import { ActivePlanWithUser } from './ActivePlanWithUser'
import { User } from '../../users/models/User'

export interface ActivePlanWithUserDTO {
  id: string
  userCircleID: string
  userProfessionalID: string
  professionalMessage: string
  status: string
  startDate: Date
  finishDate: Date
  reason: string
  observation: string
  protocol: string
  user: User
  rcv: number
}

export function emptyActivePlanWithUserDTO() {
  return {
    id: uuidv4(),
    userCircleID: '',
    userProfessionalID: '',
    professionalMessage: '',
    status: '',
    startDate: new Date(),
    finishDate: new Date(),
    reason: '',
    observation: '',
    protocol: '',
    user: {
      id: '',
      name: '',
      email: '',
      password: '',
      role: '',
      status: '',
      createdAt: new Date(),
      updatedAt: new Date(),
    },
    rcv: 1,
  }
}

export function fromModel(e: ActivePlanWithUser): ActivePlanWithUserDTO {
  return {
    id: e.id,
    userCircleID: e.userCircleID,
    userProfessionalID: e.userProfessionalID,
    professionalMessage: e.professionalMessage,
    status: e.status,
    startDate: e.startDate,
    finishDate: e.finishDate,
    reason: e.reason,
    observation: e.observation,
    protocol: e.protocol,
    user: e.user,
    rcv: e.rcv,
  }
}

export function toModel(d: ActivePlanWithUserDTO): ActivePlanWithUser {
  return new ActivePlanWithUser(d)
}

export function toModelArray(activePlanArr: ActivePlanWithUserDTO[]): ActivePlanWithUser[] {
  const auxArray: ActivePlanWithUser[] = []
  activePlanArr.forEach((value) => auxArray.push(toModel(value)))
  return auxArray
}
