import { getUserContainer } from '../../container/user-module'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { User, UserQuery } from '../../modules/users/models/User'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { BoolQueryParam, Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import { ROUTE_FORM_GENERATOR } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import styles from './TableCircles.module.css'
import { useSnackbar } from 'notistack'
import plus from '../../assets/table_icons/ico-tabla-plus.svg'
import minus from '../../assets/table_icons/ico-tabla-minus.svg'
import { v4 as uuidv4 } from 'uuid'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import spinnerStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { getFormContainer } from '../../container/form-module'
import { UserForm } from '../../modules/forms/models/UserForm'
import { ReadingStatus } from '../../modules/forms/enums/ReadingStatus'
import { forkJoin, Observable, of } from 'rxjs'
import selectedUser from '../../assets/table_icons/ico-check-hover.svg'
import notSelectedUser from '../../assets/table_icons/ico-check.svg'
import { RolesService } from '../../modules/users/services/RolesServices'
import { Roles } from '../../modules/users/enums/Roles'
import { useGlobalContext } from '../../common/utils/GlobalRoleContext'
import { Alert, Box, Checkbox } from '@mui/material'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)

enum RolesToAssign {
  CIRCLES = 'allCircles',
  PATIENTS = 'allPatients',
  FAMILIARS = 'allFamiliars',
  CARERS = 'allCarers',
  EXTERN = 'allExtern',
}

type AssignedUser = {
  userID: string
  circleID: string | null
}

type RowItem = {
  uuid: string
  id: string
  name: string
  birthDate: Date
  cip: string
  dni: string
  circleID: string | null
  roles: string[]
}

type RolesCheckbox = {
  label: RolesToAssign
  checked: boolean
}

type TableCirclesProps = {
  id?: string
  allPatients?: User[]
  handlerUpdateUsers?: (users: AssignedUser[]) => void
  handlerUpdateAssignedUsers?: (users: AssignedUser[]) => void
}

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)

export function TableCircles(props: TableCirclesProps) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const loggedUser = loggedUserService.get()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [circles, setCircles] = useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [userItems, setUserItems] = useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [circlesPerPage, setCirclesPerPage] = useState<number>(999)
  const [pager, setPager] = useState<Pager>()
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'cip',
      label: t('CIP'),
    },
  ])
  const [circlesUsers, setCirclesUsers] = useState<Map<string, RowItem[]>>(new Map())
  const [currentOpenCircle, setCurrentOpenCircle] = useState<string>('')
  const [checkboxRoles, setCheckboxRoles] = useState<RolesCheckbox[]>(
    Object.values(RolesToAssign).map((f) => ({ label: f, checked: false }))
  )
  const [assignedUsers, setAssignedUsers] = useState<AssignedUser[]>([])
  const [message, setMessage] = useState<string>('')
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [allPatients, setAllPatients] = useState<User[]>([])
  const [allFamiliars, setAllFamiliars] = useState<Map<string, string[]>>(
    new Map<string, string[]>()
  )
  const [allCuidador, setAllCuidadores] = useState<Map<string, string[]>>(
    new Map<string, string[]>()
  )

  const [allExtern, setAllExtern] = useState<Map<string, string[]>>(new Map<string, string[]>())
  const { role } = useGlobalContext()

  useEffect(() => {
    userService.getUsersByDoctor(loggedUser?.id ?? '').subscribe((res) => {
      setAllPatients(res)
    })
  }, [])

  useEffect(() => {
    if (allPatients.length > 0) {
      const fams: Map<string, string[]> = new Map<string, string[]>()
      allPatients.forEach((patient, i) => {
        userService.getRelated(patient.id).subscribe((res) => {
          const f = res?.relatedUsers.filter((related) => related.kind === 3)
          if (f && f?.length > 0) {
            fams.set(
              patient.id,
              f.map((f) => f.id)
            )
          }
          if (i === allPatients.length - 1) {
            setAllCuidadores(fams)
          }
        })
      })
    }
  }, [allPatients])

  useEffect(() => {
    if (allPatients.length > 0) {
      const fams: Map<string, string[]> = new Map<string, string[]>()
      allPatients.forEach((patient, i) => {
        userService.getRelated(patient.id).subscribe((res) => {
          const f = res?.relatedUsers.filter((related) => related.kind === 7)
          if (f && f?.length > 0) {
            fams.set(
              patient.id,
              f.map((f) => f.id)
            )
          }
          if (i === allPatients.length - 1) {
            setAllExtern(fams)
          }
        })
      })
    }
  }, [allPatients])

  useEffect(() => {
    if (allPatients.length > 0) {
      const fams: Map<string, string[]> = new Map<string, string[]>()
      allPatients.forEach((patient, i) => {
        userService.getRelated(patient.id).subscribe((res) => {
          const f = res?.relatedUsers.filter((related) => related.kind === 6)
          if (f && f?.length > 0) {
            fams.set(
              patient.id,
              f.map((f) => f.id)
            )
          }
          if (i === allPatients.length - 1) {
            setAllFamiliars(fams)
          }
        })
      })
    }
  }, [allPatients])

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })

    if (!props.id) {
      return
    }
    userFormService
      .getFilteredList(
        new Query({
          query: [new QueryParam('formID', props.id)],
        })
      )
      .subscribe((res) => {
        setAssignedUsers(res.items.map((u) => ({ userID: u.userID, circleID: u.circleID })))
      })
  }, [])

  useEffect(() => {
    const ids = loggedUser?.related.map((r) => r.id) ?? []
    ids.push(loggedUser?.id ?? '')
    userService
      .getFilteredList(
        new Query({
          pager: { offset: page * circlesPerPage, limit: circlesPerPage },
          query: [
            {
              name: 'isCircle',
              value: new BoolQueryParam(true),
            },
            {
              name: 'ids',
              value: ids,
            },
            ...searcherQuery(searcher),
          ],
        })
      )
      .subscribe((cs) => {
        setCount(cs.count)
        const newList = emptyList<RowItem>()
        const roles: string[] = []
        role.forEach((r) => {
          roles.push(r.name)
        })
        newList.items = cs.items.map((u) => ({
          uuid: uuidv4(),
          id: u.id,
          name: u.name,
          birthDate: u.birthDate,
          cip: u.cip,
          dni: u.dni,
          circleID: null,
          roles: u.roles,
        }))
        newList.count = cs.count
        setUserItems(newList)
        setCircles(newList)
      })
  }, [pager, searcher])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: circlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, circlesPerPage])

  useEffect(() => {
    setMessage('')
  }, [assignedUsers, checkboxRoles])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const goBack = () => navigate(ROUTE_FORM_GENERATOR)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setCirclesPerPage(10)
      return
    }
    setCirclesPerPage(Number.parseInt(event.target.value))
  }

  const showUsersInCircle = (id: string) => setCurrentOpenCircle(id === currentOpenCircle ? '' : id)

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    !currentOpenCircle && setUserItems(circles)

    if (circlesUsers.has(currentOpenCircle)) {
      const index = circles.items.findIndex((c) => c.id === currentOpenCircle)
      if (index > -1) {
        const circlesCopy = emptyList<RowItem>()
        circlesCopy.items = circles.items
          .slice(0, index + 1)
          .concat(circlesUsers.get(currentOpenCircle) ?? [], circles.items.slice(index + 1))
        circlesCopy.count = circles.count
        setUserItems(circlesCopy)
      }
      return
    }

    userService.getRelated(currentOpenCircle).subscribe((res) => {
      if (!res) {
        return
      }
      let usersInCircle: RowItem[] = []
      setIsLoading(true)
      const circlesCopy = emptyList<RowItem>()
      const index = circles.items.findIndex((c) => c.id === currentOpenCircle)
      if (index > -1) {
        usersInCircle.push({
          uuid: uuidv4(),
          id: currentOpenCircle,
          name: circles.items[index].name,
          birthDate: circles.items[index].birthDate,
          cip: circles.items[index].cip,
          dni: circles.items[index].dni,
          circleID: currentOpenCircle,
          roles: circles.items[index].roles,
        })
      } else {
        circlesCopy.items = circles.items
      }
      circlesCopy.count = circles.count
      getUsers(res.relatedUsers.map((r) => r.id)).subscribe((users) => {
        users
          .filter((u) => u.roles.every((r) => roles.get(r) !== Roles.Professional))
          .forEach((u) => {
            usersInCircle.push({
              uuid: uuidv4(),
              id: u.id,
              name: u.name,
              birthDate: u.birthDate,
              cip: u.cip,
              dni: u.dni,
              circleID: currentOpenCircle,
              roles: u.roles,
            })
          })
        usersInCircle = usersInCircle.sort((a, b) => a.name.localeCompare(b.name))
        circlesCopy.items = circles.items
          .slice(0, index + 1)
          .concat(usersInCircle, circles.items.slice(index + 1))

        setIsLoading(false)
        const newMap = new Map(circlesUsers)
        newMap.set(currentOpenCircle, usersInCircle)
        setCirclesUsers(newMap)
        setUserItems(circlesCopy)
      })
    })
  }, [currentOpenCircle])

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, label: string) => {
    if (label === RolesToAssign.CIRCLES) {
      const selected = checkboxRoles.find((r) => r.label === label)?.checked
      setCheckboxRoles(checkboxRoles.map((rs) => ({ label: rs.label, checked: !selected })))
      return
    }
    setCheckboxRoles(
      checkboxRoles.map((rs) => {
        if (rs.label === RolesToAssign.CIRCLES) {
          rs.checked = false
        }
        return { label: rs.label, checked: rs.label === label ? !rs.checked : rs.checked }
      })
    )
  }

  const addUser = (u: RowItem) => {
    if (u.circleID) {
      if (!assignedUsers.find((r) => r.userID === u.id && r.circleID === currentOpenCircle)) {
        setAssignedUsers([...assignedUsers, { userID: u.id, circleID: currentOpenCircle }])
      }
      return
    }

    if (circlesUsers.has(u.id)) {
      const result = [...assignedUsers]
      circlesUsers.get(u.id)?.forEach((uc) => {
        if (!result.find((r) => r.userID === uc.id && r.circleID === u.id)) {
          result.push({ userID: uc.id, circleID: u.id })
        }
        setAssignedUsers(result)
      })
      return
    }

    userService.getRelated(u.id).subscribe((res) => {
      if (!res) {
        return
      }
      const rows = [{ userID: u.id, circleID: u.id }]
      getUsers(res.relatedUsers.map((r) => r.id)).subscribe((users) => {
        const usersInCircle: RowItem[] = []
        users
          .filter((u) => u.roles.every((r) => roles.get(r) !== Roles.Professional))
          .forEach((user) => {
            usersInCircle.push({
              uuid: uuidv4(),
              id: user.id,
              name: user.name,
              birthDate: user.birthDate,
              cip: user.cip,
              dni: user.dni,
              circleID: u.id,
              roles: u.roles,
            })
            rows.push({ userID: user.id, circleID: u.id })
          })
        const patient = {
          uuid: uuidv4(),
          id: u.id,
          name: u.name,
          birthDate: u.birthDate,
          cip: u.cip,
          dni: u.dni,
          circleID: u.id,
          roles: u.roles,
        }
        usersInCircle.push(patient)

        const newMap = new Map(circlesUsers)
        newMap.set(
          u.id,
          usersInCircle.sort((a, b) => a.name.localeCompare(b.name))
        )
        // props.handlerUpdateAssignedUsers(rows)
        setAssignedUsers([...assignedUsers, ...rows])
        setCirclesUsers(new Map(newMap))
      })
    })

    /* setTimeout(() => {
      saveForm()
    }, 2000) */
  }

  const removeUser = (u: RowItem) => {
    if (u.circleID) {
      setAssignedUsers(assignedUsers.filter((fu) => fu.userID !== u.id))
      return
    }
    setAssignedUsers(assignedUsers.filter((r) => r.circleID !== u.id))
  }

  const isSelected = (u: RowItem): boolean => {
    return u.circleID
      ? !!assignedUsers.find((fu) => fu.userID === u.id && fu.circleID === u.circleID)
      : !!circlesUsers
          .get(u.id)
          ?.every((uc) => assignedUsers.find((fu) => fu.userID === uc.id && fu.circleID === u.id))
  }

  const getUserRole2 = (i: RowItem) => {
    if (roles.get(i.roles[0]) === Roles.Patient && i.id !== currentOpenCircle) {
      return Roles.FamilyOrTutor
    }
    if (roles.get(i.roles[0]) === Roles.FamilyOrTutor && i.id === currentOpenCircle) {
      return Roles.Patient
    }
    return roles.get(i.roles[0]) ?? ''
  }

  const fields: Field<RowItem>[] = [
    {
      name: 'name',
      label: t('name'),
      renderFunc: (f, i) => (
        <div style={{ paddingTop: '5px' }}>
          {!i.circleID && (
            <img
              onClick={() => !i.circleID && showUsersInCircle(i.id)}
              className={styles.collapse}
              src={currentOpenCircle === i.id ? minus : plus}
              alt={currentOpenCircle ? 'showUsers' : 'hideUsers'}
            />
          )}
          <label className={styles.nameLabel}>{i.name}</label>
        </div>
      ),
      styleFunc: (f, i) => (i.circleID ? styles.subRow : ''),
    },
    {
      name: 'circleID',
      label: '',
      renderFunc: (f, i) => (i.circleID ? t(getUserRole2(i)) : ''),
      styleFunc: (f, i) => (i.circleID ? styles.subRow : ''),
    },
    {
      name: 'birthDate',
      label: t('birthDate'),
      renderFunc: (f, i) => (!i.circleID ? new Date(i.birthDate).toLocaleDateString() : ''),
      styleFunc: (f, i) => (i.circleID ? styles.subRow : ''),
    },
    {
      name: 'cip',
      label: t('CIP'),
      renderFunc: (f, i) => (!i.circleID ? i.cip : ''),
      styleFunc: (f, i) => (i.circleID ? styles.subRow : ''),
    },
    {
      name: 'dni',
      label: t('DNI'),
      renderFunc: (f, i) => (!i.circleID ? i.dni : ''),
      styleFunc: (f, i) => (i.circleID ? styles.subRow : ''),
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => setSearcher(svs),
  }

  const actions: Actions<RowItem> = {
    actionsColumn: t('select'),
    items: [
      {
        handler: addUser,
        icon: notSelectedUser,
        label: 'check_circle',
        hidden: (u) => isSelected(u),
      },
      {
        handler: removeUser,
        icon: selectedUser,
        label: 'no_check_circle',
        hidden: (u) => !isSelected(u),
      },
    ],
    styleFunc: (i) => (i.circleID ? styles.subRow : ''),
  }

  const sendToUsers = (users: AssignedUser[]) => {
    const circlesSent = new Array<String>()
    return forkJoin(
      users.map((fu) => {
        if (fu.circleID && props.id) {
          const userForm = new UserForm({
            circleID: fu.circleID,
            formID: props.id,
            id: uuidv4(),
            readingStatus: ReadingStatus.Pending,
            userFormValues: [],
            userID: fu.userID,
          })

          circlesSent.push(fu.circleID)
          return userFormService.add(userForm)
        }
        return of(fu)
      })
    )
  }

  const saveForm = () => {
    const checked = checkboxRoles.filter((r) => r.checked)
    if (checked.length > 0) {
      const users: AssignedUser[] = []
      if (checked.find((r) => r.label === RolesToAssign.PATIENTS)) {
        allPatients.forEach((p) => {
          users.push({
            circleID: p.id,
            userID: p.id,
          })
        })
      }
      if (checked.find((r) => r.label === RolesToAssign.FAMILIARS)) {
        allPatients.forEach((patient) => {
          allFamiliars.get(patient.id)?.forEach((fam) => {
            users.push({
              circleID: patient.id,
              userID: fam,
            })
          })
        })
      }
      if (checked.find((r) => r.label === RolesToAssign.EXTERN)) {
        allPatients.forEach((patient) => {
          allExtern.get(patient.id)?.forEach((fam) => {
            users.push({
              circleID: patient.id,
              userID: fam,
            })
          })
        })
      }
      if (checked.find((r) => r.label === RolesToAssign.CARERS)) {
        allPatients.forEach((patient) => {
          allCuidador.get(patient.id)?.forEach((fam) => {
            users.push({
              circleID: patient.id,
              userID: fam,
            })
          })
        })
      }
      sendToUsers(users).subscribe((_) => {
        enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
        goBack()
      })
      goBack()
      return
    }
    sendToUsers(assignedUsers).subscribe(() => {
      enqueueSnackbar(t('changesWereSaved'), { variant: 'success' })
      goBack()
    })
  }

  return (
    <Box>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={() => navigate(ROUTE_FORM_GENERATOR)}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('assign')}
          handler={saveForm}
        />
      </Box>
      {message && (
        <Box mb={3}>
          <Alert severity="success" key="message" id="message">
            {t(message)}
          </Alert>
        </Box>
      )}
      <Box mb={3} className={styles.selectBox}>
        <p style={{ paddingLeft: '10px' }}>
          <b>{t('select')}:</b>
        </p>
        {checkboxRoles.map((f) => (
          <>
            <Checkbox
              name={f.label}
              checked={f.checked}
              onChange={(e) => handleCheckbox(e, f.label)}
            />
            <p style={{ display: 'inline' }}>{t(f.label)}</p>
          </>
        ))}
      </Box>
      <Box mb={3}>
        {!isLoading ? (
          checkboxRoles.every((r) => !r.checked) && (
            <AppTable
              items={userItems.items}
              rowKeyField={'uuid'}
              fields={fields}
              actions={actions}
              search={search}
              pager={pager}
            />
          )
        ) : (
          <LoadingSpinner className={spinnerStyles.loadingSpinner} />
        )}
      </Box>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<UserQuery>[]
): QueryParam<UserQuery>[] | QueryParamN<UserQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
