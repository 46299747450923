import React, { useState } from 'react'
import { FormDTO } from 'modules/forms/models/FormDTO'
import { FormFieldDTO } from 'modules/forms/models/FormDTO'
import { FormFieldType } from 'modules/forms/enums/FormFieldType'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import CustomToolbar from '../calendar/CustomToolbar'
import styles from '../form-patients/View.module.css'
import { FileItem } from 'components/form-card/FileItem'
import { FormField } from 'modules/forms/models/FormField'
import { v4 as uuidv4 } from 'uuid'
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import attach_file_icon from 'assets/events_icons/ico-clip-adjunto.svg'
import { useTranslation } from 'react-i18next'

interface PreviewFormsProps extends FormDTO {
  disabled?: boolean
}

export function PreviewForm({ disabled = false, ...props }: PreviewFormsProps) {
  const { title, description, formFields, fieldWithoutAnswer } = props
  const [valueForm, setValueForm] = useState<any>({})
  const { t } = useTranslation()
  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setValueForm(Object.assign({ ...valueForm }, { [name]: value }))
  }

  const generateTextField = (formField: FormFieldDTO, multiline: boolean, i: number) => {
    const fieldKey = `input-${formField.id}-${i}`

    return (
      <Box mb={3} key={fieldKey}>
        <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
          {formField.title} {formField.required && '*'}
        </p>
        <TextFieldItem
          key={formField.id}
          disabled={disabled}
          valueID={i}
          name={fieldKey}
          field={formField.id}
          type={formField.type === FormFieldType.Number ? 'number' : 'text'}
          label={''}
          fullWidth={formField.type !== FormFieldType.Number}
          rows={multiline ? 10 : undefined}
          value={valueForm[formField.id]}
          handleChange={handleInputChange}
          required={formField?.required}
        />
      </Box>
    )
  }

  const generateDateField = (formField: FormFieldDTO) => {
    const [selectedDate, setSelectedDate] = useState(new Date())
    return (
      <Box mb={3} key={formField.id + uuidv4()}>
        <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
          {formField.title} {formField.required && '*'}
        </p>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            key={formField.id}
            label={''}
            disabled={disabled}
            inputFormat={'dd/MM/yyyy'}
            value={selectedDate}
            onChange={(newDate: Date | null) => setSelectedDate(newDate ?? new Date())}
            renderInput={(props: any) => <TextField {...props} variant="outlined" />}
            ToolbarComponent={CustomToolbar}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateSelectField = (formField: FormFieldDTO, multiple: boolean) => {
    if (formField instanceof FormField) {
      const form = formField as unknown as FormField
      const [selectedValues, setSelectedValues] = useState<string[]>([])

      const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
        const selected = event.target.value as string[]
        setSelectedValues(selected)
      }

      return (
        <Box mb={3} key={formField.id + uuidv4()}>
          <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
            {formField.title} {formField.required && '*'}
          </p>
          <Select
            key={formField.id}
            id={formField.id}
            variant={'outlined'}
            disabled={disabled}
            value={selectedValues}
            onChange={handleSelectChange}
            className={styles.select}
            multiple={multiple}
          >
            {form.getOptionsValues().map((k) => (
              <MenuItem key={k} value={k}>
                {k}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    } else {
      return (
        <Box mb={3} key={formField.id + uuidv4()}>
          <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
            {formField.title} {formField.required && '*'}
          </p>
          <Select
            disabled={disabled}
            key={formField.id}
            id={formField.id}
            variant={'outlined'}
            value={formField.optionValues}
            onChange={() => {}}
            className={styles.select}
            multiple={multiple}
            required={formField.required}
          >
            {formField.optionValues.map((k) => (
              <MenuItem key={k} value={k}>
                {k}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )
    }
  }

  const generateCheckboxField = (formField: FormFieldDTO) => {
    return (
      <Box mb={3} key={formField.id + uuidv4()} style={{ display: 'flex', alignItems: 'center' }}>
        <p className={styles.checkbox} style={{ marginRight: '10px' }}>
          {formField.title}
        </p>
        <Checkbox
          style={{ width: '25px', height: '25px' }}
          name={formField.id}
          disabled={disabled}
          onChange={() => {}}
        />
      </Box>
    )
  }

  const generateRadioButtonField = (formField: FormFieldDTO) => {
    if (formField instanceof FormField) {
      const form = formField as unknown as FormField
      const radioButtons = Array.isArray(form.getOptionsValues()) ? form.getOptionsValues() : []
      return (
        radioButtons.length > 0 && (
          <Box mb={3} key={formField.id + uuidv4()}>
            <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
              {formField.title} {formField.required && '*'}
            </p>
            <RadioGroup name={formField.title}>
              {radioButtons.map((o, i) => (
                <FormControlLabel
                  key={`${o}-${i}`}
                  value={o}
                  control={<Radio disabled={disabled} />}
                  label={o}
                  onChange={() => {}}
                />
              ))}
            </RadioGroup>
          </Box>
        )
      )
    } else {
      const radioButtons = Array.isArray(formField.optionValues) ? formField.optionValues : []
      return (
        radioButtons.length > 0 && (
          <Box mb={3} key={formField.id + uuidv4()}>
            <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
              {formField.title} {formField.required && '*'}
            </p>
            <RadioGroup name={formField.title}>
              {radioButtons.map((o, i) => (
                <FormControlLabel
                  key={`${o}-${i}`}
                  value={o}
                  control={<Radio disabled={disabled} />}
                  label={o}
                  onChange={() => {}}
                />
              ))}
            </RadioGroup>
          </Box>
        )
      )
    }
  }

  const generateFileField = (formField: FormFieldDTO) => {
    return (
      <Box mb={3} key={formField.id + uuidv4()}>
        <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
          {formField.title} {formField.required && '*'}
        </p>
        <FileItem
          field={formField.id}
          filesID={[]}
          handleFiles={() => {}}
          userID=""
          cleanFiles={false}
          uploadRemoveFileDirectly={true}
          disabled={true}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('addFile')}
          span={true}
          disabled={disabled}
          startIcon={attach_file_icon}
          handler={() => {}}
        />
      </Box>
    )
  }

  const generateTimeField = (formField: FormFieldDTO) => {
    const [selectedDate, setSelectedDate] = useState(new Date())
    return (
      <Box key={formField.id + uuidv4()}>
        <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
          {formField.title} {formField.required && '*'}
        </p>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopTimePicker
            key={formField.id}
            label={''}
            value={selectedDate}
            disabled={disabled}
            onChange={(newDate) => setSelectedDate(newDate ?? new Date())}
            renderInput={(props: any) => <TextField {...props} variant="outlined" />}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateDateTimeField = (formField: FormFieldDTO) => {
    const [selectedDate, setSelectedDate] = useState(new Date())
    return (
      <Box key={formField.id + uuidv4()}>
        <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
          {formField.title} {formField.required && '*'}
        </p>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            key={formField.id}
            label={''}
            inputFormat={'dd/MM/yyyy HH:mm'}
            value={selectedDate}
            disabled={disabled}
            onChange={(newDate) => setSelectedDate(newDate ?? new Date())}
            renderInput={(props: any) => <TextField {...props} variant="outlined" />}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateNoResponseField = (formField: FormFieldDTO) => {
    return (
      <Box mb={3} key={formField.id + uuidv4()}>
        <p className={styles.text} style={{ fontFamily: 'Poppins' }}>
          {formField.title}
        </p>
      </Box>
    )
  }

  const generateField = (formField: FormFieldDTO, i: number) => {
    switch (formField.type) {
      case FormFieldType.Text:
      case FormFieldType.TextArea:
      case FormFieldType.Number: {
        const multiline = formField.type === FormFieldType.TextArea
        return generateTextField(formField, multiline, i)
      }
      case FormFieldType.Date:
        return generateDateField(formField)

      case FormFieldType.Select:
      case FormFieldType.MultiSelect: {
        const multiple = formField.type === FormFieldType.MultiSelect
        return generateSelectField(formField, multiple)
      }

      case FormFieldType.CheckBox:
        return generateCheckboxField(formField)

      case FormFieldType.RadioButton:
        return generateRadioButtonField(formField)

      case FormFieldType.File:
        return generateFileField(formField)

      case FormFieldType.Time:
        return generateTimeField(formField)

      case FormFieldType.DateTime:
        return generateDateTimeField(formField)

      case FormFieldType.NoResponse:
        return generateNoResponseField(formField)
    }
  }

  return (
    <>
      <h2>{title}</h2>
      <Box style={{ width: '100%', wordWrap: 'break-word', marginBottom: 14 }}>
        <p style={{ margin: 0 }}>{description}</p>
      </Box>
      {formFields.map((ufv, i) => {
        return <>{generateField(ufv, i)}</>
      })}
      <p>{fieldWithoutAnswer}</p>
    </>
  )
}
