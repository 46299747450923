import { SegmentedNotification, SegmentedMessage } from './SegmentedNotification'
import { v4 as uuidv4 } from 'uuid'

export interface SegmentedNotificationDTO {
  id?: string
  name: string
  pathology: string
  fromAge: number
  toAge: number
  treatment: string
  diagnostic: string
  userId: string
  circleId: string
  userCircleId: string
  messageTitle?: string
  messageDescription?: string
  createdAt: Date
  updatedAt: Date
}

export interface SegmentedMessageDTO {
  id?: string
  title: string
  description: string
  segmentedId: string
  createdAt: Date
  updatedAt: Date
}

export function EmptySegmentedMessageDTO() {
  return {
    id: uuidv4(),
    title: '',
    description: '',
    segmentedId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  }
}

export function EmptySegmentedNotificationDTO() {
  return {
    id: uuidv4(),
    name: '',
    pathology: '',
    fromAge: 0,
    toAge: 0,
    treatment: '',
    diagnostic: '',
    userId: '',
    circleId: '',
    userCircleId: '',
    messageTitle: '',
    messageDescription: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  }
}

export function UpdateSegmentedNotificationDTO() {
  return {
    id: '',
    updatedAt: new Date(),
  }
}

export function fromModelMessage(n: SegmentedMessage): SegmentedMessageDTO {
  return {
    id: n.id,
    title: n.title,
    description: n.description,
    segmentedId: n.segmentedId,
    createdAt: n.createdAt,
    updatedAt: n.updatedAt,
  }
}

export function toModelMessage(d: SegmentedMessageDTO): SegmentedMessage {
  return new SegmentedMessage({
    id: d.id,
    title: d.title,
    description: d.description,
    segmentedId: d.segmentedId,
    createdAt: new Date(d.createdAt),
    updatedAt: new Date(d.updatedAt),
  })
}

export function fromModel(n: SegmentedNotification): SegmentedNotificationDTO {
  return {
    id: n.id,
    name: n.name,
    pathology: n.pathology,
    fromAge: n.fromAge,
    toAge: n.toAge,
    treatment: n.treatment,
    diagnostic: n.diagnostic,
    userId: n.userId,
    circleId: n.circleId,
    userCircleId: n.userCircleId,
    messageTitle: n.messageTitle,
    messageDescription: n.messageDescription,
    createdAt: n.createdAt,
    updatedAt: n.updatedAt,
  }
}

export function toModel(d: SegmentedNotificationDTO): SegmentedNotification {
  return new SegmentedNotification({
    id: d.id,
    name: d.name,
    pathology: d.pathology,
    fromAge: d.fromAge,
    toAge: d.toAge,
    treatment: d.treatment,
    diagnostic: d.diagnostic,
    userId: d.userId,
    circleId: d.circleId,
    userCircleId: d.userCircleId,
    messageTitle: d.messageTitle,
    messageDescription: d.messageDescription,
    createdAt: new Date(d.createdAt),
    updatedAt: new Date(d.updatedAt),
  })
}
