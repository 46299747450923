import React, { useEffect, useRef } from 'react'
import { Chart, ChartTypeRegistry } from 'chart.js'

interface Dataset {
  data: number[]
  backgroundColor?: string | string[]
  borderColor?: string
  label?: string
}

interface GenericChartProps {
  datasets: Dataset[]
  labels: string[]
  typeChart: keyof ChartTypeRegistry
  legendPosition?: 'top' | 'bottom' | 'left' | 'right'
  backgroundColor?: string | string[]
  title: string
  showXAxis?: boolean
  showYAxis?: boolean
  showGridLines?: boolean
  yAxisConfig?: {
    min?: number
    max?: number
    stepSize?: number
  }
}

const GenericChartDashboard: React.FC<GenericChartProps> = ({
  datasets,
  labels,
  typeChart,
  legendPosition,
  title,
  showXAxis = true, // Por defecto, los ejes están visibles
  showYAxis = true,
  showGridLines = true,
  yAxisConfig,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null)
  let myChart: Chart | null = null

  const chartData = {
    datasets: datasets.map((dataset) => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor: dataset.backgroundColor,
    })),
    labels,
  }

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: title,
      },
      legend: {
        position: legendPosition ?? 'bottom',
      },
    },
    scales: {
      y: {
        display: showYAxis, // Controla la visibilidad del eje Y
        grid: {
          display: showGridLines, // Controla la visibilidad de las líneas de la cuadrícula en el eje Y
        },
        beginAtZero: true,
        stacked: true,
        min: yAxisConfig?.min,
        max: yAxisConfig?.max,
        ticks: {
          stepSize: yAxisConfig?.stepSize,
          autoskip: false,
        },
      },
      x: {
        display: showXAxis, // Controla la visibilidad del eje X
        grid: {
          display: showGridLines, // Controla la visibilidad de las líneas de la cuadrícula en el eje X
        },
        beginAtZero: true,
        stacked: true,
      },
    },
    animation: {
      duration: 0,
      animateRotate: false,
    },
  }

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d')

      if (ctx) {
        // Destruye la instancia anterior del gráfico si existe
        if (myChart) {
          myChart.destroy()
        }

        // Crea una nueva instancia del gráfico
        myChart = new Chart(ctx, {
          type: typeChart,
          data: chartData,
          options: chartOptions,
        })
      }
    }

    // Función de limpieza para destruir la instancia del gráfico cuando el componente se desmonte
    return () => {
      if (myChart) {
        myChart.destroy()
      }
    }
  }, [datasets])

  return <canvas id="myChart" ref={chartRef} />
}

export default GenericChartDashboard
