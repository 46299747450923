import { Box, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AppTable, Field } from '../../../components/table'
import { useNavigate } from 'react-router-dom'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { Actions, Pager } from '../../../components/table_type/types'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_CONFIGURATION,
  ROUTE_CREATE,
  ROUTE_PATHOLOGY_SYMPTOMS_FORM,
} from '../../../routes/routes-constants'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { SYMPTOM_SERVICE_KEY } from '../../../modules/patient-data'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { SymptomService } from '../../../modules/patient-data/services/SymptomService'
import { Symptom } from '../../../modules/patient-data/models/Symptom'
import genericStyle from '../../../common/utils/generic.module.css'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../assets/table_icons/ico-eliminar.svg'
import { CustomModal } from 'components/modal/CustomModal'

const patientDataContainer = getPatientDataContainer()

const symptomService = patientDataContainer.get<SymptomService>(SYMPTOM_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [count, setCount] = useState<number>(0)
  const [symptoms, setSymptoms] = useState<ItemList<Symptom>>(emptyList<Symptom>())
  const [pathologiesPerPage, setPathologiesPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentSymptom, setCurrentSymptom] = useState<Symptom>()

  useEffect(() => {
    symptomService
      .getFilteredList(
        new Query({
          pager: { offset: pathologiesPerPage * page, limit: pathologiesPerPage },
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setSymptoms(res)
        /* setCount(res.count)
        const pathologies = new Set<string>()
        res.items.forEach((s) => {
          s.pathologies.forEach((p) => {
            pathologies.add(p)
          })
        })
        getPathologies(Array.from(pathologies.values())).subscribe((pl) => {
          const newMap = new Map()
          pl.filter((p) => p).forEach((p) => {
            const sl = res.items.filter((s) => s.pathologies.includes(p.id || ''))
            sl.forEach((s) => {
              if (newMap.has(s.id)) {
                newMap.set(s.id, [...newMap.get(s.id), p.name])
              } else {
                newMap.set(s.id, [p.name])
              }
            })
          })
          setPathologiesPerSymptoms(newMap)
        }) */
      })
  }, [pager, isLoading])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setPathologiesPerPage(10)
      return
    }
    setPathologiesPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: pathologiesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, pathologiesPerPage])

  const goBack = () => navigate(ROUTE_CONFIGURATION)

  const createSymptom = () => navigate(`${ROUTE_PATHOLOGY_SYMPTOMS_FORM}/${ROUTE_CREATE}`)

  const editSymptom = (s: Symptom) => navigate(`${ROUTE_PATHOLOGY_SYMPTOMS_FORM}/${s.id}`)

  const removeSymptom = (s: Symptom) => {
    setCurrentSymptom(s)
    setOpenDeleteModal(true)
  }

  const fields: Field<Symptom>[] = [
    {
      name: 'name',
      label: t('name'),
    },
  ]

  const actions: Actions<Symptom> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editSymptom,
        icon: editIcon,
        label: 'edit',
      },
      {
        handler: removeSymptom,
        icon: deleteIcon,
        label: 'delete',
      },
    ],
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteSymptom = () => {
    if (currentSymptom?.id) {
      symptomService.delete(currentSymptom.id).subscribe((_) => setIsLoading(!isLoading))
    }
    setOpenDeleteModal(false)
    setIsLoading(!isLoading)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={goBack}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('createSymptom')}
          handler={createSymptom}
        />
      </Box>
      <Box>
        <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <CustomModal
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteSymptom}
            title={t('deleteSymptom')}
            warningText={t('irreversibleSymptomAction')}
          />
        </Modal>
        <AppTable
          items={symptoms.items}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          pager={pager}
        />
      </Box>
    </Box>
  )
}
