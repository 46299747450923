import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES, ROUTE_ARTICLES_FORM } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { Actions, Pager, Search, SearchValue } from '../../components/table_type/types'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import relevanciaNaranja from '../../assets/esfera/article/relevancia naranja.svg'
import relevanciaRojo from '../../assets/esfera/article/relevancia rojo.svg'
import relevanciaVerde from '../../assets/esfera/article/relevancia verde.svg'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { Permission } from '../../common/permission'
import { Modal } from '@mui/material'
import { CustomModal } from 'components/modal/CustomModal'
import { ArticleRelevance } from 'modules/content/enums/ArticleRelevance'
import { ArticleStatus, statusTypes } from 'modules/content/enums/ArticleStatus'
import { getCircleContainer } from '../../container/circle-module'
import { CircleService } from '../../modules/circle/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/circle'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { CircleQuery } from '../../modules/circle/models/Circle'
import { VisualizationService } from '../../modules/content/services/VisualizationService'
import { VISUALIZATIONS_SERVICE_KEY } from '../../modules/content/container'
import { UserCircleQuery } from '../../modules/user-circle/models/UserCircle'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)
const circleService = getCircleContainer().get<CircleService>(CIRCLE_SERVICE_KEY)
const visualizationsService = contentContainer.get<VisualizationService>(VISUALIZATIONS_SERVICE_KEY)

type ArticleProps = {}

const circleList = new Map()

export function Table(props: ArticleProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(10)
  const [pager, setPager] = useState<Pager>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [category, setCategory] = useState<CircleDTO[]>([])
  const [currentArticle, setCurrentArticle] = useState<Article>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtering, setFiltering] = useState<boolean>(false)
  const [searcher, setSearcher] = useState<SearchValue<Article>[]>([
    {
      name: 'id',
      label: t('search') + '...',
      value: '',
    },
  ])

  const search: Search<Article> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<Article>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }
        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })
      setSearcher(result)
    },
  }
  const navigate = useNavigate()

  const statusOptions = statusTypes()

  const getArticles = () => {
    const logged = JSON.parse(localStorage.getItem('logged user') ?? 'null')
    const query = [
      new QueryParam<Article>('type', 'article'),
      new QueryParam<Article>('userID', logged.id),
    ]

    if (searcher[0].value !== '' && searcher[0].value !== undefined) {
      query.push(new QueryParam<Article>('titleCoincidences', searcher[0].value))
    }
    articlesService
      .getFilteredList(
        new Query({
          query,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((results) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        for (let i = 0; i < results.count; i++) {
          const item = results.items[i]
          if (item && item.id !== undefined) {
            visualizationsService.getVisualizationsByArticleID(item.id).subscribe((response) => {
              if (item) item.visualizations = response.count

              articleList.items = results.items
              articleList.count = results.count
              articleList.items = articleList.items.sort((a, b) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
              })
              setArticles(articleList)
            })
            articlesService.getInteractions(item.id).subscribe((response) => {
              if (item) item.interactions = response.interactions ?? 0
              articleList.items = results.items
              articleList.count = results.count
              articleList.items = articleList.items.sort((a, b) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
              })
              setArticles(articleList)
            })

            userCircleService
              .getFilteredListWithCircleAndUser(
                new Query<UserCircleQuery>({
                  query: [new QueryParam('circleID', item.circleId.toString())],
                })
              )
              .subscribe((response) => {
                item.scope = response.count
                articleList.items = results.items
                articleList.count = results.count
                articleList.items = articleList.items.sort((a, b) => {
                  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                })
                setArticles(articleList)
              })
          }
        }
        articleList.items = results.items
        articleList.count = results.count
        articleList.items = articleList.items.sort((a, b) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })
        setArticles(articleList)
        setCount(articleList.count)
      })

    circleService.getAllCirclesWithSpecialtyAndCreator().subscribe((res) => {
      res.items.forEach((o: any) => circleList.set(o.id, o.name))
    })
  }

  useEffect(() => {
    if (!isLoading) {
      return
    }
    getArticles()
  }, [isLoading])

  useEffect(() => {
    getArticles()
  }, [searcher])

  useEffect(() => {
    circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
      if (!res) return
      setCategory(res.items)
    })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(10)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  const handleSee = (article: Article) => navigate(`${ROUTE_ARTICLES}/${article.id}`)

  const editArticle = (article: Article) => {
    if (
      !(
        article.status === ArticleStatus[ArticleStatus.rechazado] ||
        article.status === ArticleStatus[ArticleStatus.borrador] ||
        article.status === ArticleStatus[ArticleStatus.pendiente]
      )
    ) {
      navigate(`${ROUTE_ARTICLES}/${article.id}`)
    } else {
      navigate(`${ROUTE_ARTICLES_FORM}/${article.id}`)
    }
  }

  const removeArticle = (article: Article) => {
    setCurrentArticle(article)
    setOpenDeleteModal(true)
  }

  const fields: Field<Article>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => (
        <div style={{ margin: '0px', cursor: 'pointer' }} onClick={() => handleSee(i)}>
          {i.title}
        </div>
      ),
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) => new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'updatedAt',
      label: t('lastUpdate'),
      renderFunc: (f, i) => new Date(i.updatedAt).toLocaleDateString(),
    },
    {
      name: 'relevance',
      label: t('relevance'),
      renderFunc: (f, i) => {
        let relevanceIcon = ''
        let colorRelevance = ''

        switch (i.relevance) {
          case ArticleRelevance[ArticleRelevance.baja]:
            colorRelevance = '#6ce32b'
            relevanceIcon = relevanciaVerde
            break
          case ArticleRelevance[ArticleRelevance.media]:
            colorRelevance = 'orange'
            relevanceIcon = relevanciaNaranja
            break
          case ArticleRelevance[ArticleRelevance.alta]:
            colorRelevance = 'red'
            relevanceIcon = relevanciaRojo
            break
          default:
            break
        }

        return (
          <div style={{ color: colorRelevance }}>
            <img src={relevanceIcon}></img>
          </div>
        )
      },
    },
    {
      name: 'scope',
      label: t('scope'),
      renderFunc: (f, i) => (
        <div style={{ margin: '0px' }}>
          {i.scope} {t('conversationUsers')}
        </div>
      ),
    },
    {
      name: 'visualizations',
      label: t('visualizations'),
      renderFunc: (f, i) => <div style={{ margin: '0px' }}>{i.visualizations}</div>,
    },
    {
      name: 'interactions',
      label: t('interactions'),
      renderFunc: (f, i) => <div style={{ margin: '0px' }}>{i.interactions}</div>,
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => {
        let colorStatus = ''
        let textStatus = ''

        switch (i.status) {
          case ArticleStatus[ArticleStatus.borrador]:
            textStatus = statusOptions[ArticleStatus.borrador]
            colorStatus = 'orange'
            break
          case ArticleStatus[ArticleStatus.pendiente]:
            textStatus = statusOptions[ArticleStatus.pendiente]
            colorStatus = 'silver'
            break
          case ArticleStatus[ArticleStatus.publicado]:
            textStatus = statusOptions[ArticleStatus.publicado]
            colorStatus = '#6ce32b'
            break
          case ArticleStatus[ArticleStatus.rechazado]:
            textStatus = statusOptions[ArticleStatus.rechazado]
            colorStatus = 'red'
            break
          case ArticleStatus[ArticleStatus.validando]:
            textStatus = statusOptions[ArticleStatus.validando]
            colorStatus = 'blue'
            break
          default:
            break
        }

        return (
          <b className={'status' + i.status} style={{ color: colorStatus }}>
            {textStatus}
          </b>
        )
      },
    },
    {
      name: 'circleId',
      label: t('circle'),
      renderFunc: (f, i) => {
        const circleName = category.find((category) => {
          if (category.id === i.circleId) {
            return category.name
          } else return ''
        })

        return circleName ? circleName?.name : ''
      },
    },
  ]

  const actions: Actions<Article> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editArticle,
        icon: editIcon,
        label: 'edit',
        hidden: (item) =>
          !(
            item.status === ArticleStatus[ArticleStatus.rechazado] ||
            item.status === ArticleStatus[ArticleStatus.borrador] ||
            item.status === ArticleStatus[ArticleStatus.pendiente]
          ),
      },
      {
        handler: handleSee,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: removeArticle,
        icon: deleteIcon,
        label: 'delete',
        hidden: () => !loggedUserService.userCan(Permission.createContent),
      },
    ],
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteArticle = () => {
    if (currentArticle?.id)
      articlesService.delete(currentArticle.id).subscribe((_) => setIsLoading(true))
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  return (
    <>
      <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <>
          <CustomModal
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteArticle}
            title={t('deleteArticle')}
            warningText={t('irreversibleArticleAction')}
          />
        </>
      </Modal>
      <>
        <AppTable
          items={articles.items}
          rowKeyField="id"
          fields={fields}
          search={search}
          actions={actions}
          pager={pager}
        />
      </>
    </>
  )
}
