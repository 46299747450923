/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Avatar, Box } from '@mui/material'
import styles from './NotificationCard.module.css'
import stylesTitle from './List.module.css'
import womanIcon from '../../assets/chatIcons/woman.svg'
import maleIcon from '../../assets/chatIcons/male.svg'

export type NotificationCardProps = {
  id: string | undefined
  clickEvent: any
  title?: string
  description?: string
  data: any
  gender?: string
  avatarUrl: string
  lastMessage?: string
  date?: Date
}

function dataPrettier(data: string) {
  return (
    <>
      <p>{new Date(data).toLocaleDateString('es-ES')}</p>
      <p>{new Date(data).toLocaleTimeString()}</p>
    </>
  )
}

export function NotificationCard(props: NotificationCardProps): JSX.Element {
  const [gender, setGender] = useState('')

  const handleData = () => {
    props.clickEvent(props)
  }
  return (
    <Box key={props.id} className={styles.conversationWall} display="flex" onClick={handleData}>
      <Box className={styles.avatarWrapper} flexDirection="column">
        <div>{props.gender}</div>
        <Avatar
          src={gender === 'female' ? womanIcon : maleIcon}
          style={{ color: '#d2e2ee', backgroundColor: '#FFFFFF', padding: '0' }}
        />
      </Box>
      <Box className={styles.conversationBlock}>
        <h3 className={styles.conversationName}>{props.title}</h3>
        <p className={styles.conversationDescription}>{props.description}</p>
      </Box>
      <Box className={styles.dateWrapper}>
        {props.data.updatedAt && dataPrettier(props.data.updatedAt.toString())}
      </Box>
    </Box>
  )
}
