import React, { useEffect, useState, useMemo } from 'react'
import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { Query } from '../../../../common/api/Query'
import { Box, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './CircleStateCard.module.scss'

type Status = {
  type: 'active' | 'refused' | 'pending' | 'finished'
  count: number
}

type PieCircleStateCardProps = {
  activePlanService: ActivePlanService
  refreshStatusCircleModal: boolean
}

const PieCircleStateCard: React.FC<PieCircleStateCardProps> = ({
  activePlanService,
  refreshStatusCircleModal,
}) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState<string>(t('selectFilter'))
  const [circleStatus, setCircleStatus] = useState<Status[]>([])
  const statusTypes = useMemo(
    () => ({
      selectFilter: 'Seleccionar filtro',
      active: 'Activos',
      refused: 'Rechazado',
      pending: 'Pendiente',
      finished: 'Cerrado',
    }),
    []
  )

  const backgroundColors = useMemo(
    () => ({
      active: '#0063A6',
      refused: '#F18B24',
      pending: '#B6B5B5',
      finished: '#F1DD25',
    }),
    []
  )
  useEffect(() => {
    const subscription = activePlanService
      .getFilteredList(new Query({ query: [] }))
      .subscribe((response) => {
        if (!response) return
        const statusCounts = {
          active: response.items.filter(
            (item) =>
              item.status === 'active' &&
              (statusTypes[item.status] === selectedItem || selectedItem === t('selectFilter'))
          ).length,
          refused: response.items.filter(
            (item) =>
              item.status === 'refused' &&
              (statusTypes[item.status] === selectedItem || selectedItem === t('selectFilter'))
          ).length,
          pending: response.items.filter(
            (item) =>
              item.status === 'pending' &&
              (statusTypes[item.status] === selectedItem || selectedItem === t('selectFilter'))
          ).length,
          finished: response.items.filter(
            (item) =>
              item.status === 'finished' &&
              (statusTypes[item.status] === selectedItem || selectedItem === t('selectFilter'))
          ).length,
        }

        const filteredStatusCounts: Status[] = Object.entries(statusCounts)
          .filter(([_, count]) => count > 0)
          .map(([type, count]) => ({
            type: type as 'active' | 'refused' | 'pending' | 'finished',
            count,
          }))

        setCircleStatus(filteredStatusCounts)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [activePlanService, refreshStatusCircleModal, selectedItem])
  const data = circleStatus.map((status) => status.count)
  const backgroundColor = circleStatus.map((status) => backgroundColors[status.type])
  const [labels, setLabels] = useState<string[]>([])
  useEffect(() => {
    setLabels(circleStatus.map((status) => statusTypes[status.type]))
  }, [circleStatus, refreshStatusCircleModal])

  useEffect(() => {
    setSelectedItem(statusTypes.selectFilter)
  }, [refreshStatusCircleModal])

  const [filterOptions, setFilterOptions] = useState<string[]>([])
  useEffect(() => {
    if (labels.length > 1 || selectedItem === statusTypes.selectFilter) {
      setFilterOptions(
        Object.values(statusTypes).filter(
          (statusType) => statusType === statusTypes.selectFilter || labels.includes(statusType)
        )
      )
    }
  }, [refreshStatusCircleModal, labels])

  const datasets = [
    {
      labels,
      data,
      backgroundColor,
    },
  ]
  return (
    <Box className={style.chartContainer}>
      {circleStatus.length > 0 && (
        <>
          <GenericChartDashboard
            datasets={datasets}
            labels={selectedItem === statusTypes.selectFilter ? labels : [selectedItem]}
            title={''}
            typeChart={ChartEnum.Pie}
            legendPosition={'right'}
            showGridLines={false}
            showXAxis={false}
            showYAxis={false}
          />
          <Select
            MenuProps={{
              MenuListProps: {
                sx: {
                  'li.MuiButtonBase-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontFamily: 'Poppins',
                    paddingLeft: '10px',
                  },
                },
              },
            }}
            SelectDisplayProps={{
              style: {
                paddingLeft: '10px',
                fontFamily: 'Poppins',
                fontSize: '14px',
              },
            }}
            variant="outlined"
            style={{
              backgroundColor: '#f5f2f2',
              marginRight: '10px',
              width: '20%',
              height: '40px',
              minWidth: 'fit-content',
              alignSelf: 'flex-end', // Asegura que solo el Select esté pegado a la derecha
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            value={selectedItem}
            defaultValue={selectedItem}
            onChange={(e) => {
              setSelectedItem(e.target.value)
            }}
          >
            {filterOptions.map((k: string, index: number) => (
              <MenuItem key={k + index} value={k}>
                {t(k)}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      {circleStatus.length === 0 && <div className={style.noData}>{t('noData')}</div>}
    </Box>
  )
}

export default PieCircleStateCard
