import { getUserContainer } from '../../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../../modules/users'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { User } from '../../../modules/users/models/User'
import { ILoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'
import styles from './AsignForm.module.css'
import { v4 as uuidv4 } from 'uuid'
import { UserForm } from '../../../modules/forms/models/UserForm'
import { Box, Checkbox, Typography } from '@mui/material'
import { CheckboxAssignType } from '../../../modules/forms/enums/CheckboxAssignType'
import { FormDTO } from '../../../modules/forms/models/FormDTO'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

enum RolesToAssign {
  CIRCLES = 'AllMyCircles',
  PATIENTS = 'AllMyPatients',
  FAMILIARS = 'AllFamiliars',
  CARERS = 'AllCareers',
  EXTERN = 'AllExternal',
}

type AssignedUser = {
  userID: string
  circleID: string | null
}

export type RowItem = {
  uuid: string
  id: string
  name: string
  birthDate: Date
  cip: string
  dni: string
  circleID: string | null
  roles: string[]
  userCircleID: string
  isPediatric?: boolean
}

export type RolesCheckbox = {
  label: RolesToAssign
  checked: boolean
}

type AssignFormSectionProps = {
  id?: string
  allPatients: User[]
  handlerAssignedUser: (assignedUsers: AssignedUser[]) => void
  handleSaveForm: (saveForm: () => void) => void
  saving: boolean
  mainRef: React.RefObject<HTMLDivElement> | undefined
  usersFormEdit: UserForm[]
  currentCirclePathology: string[] | undefined
  recoveredSelected: (s: RowItem[]) => void
  preview: boolean
  selected: RowItem[]
  handleCheckBoxSelected: (selected: CheckboxAssignType[]) => void
  form: FormDTO
  recoveredCheckBoxRoles: (roles: RolesCheckbox[]) => void
  checkboxRecover: RolesCheckbox[]
}

export function AssignFormSection({
  id,
  handleSaveForm,
  saving,
  preview,
  handleCheckBoxSelected,
  form,
  recoveredCheckBoxRoles,
  checkboxRecover,
}: AssignFormSectionProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()

  const [checkboxRoles, setCheckboxRoles] = useState<RolesCheckbox[]>(
    checkboxRecover.length > 0
      ? checkboxRecover
      : Object.values(RolesToAssign).map((f) => ({ label: f, checked: false }))
  )
  const [custom, setCustom] = useState<boolean>(false)

  // Boolean to find out if the user is a extern professional
  const isExternProfessional = loggedUser?.roles?.some((role) => role === 'externProfessional')

  useEffect(() => {
    if (id) {
      if (form.checkBoxesSelected && form.checkBoxesSelected !== '') {
        setCustom(false)
        const auxArrayCheckBoxes = form.checkBoxesSelected?.split(',')
        const auxArrayCheckBoxesSelected: CheckboxAssignType[] = []
        if (!auxArrayCheckBoxes) return
        auxArrayCheckBoxes.forEach((f) => {
          if (f === CheckboxAssignType.AllMyCircles.toString()) {
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.CIRCLES)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.PATIENTS)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.FAMILIARS)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.EXTERN)
              .map((f) => (f.checked = true))
            checkboxRoles
              .filter((f) => f.label === RolesToAssign.CARERS)
              .map((f) => (f.checked = true))
            auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllMyCircles)
          } else {
            if (f === CheckboxAssignType.AllMyPatients.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.PATIENTS)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllMyPatients)
            }
            if (f === CheckboxAssignType.AllFamiliars.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.FAMILIARS)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllFamiliars)
            }
            if (f === CheckboxAssignType.AllExternal.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.EXTERN)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllExternal)
            }
            if (f === CheckboxAssignType.AllCareers.toString()) {
              checkboxRoles
                .filter((f) => f.label === RolesToAssign.CARERS)
                .map((f) => (f.checked = true))
              auxArrayCheckBoxesSelected.push(CheckboxAssignType.AllCareers)
            }
          }
        })
        handleCheckBoxSelected(auxArrayCheckBoxesSelected)
        recoveredCheckBoxRoles(checkboxRoles)
      } else {
        setCustom(true)
      }
    }
  }, [id, preview])

  useEffect(() => {
    // if (!saving) return
    handleSaveForm(async () => await saveForm())
  }, [saving, checkboxRoles])

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, label: string) => {
    setCustom(false)
    if (label === RolesToAssign.CIRCLES) {
      const selected = checkboxRoles.find((r) => r.label === label)?.checked
      setCheckboxRoles(checkboxRoles.map((rs) => ({ label: rs.label, checked: !selected })))
      return
    }
    setCheckboxRoles(
      checkboxRoles.map((rs) => {
        if (rs.label === RolesToAssign.CIRCLES) {
          rs.checked = false
        }
        return { label: rs.label, checked: rs.label === label ? !rs.checked : rs.checked }
      })
    )
  }

  const saveForm = async () => {
    const selectedRoles = checkboxRoles.filter((r) => r.checked).map((r) => r.label)
    const arrayCheckBoxSelected: CheckboxAssignType[] = []

    if (selectedRoles.includes(RolesToAssign.CIRCLES)) {
      arrayCheckBoxSelected.push(CheckboxAssignType.AllMyCircles)
    } else {
      if (selectedRoles.includes(RolesToAssign.PATIENTS)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllMyPatients)
      }
      if (selectedRoles.includes(RolesToAssign.FAMILIARS)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllFamiliars)
      }
      if (selectedRoles.includes(RolesToAssign.EXTERN)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllExternal)
      }
      if (selectedRoles.includes(RolesToAssign.CARERS)) {
        arrayCheckBoxSelected.push(CheckboxAssignType.AllCareers)
      }
    }

    handleCheckBoxSelected(arrayCheckBoxSelected)
  }

  useEffect(() => {
    if (custom) {
      form.checkBoxesSelected = ''
      setCheckboxRoles(Object.values(RolesToAssign).map((f) => ({ label: f, checked: false })))
    }
  }, [custom])

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{t('myCircles')}</Typography>
      {!isExternProfessional && (
        <Box style={{ marginBottom: 15 }} className={styles.selectBox}>
          <Box className={styles.selectCheckBoxContent}>
            {checkboxRoles.map((f) => (
              <Box key={f.label + uuidv4()} style={{ minWidth: 214 }}>
                <Checkbox
                  style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                  name={f.label}
                  checked={custom ? false : f.checked}
                  onChange={(e) => handleCheckbox(e, f.label)}
                />
                <p style={{ display: 'inline' }}>{t(f.label)}</p>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}
