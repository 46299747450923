import React, { ReactNode, useEffect, useState } from 'react'
import { Grid, Box } from '@mui/material'
import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import PatientNumberCard from './patient-number-card/PatientNumberCard'
import SportCenterCard from './sport-center-card/SportCenterCard'
import PatientStatisicsCard from './patient-statisics-card/PatientStatisicsCard'
import PieCircleStateCard from './pie-circle-state-card/CircleStateCard'
import { ActivePlanService } from 'modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from 'modules/active-plan'
import { getActivePlanContainer } from 'container/active-plan-module'
import seeIcon from 'assets/dashboard/seeIcon.svg'
import { StatusCircleModal } from './pie-circle-state-card/StatusCircleModal'
import { ChatsCard } from '../patient/chats-card/ChatsCard'
import { EducationalContentCard } from '../patient/educational-content-card/EducationalContentCard'
import { ActiveQueriesCard } from '../patient/active-query-card/ActiveQueriesCard'
import { ProfessionalCirclesCard } from './professional-circles-card/ProfessionalCirclesCard'
import { NumericStatsCard } from '../patient/numeric-stats-card/NumericStatsCard'
import { NotificationsCard } from './notification-card/NotificationsCard'
import { MonitoredActivityCard } from '../patient/monitored-activity-card/MonitoredActivityCard'
import { ROUTE_WEARABLES } from 'routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from 'common/utils/GlobalRoleContext'
import { Roles } from 'modules/users/enums/Roles'
import { EpilepsyCard } from './epilepsy-card/EpilepsyCard'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import ModalGroupManagement from './group-management/ModalGroupManagement'
import { useProfCircle } from 'common/utils/prof-circle-context/ProfCircleContext'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

interface CardSectionProps {
  label: string
  children: ReactNode
  showIcon?: boolean
  onClick?: () => void
}

const CardSection: React.FC<CardSectionProps> = ({ label, children, showIcon, onClick }) => (
  <Grid mr={2} item xs={12} sm={6} md={6}>
    <DashboardSectionCard
      onClick={onClick}
      icon={showIcon ? seeIcon : ''}
      label={label}
      borderBottom={'2px solid #68B3E0'}
    >
      {children}
    </DashboardSectionCard>
  </Grid>
)

export const DashboardProfessional = () => {
  const { t } = useTranslation()
  const { role } = useGlobalContext()
  const navigate = useNavigate()
  const [openStatusCircleModal, setOpenStatusCircleModal] = useState(false)
  const [numberPendingChats, setNumberPendingChats] = useState<number>(0)
  const [numberNewResources, setNumberNewResources] = useState<number>(0)
  const isActivePlanManager = role.some((role) => role.name === Roles.ManagerActivePlan)
  const isEpilepticManager = role.some((role) => role.name === Roles.ManagerEpileptic)
  const { selectedProfCircle, setSelectedProfCircle } = useProfCircle()

  const [refreshStatusCircleModal, setRefreshStatusCircleModal] = useState(false)

  const [openGroupManagement, setOpenGroupManagement] = useState<boolean>(false)

  const wearables = () => {
    navigate(ROUTE_WEARABLES)
  }

  const handlerOpenStatusCircleModal = () => {
    setOpenStatusCircleModal(!openStatusCircleModal)
    setRefreshStatusCircleModal(!refreshStatusCircleModal)
  }

  const handleOpenGroupManagement = () => {
    setOpenGroupManagement(true)
  }

  const handleCloseGroupManagement = () => {
    setOpenGroupManagement(false)
  }

  useEffect(() => {
    setSelectedProfCircle(undefined)
  }, [])

  return (
    <Box>
      <DashboardSectionCard label={'generalSection'}>
        <Grid width={'100%'} container spacing={2}>
          <Grid mt={4} item xs={12} sm={6} md={4}>
            <NotificationsCard
              ProfessionalNotifications={true}
              SetNumberNewResources={setNumberNewResources}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Grid display={'flex'} xs={12} sm={6} md={12}>
              <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                <ProfessionalCirclesCard />
              </Grid>
              <Grid mt={4} item xs={12} sm={6} md={6}>
                <ChatsCard
                  setNumberPendingChats={setNumberPendingChats}
                  professionalDashboard={true}
                />
              </Grid>
            </Grid>
            <Grid display={'flex'} xs={12} sm={6} md={12}>
              <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                <EducationalContentCard />
              </Grid>
              <Grid mt={4} item xs={12} sm={6} md={6}>
                <ActiveQueriesCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DashboardSectionCard>
      {(isActivePlanManager || selectedProfCircle?.name === 'Plan Activa') && (
        <>
          <DashboardSectionCard label={'wearables'} showMore={true} onClick={wearables}>
            <MonitoredActivityCard />
          </DashboardSectionCard>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('groupsManagement')}
              handler={handleOpenGroupManagement}
            />
          </Box>

          <DashboardSectionCard label={'activePlan'}>
            <Grid container spacing={2}>
              <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
                <CardSection label={'numberOfPatients'}>
                  <SportCenterCard />
                </CardSection>
                <CardSection label={'statisticsPatients'}>
                  <PatientNumberCard refreshStatusCircleModal={refreshStatusCircleModal} />
                </CardSection>
              </Grid>
              <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
                <CardSection label={'numberOfPatients'}>
                  <PatientStatisicsCard
                    activePlanService={activePlanService}
                    refreshStatusCircleModal={refreshStatusCircleModal}
                  />
                </CardSection>
                <CardSection
                  onClick={handlerOpenStatusCircleModal}
                  showIcon={true}
                  label={'circleStatus'}
                >
                  <PieCircleStateCard
                    activePlanService={activePlanService}
                    refreshStatusCircleModal={refreshStatusCircleModal}
                  />
                </CardSection>
                {/* <CardSection label={'numberOfPatients'}>
                  <PatientGroupCard />
                </CardSection> */}
              </Grid>
            </Grid>
          </DashboardSectionCard>
          {openGroupManagement && (
            <ModalGroupManagement
              open={openGroupManagement}
              onClose={handleCloseGroupManagement}
              activePlanService={activePlanService}
            />
          )}
        </>
      )}
      {openStatusCircleModal && (
        <StatusCircleModal
          open={openStatusCircleModal}
          onClose={handlerOpenStatusCircleModal}
          setRefreshStatusCircleModal={setRefreshStatusCircleModal}
        />
      )}

      {(isEpilepticManager || selectedProfCircle?.name === 'Epilepsia') && (
        <DashboardSectionCard label={'epilepsya'} marginTop={50}>
          <EpilepsyCard />
        </DashboardSectionCard>
      )}

      <NumericStatsCard
        numberPendingChats={numberPendingChats}
        numberNewResources={numberNewResources}
      ></NumericStatsCard>
    </Box>
  )
}
