import { User } from 'modules/users/models/User'
import { Query } from '../../../common/api/Query'

export type ActivePlanWithUserProps = {
  id: string
  userCircleID: string
  userProfessionalID: string
  professionalMessage: string
  status: string
  startDate: Date
  finishDate: Date
  reason: string
  observation: string
  protocol: string
  user: User
  rcv: number
}

export class ActivePlanWithUser {
  private readonly _id: string
  private _userCircleID: string
  private _userProfessionalID: string
  private _professionalMessage: string
  private _status: string
  private _startDate: Date
  private _finishDate: Date
  private _reason: string
  private _observation: string
  private _protocol: string
  private _user: User
  private _rcv: number

  constructor(props: ActivePlanWithUserProps) {
    this._id = props.id
    this._userCircleID = props.userCircleID
    this._userProfessionalID = props.userProfessionalID
    this._professionalMessage = props.professionalMessage
    this._status = props.status
    this._startDate = props.startDate
    this._finishDate = props.finishDate
    this._reason = props.reason
    this._observation = props.observation
    this._protocol = props.protocol
    this._user = props.user
    this._rcv = props.rcv
  }

  get id(): string {
    return this._id
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  get status(): string {
    return this._status
  }

  set status(value: string) {
    this._status = value
  }

  get startDate(): Date {
    return new Date(this._startDate)
  }

  set startDate(value: Date) {
    this._startDate = value
  }

  get finishDate(): Date {
    return new Date(this._finishDate)
  }

  set finishDate(value: Date) {
    this._finishDate = value
  }

  get userProfessionalID(): string {
    return this._userProfessionalID
  }

  set userProfessionalID(value: string) {
    this._userProfessionalID = value
  }

  get professionalMessage(): string {
    return this._professionalMessage
  }

  set professionalMessage(value: string) {
    this._professionalMessage = value
  }

  get reason(): string {
    return this._reason
  }

  set reason(value: string) {
    this._reason = value
  }

  get observation(): string {
    return this._observation
  }

  set observation(value: string) {
    this._observation = value
  }

  get protocol(): string {
    return this._protocol
  }

  set protocol(value: string) {
    this._protocol = value
  }

  get user(): User {
    return this._user
  }

  get rcv(): number {
    return this._rcv
  }

  set rcv(value: number) {
    this._rcv = value
  }
}

export class ActivePlanWithUserQuery extends Query<ActivePlanWithUser> {
  ids?: string[]
  id?: string
  userCircleID?: string
  userProfessionalID?: string
  professionalMessage?: string
  status?: string
  startDate?: Date
  finishDate?: Date
  reason?: string
  observation?: string
  patientByAge?: number
  patientByHospital?: number
}
