import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  emptyPatientSymptomDTO,
  fromModel,
  PatientSymptomDTO,
} from 'modules/patient-data/models/PatientSymptomDTO'
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CALENDAR } from 'routes/routes-constants'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { getPatientDataContainer } from 'container/patient-data-module'
import { PATIENT_SYMPTOM_SERVICE_KEY, SYMPTOM_SERVICE_KEY } from 'modules/patient-data'
import { PatientSymptomService } from 'modules/patient-data/services/PatientSymptomService'
import { BoolQueryParam, Query, QueryParam } from 'common/api/Query'
import { SymptomService } from 'modules/patient-data/services/SymptomService'
import { SymptomQuery } from 'modules/patient-data/models/Symptom'
import { ConditionField } from 'modules/patient-data/enums/PatientSymptomsNames'
import { FormFieldType } from 'modules/forms/enums/FormFieldType'
import styles from './GeneralSymptomsModal.module.css'
import { emptyPatientSymptomConditionDTO } from 'modules/patient-data/models/PatientSymptomConditionDTO'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import close from 'assets/esfera/buttons/delete.svg'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { Header } from 'components/header/Header'
import { TextFieldItem } from 'components/form-card/TextFieldItem'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

enum SymptomErrors {
  NO_SYMPTOM = 'aSymptomMustBeSelected',
  REQUIRED_FIELDS = 'requiredFields',
}

type AutocompleteSymptomInterface = {
  commonSymptom: string
  key: string
  value: string
}

type SymptomsFormProps = {
  id?: string
  open?: boolean
  onClose?: () => void
  date?: Date
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const patientDataContainer = getPatientDataContainer()
const patientSymptomService = patientDataContainer.get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const symptomService = patientDataContainer.get<SymptomService>(SYMPTOM_SERVICE_KEY)

export function GeneralSymptomsModal(props: SymptomsFormProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { selectedUserCircle } = useCircleConfiguration()

  const loggedUser = loggedUserService.get()
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  const [symptomMap, setSymptomMap] = useState<Map<string, AutocompleteSymptomInterface>>(new Map())
  const [selectedSymptom, setSelectedSymptom] = useState<AutocompleteSymptomInterface>()
  const [patientSymptom, setPatientSymptom] = useState<PatientSymptomDTO>(emptyPatientSymptomDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conditions, setConditions] = useState<ConditionField[]>([])

  useEffect(() => {
    setIsEditing(!!props.id)
    if (props.id) {
      patientSymptomService.getByID(props.id).subscribe((res) => {
        if (res) {
          setPatientSymptom(fromModel(res))
        }
      })
    } else {
      if (loggedUser?.id && selectedUserCircle?.user.id) {
        setPatientSymptom(
          Object.assign(
            { ...patientSymptom },
            {
              creatorID: loggedUser.id,
            }
          )
        )
      }
    }
    if (!selectedUserCircle) {
      return
    }
    const updatedSymptomMap = new Map(symptomMap)
    const uniqueSymptomIds = new Set()

    symptomService
      .getFilteredList(
        new Query<SymptomQuery>({
          query: [new QueryParam<SymptomQuery>('commonSymptom', new BoolQueryParam(true))],
        })
      )
      .subscribe((sl) => {
        sl.items.forEach((s) => {
          const symptomId = s.id ?? ''
          if (!uniqueSymptomIds.has(symptomId)) {
            const symptomData: AutocompleteSymptomInterface = {
              commonSymptom: s.commonSymptom ? 'true' : 'false',
              key: symptomId,
              value: s.name ?? '',
            }
            updatedSymptomMap.set(symptomId, symptomData)
            uniqueSymptomIds.add(symptomId)
          }
        })

        setSymptomMap(updatedSymptomMap)
      })
  }, [selectedUserCircle])

  useEffect(() => {
    if (!selectedSymptom) {
      return
    }
    setErrorMessage('')
    // const conds = patientSymptoms.get(selectedSymptom.value) ?? [] comentado por tarea esfera 755

    // setConditions(conds)
    setPatientSymptom(
      Object.assign(
        { ...patientSymptom }
        // {
        //   conditions: conds.map((c) => ({
        //     name: c.name,
        //     type: c.valueType,
        //     value: falsyValue(c.valueType),
        //   })),
        // }
      )
    )
  }, [selectedSymptom])

  useEffect(() => {
    if (props.id && symptomMap.has(patientSymptom.symptomID)) {
      setSelectedSymptom(symptomMap.get(patientSymptom.symptomID))
    }
  }, [symptomMap])

  // const falsyValue = (vt: ValueTypes): any => {
  //   switch (vt) {
  //     case ValueTypes.Bool:
  //       return false
  //     case ValueTypes.Float:
  //       return 0
  //     default:
  //       return undefined
  //   }
  // }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setPatientSymptom(Object.assign({ ...patientSymptom }, { [e.target.name]: e.target.value }))

  const handleStartDate = (event: any) =>
    event &&
    setPatientSymptom(Object.assign({ ...patientSymptom }, { symptomStartDate: event.toDate() }))

  const handleEndDate = (event: any) =>
    event && setPatientSymptom(Object.assign({ ...patientSymptom }, { finishDate: event.toDate() }))

  const handleSymptomID = (value: any) => {
    if (!value) {
      return
    }
    setSelectedSymptom(value)
    setPatientSymptom(Object.assign({ ...patientSymptom }, { symptomID: value.key }))
  }

  const createField = (c: ConditionField): JSX.Element => {
    switch (c.fieldType) {
      case FormFieldType.CheckBox:
        return (
          <Box style={{ marginBottom: 13 }}>
            <p className={styles.label + ' ' + styles.checkBoxLabel}>{c.name}</p>
            <Checkbox
              name={c.name}
              checked={patientSymptom.conditions.find((cond) => cond.name === c.name)?.value}
              onChange={(e) => handleCheckbox(e, c.valueType)}
            />
          </Box>
        )
      case FormFieldType.Select:
        return (
          <Box style={{ marginBottom: 13 }}>
            <p className={styles.label}>{c.name}</p>
            <Select
              key={c.name}
              id={c.name}
              variant={'outlined'}
              value={
                patientSymptom.conditions.find(
                  (cond) => cond.name === c.name && !Object.is(+cond.value, NaN)
                )?.value ?? ''
              }
              onChange={(e) => handleSelect(e, c.name)}
              className={styles.select}
              required={true}
            >
              {Array.from(c.options ? c.options.keys() : []).map((k) => (
                <MenuItem key={k} value={c.options ? c.options.get(k) : ''}>
                  {k}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )
      default:
        return <></>
    }
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>, type: number) => {
    const c = emptyPatientSymptomConditionDTO()
    c.name = e.target.name
    c.type = type
    c.value = e.target.checked
    const newConditions = patientSymptom.conditions.map((cond) => {
      if (cond.name === c.name) {
        return c
      }
      return cond
    })
    setPatientSymptom(Object.assign({ ...patientSymptom }, { conditions: newConditions }))
  }

  const handleSelect = (event: SelectChangeEvent<{ value: unknown }>, name: string) =>
    setPatientSymptom(
      Object.assign(
        { ...patientSymptom },
        {
          conditions: patientSymptom.conditions.map((cond) => {
            if (cond.name === name) {
              return {
                name: cond.name,
                type: cond.type,
                value: +(event.target.value as unknown as number),
              }
            }
            return cond
          }),
        }
      )
    )

  const goBack = () => navigate(ROUTE_CALENDAR, { state: { selectedDate: props.date } })

  const validateSymptom = () => {
    if (!patientSymptom.symptomID) {
      setErrorMessage(SymptomErrors.NO_SYMPTOM)
      return false
    }

    const required = conditions.filter((c) => c.required)
    let found = true
    required.forEach((c) => {
      if (
        !patientSymptom.conditions.find(
          (cond) => c.options && Array.from(c.options.values()).find((v) => +v === cond.value)
        )
      ) {
        found = false
      }
    })
    if (!found) {
      setErrorMessage(SymptomErrors.REQUIRED_FIELDS)
      return false
    }

    setErrorMessage('')
    return true
  }

  const saveSymptom = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateSymptom()) {
      return
    }

    patientSymptom.userID = selectedUserCircle?.user.id ?? ''
    patientSymptom.userCircleID = selectedUserCircle?.id ?? ''

    if (props.id) {
      patientSymptomService.update(patientSymptom).subscribe(() => {
        goBack()
        props.onClose && props.onClose()
      })
      return
    }

    patientSymptomService.add(patientSymptom).subscribe(() => {
      goBack()
      props.onClose && props.onClose()
    })
  }

  // FIXME - Avoid using inline styles / quick fix

  return (
    <Modal className={styles.modal} open={props.open ?? false} onClose={props.onClose}>
      <Box className={styles.modalContainer}>
        <Box className={styles.wrapper}>
          <Box className={styles.exitIcon}>
            <img className={styles.icon} src={close} alt={'close icon'} onClick={props.onClose} />
          </Box>
          <Header label={t('symptoms')} />
          <form onSubmit={saveSymptom} className={styles.formBox}>
            <Box className={styles.inputsRow}>
              <Box className={styles.inputBox}>
                <span className={styles.symptomLabel}>{t('symptom')}*</span>

                <Autocomplete
                  key={patientSymptom.id}
                  id={patientSymptom.id}
                  options={Array.from(symptomMap.values()).sort((a, b) => {
                    const sort = a.value.localeCompare(b.value)
                    return sort !== 0 ? sort : a.value.localeCompare(b.value)
                  })}
                  getOptionLabel={(opt) => opt.value}
                  value={selectedSymptom ?? { commonSymptom: '', key: '', value: '' }}
                  onChange={(_, v) => handleSymptomID(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={t('symptom')}
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                  className={styles.autocompleteStyle}
                  noOptionsText={t('noElementsAvailable')}
                  disabled={isEditing}
                />
              </Box>

              <Box className={styles.inputBox}>
                <span className={styles.symptomLabel}>{t('startDate')}*</span>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DateTimePicker
                    PopperProps={{
                      sx: {
                        '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                          fontFamily: 'Open Sans',
                          textTransform: 'capitalize',
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: <CalendarMonthOutlinedIcon />, // para que se vea el icono en móvil
                    }}
                    components={{
                      OpenPickerIcon: CalendarMonthOutlinedIcon,
                    }}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        variant={'outlined'}
                        helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    inputFormat="DD/MM/YYYY HH:mm"
                    onChange={handleStartDate}
                    value={patientSymptom.symptomStartDate}
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              </Box>

              <Box className={styles.inputBox}>
                <span className={styles.symptomLabel}>{t('finishDate')}</span>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DateTimePicker
                    PopperProps={{
                      sx: {
                        '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                          fontFamily: 'Open Sans',
                          textTransform: 'capitalize',
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: <CalendarMonthOutlinedIcon />, // para que se vea el icono en móvil
                    }}
                    components={{
                      OpenPickerIcon: CalendarMonthOutlinedIcon,
                    }}
                    onError={(reason) => {
                      switch (reason) {
                        case 'invalidDate':
                          setDateTimePickerError(t('invalidDateMessage'))
                          break
                        case 'minDate':
                          setDateTimePickerError(t('minDateMessage'))
                          break
                      }
                    }}
                    renderInput={(props) => (
                      <TextField
                        variant={'outlined'}
                        helperText={props.error && DateTimePickerError}
                        {...props}
                      />
                    )}
                    key={'finishDate'}
                    inputFormat="DD/MM/YYYY HH:mm"
                    onChange={handleEndDate}
                    value={patientSymptom.finishDate}
                    minDate={dayjs(patientSymptom.symptomStartDate ?? undefined)}
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            {/* Segunda sección: Condiciones */}
            {conditions.map((c) => createField(c))}

            {/* Tercera sección: Comentarios */}
            <Box className={styles.commentBox}>
              <Box mb={1}>
                <p>{t('comments')}</p>
              </Box>
              <TextFieldItem
                field="comment"
                value={patientSymptom.comment}
                label={''}
                type={'text'}
                handleChange={handleInput}
                rows={6}
                required={false}
              />
            </Box>

            {/* Mensajes de error */}
            {errorMessage && (
              <Box className={styles.errorBox}>
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  {t(errorMessage)}
                  {errorMessage === SymptomErrors.REQUIRED_FIELDS
                    ? ': ' +
                      conditions
                        .filter((c) => c.required)
                        .map((c) => c.name)
                        .join(', ')
                    : ''}
                </Alert>
              </Box>
            )}
          </form>
          {/* Botones */}
          <Box className={styles.buttonsContainer}>
            <AppButton
              theme={ButtonTheme.whiteAndBlue}
              type={'button'}
              label={t('back')}
              handler={() => {
                props.onClose && props.onClose()
              }}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={saveSymptom}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
