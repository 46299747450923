import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './EpilepsyCard'
import { UserForm } from '../../../../modules/forms/models/UserForm'

type CrisisSeverityCardProps = {
  userForms: UserForm[]
}
export const CrisisSeverity: React.FC<CrisisSeverityCardProps> = ({ userForms }) => {
  const { t } = useTranslation()
  const [severityCrisis, setSeverityCrisis] = useState<number[]>([])
  const [monthLabel, setMonthLabel] = useState<string[]>([])

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]

  useEffect(() => {
    if (userForms.length > 0) getScores()
  }, [userForms])

  const getScores = () => {
    const monthlyScores: Record<string, number[]> = {}

    userForms.forEach((form) => {
      const date = new Date(form.userFormValues[0].date)
      const monthYear = `${labels[date.getMonth()]} ${date.getFullYear()}`

      if (!monthlyScores[monthYear]) {
        monthlyScores[monthYear] = []
      }

      monthlyScores[monthYear].push(form.totalScore ?? 0)
    })

    const sortedMonths = Object.keys(monthlyScores).sort((a, b) => {
      const dateA = new Date(a.split(' ')[1] + '-' + (labels.indexOf(a.split(' ')[0]) + 1))
      const dateB = new Date(b.split(' ')[1] + '-' + (labels.indexOf(b.split(' ')[0]) + 1))
      return dateA.getTime() - dateB.getTime()
    })

    const averageScores = sortedMonths.map((month) => {
      const scores = monthlyScores[month]
      return scores.reduce((sum, score) => sum + score, 0) / scores.length
    })

    setMonthLabel(sortedMonths)
    setSeverityCrisis(averageScores)
  }

  const datasets = [
    {
      label: t('severityCrisis'),
      data: severityCrisis,
      borderColor: 'blue',
    },
  ]

  return (
    <CardComponent title={t('severityCrisis')}>
      <GenericChartDashboardComponent
        datasets={datasets}
        labels={monthLabel}
        title={''}
        typeChart={'bar'}
        legendPosition={'bottom'}
        yAxisConfig={{
          min: 0,
          max: 10,
          stepSize: 2,
        }}
      />
    </CardComponent>
  )
}
