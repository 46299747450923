import { Box, Divider, Tooltip, useMediaQuery } from '@mui/material'
import style from './CalendarModal.module.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from 'components/header/Header'
import close from 'assets/esfera/buttons/delete.svg'
import {
  dateToDateTimeWithoutSecondsString,
  dateToTimeWithoutSecondsString,
} from 'common/utils/date'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { getUserContainer } from 'container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from 'modules/users'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { viewFile } from 'common/utils/file'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { Link, useNavigate } from 'react-router-dom'
import { User } from 'modules/users/models/User'
import { EventCategory, eventCategoryTypes } from 'modules/calendar/enums/EventCategory'
import { reduceString } from 'common/utils/strings'
import { File as F } from 'modules/files/models/File'
import InfoIcon from 'assets/neuropediatria_icon/icono-info.svg'
import { Chip } from '@mui/material'
import { AllCalendarEventDTO } from 'modules/calendar/models/AllCalendarEventDTO'
import { Zone, zoneTypes } from 'modules/epileptical/enums/Zone'
import { Duration, durationTypes } from 'modules/epileptical/enums/Duration'
import { Awareness, awarenessTypes } from 'modules/epileptical/enums/Awareness'
import { Movement, movementTypes } from 'modules/epileptical/enums/Movement'
import { Vision, visionTypes } from 'modules/epileptical/enums/Vision'
import { SkinColor, skinColorTypes } from 'modules/epileptical/enums/SkinColor'
import { Symptom, symptomTypes } from 'modules/epileptical/enums/Symptom'
import { Mouth, mouthTypes } from 'modules/epileptical/enums/Mouth'
import { AfterDuration, afterDurationTypes } from 'modules/epileptical/enums/AfterDuration'
import { forkJoin, Observable } from 'rxjs'
import { ROUTE_TELECONSULTATION } from 'routes/routes-constants'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const eventCategories = eventCategoryTypes()

type CalendarModalProps = {
  handleClose: () => void
  handleEdit: (e: AllCalendarEventDTO) => void
  handleRemove: (e: AllCalendarEventDTO) => void
  event: AllCalendarEventDTO
}

export const CalendarModal = (props: CalendarModalProps) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [users, setUsers] = useState<string[]>([])
  const [nameFiles, setNameFiles] = useState<Map<string, string>>(new Map())
  const [openDraggableconfig, setOpenDraggableconfig] = useState<boolean>(false)
  const isXS = useMediaQuery('(max-width: 599px)')
  const navigate = useNavigate()

  useEffect(() => {
    if (!props.event.users) return
    getUsers(props.event.users).subscribe((ul) => {
      // TODO filter is necessary for TheSystem user
      setUsers(ul.filter((u: User) => u).map((u: User) => u.name))
    })
    if (props.event.files) {
      getFiles(props.event.files).subscribe((fl: F[]) => {
        const tmpNameFiles = new Map()
        fl.forEach((f) => {
          tmpNameFiles.set(f.id ?? '', f.name)
        })
        setNameFiles(tmpNameFiles)
      })
    }
  }, [])

  const getFiles = (ids: string[]): Observable<F[]> =>
    forkJoin(ids.map((uid) => filesService.getByID(uid)) as unknown) as Observable<F[]>

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((uid) => userService.getByID(uid)) as unknown) as Observable<User[]>

  const hours = `${dateToTimeWithoutSecondsString(props.event.startDate)} -
    ${dateToTimeWithoutSecondsString(props.event.finishDate)}`

  const canEdit = (e: AllCalendarEventDTO) => {
    return loggedUser?.id === e.creatorID
  }

  const viewResource = (id: string) => {
    filesService.downloadByID(id).subscribe((res) => {
      if (res) {
        viewFile(res)
      }
    })
  }

  const getCategoryName = (n: number) => {
    const array = Array(Object.values(eventCategories))
    return array[0][n - 1]
  }

  // const theme = createTheme({
  //   components: {
  //     MuiTooltip: {
  //       styleOverrides: {
  //         tooltip: {
  //           maxWidth: 400,
  //           fontSize: '2em',
  //           color: 'black',
  //           border: '1px solid #3f51b5',
  //           backgroundColor: '#f6f5f5',
  //         },
  //         arrow: {
  //           '&:before': {
  //             border: '1px solid #3f51b5',
  //           },
  //           color: '#f6f5f5',
  //         },
  //       },
  //     },
  //   },
  // })

  const tooltipContent = () => {
    return (
      <div style={{ fontSize: 13, backgroundColor: '#fff', padding: 4 }}>
        <Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipBold}>{t('beforeCrisis')}</Box>
          </Box>
          <Box>
            <Box className={style.toolTipText} style={{ marginLeft: '4px' }}>
              {props.event.crisisDetails.comment ?? t('noComments')}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipBold}>{t('duringTheCrisis')}</Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('zone')}: &nbsp; </Box>
            <Box className={style.toolTipText}>
              {zoneTypes()[props.event.crisisDetails.zone as Zone]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('eventDuration')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {durationTypes()[props.event.crisisDetails.duration as Duration]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('awareness')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {awarenessTypes()[props.event.crisisDetails.awareness as Awareness]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('movement')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {movementTypes()[props.event.crisisDetails.movement as Movement]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('vision')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {visionTypes()[props.event.crisisDetails.vision as Vision]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('skinColor')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {skinColorTypes()[props.event.crisisDetails.skinColor as SkinColor]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('symptom')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {symptomTypes()[props.event.crisisDetails.symptom as Symptom]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('mouth')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {mouthTypes()[props.event.crisisDetails.mouth as Mouth]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipBold}>{t('afterTheCrisis')}</Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>{t('eventDuration')}:&nbsp; </Box>
            <Box className={style.toolTipText}>
              {afterDurationTypes()[props.event.crisisDetails.afterDuration as AfterDuration]}
            </Box>
          </Box>
          <Box className={style.toolTipRow}>
            <Box className={style.toolTipText}>
              {props.event.crisisDetails.afterSymptom?.join(', ')}
            </Box>
          </Box>
        </Box>
      </div>
    )
  }
  const color = (c: string) => {
    let a
    switch (c) {
      case '#92d6c3':
        a = 'Verde'
        break
      case '#6acafa':
        a = 'Azul'
        break
      case '#ffa2de':
        a = 'Rosa'
        break
      case '#ffd539':
        a = 'Amarillo'
        break
      case '#ffb229':
        a = 'Ámbar'
        break
      case '#ff8543':
        a = 'Naranja'
        break
      case '#d70c0b':
        a = 'Rojo'
        break
      case '#020202':
        a = 'Negro'
        break
    }
    return a
  }

  const handlerDraggableConfig = () => {
    setOpenDraggableconfig(!openDraggableconfig)
  }

  const handlerGoToTeleconsultation = (id: string) => {
    navigate(ROUTE_TELECONSULTATION, { state: { conversationID: id } })
  }

  return (
    <Box className={style.modalView}>
      <Box className={style.exitIcon}>
        <img className={style.icon} src={close} alt={'close icon'} onClick={props.handleClose} />
      </Box>
      <div className={style.header}>
        <Header label={t('eventInformation')} />
      </div>
      {!isXS && <Divider style={{ backgroundColor: '#68b3e0', margin: 8, marginTop: 30 }} />}

      <Box mt={1} mb={1} display={'flex'} justifyContent={'flex-start'}>
        <p className={style.labelTitle}>
          {props.event.title === t('teleconsultaHeader')
            ? t('onlineNeurologyAppointment')
            : props.event.title === 'train'
              ? t(props.event.title)
              : props.event.title}
        </p>
      </Box>
      {!isXS && <Divider style={{ backgroundColor: '#68b3e0', margin: 8 }} />}

      {props.event.eventCategory === EventCategory.EpilepticCrisis && (
        <>
          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <div className={style.divRow}>
              <label className={style.dayType}>
                {props.event.crisisDetails.epilepticalMood === 1
                  ? 'Día bueno'
                  : props.event.crisisDetails.epilepticalMood === 2
                    ? 'Día regular'
                    : props.event.crisisDetails.epilepticalMood === 3
                      ? 'Día malo'
                      : ''}
              </label>
            </div>
          </Box>

          <Box mt={1} mb={1} display={'flex'} flexDirection={!isXS ? 'row' : 'column'}>
            <Box display={'flex'}>
              <p className={style.titleLabel}>{t('date')}:</p>
              <p>
                {new Date(props.event.startDate).toLocaleDateString('es', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </p>
            </Box>
            <Box style={{ width: '100%', marginLeft: !isXS ? 15 : 0 }}>
              <p className={style.titleLabel}>
                {t('rescueMedicationAdministered')}: {props.event.crisisDetails.medication}
              </p>
            </Box>
          </Box>

          {props.event.crisisDetails.color !== '' && (
            <>
              <Divider style={{ backgroundColor: '#68b3e0', margin: 8 }} />
              <Box
                mt={1}
                mb={1}
                display={'flex'}
                justifyContent={'space-between'}
                className={style.divRow}
              >
                <div
                  className={style.labelSintoma}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p className={style.titleLabel}>{t('medium')}:</p>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                    <div
                      className={style.color}
                      style={{ backgroundColor: props.event.crisisDetails.color }}
                    >
                      {color(props.event.crisisDetails.color)}
                    </div>
                    {!isXS ? (
                      <Tooltip arrow title={tooltipContent()}>
                        <img alt="infoIcon" src={InfoIcon} style={{ height: '70%' }} />
                      </Tooltip>
                    ) : (
                      <img
                        alt="infoIcon"
                        src={InfoIcon}
                        style={{ height: '100%', cursor: 'pointer' }}
                        onClick={handlerDraggableConfig}
                      />
                    )}
                  </div>
                </div>

                <div className={style.labelSintomaHour}>
                  <p className={style.titleLabel}>Hora inicio:</p>
                  <p className={!isXS ? style.label : style.labelResponsive}>
                    {new Date(props.event.startDate).getHours().toString().padStart(2, '0')}:
                    {new Date(props.event.startDate).getMinutes().toString().padStart(2, '0')}h
                  </p>
                </div>

                <div className={style.labelSintomaHour}>
                  <p className={style.titleLabel}>Hora fin:</p>
                  <p className={!isXS ? style.label : style.labelResponsive}>
                    {new Date(props.event.finishDate).getHours().toString().padStart(2, '0')}:
                    {new Date(props.event.finishDate).getMinutes().toString().padStart(2, '0')}h
                  </p>
                </div>
              </Box>
            </>
          )}

          {props.event.crisisDetails.observations && (
            <>
              <Divider style={{ backgroundColor: '#68b3e0', margin: 8 }} />
              <Box mt={1} mb={1} display={'flex'}>
                <div className={style.labelSintoma}>
                  <p>
                    {' '}
                    <strong>{t('observations')}: </strong>
                    <Tooltip title={props.event.crisisDetails.observations}>
                      <p>{reduceString(props.event.crisisDetails.observations, 70)}</p>
                    </Tooltip>
                  </p>
                </div>
              </Box>
            </>
          )}
        </>
      )}

      {props.event.eventCategory !== EventCategory.EpilepticCrisis && (
        <>
          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('eventCategory')}</p>
            <p className={style.label}>
              {getCategoryName(props.event.eventCategory) === 'Embarazo' ||
              props.event.eventCategory === 17
                ? 'Cita Medica'
                : getCategoryName(props.event.eventCategory)}
            </p>
          </Box>
          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('startDate')}</p>
            <p className={style.label}>
              {dateToDateTimeWithoutSecondsString(props.event.startDate)}
            </p>
          </Box>
          <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
            <p className={style.titleLabel}>{t('finishDate')}</p>
            <p className={style.label}>
              {dateToDateTimeWithoutSecondsString(props.event.finishDate)}
            </p>
          </Box>

          {props.event.eventCategory === EventCategory.Others ? (
            <>
              <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
                <p className={style.titleLabel}>{t('hour')}</p>
                <p className={style.label}>{hours}</p>
              </Box>

              <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
                <p className={style.titleLabel}>{t('place')}</p>
                <p className={style.label}>{props.event.place}</p>
              </Box>
            </>
          ) : null}
          {users.length > 0 && (
            <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
              <p className={style.titleLabel}>{t('participants')}</p>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'flex-end'}
                marginRight={'20px'}
              >
                {users.map((u) => (
                  <Chip key={u} className={style.chip} label={u} />
                ))}
              </Box>
            </Box>
          )}
          {!isXS && <Divider style={{ backgroundColor: '#68b3e0', margin: 8 }} />}
          {props.event.eventCategory === EventCategory.Teleconsultation && (
            <Box mt={1} mb={1} display="flex" justifyContent="space-between">
              <p className={style.titleLabel}>
                {t('accessConsultationLink')}{' '}
                <span
                  className={style.link}
                  onClick={() => props.event.id && handlerGoToTeleconsultation(props.event.id)}
                >
                  {' '}
                  {t('here')}
                </span>
              </p>
            </Box>
          )}
        </>
      )}

      {props.event.files && (
        <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
          <p className={style.titleLabel}>{t('files')}</p>
          <Box display={'flex'} flexWrap={'wrap'}>
            {props.event.files.map((f, i) => (
              <Link key={f} to={'.'} onClick={() => viewResource(f)}>
                <p className={style.titleLabel}>{reduceString(nameFiles.get(f) ?? '', 15)}</p>
              </Link>
            ))}
          </Box>
        </Box>
      )}
      {canEdit(props.event) && (
        <Box className={style.buttonBox}>
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('removeEvent')}
            handler={() => props.handleRemove(props.event)}
          />
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('edit')}
            handler={() => props.handleEdit(props.event)}
          />
        </Box>
      )}
    </Box>
  )
}
