import { PROMS_beckItems } from './PROMS_beckItemsProps'

export interface PROMS_beckItemsDTO {
  userFormID: string
  sadness: number
  pessimism: number
  failure: number
  lossOfPleasure: number
  guilt: number
  punishment: number
  disconformityWithSelf: number
  selfCriticism: number
  suicidalThoughts: number
  crying: number
  agitation: number
  lossOfInterest: number
  indecision: number
  devaluation: number
  lossOfEnergy: number
  sleepHabits: number
  irritability: number
  appetite: number
  difficultyConcentrating: number
  fatigue: number
  lossOfInterestInSex: number
}

export function emptyPromsBeckItems(userFormID: string): PROMS_beckItemsDTO {
  return {
    userFormID,
    sadness: 0,
    pessimism: 0,
    failure: 0,
    lossOfPleasure: 0,
    guilt: 0,
    punishment: 0,
    disconformityWithSelf: 0,
    selfCriticism: 0,
    suicidalThoughts: 0,
    crying: 0,
    agitation: 0,
    lossOfInterest: 0,
    indecision: 0,
    devaluation: 0,
    lossOfEnergy: 0,
    sleepHabits: 0,
    irritability: 0,
    appetite: 0,
    difficultyConcentrating: 0,
    fatigue: 0,
    lossOfInterestInSex: 0,
  }
}

export function beckIIToModel(dto: PROMS_beckItemsDTO): PROMS_beckItems {
  return new PROMS_beckItems(dto)
}
