import React, { ReactNode, useState } from 'react'
import { Grid, Divider, Box } from '@mui/material'
import { DashboardSectionCard } from '../../../components/section-card/DashboardSectionCard'
import { dividerStyle } from '../patient/active-plan-card/ActivePlanCardStyleMui'
import { ActivePlanService } from '../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../modules/active-plan'
import { getActivePlanContainer } from '../../../container/active-plan-module'
import seeIcon from '../../../assets/dashboard/seeIcon.svg'
import SportCenterCard from '../profesional/sport-center-card/SportCenterCard'
import PatientNumberCard from '../profesional/patient-number-card/PatientNumberCard'
import PatientStatisicsCard from '../profesional/patient-statisics-card/PatientStatisicsCard'
import PieCircleStateCard from '../profesional/pie-circle-state-card/CircleStateCard'
import PatientGroupCard from '../profesional/patient-group-card/PatientGroupCard'
import { StatusCircleModal } from '../profesional/pie-circle-state-card/StatusCircleModal'

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

interface CardSectionProps {
  label: string
  children: ReactNode
  showIcon?: boolean
  onClick?: () => void
}

const CardSection: React.FC<CardSectionProps> = ({ label, children, showIcon, onClick }) => (
  <Grid mr={2} item xs={12} sm={6} md={6}>
    <DashboardSectionCard
      onClick={onClick}
      icon={showIcon ? seeIcon : ''}
      label={label}
      borderBottom={'2px solid #68B3E0'}
    >
      {children}
    </DashboardSectionCard>
  </Grid>
)
export const DashboardCaseManager = () => {
  const [openStatusCircleModal, setOpenStatusCircleModal] = useState(false)
  const handlerOpenStatusCircleModal = () => {
    setOpenStatusCircleModal(!openStatusCircleModal)
  }
  return (
    <Box>
      <DashboardSectionCard label={'activePlan'}>
        <Grid container spacing={2}>
          <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
            <CardSection label={'numberOfPatients'}>
              <SportCenterCard />
            </CardSection>
            <CardSection label={'statisticsPatients'}>
              <Divider sx={dividerStyle} />
              <PatientNumberCard />
            </CardSection>
          </Grid>
          <Grid display={'flex'} flexDirection={'row'} mt={4} item xs={12} sm={6} md={12}>
            <CardSection label={'numberOfPatients'}>
              <PatientStatisicsCard activePlanService={activePlanService} />
            </CardSection>
            <CardSection
              onClick={handlerOpenStatusCircleModal}
              showIcon={true}
              label={'circleStatus'}
            >
              <PieCircleStateCard
                activePlanService={activePlanService}
                refreshStatusCircleModal={false}
              />
            </CardSection>
            <CardSection label={'numberOfPatients'}>
              <PatientGroupCard />
            </CardSection>
          </Grid>
        </Grid>
      </DashboardSectionCard>
      {openStatusCircleModal && (
        <StatusCircleModal open={openStatusCircleModal} onClose={handlerOpenStatusCircleModal} />
      )}

      {/* <NumericStatsCard
        numberPendingChats={numberPendingChats}
        numberNewResources={numberNewResources}
      ></NumericStatsCard> */}
    </Box>
  )
}
