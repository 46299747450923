import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { LoadingSpinner } from 'components/loading-spinner/LoadingSpinner'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import styles from './Search.module.css'
import { TableGrid } from './TableGrid'
import { Header } from '../../components/header/Header'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CREATE, ROUTE_TUTORIALS_FORM } from '../../routes/routes-constants'
import tick from '../../assets/calendar/tick.svg'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import TextField from '@mui/material/TextField'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { Permission } from '../../common/permission'

const loggedUserContainer = getUserContainer()

const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type SearchProps = {
  tagList: string[]
}

export function Search(props: SearchProps) {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filter, setFilter] = useState<any>({})
  const [searchText, setSearchText] = useState<string>('')
  const [filterToSend, setFilterToSend] = useState<string[]>([])
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [circles, setUserCircles] = useState<any>([])

  const navigate = useNavigate()

  useEffect(() => {
    const circles: any[] = []
    const userCircles: CircleDTO[] = []
    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          const uniqueCircles: CircleDTO[] = []
          res.forEach((userCircle) => {
            if (!uniqueCircles.find((circle) => circle.id === userCircle.circle.id)) {
              uniqueCircles.push(userCircle.circle)
              circles.push(userCircle.circle.id)
              userCircles.push(userCircle.circle)
            }
          })
          setIsLoading(false)
          setCircle(userCircles)
          setUserCircles(circles)
        }
      })
  }, [])

  useEffect(() => {
    const aux = {}
    Array.from(circle).forEach((obj) => {
      if (sessionStorage.getItem('tutorialFilter')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const filter = JSON.parse(sessionStorage.getItem('tutorialFilter'))
        if (filter.find((initFilter: string) => initFilter === obj.id)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          aux[obj.name] = true
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          aux[obj.name] = false
        }
      }
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const filter = JSON.parse(sessionStorage.getItem('tutorialFilter'))
    if (filter) {
      setFilterToSend(filter)
      setFilter(aux)
    }
  }, [circle])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    let aux: string[] = []
    Object.assign(aux, filterToSend)
    setFilter({
      ...filter,
      [event.target.name]: event.target.checked,
    })
    const obj = circle.find((obj) => obj.name === event.target.name)
    if (obj) {
      if (filterToSend.find((toSend) => toSend === obj.id)) {
        aux = aux.filter((auxFilter) => auxFilter !== obj.id)
        sessionStorage.setItem('tutorialFilter', JSON.stringify(aux))
        setFilterToSend(aux)
      } else {
        aux.push(obj.id)
        sessionStorage.setItem('tutorialFilter', JSON.stringify(aux))
        setFilterToSend(aux)
      }
    }
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchText(e.target.value)
  }

  const handleCreate = () => {
    navigate(ROUTE_TUTORIALS_FORM + '/' + ROUTE_CREATE)
  }

  return (
    <>
      {!isLoading ? (
        <Box className={genericStyle.pageContainer}>
          <Box>
            <Header label={t('filters')} />
          </Box>

          <div className={styles.legendCheck2}>
            <span className={styles.filterCategory} style={{ textWrap: 'nowrap' }}>
              {t('filterByCircle')}:
            </span>
            <div className={styles.legendCheck}>
              <div className={styles.legendCheck2} style={{ flexWrap: 'wrap' }}>
                {Object.values(circle).map((k) => (
                  <label key={k.id} className={styles.labelCheck} style={{ textWrap: 'nowrap' }}>
                    <input
                      type="checkbox"
                      name={k.name}
                      className={styles.checkboxInput}
                      onChange={handleCheck}
                      checked={filter[k.name]}
                    />
                    {k.name}
                    {filter[k.name] && (
                      <img src={tick} alt="Icono de tick" className={styles.checkboxImage} />
                    )}
                  </label>
                ))}
              </div>
            </div>
          </div>

          {loggedUserService.userCan(Permission.createContentTutorial) && (
            <Box mb={3} className={styles.buttons} display="flex" justifyContent="flex-end">
              <div className={styles.button}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'button'}
                  label={t('newTutorial')}
                  handler={handleCreate}
                />
              </div>
            </Box>
          )}
          <Box mb={3} display="flex" marginRight={'5px'} justifyContent="flex-end">
            <TextField
              sx={{
                '& .MuiFormLabel-root': {
                  fontFamily: 'Poppins',
                  zIndex: 0,
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'Poppins',
                },
                '& .MuiOutlinedInput-input': {
                  fontFamily: 'Poppins',
                },
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  fontFamily: 'Poppins',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  fontFamily: 'Poppins',
                },
              }}
              name={'Buscar...'}
              label={'Buscar...'}
              style={{
                width:
                  window.innerWidth < 378 ? '120px' : window.innerWidth < 490 ? '150px' : '220px',
                fontFamily: 'Poppins',
              }}
              onChange={handleChangeSearch}
              type={'text'}
              value={searchText}
              variant="outlined"
              size="small"
            />
          </Box>

          <Box>
            <Header label={t('mostViewed')} />
          </Box>

          <Box mb={3}>
            <TableGrid categoryList={filterToSend} mostViewed={true} circles={circles} />
          </Box>

          <Box>
            <Header label={t('otherTutorials')} />
          </Box>
          <Box mb={3}>
            <TableGrid
              categoryList={filterToSend}
              mostViewed={false}
              search={searchText}
              circles={circles}
            />
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </>
  )
}
