import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../generic/GenericTable.module.css'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_PROMS_PREMS_GENERATOR,
  ROUTE_PROMS_PREMS_GENERATOR_FORM,
  ROUTE_PROMS_PREMS_GENERATOR_FORM_REMOVE,
} from 'routes/routes-constants'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from 'modules/forms'
import { FormService } from 'modules/forms/services/FormService'
import { Form, FormQuery } from 'modules/forms/models/Form'
import { Query, QueryParam } from 'common/api/Query'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { AppTable } from 'components/table'
import { Actions, Pager, Search, SearchValue } from 'components/table_type/types'
import { UserFormService } from 'modules/forms/services/UserFormService'
import { UserForm, UserFormQuery } from 'modules/forms/models/UserForm'
import seeIcon from 'assets/table_icons/ico-ver.svg'
import editIcon from 'assets/table_icons/ico-edit.svg'
import deleteIcon from 'assets/table_icons/ico-eliminar.svg'
import { forkJoin, Observable } from 'rxjs'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, PROFESSIONALTYPE_SERVICE_KEY } from 'modules/users'
import { FormType } from 'modules/forms/enums/FormType'
import { ProfessionalTypesService } from 'modules/users/services/ProfessionalTypeService'
import { ProfessionalType } from 'modules/users/models/ProfessionalType'
import { PreviewForm } from './PreviewForm'
import { FormFieldDTO } from 'modules/forms/models/FormDTO'
import { ChoiceFields } from './ChoiceFields'
import { Roles } from 'modules/users/enums/Roles'
import { getFormContainer } from 'container/form-module'
import { getUserContainer } from 'container/user-module'
import styles from './Table.module.css'

const formService = getFormContainer().get<FormService>(FORM_SERVICE_KEY)
const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const professionalTypeService = getUserContainer().get<ProfessionalTypesService>(
  PROFESSIONALTYPE_SERVICE_KEY
)

interface TableForms extends Form {
  specialty?: string | undefined
  pathology?: string[] | undefined
}

export function Table() {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [forms, setForms] = useState<TableForms[]>([])
  const data = useMemo(() => forms, [forms])
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [formsPerPage, setFormsPerPage] = useState<number>(10)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userForms, setUserForms] = useState<string[]>([])
  const isManager = loggedUser?.roles?.some((role) => role.startsWith(Roles.Manager))
  const isProfessional = loggedUser?.roles.some((r) => r === Roles.Professional)
  const [isViewForm, setIsViewForm] = useState<boolean>(false)
  const [currentForm, setCurrentForm] = useState<Form>()
  useState<boolean>(false)
  const [tabValuePromsPrems, setTabValuePromsPrems] = useState(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtering, setFiltering] = useState<boolean>(false)
  const [searcher, setSearcher] = useState<SearchValue<FormQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
      value: '',
    },
  ])
  const [formsSubscriber, setFormSubscriber] = useState<Form[]>([])
  const navigate = useNavigate()

  const getUserForms = (ids: string[]): Observable<UserForm[]> =>
    forkJoin(
      ids.map((id) =>
        userFormService.getFilteredList(
          new Query({
            query: [new QueryParam<UserFormQuery>('formID', id)],
          })
        )
      )
    ) as unknown as Observable<UserForm[]>

  useEffect(() => {
    if (searcher[0].value !== '') {
      formService
        .getFilteredList(
          new Query({
            pager: { offset: page * formsPerPage, limit: formsPerPage },
            sort: [{ field: 'created', desc: true }],
            query: [
              new QueryParam<FormQuery>('title', searcher[0].value ?? ''),
              new QueryParam<FormQuery>(
                'isTemplate',
                tabValuePromsPrems === 0 ? FormType.PROMS.toString() : FormType.PREMS.toString()
              ),
            ],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setForms(res.items)
          setCount(res.count)
          getUserForms(res.items.map((f) => f.id ?? '')).subscribe((res) =>
            setUserForms(res.map((uf) => uf.id ?? ''))
          )
        })
    }
  }, [searcher])

  useEffect(() => {
    if (!loggedUser) return
    const listAux: ProfessionalType[] = []
    if (isProfessional) {
      professionalTypeService
        .getAll(
          new Query({
            sort: [{ field: 'name' }],
            pager: { offset: 0, limit: -1 },
          })
        )
        .subscribe((res) => {})
    } else {
      professionalTypeService.getProfessionalTypeByUserID(loggedUser?.id).subscribe((res) => {
        if (!res) return
        listAux.push(res)
      })
    }
  }, [loggedUser])

  useEffect(() => {
    if (searcher[0].value !== '') return
    const fetchProfessional = async () => {
      if (!loggedUser) {
        return
      }

      try {
        const res = await formService
          .getFilteredList(
            new Query({
              pager: { offset: page * formsPerPage, limit: formsPerPage },
              query: [
                new QueryParam<FormQuery>(
                  'isTemplate',
                  tabValuePromsPrems === 0 ? FormType.PROMS.toString() : FormType.PREMS.toString()
                ),
              ],
              sort: [{ field: 'TITLE', desc: false }],
            })
          )
          .toPromise()
        setForms(res.items)
        setCount(res.count)

        const userFormsRes = await getUserForms(res.items.map((f) => f.id ?? '')).toPromise()
        setUserForms(userFormsRes.map((uf) => uf.id ?? ''))
      } catch (error) {
        console.error('Error fetching template data:', error)
      }
    }

    if (isProfessional) {
      fetchProfessional().then()
    }
  }, [tabValuePromsPrems, formsPerPage, page, searcher, loggedUser, isProfessional, isManager])

  useEffect(() => {
    forms.forEach((form) => {
      if (form.specialities !== ' ') {
        form.specialty = form.specialities
      }
      if (form.circles.length > 1) {
        form.pathology = form.circles.split(',')
      }
    })
    // const interval = setInterval(() => setIsLoading(false), 1000)
    // return () => clearInterval(interval)
  }, [forms, searcher])

  const createForm = () => navigate(`${ROUTE_PROMS_PREMS_GENERATOR}`)

  const editForm = (form: Form) => {
    if (form.isTemplate === FormType.Template) {
      navigate(`${ROUTE_PROMS_PREMS_GENERATOR_FORM}/${form.id}`)
      return
    }
    navigate(`${ROUTE_PROMS_PREMS_GENERATOR_FORM}/${form.id}`)
  }

  const removeForm = (form: Form) => {
    if (!form?.id) {
      return
    }
    navigate(`${ROUTE_PROMS_PREMS_GENERATOR_FORM_REMOVE}/`, { state: { formID: form.id } })
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setFormsPerPage(10)
      return
    }
    setFormsPerPage(Number.parseInt(event.target.value))
  }

  const viewForm = (form: Form) => {
    if (!loggedUser) return

    setCurrentForm(form)
    setIsViewForm(true)
  }

  const hideRemove = (form: Form) => {
    if (!loggedUser) return false

    const isCreator = form.creator === loggedUser?.id
    const isManagerWithMultipleRoles = isManager && loggedUser?.roles.length > 1
    const isManagerAndIsTemplate = isManager
    const isTemplate = form.isTemplate === FormType.Template

    return (
      !isCreator &&
      !(isManagerWithMultipleRoles && isTemplate) &&
      !(isManagerAndIsTemplate && isTemplate)
    )
  }

  const hideEdit = (form: Form) => {
    if (!loggedUser) return false

    return !isManager
  }

  useEffect(() => {
    if (!loggedUser) return
    const auxForms: Form[] = []
    forms.forEach((form) => {
      if (form.subscribers?.includes(loggedUser?.id)) {
        auxForms.push(form)
      }
    })
    setFormSubscriber(auxForms)

    return () => {
      setFormSubscriber([])
    }
  }, [forms])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: formsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, formsPerPage])

  const search: Search<FormQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<FormQuery>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setPage(0)
      setSearcher(result)
    },
  }

  const actions: Actions<Form> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewForm,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: editForm,
        icon: editIcon,
        label: 'edit',
        hidden: hideEdit,
      },
      {
        handler: removeForm,
        icon: deleteIcon,
        label: 'delete',
        hidden: hideRemove,
      },
    ],
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValuePromsPrems(newValue)
  }
  return (
    <Box className={styles.pageContainer}>
      {!isViewForm && (
        <Tabs
          className={styles.tabs}
          value={tabValuePromsPrems}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          sx={{
            '& .MuiTab-root.Mui-selected': { color: 'transparent' },
            '& .MuiTabs-indicator': { bgcolor: 'transparent' },
          }}
        >
          <Tab
            label={t('PROMs')}
            sx={{ borderRight: '0!important' }}
            className={`${styles.tab} ${tabValuePromsPrems === 0 && styles.tabActive}`}
          />
          <Tab
            label={t('PREMs')}
            className={`${styles.tab} ${tabValuePromsPrems === 1 && styles.tabActive}`}
          />
        </Tabs>
      )}
      <Box
        className={styles.container}
        style={{
          border: !isViewForm ? '1px solid #68b3e0' : '0',
        }}
      >
        {isViewForm && (
          <>
            {currentForm && (
              <PreviewForm
                title={currentForm?.title}
                description={currentForm?.description}
                created={currentForm?.created}
                formFields={currentForm?.formFields as unknown as FormFieldDTO[]}
                repeating={currentForm?.repeating}
                startCron={currentForm?.startCron}
                finishCron={currentForm?.finishCron}
                isTemplate={currentForm?.isTemplate}
                creator={currentForm?.creator}
                fieldWithoutAnswer={currentForm?.fieldWithoutAnswer}
                id=""
                disabled
                circles={currentForm?.circles}
                specialities={currentForm?.specialities}
                monthRecurrence={currentForm?.monthRecurrence}
                checkBoxesSelected={currentForm?.checkBoxesSelected}
              />
            )}

            <Box display="flex" justifyContent="flex-end">
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type="button"
                label={t('close')}
                handler={() => {
                  setIsViewForm(false)
                }}
              />
            </Box>
          </>
        )}
        {!isViewForm && (
          <>
            <Typography
              style={{
                fontFamily: 'Poppins',
                color: '#599ad6',
                fontWeight: 'bold',
                marginBottom: '2rem',
                fontSize: '24px',
              }}
            >
              {t('manageForms')}
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box mb={3} className={style.buttonContainer} display="flex">
                {isManager && (
                  <>
                    <AppButton
                      theme={ButtonTheme.NewPrimary}
                      type={'button'}
                      label={t('createNewForm')}
                      handler={createForm}
                    />
                  </>
                )}
              </Box>
            </Box>
            <AppTable
              items={data}
              rowKeyField="id"
              fields={ChoiceFields(loggedUser, true, formsSubscriber, isManager, t)}
              actions={actions}
              search={search}
              pager={pager}
              isPreventDefault={true}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
