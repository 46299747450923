import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent } from './EpilepsyCard'
import { UserForm } from '../../../../modules/forms/models/UserForm'
import { getFormContainer } from '../../../../container/form-module'
import { FormService } from '../../../../modules/forms/services/FormService'
import { FORM_SERVICE_KEY } from '../../../../modules/forms'
import { fromModel } from '../../../../modules/forms/models/UserFormDTO'
import { Form } from '../../../../modules/forms/models/Form'
import { Box, Skeleton, Typography } from '@mui/material'
import styles from './EpilepsyCard.module.scss'
import dayjs from 'dayjs'

type AdverseEffectsCardProps = {
  userForms: UserForm[]
}

interface AdverseEffectsType {
  date: Date
  questionTitle: string
}

const formService = getFormContainer().get<FormService>(FORM_SERVICE_KEY)
export const AdverseEffects: React.FC<AdverseEffectsCardProps> = ({ userForms }) => {
  const { t } = useTranslation()
  const [adverseEffects, setAdverseEffects] = useState<AdverseEffectsType[]>([])
  const userFormsDTO = userForms.map((form) => fromModel(form))
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)

    const fetchData = async () => {
      const formTemplates: Form[] = []
      // Array de promesas de formularios
      const formPromises = userForms.map(async (userForm) => {
        // Retorna una promesa por cada formulario
        return await formService.getByID(userForm.formID).toPromise()
      })

      // Espera a que todas las promesas se resuelvan
      try {
        const resolvedForms = await Promise.all(formPromises)

        resolvedForms.forEach((form) => {
          if (form) {
            formTemplates.push(form)
          }
        })

        if (formTemplates.length === 0) {
          setIsLoading(false) // Si no se encuentran formularios, finaliza carga
          return
        }

        const adverseEffects: AdverseEffectsType[] = []

        userFormsDTO.forEach((userform, index) => {
          userform.userFormValues.forEach((formValue) => {
            if (formValue.values && formValue.values[0] === '1') {
              // Si el checkbox está marcado, entonces hay un efecto adverso
              const questionTitle = formTemplates[index].formFields.find(
                (field) => field.id === formValue.formFieldID
              )?.title
              if (questionTitle) {
                adverseEffects.push({
                  date: formValue.date,
                  questionTitle,
                })
              }
            }
          })
        })

        setAdverseEffects(adverseEffects)
      } catch (error) {
        console.error('Error al obtener formularios:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [userForms])

  return (
    <CardComponent title={t('adverseEffect')}>
      {isLoading ? (
        <>
          <Typography variant="h3">{<Skeleton />}</Typography>
          <Typography variant="h3">{<Skeleton />}</Typography>
          <Typography variant="h3">{<Skeleton />}</Typography>
        </>
      ) : (
        <Box className={styles.adverseEffectsContainer}>
          {adverseEffects.length === 0 ? (
            <Typography>{t('noAdverseEffects')}</Typography>
          ) : (
            adverseEffects.map((adverseEffect, index) => (
              <div className={styles.adverseEffectsItem} key={adverseEffect.questionTitle + index}>
                <p>
                  <b>{adverseEffect.questionTitle}</b>
                </p>
                <p>{dayjs(adverseEffect.date).format('DD/MM/YYYY')}</p>
              </div>
            ))
          )}
        </Box>
      )}
    </CardComponent>
  )
}
