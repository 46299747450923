import style from './NavigationEsfera.module.css'
import { LoggedUser } from '../../modules/users/models/LoggedUser'

export const getLinkHeaderStyle = (
  menuSelected: string,
  styleActive: string,
  type: 'submenu' | 'other',
  hover: boolean,
  ids: string[],
  debug?: any,
  loggedUser?: LoggedUser
): string => {
  let result = `${style.link}`

  if (type === 'submenu') {
    result = `${style.link} ${ids.includes(menuSelected) || hover ? styleActive : ''}`
  }

  if (type === 'other') {
    result = `${style.link} ${ids.includes(menuSelected) && !hover ? styleActive : ''}`
  }

  if (
    type === 'submenu' &&
    ids.includes(menuSelected) &&
    !hover &&
    menuSelected.endsWith('/dashboard') &&
    !loggedUser?.roles.includes('professionalSMS')
  ) {
    result = `${style.link}`
  }

  return result
}

export const getListItemStyleHoverCircle = (
  isDisabled: boolean,
  isCircleLink: boolean,
  circleHover: boolean,
  menuSelected: string,
  mobileOpen: boolean,
  styleActive: string,
  styleNormal: string,
  ...ids: string[]
): string => {
  if (isCircleLink) {
    return `${
      isDisabled
        ? style.menuItemDisabled
        : ids.includes(menuSelected) || mobileOpen || circleHover
          ? styleActive
          : styleNormal
    }`
  }
  return `${
    isDisabled
      ? style.menuItemDisabled
      : ids.includes(menuSelected) && !circleHover
        ? styleActive
        : styleNormal
  }`
}

export const getLinkStyle = (
  menuSelected: string,
  styleActive: string,
  ...ids: string[]
): string => {
  return `${style.link} ${ids.includes(menuSelected) ? styleActive : ''}`
}

export const getListItemStyle = (
  isDisabled: boolean,
  menuSelected: string,
  styleActive: string,
  styleNormal: string,
  ...ids: string[]
): string => {
  return `${
    isDisabled ? style.menuItemDisabled : ids.includes(menuSelected) ? styleActive : styleNormal
  }`
}

export const styleMenu: React.CSSProperties = {
  position: 'fixed',
  top: '141px',
  backgroundColor: 'rgba(67,67,67,0.55)',
  height: '100vh',
  // mixBlendMode: 'normal',
  zIndex: 1500, // Needed background overlap every element under
}
