import { RouteProps } from '../../routes/AppRouter'
import { useParams } from 'react-router-dom'
import { CurrentNavHeaderEsfera } from '../../components/current-nav-header-esfera/CurrentNavHeaderEsfera'
import { PromsPremsEditor as PE } from '../../features/premsProms'

export function PromsPremsGenerator(props: RouteProps) {
  const { id } = useParams()
  const title = props.title ?? ''

  return (
    <>
      <CurrentNavHeaderEsfera title={title} />
      <PE id={id ?? ''} refMain={props.refMain} />
    </>
  )
}
