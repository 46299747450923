import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { emptyLikesDTO } from '../../modules/content/models/LikesDTO'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY, LIKES_SERVICE_KEY } from '../../modules/content'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Card, Typography, Table, TableFooter, TableRow, Modal } from '@mui/material'
import { ArticleStatus } from 'modules/content/enums/ArticleStatus'
import style from './Search.module.css'
import { RichText } from 'components/text-editor/RichText'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { viewImageFileBase64 } from 'common/utils/file'
import profile from '../../assets/esfera/nav-profile-icons/config-profile-icon.svg'
import commentIcon from '../../assets/superior_menu/chat.svg'
import esferaLogo from '../../assets/brand_logo/smsBlue.svg'
import like from '../../assets/buttons/Like.svg'
import { Pagination } from '../../components/table_AP/Pagination'
import { Pager } from '../../components/table_type/types'
import { LikesService } from '../../modules/content/services/LikesService'
import { CommentsService } from '../../modules/content/services/CommentsService'
import { COMMENTS_SERVICE_KEY } from '../../modules/content/container'
import { CommentsModal } from './CommentsModal'
import { LoggedUser } from 'modules/users/models/LoggedUser'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

const likesService = contentContainer.get<LikesService>(LIKES_SERVICE_KEY)
const commentsService = contentContainer.get<CommentsService>(COMMENTS_SERVICE_KEY)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

type ArticleProps = {
  tagIDs: string[]
  circles: number[]
  loggedUser?: LoggedUser
}

const imageList = new Map()
const likesList = new Map()
const commentsList = new Map()

export function TableGrid({ tagIDs, circles, loggedUser }: ArticleProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [articleSelected, setArticle] = useState<Article>()
  const [openModalComments, setOpenModalComments] = useState<boolean>(false)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(4)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
  }, [tagIDs])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
      rowsPerPageOption: [4, 8, 16],
    })
  }, [page, count, articlesPerPage])

  // Modificar el useEffect que hace la llamada a los artículos
  useEffect(() => {
    let total: number
    const articleList = emptyList<Article>()
    const articlesQuery = [
      new QueryParam<Article>('status', [ArticleStatus[ArticleStatus.publicado]]),
      new QueryParam<Article>('type', 'article'),
    ]

    if (circles.length === 1) {
      articlesQuery.push(new QueryParam<Article>('circleID', circles[0]))
    } else if (circles.length > 1) {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', circles))
    }

    if (tagIDs.length > 0) {
      articlesQuery.push(new QueryParam<Article>('tagIDs', tagIDs))
    }

    new Promise((resolve) => {
      articlesService
        .getFilteredList(
          new Query({
            sort: [{ field: 'createdAt', desc: true }], // Esta ordenación se enviará al backend
            query: articlesQuery,
            pager: { offset: page * articlesPerPage, limit: articlesPerPage },
          })
        )
        .subscribe((res) => {
          total = res.count
          setIsLoading(false)
          // Eliminar la ordenación local - el backend ya lo hace
          articleList.count = res.items.length
          articleList.items = res.items // Los items ya vienen ordenados del backend

          res.items.forEach((articleItem) => {
            likesService.getLikesByArticleID(articleItem.id).subscribe((response) => {
              likesList.set(articleItem.id, response.count)
            })

            commentsService.getCommentsByArticleID(articleItem.id).subscribe((response) => {
              commentsList.set(articleItem.id, response)
            })

            if (articleItem.slug !== 'slug') {
              filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
                if (res) {
                  const image = await viewImageFileBase64(res)
                  imageList.set(articleItem.slug, image)
                }
              })
            }
          })
          resolve(true)
        })
    }).then(() => {
      setArticles(articleList)
      setCount(total)
    })
  }, [circles, tagIDs, isLoading, page, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(4)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
  }, [page, count, articlesPerPage])

  const handleSee = (event: any, article: Article) => {
    if (
      !event.target.className.includes('like_button') &&
      !event.target.className.includes('comment_button')
    ) {
      navigate(`${ROUTE_ARTICLES}/${article.id}`)
    }
  }

  const handleLike = (article: Article) => {
    const request = emptyLikesDTO()
    setIsLoading(false)
    request.userID = loggedUser?.id ?? ''
    request.articleID = article.id as string
    likesService.getCheckLikeExist(request.articleID, request.userID).subscribe((response) => {
      if (response.likeExists) {
        likesService.delete(response.likeID).subscribe((response) => {
          setIsLoading(true)
        })
      } else {
        likesService.add(request).subscribe((response) => {
          setIsLoading(true)
        })
      }
    })
  }

  const handleCloseCommentsModal = () => {
    setOpenModalComments(false)
  }
  const handleOpenCommentsModal = (article: Article) => {
    setArticle(article)
    setOpenModalComments(true)
  }

  return (
    <>
      <Modal open={openModalComments} onClose={handleCloseCommentsModal}>
        <CommentsModal
          handleClose={handleCloseCommentsModal}
          comments={
            commentsList.get(articleSelected?.id) && commentsList.get(articleSelected?.id).items
          }
          articleTitle={articleSelected?.title}
          title={t('comments')}
        />
      </Modal>
      <Box>
        <Grid container spacing={2}>
          {articles.items.map((item) => {
            let content = <div></div>
            if (item && item.content && item.content.charAt(0) === '[') {
              content = <RichText content={item.content} edit={true} view={true} />
            }

            let articleDate = null
            if (typeof item.createdAt === 'string') {
              articleDate = new Date(item.createdAt)
            } else {
              articleDate = item.createdAt
            }
            const articleDateString = articleDate.toLocaleDateString()

            let articleImage = null

            if (item.slug !== 'slug') {
              articleImage = imageList.get(item.slug) ?? null
            }

            const articleLikes = likesList.get(item.id)
            const articleComments = commentsList.get(item.id)

            return (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  className={style.card}
                  sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                  variant="outlined"
                  onClick={(event) => handleSee(event, item)}>
                  <div>
                    <div className={style.imageContainer}>
                      <img
                        className={style.thumbnail}
                        src={!articleImage ? esferaLogo : `data:image/jpeg;base64,${articleImage}`}
                      />

                      <div className={style.dateComments}>
                        {articleDateString}
                        <div
                          className={'comment_button ' + style.comments}
                          onClick={() => handleOpenCommentsModal(item)}>
                          <img className={'comment_button'} src={commentIcon} />
                          {articleComments && articleComments.count}
                        </div>
                      </div>
                      <div
                        className={'like_button ' + style.likes}
                        onClick={() => handleLike(item)}>
                        <img className={style.like} src={like} />
                        &nbsp;
                        {articleLikes}
                      </div>
                    </div>

                    <Typography variant="h6" color="primary" className={style.title}>
                      {item.title}
                    </Typography>

                    {content}
                    <div className={style.creator}>
                      <img src={profile} />
                      {item.authorName}
                    </div>
                  </div>
                </Card>
              </Grid>
            )
          })}
        </Grid>
        <Table>
          <TableFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TableRow sx={{ borderBottom: 0 }}>{pager && <Pagination {...pager} />}</TableRow>
          </TableFooter>
        </Table>
      </Box>
    </>
  )
}
