import { Box, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import style from './GroupManagementCard.module.scss'
import iconClose from '../../../../assets/dashboard/closeIcon.svg'
import filterIcon from '../../../../assets/dashboard/filterIcon.svg'
import { Header } from '../../../../components/header/Header'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, QueryParamN } from '../../../../common/api/Query'
import { getActivePlanContainer } from '../../../../container/active-plan-module'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import { ACTIVE_PLAN_SERVICE_KEY } from '../../../../modules/active-plan'
import { AppTable } from '../../../../components/table'
import { Field, Pager, Search, SearchValue } from '../../../../components/table_type/types'
import tick from '../../../../assets/calendar/tick.svg'
import { ActivePlanStatusType } from '../../../../common/enums/activePlan'
import {
  ActivePlanCustom,
  ActivePlanCustomQuery,
} from '../../../../modules/active-plan/models/ActivePlanCustom'
import downloadFileIcon from '../../../../assets/events_icons/download_icon.svg'
import { downloadFile } from '../../../../common/utils/file'
import { dateDateToAge, dateToDateString } from 'common/utils/date'
import { LoadingSpinnerMini } from 'components/loading-spinner/LoadingSpinner'
import DownloadAlert from 'components/download-alert/DownloadAlert'

type GroupManagementModalProps = {
  open: boolean
  title: string
  onClose: () => void
}

type FilterComponentProps = {
  status: ActivePlanStatusType[]
  filters: ActivePlanStatusType[]
}

const activePlanService = getActivePlanContainer().get<ActivePlanService>(ACTIVE_PLAN_SERVICE_KEY)

export const GroupManagementCard: React.FC<GroupManagementModalProps> = ({
  open,
  onClose,
  title,
}) => {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<ActivePlanStatusType[]>([
    ActivePlanStatusType.Active,
    ActivePlanStatusType.Pending,
    ActivePlanStatusType.Refused,
    ActivePlanStatusType.Finished,
  ])
  const status: ActivePlanStatusType[] = [
    ActivePlanStatusType.Active,
    ActivePlanStatusType.Pending,
    ActivePlanStatusType.Refused,
    ActivePlanStatusType.Finished,
  ]
  const [searcher, setSearcher] = useState<SearchValue<ActivePlanCustomQuery>[]>([
    {
      name: 'customSearcher',
      label: t('search') + '...',
      value: '',
    },
  ])
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [apCustom, setAPCustom] = useState<ActivePlanCustom[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [downloadSuccess, setDownloadSuccess] = useState(false)
  const [downloadError, setDownloadError] = useState(false)

  useEffect(() => {
    if (filters.length === 0) {
      setAPCustom([])
      setCount(0)
      return
    }
    const subscription = activePlanService
      .getDataForManagerTable(
        new Query({
          query: [
            new QueryParam<ActivePlanCustomQuery>('statusArray', filters),
            ...searcherQuery(searcher),
          ],
          sort: [{ field: 'id', desc: true }],
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
        })
      )
      .subscribe((res) => {
        if (!res) {
          setAPCustom([])
          setCount(0)
          return
        }
        setCount(res.count)
        setAPCustom(res.items)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [count, page, searcher, itemsPerPage, filters.length])

  const handlePaginationChange = (event: unknown, value: number) => {
    setPage(value)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage, searcher, filters.length])

  const search: Search<ActivePlanCustomQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<ActivePlanCustomQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const fields: Field<ActivePlanCustom>[] = [
    {
      name: 'completeName',
      label: t('name'),
    },
    {
      name: 'dni',
      label: t('dni'),
    },
    {
      name: 'centerName',
      label: t('sportCenter'),
    },
    {
      name: 'groupName',
      label: t('group'),
    },
    {
      name: 'birthDate',
      label: t('age'),
      renderFunc: (f, i) => dateDateToAge(new Date(i.birthDate)).toString(),
    },
    {
      name: 'startDate',
      label: t('start'),
      renderFunc: (f, i) => dateToDateString(new Date(i.startDate)),
    },
    {
      name: 'finishDate',
      label: t('end'),
      renderFunc: (f, i) => dateToDateString(new Date(i.finishDate)),
    },
    {
      name: 'attendance',
      label: t('attendancePercent'),
      renderFunc: (f, i) => i.attendance.toString() + '%',
    },
    {
      name: 'intensity',
      label: t('effort'),
    },
    {
      name: 'physicalCondition',
      label: t('EFLevel'),
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(i.status),
    },
  ]

  const getFilter = (value: ActivePlanStatusType) => {
    return filters.includes(value)
      ? () => {
          setPage(0)
          setFilters(filters.filter((f) => f !== value))
        }
      : () => {
          setPage(0)
          setFilters([...filters, value])
        }
  }

  const getStyleClass = (value: ActivePlanStatusType): string => {
    switch (value) {
      case ActivePlanStatusType.Active:
        return style.labelCheckActive
      case ActivePlanStatusType.Pending:
        return style.labelCheckPending
      case ActivePlanStatusType.Refused:
        return style.labelCheckRefused
      default:
        return style.labelCheckInactive
    }
  }

  const FilterComponent: React.FC<FilterComponentProps> = ({ status, filters }) => {
    return (
      <>
        {status.map((value, index) => (
          <label key={index} className={getStyleClass(value)}>
            <input
              type="checkbox"
              name="tratamientos"
              className={style.checkboxInput}
              onChange={getFilter(value)}
              checked={filters.includes(value)}
            />
            {value === ActivePlanStatusType.Finished ? t('noActive') : t(value)}
            {filters.includes(value) && (
              <img src={tick} alt="Icono de tick" className={style.checkboxImage} />
            )}
          </label>
        ))}
      </>
    )
  }

  const getExcelFile = () => {
    setIsLoading(true)
    setDownloadSuccess(false)
    setDownloadError(false)
    activePlanService.getExcelTable().subscribe((res) => {
      if (!res) {
        setIsLoading(false)
        setDownloadError(true)
        return
      }
      downloadFile(res.name, res.mimeType, res.data)
      setIsLoading(false)
      setDownloadSuccess(true)
    })
  }

  return (
    <Modal className={style.modalContainer} open={open} onClose={onClose}>
      <Box className={style.content}>
        <Box className={style.iconContainer}>
          <img className={style.icon} src={iconClose} alt={'icon close'} onClick={onClose} />
        </Box>
        <Header label={title} />
        <Box>
          <Box mt={4} className={style.filterContainer}>
            <Box display={'flex'}>
              <img className={style.iconFilter} src={filterIcon} alt={'filter icon'} />
              <Typography className={style.filterText}>{t('filters')}</Typography>
            </Box>
          </Box>
          <Box className={style.titleRowContainer}>
            <Box className={style.titleCheckContainer}>
              <Box className={style.filterContainerRow}>
                <Typography className={style.filterByCategoryText}>
                  {t('filterByCategory')}:{' '}
                </Typography>
                <FilterComponent status={status} filters={filters} />
              </Box>

              <Box>
                {isLoading ? (
                  <LoadingSpinnerMini />
                ) : (
                  <img
                    className={style.iconDownload}
                    src={downloadFileIcon}
                    alt={'filter icon'}
                    onClick={getExcelFile}
                  />
                )}
              </Box>
            </Box>
          </Box>

          <DownloadAlert type="success" open={downloadSuccess} />
          <DownloadAlert type="error" open={downloadError} />

          <Box className={style.tableContainer}>
            <AppTable
              fields={fields}
              search={search}
              pager={pager}
              isPreventDefault={true}
              items={apCustom}
              rowKeyField={'cip'}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const searcherQuery = (
  svs: SearchValue<ActivePlanCustomQuery>[]
): QueryParam<ActivePlanCustomQuery>[] | QueryParamN<ActivePlanCustomQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
