import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './EpilepsyCard'
import { UserForm, UserFormQuery } from '../../../../modules/forms/models/UserForm'
import { getFormContainer } from '../../../../container/form-module'
import { UserFormService } from '../../../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../../../modules/forms'
import { Query, QueryParam } from '../../../../common/api/Query'
import { MenuItem, Select, Skeleton } from '@mui/material'

type PsychologicalHealthCardProps = {
  userForms: UserForm[]
}

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)

export const PsychologicalHealth: React.FC<PsychologicalHealthCardProps> = ({ userForms }) => {
  const { t } = useTranslation()
  const [psychologicalHealth, setPsychologicalHealth] = useState<number[]>([])
  const [monthLabel, setMonthLabel] = useState<string[]>([])
  const memoizedUserForms = useMemo(() => userForms, [JSON.stringify(userForms)])
  const items = [
    'totalScore',
    'sadness',
    'pessimism',
    'failure',
    'lossOfPleasure',
    'guilt',
    'punishment',
    'disconformityWithSelf',
    'selfCriticism',
    'suicidalThoughts',
    'crying',
    'agitation',
    'lossOfInterest',
    'indecision',
    'devaluation',
    'lossOfEnergy',
    'sleepHabits',
    'irritability',
    'appetite',
    'difficultyConcentrating',
    'fatigue',
    'lossOfInterestInSex',
  ]
  const [selectedItem, setSelectedItem] = useState<string>(items[0])
  const [isLoading, setIsLoading] = useState(false)

  const [datasets, setDataSets] = useState([
    {
      label: t('PsychologicalHealth'),
      data: psychologicalHealth,
      borderColor: 'blue',
    },
  ])

  useEffect(() => {
    setDataSets([
      {
        label: t('PsychologicalHealth'),
        data: psychologicalHealth,
        borderColor: 'blue',
      },
    ])
  }, [psychologicalHealth])

  const labels = [
    'En',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ]
  /* eslint-disable @typescript-eslint/promise-function-async */
  useEffect(() => {
    if (userForms.length > 0) {
      setIsLoading(true)

      const questionData: Map<string, number[]> = new Map<string, number[]>(
        items.map((key) => [key, []])
      )

      // Convertir cada solicitud a una Promise
      const fetchPromises = userForms.map((form) =>
        userFormService
          .getPromsBeckItems(
            new Query<UserFormQuery>({
              query: [new QueryParam('ids', [form.id ?? ''])],
            })
          )
          .toPromise()
          .then((res) => {
            setIsLoading(false)
            if (res) {
              res.forEach((values) => {
                questionData.get('totalScore')?.push(form.totalScore ?? 0)
                questionData.get('sadness')?.push(values.sadness)
                questionData.get('pessimism')?.push(values.pessimism)
                questionData.get('failure')?.push(values.failure)
                questionData.get('lossOfPleasure')?.push(values.lossOfPleasure)
                questionData.get('guilt')?.push(values.guilt)
                questionData.get('punishment')?.push(values.punishment)
                questionData.get('disconformityWithSelf')?.push(values.disconformityWithSelf)
                questionData.get('selfCriticism')?.push(values.selfCriticism)
                questionData.get('suicidalThoughts')?.push(values.suicidalThoughts)
                questionData.get('crying')?.push(values.crying)
                questionData.get('agitation')?.push(values.agitation)
                questionData.get('lossOfInterest')?.push(values.lossOfInterest)
                questionData.get('indecision')?.push(values.indecision)
                questionData.get('devaluation')?.push(values.devaluation)
                questionData.get('lossOfEnergy')?.push(values.lossOfEnergy)
                questionData.get('sleepHabits')?.push(values.sleepHabits)
                questionData.get('irritability')?.push(values.irritability)
                questionData.get('appetite')?.push(values.appetite)
                questionData.get('difficultyConcentrating')?.push(values.difficultyConcentrating)
                questionData.get('fatigue')?.push(values.fatigue)
                questionData.get('lossOfInterestInSex')?.push(values.lossOfInterestInSex)
              })
            }
          })
      )

      // Esperar a que todas las promesas terminen antes de actualizar el estado
      Promise.all(fetchPromises).then(() => {
        const monthList: Record<string, number> = {}
        userForms.forEach((form, index) => {
          const date = new Date(form.userFormValues[0].date)
          const monthYear = `${labels[date.getMonth()]} ${date.getFullYear()}`
          const selectedItemScore = questionData.get(selectedItem)?.at(index)
          monthList[monthYear] = selectedItemScore ?? 0
        })

        setMonthLabel(Object.entries(monthList).map((val) => val[0]))
        setPsychologicalHealth(Object.entries(monthList).map((val) => val[1]))
      })
    }
  }, [memoizedUserForms, selectedItem])

  return (
    <CardComponent title={t('PsychologicalHealth')}>
      {isLoading ? (
        <>
          <Skeleton variant="rectangular" width="100%"></Skeleton>
        </>
      ) : (
        <>
          <GenericChartDashboardComponent
            datasets={datasets}
            labels={monthLabel}
            title={''}
            typeChart={'bar'}
            legendPosition={'bottom'}
            yAxisConfig={{
              min: 0,
              max: selectedItem === 'totalScore' ? 63 : 3,
              stepSize: selectedItem === 'totalScore' ? 9 : 1,
            }}
          />

          <Select
            MenuProps={{
              MenuListProps: {
                sx: {
                  'li.MuiButtonBase-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontFamily: 'Poppins',
                    paddingLeft: '10px',
                  },
                },
              },
            }}
            SelectDisplayProps={{
              style: {
                paddingLeft: '10px',
                fontFamily: 'Poppins',
                fontSize: '14px',
              },
            }}
            fullWidth
            variant="outlined"
            style={{
              backgroundColor: '#f5f2f2',
              width: '50%',
              marginLeft: '50%',
              marginTop: '20px',
              height: '40px',
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            value={selectedItem}
            defaultValue={items[0]}
            onChange={(e) => setSelectedItem(e.target.value)}
          >
            {items.map((k: string, index: number) => (
              <MenuItem key={k + index} value={k}>
                {t(k)}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </CardComponent>
  )
}
