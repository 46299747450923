/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Card, Typography, Table, TableFooter, TableRow } from '@mui/material'
import style from './Search.module.css'
import { getFileContainer } from 'container/file-module'
import { File } from 'modules/files/models/File'
import { Article } from '../../modules/content/models/Article'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { getContentContainer } from '../../container/content-module'
import { Pager } from '../../components/table_type/types'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { RichText } from '../../components/text-editor/RichText'
import docx from '../../assets/template_icons/docx-6-64.png'
import pdf from '../../assets/template_icons/icon-file-type-pdf-64.png'
import xls from '../../assets/template_icons/icon-file-type-excel-64.png'
import ppt from '../../assets/template_icons/icon-file-type-ppt-64.png'
import video from '../../assets/template_icons/video-143-64.png'
import image from '../../assets/template_icons/image-gallery.png'
import { Pagination } from '../../components/table_AP/Pagination'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

type TemplateProps = {
  categoryList: string[]
  search?: string
  mostViewed?: boolean
  circles: number[]
}

export function TableGrid(props: TemplateProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [templates, setTemplates] = useState<ItemList<File>>(emptyList<File>())
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [articlesInit, setInitArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [articlesPerPage, setArticlesPerPage] = useState<number>(4)
  const [count, setCount] = useState<number>(0)
  const navigate = useNavigate()

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
      rowsPerPageOption: [4, 8, 16],
    })
  }, [page, count, articlesPerPage])

  useEffect(() => {
    setIsLoading(true)
  }, [page, count, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(4)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
  }, [props.categoryList])

  useEffect(() => {
    const articlesQuery = [new QueryParam<Article>('type', 'template')]
    if (props.categoryList.length === 1) {
      articlesQuery.push(new QueryParam<Article>('circleID', props.categoryList[0]))
    } else if (props.categoryList.length > 1) {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', props.categoryList))
    } else {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', props.circles))
    }
    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          articleList.items.push(articleItem)
          articleList.count++
        }

        setArticles(articleList)
        setInitArticles(articleList)
        setCount(res.count)
      })
  }, [props.search, props.categoryList, isLoading])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    const articlesQuery = [new QueryParam<Article>('type', 'template')]
    if (props.categoryList.length === 1) {
      articlesQuery.push(new QueryParam<Article>('circleID', props.categoryList[0]))
    } else if (props.categoryList.length > 1) {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', props.categoryList))
    } else {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', props.circles))
    }

    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          sort: [{ field: 'title' }],
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          articleList.items.push(articleItem)
          articleList.count++
        }

        setArticles(articleList)
        setInitArticles(articleList)
        setCount(res.count)
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
  }, [count])

  const handleSee = (event: any, article: Article) => {
    if (
      !event.target.className.includes('like_button') &&
      !event.target.className.includes('comment_button')
    ) {
      navigate(`${ROUTE_ARTICLES}/${article.id}`)
    }
  }

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          {articles.items.map((item, index) => {
            let icon
            if (item.slug) {
              if (item.slug === 'doc' || item.slug === 'docx') {
                icon = docx
              } else if (item.slug === 'ppt' || item.slug === 'pptx') {
                icon = ppt
              } else if (item.slug === 'xls' || item.slug === 'xlsx') {
                icon = xls
              } else if (item.slug === 'pdf') {
                icon = pdf
              } else if (
                item.slug === 'avi' ||
                item.slug === 'wmv' ||
                item.slug === 'mkv' ||
                item.slug === 'mp4' ||
                item.slug === 'mov'
              ) {
                icon = video
              } else if (
                item.slug === 'jpeg' ||
                item.slug === 'jpg' ||
                item.slug === 'bmp' ||
                item.slug === 'gif' ||
                item.slug === 'png' ||
                item.slug === 'svg'
              ) {
                icon = image
              }
            }
            let content = <div></div>
            if (item && item.content && item.content.charAt(0) === '[') {
              content = <RichText content={item.content} edit={true} view={true} />
            }

            return (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  className={style.card}
                  sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                  variant="outlined"
                  onClick={(event) => handleSee(event, item)}
                >
                  <div className={style.imageContainer}>
                    <div>
                      <Typography variant="h6" color="silver" className={style.title}>
                        {item.title}
                      </Typography>
                      {/* @ts-ignore */}
                      <Typography variant="h7" color="textPrimary" className={style.titleEditor}>
                        {content}
                      </Typography>
                      <div className={style.icon}>
                        <img src={icon} />
                      </div>
                    </div>
                  </div>
                </Card>
              </Grid>
            )
          })}
        </Grid>
        <Table>
          <TableFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TableRow sx={{ borderBottom: 0 }}>{pager && <Pagination {...pager} />}</TableRow>
          </TableFooter>
        </Table>
      </Box>
    </>
  )
}
