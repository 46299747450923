import GenericChartDashboard from '../../../../components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartEnum } from '../../../../components/dashboard/generic-chart-dashboard/ChartEnum'
import { ActivePlanService } from '../../../../modules/active-plan/services/ActivePlanService'
import React, { useEffect, useMemo, useState } from 'react'
import { Query } from '../../../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

type PatientStatisicsCardProps = {
  activePlanService: ActivePlanService
  refreshStatusCircleModal?: boolean
}

type Status = {
  type: 'general' | 'suma' | 'cardio' | 'families' | 'parkinson'
  count: number
}

const PatientStatisicsCard: React.FC<PatientStatisicsCardProps> = ({
  activePlanService,
  refreshStatusCircleModal,
}) => {
  const { t } = useTranslation()
  const backgroundColors = ['#0063A6', '#F18B24', '#B6B5B5', '#F1DD25', '#68B3E0', '#4CBF0D']
  const [label, setLabel] = useState<string[]>([])
  const [datasets, setDataSets] = useState<
    { data: number[]; backgroundColor: string; label: string }[]
  >([])
  const statusTypes = useMemo(
    () => ({
      general: 'general',
      suma: 'suma',
      cardio: 'cardio',
      families: 'families',
      parkinson: 'parkinson',
    }),
    []
  )

  useEffect(() => {
    const subscription = activePlanService
      .getFilteredList(new Query({ query: [] }))
      .subscribe((response) => {
        if (!response) return

        const statusCounts = {
          general: response.items.filter(
            (item) => item.protocol === 'general' && item.status === 'active'
          ).length,
          suma: response.items.filter(
            (item) => item.protocol === 'suma' && item.status === 'active'
          ).length,
          cardio: response.items.filter(
            (item) => item.protocol === 'cardio' && item.status === 'active'
          ).length,
          families: response.items.filter(
            (item) => item.protocol === 'families' && item.status === 'active'
          ).length,
          parkinson: response.items.filter(
            (item) => item.protocol === 'parkinson' && item.status === 'active'
          ).length,
        }

        const filteredStatusCounts: Status[] = Object.entries(statusCounts)
          .filter(([_, count]) => count > 0)
          .map(([type, count]) => ({
            type: type as 'general' | 'suma' | 'cardio' | 'families' | 'parkinson',
            count,
          }))

        setLabel(createEmptyLabel(filteredStatusCounts.length))
        setDataSets(
          filteredStatusCounts.map((item, index) => ({
            data: createArrByLength(filteredStatusCounts.length, item.count, index),
            backgroundColor: backgroundColors[index],
            label: filteredStatusCounts.map((status) => statusTypes[status.type])[index],
          }))
        )
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [activePlanService, refreshStatusCircleModal])

  const createEmptyLabel = (length: number): string[] => {
    const auxArr: string[] = []
    for (let i = 0; i < length; i++) {
      auxArr.push('')
    }
    return auxArr
  }

  const createArrByLength = (length: number, value: number, valueIndex: number): number[] => {
    const auxArr: number[] = []
    for (let i = 0; i < length; i++) {
      if (i === valueIndex) {
        auxArr.push(value)
      } else {
        auxArr.push(0)
      }
    }
    return auxArr
  }

  return (
    <Box style={{ height: '340px' }}>
      <GenericChartDashboard
        datasets={datasets}
        labels={label}
        title={t('request')}
        typeChart={ChartEnum.Bar}
        legendPosition={'bottom'}
        yAxisConfig={{
          min: 0,
          max: 10 + (datasets.length > 0 ? Math.max(...datasets.flatMap((item) => item.data)) : 0),
          stepSize: Math.trunc(
            0.3 *
              (10 + (datasets.length > 0 ? Math.max(...datasets.flatMap((item) => item.data)) : 0))
          ),
        }}
      />
    </Box>
  )
}
export default PatientStatisicsCard
