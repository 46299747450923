import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_CIRCLE_REGISTER,
  ROUTE_DASHBOARD,
  ROUTE_DASHBOARD_PROFESSIONAL,
} from './routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Roles } from './modules/users/enums/Roles'
import style from './Login.module.css'
import './fumi.css'
import './assets/css/fontAwesome.css'
import logoHeader from './assets/brand_logo/background-title.png'
import { Box, Link } from '@mui/material'
import logo from './assets/brand_logo/sms.svg'
import concierge from './assets/brand_logo/conserjeria.png'
import murciaHealth from './assets/brand_logo/murciasalud.png'
import PasswordRecover from './PasswordRecover'
import ChangePassword from './ChangePassword'
import ExternRegister from './ExternRegister'
import { UserNotFound } from './UserNotFound'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import { VerifyUser } from './VerifyUser'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const PATIENTS_URL = process.env.REACT_APP_PATIENTS_URL
const PATIENTS_AUTH_URL = process.env.REACT_APP_PATIENTS_AUTH_URL
const PROFESSIONALS_URL = process.env.REACT_APP_PROFESSIONALS_URL
const PROFESSIONALS_AUTH_URL = process.env.REACT_APP_PROFESSIONALS_AUTH_URL
const LoginCas = process.env.REACT_APP_CAS_LOGIN

enum View {
  default,
  recoverPassword,
  registerUser,
  changePassword,
  userNotFound,
}

type LoginProps = {
  handlerViewChooseRole?: () => void
}

export default function Login(props: LoginProps) {
  useEffect(() => {
    ifRedirectToCAS()
    const ticket = new URLSearchParams(window.location.search).get('ticket')
    if (ticket) {
      doLogin(ticket, ticketType())
    }
  }, [])

  const { t } = useTranslation()
  const classes = useStyles()

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [hasError, setHasError] = useState(false)
  const [view, setView] = useState(View.default)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const navigate = useNavigate()
  const [showVerifyUser, setShowVerifyUser] = useState(false)

  useEffect(() => {
    queryString.includes('changePassword') && setView(View.changePassword)
    queryString.includes('userNotFound') && setView(View.userNotFound)
    queryString.includes('ticket') && setView(View.userNotFound)
    queryString.includes('token') && setView(View.registerUser)
  }, [])

  const handlerLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doLogin()
  }

  const doLogin = (ticket?: string, ticketType?: 'patient' | 'professional') => {
    setShowVerifyUser(true)
    const currentURL = window.location.href
    loggedUserService
      .login({ login, password, ticket, ticketType, currentURL })
      .subscribe((res) => {
        if (!res) {
          LoginCas && setView(View.userNotFound)
          setHasError(true)
          setShowVerifyUser(false)
          return
        }
        if (res.id === 'banned') {
          setHasError(true)
          setShowVerifyUser(false)
          return
        }
        const isProfSMS = res?.roles.some((item) => item === Roles.Professional)

        const isManager = res?.roles.some((item) => item.includes(Roles.Manager))

        if (!isProfSMS && !isManager && res.roles.length > 1) {
          props.handlerViewChooseRole && props.handlerViewChooseRole()
        }

        setHasError(false)

        if (currentURL.includes('/register')) {
          navigate(ROUTE_CIRCLE_REGISTER + queryString)
          return
        }

        if (isProfSMS || isManager) {
          navigate(ROUTE_DASHBOARD_PROFESSIONAL)
          return
        }

        navigate(ROUTE_DASHBOARD)
      })
  }

  const handleRecover = () => {
    setView(View.recoverPassword)
  }

  return (
    <Box>
      <Box
        style={{ height: view === View.registerUser ? '' : '100vh' }}
        className={style.container}
      >
        <Box className={style.header} display="flex" flexDirection="column">
          <Box display="flex" className={style.headerTop}>
            <img src={logoHeader} alt={t('logoHeader')} className={style.logoHeader} />
            <Box className={style.headerTopLeft} display="flex">
              <img src={logo} alt={t('logo')} />
              <Box justifyContent="center" alignItems="center" display="flex">
                <p>{t('loginTitle')}</p>
              </Box>
            </Box>
            <Box className={style.headerTopRight} display="block" justifyContent="right">
              <img src={concierge} alt={t('concierge')} />
              <img src={murciaHealth} alt={t('murciaHealth')} />
            </Box>
          </Box>
        </Box>
        {showVerifyUser ? (
          <VerifyUser />
        ) : LoginCas === 'true' ? (
          view === View.userNotFound ? (
            <UserNotFound />
          ) : (
            <Box className={style.loginContainer} display={'flex'}>
              <Box className={style.login}>
                <h2>{t('redirectToLogin')}</h2>
              </Box>
            </Box>
          )
        ) : view === View.changePassword ? (
          <ChangePassword id={urlParams.get('changePassword') ?? ''} />
        ) : view === View.registerUser ? (
          <ExternRegister token={urlParams.get('token') ?? ''} />
        ) : view === View.recoverPassword ? (
          <PasswordRecover />
        ) : (
          <>
            <Box className={style.loginContainer} display={'flex'}>
              <Box className={style.login}>
                <h2>{t('loginUserAndPassword')}</h2>
                {hasError && <Typography color="error">{t('auth error text')}</Typography>}
                <form className={style.loginFields} noValidate onSubmit={handlerLogin}>
                  <span
                    style={{ marginTop: window.innerWidth > 599 ? '50px' : '' }}
                    className="input input--fumi"
                  >
                    <input
                      className="input__field input__field--fumi"
                      type="text"
                      id="login"
                      value={login}
                      onChange={(e) => setLogin(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label className="input__label input__label--fumi">
                      <i className="fa fa-fw fa-user icon icon--fumi" title="Usuario (CIP o DNI)" />
                      <span className="input__label-content input__label-content--fumi">
                        {t('loginEmail')}
                      </span>
                    </label>
                  </span>
                  <span style={{ marginBottom: '0.8rem' }} className="input input--fumi">
                    <input
                      className="input__field input__field--fumi"
                      type="password"
                      name="Contraseña"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="off"
                      required
                    />
                    <label className="input__label input__label--fumi">
                      <i className="fa fa-fw fa-lock icon icon--fumi" title="Contraseña" />
                      <span className="input__label-content input__label-content--fumi">
                        {t('loginPassword')}
                      </span>
                    </label>
                  </span>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    <p style={{ fontWeight: 'bolder', color: 'white' }}>{t('loginSubmit')}</p>
                  </Button>
                </form>
                <Link onClick={handleRecover} style={{ cursor: 'pointer' }}>
                  <h5>{t('loginForgotPassword')}</h5>
                </Link>
              </Box>
            </Box>
          </>
        )}
        <Box className={style.footer} display="flex" flexDirection="column">
          <p style={{ fontWeight: 'bolder' }}>© Servicio Murciano de Salud</p>
          <p>Calle Central, 7, Edificio Habitamia 1, 30100, Espinardo (Murcia)</p>
          <p>{t('loginContactPhone')}: 968 36 59 00</p>
        </Box>
      </Box>
    </Box>
  )
}

function ifRedirectToCAS() {
  if (new URLSearchParams(window.location.search).get('ticket')) {
    return
  }

  if (isPatient()) {
    window.location.replace(
      `${PATIENTS_AUTH_URL}?service=${encodeURIComponent(window.location.href ?? '')}`
    )
  }
  if (isProfessional()) {
    window.location.replace(
      `${PROFESSIONALS_AUTH_URL}?service=${encodeURIComponent(window.location.href || '')}`
    )
  }
}

function ticketType(): 'professional' | 'patient' {
  return isProfessional() ? 'professional' : 'patient'
}

export function isPatient(): boolean {
  return !!(PATIENTS_URL && window.location.toString().includes(PATIENTS_URL))
}

export function isProfessional(): boolean {
  return !!(PROFESSIONALS_URL && window.location.toString().includes(PROFESSIONALS_URL))
}
