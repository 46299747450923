import { RouteProps, useNavigate } from 'react-router-dom'
import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { Box, Grid } from '@mui/material'
import { NotificationsCardPatient } from './notification-card-patient/NotificationsCardPatient'
import { ChatsCard } from './chats-card/ChatsCard'
import { CirclesCard } from './circles-card/CirclesCard'
import { EducationalContentCard } from './educational-content-card/EducationalContentCard'
import { ActiveQueriesCard } from './active-query-card/ActiveQueriesCard'
import { MonitoredActivityCard } from './monitored-activity-card/MonitoredActivityCard'
import { ActivePlanCard } from './active-plan-card/ActivePlanCard'
import { useEffect, useState } from 'react'
import { ACTIVE_PLAN_CIRCLE_ID, EPILEPSY_CIRCLE_ID } from 'common/const'
import { NumericStatsCard } from './numeric-stats-card/NumericStatsCard'
import { MonthView } from '../../calendar/MonthView'
import { useCalendarEvents } from 'hooks/calendarEvents/useCalendarEvents'
import { ROUTE_WEARABLES } from 'routes/routes-constants'
import { EpilepsyCard } from './epilepsy-card/EpilepsyCard'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { emptyCalendarEvents } from 'common/utils/calendar-events/CalendarEventsContext'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { dateDateToAge } from 'common/utils/date'
import { UserCircleWithCircleAndUserDTO } from 'modules/user-circle/models/UserCircleWithCircleAndUserDTO'
import { Roles } from 'modules/users/enums/Roles'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const DashboardPatient = (props: RouteProps) => {
  const { calendarEvents, setRefreshCalendarEvents } = useCalendarEvents()
  const { selectedUserCircle } = useCircleConfiguration()
  const [numberPendingChats, setNumberPendingChats] = useState<number>(0)
  const [numberNewResources, setNumberNewResources] = useState<number>(0)

  const vp = emptyCalendarEvents(calendarEvents)

  useEffect(() => {
    setRefreshCalendarEvents(true)
  }, [])

  const hideOnlineQuerys = shouldHideActiveQueries(
    selectedUserCircle,
    loggedUserService,
    dateDateToAge
  )

  const navigate = useNavigate()
  const wearables = () => {
    navigate(ROUTE_WEARABLES)
  }

  return (
    <Box px={0}>
      <DashboardSectionCard label={'calendar'}>
        <MonthView {...vp} />
      </DashboardSectionCard>
      <DashboardSectionCard label={'generalSection'} showMore={false}>
        <Box>
          <Grid width={'100%'} container spacing={2}>
            <Grid mt={4} item xs={12} sm={6} md={4}>
              <NotificationsCardPatient
                ProfessionalNotifications={false}
                SetNumberNewResources={setNumberNewResources}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Grid display={'flex'} xs={12} sm={6} md={12}>
                <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                  <CirclesCard />
                </Grid>
                <Grid mt={4} item xs={12} sm={6} md={6}>
                  <ChatsCard setNumberPendingChats={setNumberPendingChats} />
                </Grid>
              </Grid>
              <Grid display={'flex'} xs={12} sm={6} md={12}>
                <Grid mt={4} mr={2} item xs={12} sm={6} md={6}>
                  <EducationalContentCard />
                </Grid>
                <Grid mt={4} item xs={12} sm={6} md={6}>
                  {hideOnlineQuerys && <ActiveQueriesCard />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DashboardSectionCard>
      {selectedUserCircle?.circle.id === ACTIVE_PLAN_CIRCLE_ID && (
        <>
          <DashboardSectionCard label={'wearables'} showMore={true} onClick={wearables}>
            <MonitoredActivityCard />
          </DashboardSectionCard>
          <DashboardSectionCard label={'activePlan'} marginTop={50}>
            <ActivePlanCard />
          </DashboardSectionCard>
        </>
      )}

      {selectedUserCircle?.circle.id === EPILEPSY_CIRCLE_ID && (
        <DashboardSectionCard label={'epilepsya'} marginTop={50}>
          <EpilepsyCard />
        </DashboardSectionCard>
      )}
      <NumericStatsCard
        numberPendingChats={numberPendingChats}
        numberNewResources={numberNewResources}
      />
    </Box>
  )
}

export function shouldHideActiveQueries(
  selectedUserCircle: UserCircleWithCircleAndUserDTO | undefined,
  loggedUserService: ILoggedUserService,
  dateDateToAge: Function
): boolean {
  const rolesToExclude = [Roles.ProfessionalExtern, Roles.FamilyOrTutor, Roles.CarerOrTeacher]
  const rolesToIncludeFamily = [Roles.FamilyOrTutor]

  const rolesTohideActiveQuerySection = loggedUserService.get()?.roles

  // if it includes a role to exclude , it should send false
  const hideOnlineQuerys = rolesToExclude.some((role) =>
    rolesTohideActiveQuerySection?.includes(role)
  )

  const userSelectedAge = selectedUserCircle?.user.birthDate
    ? dateDateToAge(new Date(selectedUserCircle.user.birthDate)) + 1
    : undefined

  const isUnderAge = userSelectedAge && userSelectedAge < 18

  const isFamilyRole = rolesToIncludeFamily.some((role) =>
    rolesTohideActiveQuerySection?.includes(role)
  )

  return userSelectedAge === undefined ? false : (isFamilyRole && isUnderAge) || !hideOnlineQuerys
}
