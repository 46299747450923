import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { TextField } from '@mui/material'
import style from './Searcher.module.css'
import { ItemList } from '../../common/models/ItemList'
import { SegmentedNotification } from '../../modules/notifications/models/SegmentedNotification'

type SearcherProps = {
  handler: any
  data?: ItemList<SegmentedNotification>
  reset?: boolean
}

export function Searcher(props: SearcherProps): JSX.Element {
  const [search, setSearch] = useState<string>('')

  const handlerChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value)

  useEffect(() => {
    const result = props.data?.items.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    )
    props.handler(result)
  }, [search])

  useEffect(() => {
    setSearch('')
  }, [props.reset])

  return (
    <>
      <Box className={style.inputBox}>
        <TextField
          sx={{
            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { fontFamily: 'Poppins' },
            zIndex: 0,
          }}
          className={style.inputSearcher}
          label={'Buscar...'}
          variant={'outlined'}
          type="text"
          value={search}
          onChange={handlerChange}
        />
      </Box>
    </>
  )
}
