import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { Permission } from 'common/permission'
import {
  ROUTE_ARTICLE,
  ROUTE_ARTICLES,
  ROUTE_CIRCLE_CONFIGURATOR,
  ROUTE_ARTICLES_FORM_EDIT,
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_FORM_EDIT,
  ROUTE_CIRCLE_REGISTER,
  ROUTE_CIRCLES,
  ROUTE_CONFIGURATION,
  ROUTE_DENTIST_CONFIG,
  ROUTE_DENTIST_FORM_EDIT,
  ROUTE_DENTIST_MAP,
  ROUTE_DENTISTS_LIST,
  ROUTE_EDIT_VACCINE,
  ROUTE_EVOLUTION_CONTROL,
  ROUTE_FAMILIAR_DATA,
  ROUTE_FAMILIAR_DATA_FORM_EDIT,
  ROUTE_FORM_GENERATOR,
  ROUTE_FORM_GENERATOR_CIRCLES,
  ROUTE_FORM_GENERATOR_FORM_EDIT,
  ROUTE_FORM_GENERATOR_FORM_REMOVE,
  ROUTE_FORM_GENERATOR_FORM_RESULTS,
  ROUTE_GOALS_FORM,
  ROUTE_GROWTH_MONITORING,
  ROUTE_GROWTH_MONITORING_FORM_EDIT,
  ROUTE_LANDMARK,
  ROUTE_LANDMARK_FORM_EDIT,
  ROUTE_LINK_WEARABLE,
  ROUTE_MEDICAL_DATA,
  ROUTE_MESSENGER,
  ROUTE_MOBILE_MENU,
  ROUTE_NOTIFICATIONS_CONFIG,
  ROUTE_PATHOLOGY_SYMPTOMS_FORM_EDIT,
  ROUTE_PATIENT_CALENDAR_CONFIG,
  ROUTE_PATIENT_CALENDAR_CONFIG_FORM_EDIT,
  ROUTE_PATIENT_DATA,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_FORMS_VIEW,
  ROUTE_PATIENT_SYMPTOMS,
  ROUTE_PATIENT_SYMPTOMS_FORM_EDIT,
  ROUTE_RESOURCES,
  ROUTE_RESOURCES_FORM_EDIT,
  ROUTE_RESOURCES_ID,
  ROUTE_RESOURCES_SHARED_WITH_ME,
  ROUTE_RESOURCES_SHARED_WITH_ME_ID,
  ROUTE_ROLE_PERMISSIONS,
  ROUTE_SEGMENTED_NOTIFICATIONS,
  ROUTE_SYMPTOMS_CONFIG,
  ROUTE_TAG_EDIT,
  ROUTE_TAG_LIST,
  ROUTE_TREATMENTS,
  ROUTE_USER_PROFILE,
  ROUTE_USERS,
  ROUTE_USERS_CREATE,
  ROUTE_VACCINATION_SCHEDULE,
  ROUTE_VACCINES,
  ROUTE_VACCINES_CREATE,
  ROUTE_WEARABLES,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_SHARE_RESOURCES,
  ROUTE_SHARE_RESOURCES_CREATE,
  ROUTE_SHARE_RESOURCES_FORM_EDIT,
  ROUTE_SHARE_RESOURCES_ID,
  ROUTE_SHARE_RESOURCES_ASSIGN,
  ROUTE_QUERY_CONFIG,
  ROUTE_QUERY,
  ROUTE_TEMPLATE_FORM_GENERATOR_EDIT,
  ROUTE_USER_PROFILE_EDIT,
  ROUTE_ADD_NEW_CIRCLE,
  ROUTE_ADD_NEW_CIRCLE_CIRCLE_CONFIGURATOR,
  ROUTE_ADD_NEW_CIRCLE_RESOURCES_CONFIGURATOR,
  ROUTE_ADD_NEW_CIRCLE_FORM_CONFIGURATOR,
  ROUTE_ADD_NEW_CIRCLE_SYMPTOMS_CONFIGURATOR,
  ROUTE_EDIT_ACTIVE_CIRCLE_ADD_NEW_CIRCLE,
  ROUTE_EDIT_ACTIVE_CIRCLE_CIRCLE_CONFIGURATOR,
  ROUTE_EDIT_ACTIVE_CIRCLE_SYMPTOMS_CONFIGURATOR,
  ROUTE_SPORTCENTRE_CONFIG,
  ROUTE_CITY_HALL_CONFIG,
  ROUTE_MY_POSTS_CONTENT,
  ROUTE_ARTICLES_VALIDATE,
  ROUTE_ARTICLES_HISTORY,
  ROUTE_TUTORIAL_CONTENT,
  ROUTE_TEMPLATES_PRO_CONTENT,
  ROUTE_TEMPLATES_FORM_EDIT,
  ROUTE_TUTORIALS_FORM_EDIT,
  ROUTE_ADMIN_TUTORIAL_TEMPLATES,
  ROUTE_ACTIVE_PLAN_DATA,
  ROUTE_DASHBOARD,
  ROUTE_PROMS_PREMS,
  ROUTE_PROMS_PREMS_GENERATOR,
  ROUTE_PROMS_PREMS_GENERATOR_FORM_EDIT,
  ROUTE_DASHBOARD_PROFESSIONAL,
  ROUTE_DASHBOARD_CASE_MANAGER,
  ROUTE_LEGAL_ADVICE,
  ROUTE_TELECONSULTATION,
  ROUTE_SEGMENTED_NOTIFICATION,
  ROUTE_PROMS_PREMS_GENERATOR_FORM,
} from './routes-constants'

import { Messenger } from 'pages/messenger'
import { Calendar } from 'pages/calendar'
import { Articles } from 'pages/articles'
import { ArticlesForm } from 'pages/articles-form'
import { ArticlesMyPosts } from 'pages/articles-my-posts'
import { ArticlesView } from 'pages/articles-view'
import { TagForm } from 'pages/tag-form'
import { FormGenerator } from 'pages/form-generator'
import { FormGeneratorTable } from 'pages/form-generator-table'
import {
  ARTICLES_TITLE,
  CALENDAR_TITLE,
  CIRCLE_REGISTER_TITLE,
  CIRCLES_TITLE,
  CONFIGURATION_TITLE,
  CREATE_PARTICIPANT_TITLE,
  DENTIST_CONFIG_TITLE,
  DENTIST_LIST_TITLE,
  DENTIST_MAP_TITLE,
  EDIT_VACCINE_TITLE,
  EVOLUTION_CONTROL_TITLE,
  FAMILIAR_DATA_TITLE,
  FORM_GENERATOR_EDITOR_TITLE,
  FORM_GENERATOR_REMOVE_TITLE,
  FORM_GENERATOR_RESULTS_TITLE,
  FORM_GENERATOR_TITLE,
  FORM_GENERATOR_USERS_TITLE,
  GOALS_TITLE,
  GROWTH_MONITORING_TITLE,
  LANDMARK_TITLE,
  MEDICAL_DATA_TITLE,
  MESSENGER_TITLE,
  MOBILE_MENU_TITLE,
  PATHOLOGY_SYMPTOM_TITLE,
  PATIENT_CALENDAR_CONFIG_TITLE,
  PATIENT_FORM_VIEW_TITLE,
  PATIENTS_FORMS_TITLE,
  RESOURCES_NOTIFICATIONS_CONFIG_TITLE,
  RESOURCES_SHARED_WITH_ME_TITLE,
  RESOURCES_TITLE,
  ROLE_PERMISSIONS_TITLE,
  SYMPTOMS_CONFIG_TITLE,
  SYMPTOMS_TITLE,
  TAGS_TITLE,
  TREATMENTS_TITLE,
  USER_PROFILE_TITLE,
  USERS_TITLE,
  VACCINATION_SCHEDULE_TITLE,
  VACCINES_CREATE_TITLE,
  VACCINES_TITLE,
  WEARABLES_TITLE,
  PASSWORD_CHANGE_TITLE,
  SHARE_RESOURCES,
  QUERY_CONFIG_TITLE,
  QUERY_TITLE,
  CIRCLE_CONFIGURATOR_TITLE,
  SPORT_CENTRE_CONFIG_TITLE,
  CITY_HALL_CONFIG_TITLE,
  ARTICLES_NEW_POST,
  ARTICLES_MY_POSTS,
  ARTICLES_VALIDATE_TITLE,
  ARTICLES_HISTORY,
  TUTORIAL_CONTENT_TITLE,
  TEMPLATES_PRO_TITLE,
  NEW_TUTORIAL,
  NEW_TEMPLATE,
  ADMIN_TUTORIAL_TEMPLATES,
  ACTIVE_PLAN_DATA_TITLE,
  PATIENT_DATA_TITLE,
  DASHBOARD_TITLE,
  TELECONSULTATION_TITLE,
  PROMS_PREMS_TITLE,
  SEGMENTEDE_NOTIFICATIONS_TITLE,
} from './title-constants'
import { FormGeneratorResults } from 'pages/form-generator-results'
import { Configuration } from 'pages/configuration'
import { CalendarForm } from 'pages/calendar-form'
import { PatientFormsTable } from 'pages/patient-forms-list'
import { PatientFormView } from 'pages/patient-forms-view'
import { Resources } from 'pages/resources'
import { ResourcesForm } from 'pages/resources-form'
import { NotificationsConfig } from 'pages/notifications-config/NotificationsConfig'
import { RolePermissions } from 'pages/role-permissions'
import { Circles } from 'pages/circles'
import { EditParticipant, Participants } from 'pages/participants'
import { FormGeneratorCircles } from 'pages/form-generator-circles'
import { UserProfileEdit } from 'pages/user-profile'
import { UserProfile } from 'pages/user-profile'
import { FormGeneratorRemove } from 'pages/form-generator-remove'
import { PromsPremsRemove } from 'pages/promsPrems-remove'
import { PatientData } from 'pages/patient-data'
import { Symptoms } from 'pages/symptoms'
import { SymptomsForm } from 'pages/symptoms-form'
import { Treatments } from 'pages/treatments'
import { Vacusan } from 'pages/vacusan'
import { EvolutionControl } from 'pages/evolution-control'
import { SymptomsConfig } from 'pages/symptoms-config'
import { SymptomsConfigForm } from 'pages/symptoms-config-form'
import { DentistConfig } from 'pages/dentist-config/DentistConfig'
import { MedicalData } from 'pages/medicalData'
import { DentistConfigForm } from 'pages/dentist-config-form'
import { Landmark } from 'pages/landmark'
import { LandmarkForm } from 'pages/landmark-form'
import { DentistMap } from 'pages/dentist-map'
import { DentistList } from 'pages/dentist-list'
import { FamiliarData } from 'pages/familiar-data'
import { GrowthMonitoringList } from 'pages/growth-monitoring-list'
import { GrowthMonitoringForm } from 'pages/growth-monitoring-form'
import { PatientCalendarConfigList } from 'pages/patient-calendar-config-list'
import { PatientCalendarConfigForm } from 'pages/patient-calendar-config-form'
import { FamiliarDataForm } from 'pages/familiar-data-form'
import { MobileMenu } from 'pages/mobile-menu'
import { Tags } from 'pages/tags'
import { Link, Wearables } from 'pages/wearables'
import { GoalsForm } from 'pages/goals-form'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users/container'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { EditVaccine, Vaccines } from 'pages/vaccines'
import { CircleRegister } from 'pages/circleRegister'
import { PasswordConfig } from 'pages/password-config'
import { ShareResources } from 'pages/share-resources'
import { ResourcesShare } from 'pages/resources-share'
import { ShareResourcesAssign } from 'pages/share-resources/ShareResourcesAssign'
import { QueryConfig } from 'pages/query-config'
import { QueryView } from 'pages/query'
import { FormTemplateGenerator } from 'pages/form-template-generator'
import { ActivePlanConfig } from 'pages/sportCenter-config'
import { CircleConfigurator } from 'pages/circle-configurator'
import { AddNewCircle } from 'pages/add-new-circle'
import { AddNewCircleResourcesConfigurator } from 'features/circle-configurator/add-new-circle/3-resources/AddNewCircleResourcesConfigurator'
import { EditExistingActiveCircle } from 'features/circle-configurator/edit-active-circle/1-definition/EditExistingActiveCircle'
import { EditActiveCircleConfigurator } from 'features/circle-configurator/edit-active-circle/2-configurator/EditActiveCircleConfigurator'
import { EditCircleSymptomsConfigurator } from 'features/circle-configurator/edit-active-circle/5-symptoms/EditCircleSymptomsConfigurator'
import { CityHallConfig } from 'pages/cityHall-config'
import { ArticlesValidate } from 'pages/articles-validate'
import { ArticlesHistory } from 'pages/articles-history'
import { Tutorials } from 'pages/tutorials'
import { Templates } from 'pages/templates'
import { TemplatesForm } from 'pages/templates-form'
import { TutorialsForm } from 'pages/tutorials-form'
import { AdminTutorialTemplates } from 'pages/tutorials-templates-admin'
import { SegmentedNotifications } from 'pages/segmented-notifications'
import { SegmentedNotificationsForm } from 'pages/segmented-notifications-form'
import { ActivePlan } from 'pages/activePlan'
import LegalAdvice from 'pages/configuration/LegalAdvice'
import { Dashboard } from 'pages/dashboard/Dashboard'
import { DashboardProfessional } from 'pages/dashboard-professional/DashboardProfessional'
import { DashboardCaseManager } from 'pages/dashboard-caseManager/DashboardCaseManager'
import { TeleConsultation } from 'pages/teleconsultation'
import { PromsPrems } from 'pages/promsPrems/PromsPrems'
import { PromsPremsGenerator } from 'pages/promsPrems-generator/PromsPremsGenerator'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { AddNewCircleConfigurator } from 'features/circle-configurator/add-new-circle/2-circle-configurator/AddNewCircleConfigurator'
import { AddNewCircleFormConfigurator } from 'features/circle-configurator/add-new-circle/4-forms/AddNewCircleFormConfigurator'
import { AddNewCircleSymptomsConfigurator } from 'features/circle-configurator/add-new-circle/5-symptoms/AddNewCircleSymptomsConfigurator'

export type RouteProps = {
  title?: string
  subtitle?: string
  circleSubtitle?: string
  id?: string
  refMain?: React.RefObject<HTMLDivElement>
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const AppRouter: React.FC = (props: RouteProps) => {
  const { selectedUserCircle } = useCircleConfiguration()
  const generalRouts = (
    <>
      {loggedUserService.userCan(Permission.registerCircles) && (
        <Route
          path={ROUTE_CIRCLE_REGISTER}
          element={<CircleRegister title={CIRCLE_REGISTER_TITLE} />}
        />
      )}

      <Route path={ROUTE_DASHBOARD} element={<Dashboard title={DASHBOARD_TITLE} />} />

      <Route path={ROUTE_PROMS_PREMS} element={<PromsPrems title={PROMS_PREMS_TITLE} />} />

      <Route
        path={ROUTE_PROMS_PREMS_GENERATOR_FORM_EDIT}
        element={<PromsPremsGenerator refMain={props.refMain} />}
      />

      <Route
        path={ROUTE_PROMS_PREMS_GENERATOR_FORM}
        element={<PromsPremsRemove title={PROMS_PREMS_TITLE} />}
      />

      <Route
        path={ROUTE_PROMS_PREMS_GENERATOR}
        element={<PromsPremsGenerator title={PROMS_PREMS_TITLE} refMain={props.refMain} />}
      />
      <Route
        path={ROUTE_DASHBOARD_PROFESSIONAL}
        element={<DashboardProfessional title={DASHBOARD_TITLE} />}
      />

      <Route
        path={ROUTE_DASHBOARD_CASE_MANAGER}
        element={<DashboardCaseManager title={DASHBOARD_TITLE} />}
      />

      <Route path={ROUTE_CIRCLES} element={<Circles title={CIRCLES_TITLE} />} />
      {loggedUserService.userCan(Permission.createFormTemplates) && (
        <Route
          path={ROUTE_FORM_GENERATOR_FORM_EDIT}
          element={<FormGenerator title={FORM_GENERATOR_EDITOR_TITLE} refMain={props.refMain} />}
        />
      )}
      {loggedUserService.userCan(Permission.createFormTemplates) && (
        <Route
          path={ROUTE_FORM_GENERATOR_FORM_REMOVE}
          element={<FormGeneratorRemove title={FORM_GENERATOR_REMOVE_TITLE} />}
        />
      )}
      {loggedUserService.userCan(Permission.configVaccines) && (
        <Route path={ROUTE_EDIT_VACCINE} element={<EditVaccine title={EDIT_VACCINE_TITLE} />} />
      )}

      {loggedUserService.userCan(Permission.configVaccines) && (
        <Route
          path={ROUTE_VACCINES_CREATE}
          element={<EditVaccine title={VACCINES_CREATE_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.configVaccines) && (
        <Route path={ROUTE_VACCINES} element={<Vaccines title={VACCINES_TITLE} />} />
      )}

      {loggedUserService.userCan(Permission.configVaccines) && (
        <Route path={ROUTE_VACCINES} element={<Vaccines title={VACCINES_TITLE} />} />
      )}

      <Route
        path={ROUTE_FORM_GENERATOR_FORM_RESULTS}
        element={<FormGeneratorResults title={FORM_GENERATOR_RESULTS_TITLE} />}
      />

      {loggedUserService.userCan(Permission.createFormTemplates) && (
        <Route
          path={ROUTE_FORM_GENERATOR}
          element={<FormGeneratorTable title={FORM_GENERATOR_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.createFormTemplates) && (
        <Route
          path={ROUTE_FORM_GENERATOR_CIRCLES}
          element={<FormGeneratorCircles title={FORM_GENERATOR_USERS_TITLE} />}
        />
      )}

      <Route path={ROUTE_ARTICLES} element={<Articles title={ARTICLES_TITLE} />} />
      <Route path={ROUTE_ARTICLE} element={<ArticlesView />} />
      <Route
        path={ROUTE_SEGMENTED_NOTIFICATIONS}
        element={<SegmentedNotifications title={SEGMENTEDE_NOTIFICATIONS_TITLE} />}
      />
      <Route
        path={ROUTE_SEGMENTED_NOTIFICATION}
        element={<SegmentedNotificationsForm title={SEGMENTEDE_NOTIFICATIONS_TITLE} />}
      />

      <Route path={ROUTE_TUTORIAL_CONTENT} element={<Tutorials title={TUTORIAL_CONTENT_TITLE} />} />
      <Route
        path={ROUTE_TEMPLATES_PRO_CONTENT}
        element={<Templates title={TEMPLATES_PRO_TITLE} />}
      />
      <Route
        path={ROUTE_ADMIN_TUTORIAL_TEMPLATES}
        element={<AdminTutorialTemplates title={ADMIN_TUTORIAL_TEMPLATES} />}
      />

      {loggedUserService.userCan(Permission.createContent) && (
        <>
          <Route
            path={ROUTE_MY_POSTS_CONTENT}
            element={<ArticlesMyPosts title={ARTICLES_MY_POSTS} />}
          />
          <Route
            path={ROUTE_ARTICLES_FORM_EDIT}
            element={<ArticlesForm title={ARTICLES_NEW_POST} />}
          />
          <Route
            path={ROUTE_TEMPLATES_FORM_EDIT}
            element={<TemplatesForm title={NEW_TEMPLATE} />}
          />
          <Route
            path={ROUTE_TUTORIALS_FORM_EDIT}
            element={<TutorialsForm title={NEW_TUTORIAL} />}
          />
          <Route path={ROUTE_TAG_LIST} element={<Tags title={TAGS_TITLE} />} />
          <Route path={ROUTE_TAG_EDIT} element={<TagForm title={TAGS_TITLE} />} />
        </>
      )}

      {loggedUserService.userCan(Permission.validateContent) && (
        <>
          <Route
            path={ROUTE_ARTICLES_VALIDATE}
            element={<ArticlesValidate title={ARTICLES_VALIDATE_TITLE} />}
          />
          <Route
            path={ROUTE_ARTICLES_HISTORY}
            element={<ArticlesHistory title={ARTICLES_HISTORY} />}
          />
        </>
      )}

      <Route path={ROUTE_CONFIGURATION} element={<Configuration title={CONFIGURATION_TITLE} />} />

      {loggedUserService.userCan(Permission.configureSymptoms) && (
        <Route
          path={ROUTE_SYMPTOMS_CONFIG}
          element={<SymptomsConfig title={SYMPTOMS_CONFIG_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.configureSymptoms) && (
        <Route
          path={ROUTE_PATHOLOGY_SYMPTOMS_FORM_EDIT}
          element={<SymptomsConfigForm title={PATHOLOGY_SYMPTOM_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.editViewRolePermissions) && (
        <Route
          path={ROUTE_ROLE_PERMISSIONS}
          element={<RolePermissions title={ROLE_PERMISSIONS_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.configureDentists) && (
        <Route
          path={ROUTE_DENTIST_CONFIG}
          element={<DentistConfig title={DENTIST_CONFIG_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.configureDentists) && (
        <Route
          path={ROUTE_DENTIST_FORM_EDIT}
          element={<DentistConfigForm title={DENTIST_CONFIG_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.configurePatientCalendar) && (
        <Route path={ROUTE_QUERY_CONFIG} element={<QueryConfig title={QUERY_CONFIG_TITLE} />} />
      )}

      {loggedUserService.userCan(Permission.configurePatientCalendar) && (
        <Route
          path={ROUTE_PATIENT_CALENDAR_CONFIG}
          element={<PatientCalendarConfigList title={PATIENT_CALENDAR_CONFIG_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.configurePatientCalendar) && (
        <Route
          path={ROUTE_PATIENT_CALENDAR_CONFIG_FORM_EDIT}
          element={<PatientCalendarConfigForm title={PATIENT_CALENDAR_CONFIG_TITLE} />}
        />
      )}
      <Route path={ROUTE_MOBILE_MENU} element={<MobileMenu title={MOBILE_MENU_TITLE} />} />
      <Route
        path={ROUTE_USER_PROFILE_EDIT}
        element={<UserProfileEdit title={USER_PROFILE_TITLE} />}
      />
      <Route path={ROUTE_USER_PROFILE} element={<UserProfile title={USER_PROFILE_TITLE} />} />
      {loggedUserService.userCan(Permission.configureNotifications) && (
        <Route
          path={ROUTE_NOTIFICATIONS_CONFIG}
          element={<NotificationsConfig title={RESOURCES_NOTIFICATIONS_CONFIG_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.SeeSharedResources) && (
        <Route path={ROUTE_SHARE_RESOURCES} element={<ShareResources title={SHARE_RESOURCES} />} />
      )}

      {loggedUserService.userCan(Permission.SeeSharedResources) && (
        <Route
          path={ROUTE_SHARE_RESOURCES_CREATE}
          element={<ResourcesShare title={SHARE_RESOURCES} />}
        />
      )}

      {loggedUserService.userCan(Permission.SeeSharedResources) && (
        <Route path={ROUTE_SHARE_RESOURCES_FORM_EDIT} element={<ResourcesShare />} />
      )}

      {loggedUserService.userCan(Permission.SeeSharedResources) && (
        <Route
          path={ROUTE_SHARE_RESOURCES_ID}
          element={<ShareResources title={SHARE_RESOURCES} />}
        />
      )}

      <Route
        path={ROUTE_SHARE_RESOURCES_ASSIGN}
        element={<ShareResourcesAssign title={SHARE_RESOURCES} />}
      />

      <Route path={ROUTE_RESOURCES_FORM_EDIT} element={<ResourcesForm title={RESOURCES_TITLE} />} />

      <Route
        path={ROUTE_CHANGE_PASSWORD}
        element={<PasswordConfig title={PASSWORD_CHANGE_TITLE} />}
      />

      <Route
        path={ROUTE_TEMPLATE_FORM_GENERATOR_EDIT}
        element={<FormTemplateGenerator refMain={props.refMain} />}
      />

      <Route
        path={ROUTE_CIRCLE_CONFIGURATOR}
        element={<CircleConfigurator title={CIRCLE_CONFIGURATOR_TITLE} />}
      />

      <Route path={ROUTE_ADD_NEW_CIRCLE} element={<AddNewCircle />} />

      <Route
        path={ROUTE_ADD_NEW_CIRCLE_CIRCLE_CONFIGURATOR}
        element={<AddNewCircleConfigurator />}
      />

      <Route
        path={ROUTE_ADD_NEW_CIRCLE_RESOURCES_CONFIGURATOR}
        element={<AddNewCircleResourcesConfigurator />}
      />
      <Route
        path={ROUTE_ADD_NEW_CIRCLE_FORM_CONFIGURATOR}
        element={<AddNewCircleFormConfigurator />}
      />
      <Route
        path={ROUTE_ADD_NEW_CIRCLE_SYMPTOMS_CONFIGURATOR}
        element={<AddNewCircleSymptomsConfigurator />}
      />
      <Route
        path={ROUTE_EDIT_ACTIVE_CIRCLE_ADD_NEW_CIRCLE}
        element={<EditExistingActiveCircle />}
      />
      <Route
        path={ROUTE_EDIT_ACTIVE_CIRCLE_CIRCLE_CONFIGURATOR}
        element={<EditActiveCircleConfigurator />}
      />
      <Route
        path={ROUTE_EDIT_ACTIVE_CIRCLE_SYMPTOMS_CONFIGURATOR}
        element={<EditCircleSymptomsConfigurator />}
      />

      {loggedUserService.userCan(Permission.configActivePlans) && (
        <>
          <Route
            path={ROUTE_CITY_HALL_CONFIG}
            element={
              <CityHallConfig title={CITY_HALL_CONFIG_TITLE} subtitle={CITY_HALL_CONFIG_TITLE} />
            }
          />
          <Route
            path={ROUTE_SPORTCENTRE_CONFIG}
            element={
              <ActivePlanConfig
                title={SPORT_CENTRE_CONFIG_TITLE}
                subtitle={SPORT_CENTRE_CONFIG_TITLE}
              />
            }
          />
        </>
      )}
      <Route path={ROUTE_LEGAL_ADVICE} element={<LegalAdvice />} />

      <Route path="*" element={<Navigate to={ROUTE_CIRCLES} />} />
    </>
  )

  const circleRouts = (
    <>
      <Route path={ROUTE_USERS} element={<Participants title={USERS_TITLE} />} />
      <Route
        path={ROUTE_USERS_CREATE}
        element={<EditParticipant title={CREATE_PARTICIPANT_TITLE} />}
      />
      <Route
        path={ROUTE_USER_PROFILE_EDIT}
        element={<UserProfileEdit title={USER_PROFILE_TITLE} />}
      />
      <Route path={ROUTE_USER_PROFILE} element={<UserProfile title={USER_PROFILE_TITLE} />} />
      <Route path={ROUTE_DASHBOARD} element={<Dashboard title={DASHBOARD_TITLE} />} />

      <Route path={ROUTE_MESSENGER} element={<Messenger title={MESSENGER_TITLE} />} />
      <Route path={ROUTE_CALENDAR} element={<Calendar title={CALENDAR_TITLE} />} />

      <Route path={ROUTE_CALENDAR_FORM_EDIT} element={<CalendarForm title={CALENDAR_TITLE} />} />

      {loggedUserService.userCan(Permission.userForms) && (
        <Route
          path={ROUTE_PATIENT_FORMS_VIEW}
          element={<PatientFormView title={PATIENT_FORM_VIEW_TITLE} />}
        />
      )}
      {loggedUserService.userCan(Permission.userForms) && (
        <Route
          path={ROUTE_PATIENT_FORMS}
          element={<PatientFormsTable title={PATIENTS_FORMS_TITLE} />}
        />
      )}
      <Route path={ROUTE_RESOURCES} element={<Resources title={RESOURCES_TITLE} />} />
      <Route path={ROUTE_RESOURCES_ID} element={<Resources title={RESOURCES_TITLE} />} />
      <Route
        path={ROUTE_RESOURCES_SHARED_WITH_ME}
        element={<Resources title={RESOURCES_SHARED_WITH_ME_TITLE} />}
      />
      <Route
        path={ROUTE_RESOURCES_SHARED_WITH_ME_ID}
        element={<Resources title={RESOURCES_SHARED_WITH_ME_TITLE} />}
      />
      <Route path={ROUTE_RESOURCES_FORM_EDIT} element={<ResourcesForm title={RESOURCES_TITLE} />} />

      <Route path={ROUTE_PATIENT_DATA} element={<PatientData title={PATIENT_DATA_TITLE} />} />

      <Route path={ROUTE_PATIENT_SYMPTOMS} element={<Symptoms title={SYMPTOMS_TITLE} />} />

      <Route
        path={ROUTE_PATIENT_SYMPTOMS_FORM_EDIT}
        element={<SymptomsForm title={SYMPTOMS_TITLE} />}
      />
      <Route path={ROUTE_TREATMENTS} element={<Treatments title={TREATMENTS_TITLE} />} />

      {loggedUserService.userCan(Permission.seeVaccinationCalendar) && (
        <Route
          path={ROUTE_VACCINATION_SCHEDULE}
          element={<Vacusan title={VACCINATION_SCHEDULE_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.seeEvolutionControl) && (
        <Route
          path={ROUTE_EVOLUTION_CONTROL}
          element={<EvolutionControl title={EVOLUTION_CONTROL_TITLE} />}
        />
      )}
      <Route path={ROUTE_MEDICAL_DATA} element={<MedicalData title={MEDICAL_DATA_TITLE} />} />
      <Route path={ROUTE_LANDMARK} element={<Landmark title={LANDMARK_TITLE} />} />
      <Route path={ROUTE_LANDMARK_FORM_EDIT} element={<LandmarkForm title={LANDMARK_TITLE} />} />
      <Route path={ROUTE_DENTIST_MAP} element={<DentistMap title={DENTIST_MAP_TITLE} />} />
      <Route path={ROUTE_DENTISTS_LIST} element={<DentistList title={DENTIST_LIST_TITLE} />} />
      <Route
        path={ROUTE_GROWTH_MONITORING}
        element={<GrowthMonitoringList title={GROWTH_MONITORING_TITLE} />}
      />

      {loggedUserService.userCan(Permission.growthMonitoringActions) && (
        <Route
          path={ROUTE_GROWTH_MONITORING_FORM_EDIT}
          element={<GrowthMonitoringForm title={GROWTH_MONITORING_TITLE} />}
        />
      )}
      <Route path={ROUTE_FAMILIAR_DATA} element={<FamiliarData title={FAMILIAR_DATA_TITLE} />} />

      {loggedUserService.userCan(Permission.createFamiliarData) && (
        <Route
          path={ROUTE_FAMILIAR_DATA_FORM_EDIT}
          element={<FamiliarDataForm title={FAMILIAR_DATA_TITLE} />}
        />
      )}

      {loggedUserService.userCan(Permission.seeActivePlan) && (
        <Route
          path={ROUTE_ACTIVE_PLAN_DATA}
          element={
            <ActivePlan title={PATIENT_DATA_TITLE} circleSubtitle={ACTIVE_PLAN_DATA_TITLE} />
          }
        />
      )}

      {loggedUserService.userCan(Permission.seeWearable) && (
        <Route path={ROUTE_WEARABLES} element={<Wearables title={WEARABLES_TITLE} />} />
      )}

      {loggedUserService.userCan(Permission.linkWearable) && (
        <Route path={ROUTE_LINK_WEARABLE} element={<Link title={WEARABLES_TITLE} />} />
      )}
      <Route path={ROUTE_GOALS_FORM} element={<GoalsForm title={GOALS_TITLE} />} />
      <Route path={ROUTE_QUERY} element={<QueryView title={QUERY_TITLE} />} />

      <Route
        path={ROUTE_TEMPLATE_FORM_GENERATOR_EDIT}
        element={<FormTemplateGenerator refMain={props.refMain} />}
      />

      <Route
        path={ROUTE_TELECONSULTATION}
        element={<TeleConsultation title={TELECONSULTATION_TITLE} />}
      />

      <Route path="*" element={<Navigate to={ROUTE_CIRCLES} />} />
    </>
  )

  return (
    <Routes>
      {generalRouts}
      {selectedUserCircle ? circleRouts : false}
    </Routes>
  )
}
