import { Skeleton } from '@mui/material'

type MonitoredActivityCardTextProps = {
  isLoading: boolean
  children: React.ReactNode
}

export const MontioredActivityCardText: React.FC<MonitoredActivityCardTextProps> = (
  props: MonitoredActivityCardTextProps
) => {
  if (props.isLoading) return <Skeleton width={'100%'} height={10} />
  return props.children
}
