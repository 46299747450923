import React, { useEffect, useState } from 'react'
import { Modal, Box } from '@mui/material'
import style from './ModalGroupManagement.module.css'
import { ActivePlanService } from 'modules/active-plan/services/ActivePlanService'
import { Query } from 'common/api/Query'
import { useTranslation } from 'react-i18next'
import { GroupManagementCard } from './GroupManagementCard'

interface ModalGroupManagementProps {
  open: boolean
  onClose: () => void
  activePlanService: ActivePlanService
}

type Status = {
  type: 'active' | 'rejected' | 'pending' | 'closed'
  count: number
}

const ModalGroupManagement: React.FC<ModalGroupManagementProps> = ({
  open,
  onClose,
  activePlanService,
}) => {
  const [, setGroupStatus] = useState<Status[]>([])
  const CIRCLE_STATE_CARD_TEXT = 'groupsManagement'
  const { t } = useTranslation()

  useEffect(() => {
    const subscription = activePlanService
      .getFilteredList(new Query({ query: [] }))
      .subscribe((response) => {
        if (!response) return
        const statusCounts = {
          active: response.items.filter((item) => item.status === 'active').length,
          rejected: response.items.filter((item) => item.status === 'rejected').length,
          pending: response.items.filter((item) => item.status === 'pending').length,
          closed: response.items.filter((item) => item.status === 'closed').length,
        }

        const filteredStatusCounts: Status[] = Object.entries(statusCounts)
          .filter(([_, count]) => count > 0)
          .map(([type, count]) => ({
            type: type as 'active' | 'rejected' | 'pending' | 'closed',
            count,
          }))

        setGroupStatus(filteredStatusCounts)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [activePlanService])

  // const data = groupStatus.map((status) => status.count)
  // const backgroundColor = groupStatus.map((status) => backgroundColors[status.type])
  // const labels = groupStatus.map((status) => statusTypes[status.type])

  return (
    <Modal open={open} onClose={onClose} className={style.modalOverFlow}>
      <Box className={style.modalContent}>
        <GroupManagementCard open={open} onClose={onClose} title={t(CIRCLE_STATE_CARD_TEXT)} />
      </Box>
    </Modal>
  )
}

export default ModalGroupManagement
