import { Query } from 'common/api/Query'

export type ActivePlanCustomProps = {
  id: string
  userCircleID: string
  userProfessionalID: string
  professionalMessage: string
  status: string
  startDate: Date
  finishDate: Date
  reason: string
  observation: string
  protocol: string
  completeName: string
  dni: string
  cip: string
  centerName: string
  birthDate: Date
  groupName: string
  petitionDate: Date
  attendance: number
  intensity: number
  physicalCondition: number
}

export class ActivePlanCustom {
  private readonly _id: string
  private _userCircleID: string
  private _userProfessionalID: string
  private _professionalMessage: string
  private _status: string
  private _startDate: Date
  private _finishDate: Date
  private _reason: string
  private _observation: string
  private _protocol: string
  private _completeName: string
  private _dni: string
  private _cip: string
  private _centerName: string
  _birthDate: Date
  private _groupName: string
  _petitionDate: Date
  private _attendance: number
  private _intensity: number
  private _physicalCondition: number

  constructor(props: ActivePlanCustomProps) {
    this._id = props.id
    this._userCircleID = props.userCircleID
    this._userProfessionalID = props.userProfessionalID
    this._professionalMessage = props.professionalMessage
    this._status = props.status
    this._startDate = props.startDate
    this._finishDate = props.finishDate
    this._reason = props.reason
    this._observation = props.observation
    this._protocol = props.protocol
    this._completeName = props.completeName
    this._dni = props.dni
    this._cip = props.cip
    this._centerName = props.centerName
    this._birthDate = props.birthDate
    this._groupName = props.groupName
    this._petitionDate = props.petitionDate
    this._attendance = props.attendance
    this._intensity = props.intensity
    this._physicalCondition = props.physicalCondition
  }

  get id(): string {
    return this._id
  }

  get userCircleID(): string {
    return this._userCircleID
  }

  set userCircleID(value: string) {
    this._userCircleID = value
  }

  get status(): string {
    return this._status
  }

  set status(value: string) {
    this._status = value
  }

  get startDate(): Date {
    return new Date(this._startDate)
  }

  set startDate(value: Date) {
    this._startDate = value
  }

  get finishDate(): Date {
    return new Date(this._finishDate)
  }

  set finishDate(value: Date) {
    this._finishDate = value
  }

  get userProfessionalID(): string {
    return this._userProfessionalID
  }

  set userProfessionalID(value: string) {
    this._userProfessionalID = value
  }

  get professionalMessage(): string {
    return this._professionalMessage
  }

  set professionalMessage(value: string) {
    this._professionalMessage = value
  }

  get reason(): string {
    return this._reason
  }

  set reason(value: string) {
    this._reason = value
  }

  get observation(): string {
    return this._observation
  }

  set observation(value: string) {
    this._observation = value
  }

  get protocol(): string {
    return this._protocol
  }

  set protocol(value: string) {
    this._protocol = value
  }

  get completeName(): string {
    return this._completeName
  }

  get dni(): string {
    return this._dni
  }

  get cip(): string {
    return this._cip
  }

  get centerName(): string {
    return this._centerName
  }

  get birthDate(): Date {
    return this._birthDate
  }

  get groupName(): string {
    return this._groupName
  }

  get petitionDate(): Date {
    return this._petitionDate
  }

  get attendance(): number {
    return this._attendance
  }

  set attendance(value: number) {
    this._attendance = value
  }

  get intensity(): number {
    return this._intensity
  }

  set intensity(value: number) {
    this._intensity = value
  }

  get physicalCondition(): number {
    return this._physicalCondition
  }

  set physicalCondition(value: number) {
    this._physicalCondition = value
  }
}
export class ActivePlanCustomQuery extends Query<ActivePlanCustom> {
  id?: string
  userCircleID?: string
  userProfessionalID?: string
  professionalMessage?: string
  status?: string
  startDate?: Date
  finishDate?: Date
  reason?: string
  observation?: string
  protocol?: string
  completeName?: string
  dni?: string
  cip?: string
  centerName?: string
  birthDate?: Date
  groupName?: string
  petitionDate?: Date
  attendance?: number
  intensity?: number
  physicalCondition?: number
  customSearcher?: string
}
