import React, { useEffect, useState } from 'react'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { Box, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import genericStyle from 'common/utils/generic.module.css'
import style from '../generic/GenericTable.module.css'
import icoCreateVaccine from 'assets/resource_icons/ico_add.svg'
import { ROUTE_VACCINES, ROUTE_VACCINES_CREATE } from 'routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { emptyList, ItemList } from 'common/models/ItemList'
import { Vaccine, VaccinesQuery } from 'modules/vaccines/models/Vaccines'
import { AppTable, Field } from 'components/table'
import { Actions, Pager, Search, SearchValue } from 'components/table_type/types'
import deleteIcon from 'assets/table_icons/ico-eliminar.svg'
import { VaccinesService } from 'modules/vaccines/services/VaccinesService'
import { VACCINES_SERVICE_KEY } from 'modules/vaccines'
import { getVaccinesContainer } from 'container/vaccine-module'
import { Query, QueryParam, QueryParamN } from 'common/api/Query'
import editIcon from 'assets/table_icons/ico-edit.svg'
import { CustomModal } from 'components/modal/CustomModal'
import { finalize } from 'rxjs/operators'

const vaccinesContainer = getVaccinesContainer()

const vaccinesService = vaccinesContainer.get<VaccinesService>(VACCINES_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const createVaccine = () => navigate(`${ROUTE_VACCINES_CREATE}`)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentVaccine, setCurrentVaccine] = useState<Vaccine>()
  const [page, setPage] = useState<number>(0)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [vaccines, setVaccines] = useState<ItemList<Vaccine>>(emptyList<Vaccine>())
  const [pager, setPager] = useState<Pager>()
  const [vaccinesPerPage, setVaccinesPerPage] = useState<number>(10)
  const [searcher, setSearcher] = useState<SearchValue<VaccinesQuery>[]>([
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'tvac',
      label: t('TVAC'),
    },
  ])

  useEffect(() => {
    getData()
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: vaccinesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, vaccinesPerPage, searcher])

  const getData = () => {
    setIsLoading(true)
    vaccinesService
      .getFilteredList(
        new Query({
          query: [...searcherQuery(searcher)],
          pager: { limit: vaccinesPerPage, offset: page * vaccinesPerPage },
          sort: [{ field: 'tvac', desc: true }],
        })
      )
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        setCount(res.count)
        setVaccines(res)
      })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setVaccinesPerPage(10)
      return
    }
    setVaccinesPerPage(Number.parseInt(event.target.value))
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const editVaccine = (vaccine: Vaccine) => navigate(`${ROUTE_VACCINES}/${vaccine.id}`)

  const removeVaccine = (vaccine: Vaccine) => {
    setCurrentVaccine(vaccine)
    setOpenDeleteModal(true)
  }

  const fields: Field<Vaccine>[] = [
    {
      name: 'tvac',
      label: t('TVAC'),
    },
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'description',
      label: t('description'),
    },
  ]

  const actions: Actions<Vaccine> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editVaccine,
        icon: editIcon,
        label: 'edit',
      },
      {
        handler: removeVaccine,
        icon: deleteIcon,
        label: 'delete',
      },
    ],
  }

  const search: Search<VaccinesQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<VaccinesQuery>[]) => {
      setSearcher(svs)
    },
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteVaccine = () => {
    if (currentVaccine?.id) {
      vaccinesService
        .delete(currentVaccine.id)
        .pipe(finalize(() => getData()))
        .subscribe(() => {})
    }
    setOpenDeleteModal(false)
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box>
        <Box className={style.buttonContainer} style={{ marginBottom: '30px' }}>
          <AppButton
            theme={ButtonTheme.NewPrimary}
            type={'button'}
            label={t('addVaccine')}
            startIcon={icoCreateVaccine}
            handler={createVaccine}
          />
        </Box>
        <AppTable
          isLoading={isLoading}
          items={vaccines.items}
          rowKeyField="id"
          fields={fields}
          actions={actions}
          pager={pager}
          search={search}
        />
        <Modal open={openDeleteModal} className={style.eventModal} onClose={handleCloseDeleteModal}>
          <CustomModal
            handleClose={handleCloseDeleteModal}
            handleSave={handleDeleteVaccine}
            title={t('deleteVaccine')}
            warningText={t('irreversibleVaccineAction')}
          ></CustomModal>
        </Modal>
      </Box>
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<VaccinesQuery>[]
): QueryParam<VaccinesQuery>[] | QueryParamN<VaccinesQuery>[] =>
  svs
    .filter((sv) => sv.value)
    .map((sv) => ({ name: sv.name, value: sv.value?.toLowerCase() as string }))
