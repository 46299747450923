import { getUserContainer } from '../../container/user-module'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import React, { useContext, useEffect, useState } from 'react'
import { User, UserQuery } from '../../modules/users/models/User'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { BoolQueryParam, Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Pager, SearchValue } from '../../components/table_type/types'
import { ROUTE_SHARE_RESOURCES } from '../../routes/routes-constants'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'
import { v4 as uuidv4 } from 'uuid'
import { forkJoin, Observable } from 'rxjs'
import { RolesService } from '../../modules/users/services/RolesServices'
import { Roles } from '../../modules/users/enums/Roles'
import { getSharedWithContainer } from '../../container/shared-with-module'
import { SharedWithService } from '../../modules/sharedWith/services/SharedWithService'
import { SHARED_WITH_SERVICE_KEY } from '../../modules/sharedWith'
import { SharedWith } from '../../modules/sharedWith/models/SharedWith'
import {
  fromModel as shareFromModel,
  SharedWithDTO,
} from '../../modules/sharedWith/models/SharedWithDTO'
import { emptyFileDTO, FileDTO } from '../../modules/files/models/FileDTO'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { useGlobalContext } from '../../common/utils/GlobalRoleContext'
import { fromModel as fromModelFile } from '../../modules/files/models/FileDTO'
import { CustomModal } from '../../components/modal/CustomModal'
import { CheckboxAssignType } from '../../modules/forms/enums/CheckboxAssignType'
import { UserWithRelaters } from '../../modules/users/models/UserWithRelaters'
import { useNavigate } from 'react-router-dom'
import { EditorContext } from '../../common/utils/context/EditorContext'
import { Alert, Box, Modal } from '@mui/material'
import { AssignResourceSection } from './asign-resources-section/AssignResourceSection'
import { RowItem } from '../form-generator/asign-form-section/AssignFormSection'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const sharedWithService = getSharedWithContainer().get<SharedWithService>(SHARED_WITH_SERVICE_KEY)
const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

enum RolesToAssign {
  CIRCLES = 'allCircles',
  PATIENTS = 'allPatients',
  FAMILIARS = 'allFamiliars',
  CARERS = 'allCarers',
  EXTERN = 'allExtern',
}

type AssignedUser = {
  userID: string
  circleID: string | null
}

type RolesCheckbox = {
  label: RolesToAssign
  checked: boolean
}

type TableCirclesProps = {
  id?: string
  refMain?: React.RefObject<HTMLDivElement>
  parentID?: string | null
}

const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)
const shareContainer = getSharedWithContainer()
const shareService = shareContainer.get<SharedWithService>(SHARED_WITH_SERVICE_KEY)

export function Assign(props: TableCirclesProps) {
  const {
    handlerAssignedUser,
    handleCheckBoxSelected,
    recoveredCheckBoxRoles,
    recoveredCheckBox,
    preview,
    saving,
    handleSaveForm,
    recoveredSelected,
    currentCirclePathology,
    // resourceContext,
    // setResourceContext,
    shareWith,
    selected,
    checkBoxSelected,
  } = useContext(EditorContext)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  // const [resource-patientView, setResource] = useState<FileDTO>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [circles, setCircles] = useState<ItemList<RowItem>>(emptyList<RowItem>())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userItems, setUserItems] = useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [circlesPerPage, setCirclesPerPage] = useState<number>(999)
  const [pager, setPager] = useState<Pager>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'cip',
      label: t('CIP'),
    },
  ])
  const [circlesUsers, setCirclesUsers] = useState<Map<string, RowItem[]>>(new Map())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentOpenCircle, setCurrentOpenCircle] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkboxRoles, setCheckboxRoles] = useState<RolesCheckbox[]>(
    Object.values(RolesToAssign).map((f) => ({ label: f, checked: false }))
  )
  const [assignedUsers, setAssignedUsers] = useState<AssignedUser[]>([])
  const [message, setMessage] = useState<string>('')
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const [allPatients, setAllPatients] = useState<User[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allFamiliars, setAllFamiliars] = useState<Map<string, string[]>>(
    new Map<string, string[]>()
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allCuidador, setAllCuidadores] = useState<Map<string, string[]>>(
    new Map<string, string[]>()
  )
  const [parentFile, setParentFile] = useState<FileDTO>(emptyFileDTO(loggedUser?.id, '', true))
  const [openModalPediatric, setOpenModalPediatric] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allExtern, setAllExtern] = useState<Map<string, string[]>>(new Map<string, string[]>())
  const [familiars, setFamiliars] = useState<UserWithRelaters[]>([])
  const [resourceContext, setResourceContext] = useState<FileDTO>(
    emptyFileDTO(loggedUser?.id, '', true)
  )
  const { role } = useGlobalContext()

  useEffect(() => {
    if (props.parentID === '' || props.parentID === null || !props.parentID) return
    fileService.getByID(props.parentID).subscribe((res) => {
      if (!res) return
      setParentFile(res)
    })
  }, [props.parentID])

  useEffect(() => {
    userService.getUsersByDoctor(loggedUser?.id ?? '').subscribe((res) => {
      setAllPatients(res)
    })
  }, [])

  useEffect(() => {
    if (allPatients.length > 0) {
      const fams: Map<string, string[]> = new Map<string, string[]>()
      allPatients.forEach((patient, i) => {
        userService.getRelated(patient.id).subscribe((res) => {
          const f = res?.relatedUsers.filter((related) => related.kind === 3)
          if (f && f?.length > 0) {
            fams.set(
              patient.id,
              f.map((f) => f.id)
            )
          }
          if (i === allPatients.length - 1) {
            setAllCuidadores(fams)
          }
        })
      })
    }
  }, [allPatients])

  useEffect(() => {
    if (allPatients.length > 0) {
      const fams: Map<string, string[]> = new Map<string, string[]>()
      allPatients.forEach((patient, i) => {
        userService.getRelated(patient.id).subscribe((res) => {
          const f = res?.relatedUsers.filter((related) => related.kind === 7)
          if (f && f?.length > 0) {
            fams.set(
              patient.id,
              f.map((f) => f.id)
            )
          }
          if (i === allPatients.length - 1) {
            setAllExtern(fams)
          }
        })
      })
    }
  }, [allPatients])

  useEffect(() => {
    if (allPatients.length > 0) {
      const fams: Map<string, string[]> = new Map<string, string[]>()
      allPatients.forEach((patient, i) => {
        userService.getRelated(patient.id).subscribe((res) => {
          const f = res?.relatedUsers.filter((related) => related.kind === 6)
          if (f && f?.length > 0) {
            fams.set(
              patient.id,
              f.map((f) => f.id)
            )
          }
          if (i === allPatients.length - 1) {
            setAllFamiliars(fams)
          }
        })
      })
    }
  }, [allPatients])

  useEffect(() => {
    roleService
      .getAll(
        new Query({
          sort: [{ field: 'name' }],
          pager: { offset: 0, limit: -1 },
        })
      )
      .subscribe((res) => {
        const tmpMap = new Map<string, string>()
        res.forEach((r) => tmpMap.set(r.id, r.name))
        setRoles(tmpMap)
      })

    if (!props.id) {
      return
    }

    sharedWithService
      .getFilteredItems(
        new Query({
          query: [new QueryParam('fileId', props.id)],
        })
      )
      .subscribe((res) => {
        setAssignedUsers(res.map((u) => ({ userID: u.userId, circleID: u.userCircleId })))
      })

    fileService.getByID(props.id).subscribe((res) => res && setResourceContext(fromModelFile(res)))

    setInterval(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    const ids = loggedUser?.related.map((r) => r.id) ?? []
    ids.push(loggedUser?.id ?? '')
    userService
      .getFilteredList(
        new Query({
          pager: { offset: page * circlesPerPage, limit: circlesPerPage },
          query: [
            {
              name: 'isCircle',
              value: new BoolQueryParam(true),
            },
            {
              name: 'ids',
              value: ids,
            },
            ...searcherQuery(searcher),
          ],
        })
      )
      .subscribe((cs) => {
        setCount(cs.count)
        const newList = emptyList<RowItem>()
        newList.items = cs.items.map((u) => ({
          uuid: uuidv4(),
          id: u.id,
          name: u.name,
          birthDate: u.birthDate,
          cip: u.cip,
          dni: u.dni,
          circleID: null,
          roles: [role[0].name],
          userCircleID: '',
        }))
        newList.count = cs.count
        setUserItems(newList)
        setCircles(newList)
      })
  }, [pager, searcher])

  useEffect(() => {
    Promise.all(
      allPatients.map(async (patient) => {
        return await userService.getUsersWithRelaters(patient.id).toPromise()
      })
    ).then((result) => {
      const auxArray: (UserWithRelaters | undefined)[] = result.filter((r) => r !== null)
      auxArray && setFamiliars(auxArray as UserWithRelaters[])
    })
  }, [allPatients, loggedUser])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: circlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, circlesPerPage])

  useEffect(() => {
    setMessage('')
  }, [assignedUsers, checkboxRoles])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const goBack = () => navigate(ROUTE_SHARE_RESOURCES)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setCirclesPerPage(10)
      return
    }
    setCirclesPerPage(Number.parseInt(event.target.value))
  }
  const handleCloseModalPediatric = () => {
    setOpenModalPediatric(false)
  }

  const getUsers = (ids: string[]): Observable<User[]> =>
    forkJoin(ids.map((id) => userService.getByID(id))) as unknown as Observable<User[]>

  useEffect(() => {
    !currentOpenCircle && setUserItems(circles)

    if (circlesUsers.has(currentOpenCircle)) {
      const index = circles.items.findIndex((c) => c.id === currentOpenCircle)
      if (index > -1) {
        const circlesCopy = emptyList<RowItem>()
        circlesCopy.items = circles.items
          .slice(0, index + 1)
          .concat(circlesUsers.get(currentOpenCircle) ?? [], circles.items.slice(index + 1))
        circlesCopy.count = circles.count
        setUserItems(circlesCopy)
      }
      return
    }

    userService.getRelated(currentOpenCircle).subscribe((res) => {
      if (!res) {
        return
      }
      let usersInCircle: RowItem[] = []
      setIsLoading(true)
      const circlesCopy = emptyList<RowItem>()
      const index = circles.items.findIndex((c) => c.id === currentOpenCircle)
      if (index > -1) {
        usersInCircle.push({
          uuid: uuidv4(),
          id: currentOpenCircle,
          name: circles.items[index].name,
          birthDate: circles.items[index].birthDate,
          cip: circles.items[index].cip,
          dni: circles.items[index].dni,
          circleID: currentOpenCircle,
          roles: circles.items[index].roles,
          userCircleID: '',
        })
      } else {
        circlesCopy.items = circles.items
      }
      circlesCopy.count = circles.count
      getUsers(res.relatedUsers.map((r) => r.id)).subscribe((users) => {
        users
          .filter((u) => u.roles.every((r) => roles.get(r) !== Roles.Professional))
          .forEach((u) => {
            usersInCircle.push({
              uuid: uuidv4(),
              id: u.id,
              name: u.name,
              birthDate: u.birthDate,
              cip: u.cip,
              dni: u.dni,
              circleID: currentOpenCircle,
              roles: u.roles,
              userCircleID: '',
            })
          })
        usersInCircle = usersInCircle.sort((a, b) => a.name.localeCompare(b.name))
        circlesCopy.items = circles.items
          .slice(0, index + 1)
          .concat(usersInCircle, circles.items.slice(index + 1))

        setIsLoading(false)
        const newMap = new Map(circlesUsers)
        newMap.set(currentOpenCircle, usersInCircle)
        setCirclesUsers(newMap)
        setUserItems(circlesCopy)
      })
    })
  }, [currentOpenCircle])

  const saveForm = () => {
    if (!resourceContext) return
    const checkBoxesSelectedString = checkBoxSelected.join(',')
    const newResource: FileDTO = Object.assign(
      { ...resourceContext },
      {
        id: uuidv4(),
        creator: loggedUser?.id,
        sharedWith: [],
        checkBoxesSelected: checkBoxesSelectedString,
        clonedFrom: resourceContext.id,
      }
    )

    fileService.cloneFile(newResource).subscribe((newFile) => {
      if (newFile) {
        if (selected.length !== 0 && newResource.checkBoxesSelected === '') {
          const shareArr: SharedWithDTO[] = []
          // eslint-disable-next-line array-callback-return
          selected.map((s) => {
            const share = new SharedWith({
              userId: s.id ?? '',
              fileId: newFile.id ?? '',
              userCircleId: s.userCircleID ?? '',
              creatorId: newFile.creator,
            })

            shareArr.push(shareFromModel(share))
          })

          shareService.createNewIndividualShare(shareArr).subscribe((res) => {
            goBack()
          })
        } else {
          goBack()
        }
      }
    })
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <Box mb={3} display="flex" justifyContent="space-between">
        <AppButton
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('back')}
          handler={() => goBack()}
        />
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('assign')}
          handler={() => {
            const isPediatric = familiars.some((f) => f.isPediatrics)
            const checkBoxesSelected = checkBoxSelected.some(
              (c) => c === CheckboxAssignType.AllMyPatients
            )
            if (isPediatric && checkBoxesSelected) {
              setOpenModalPediatric(true)
              return
            }
            saveForm()
          }}
        />
      </Box>
      {message && (
        <Box mb={3}>
          <Alert severity="success" key="message" id="message">
            {t(message)}
          </Alert>
        </Box>
      )}
      <Box mb={3}>
        <AssignResourceSection
          allPatients={allPatients}
          handlerAssignedUser={handlerAssignedUser}
          handleSaveForm={handleSaveForm}
          saving={saving}
          id={resourceContext?.id}
          mainRef={props.refMain}
          shareWith={shareWith}
          currentCirclePathology={currentCirclePathology}
          recoveredSelected={recoveredSelected}
          selected={selected}
          preview={preview}
          handleCheckBoxSelected={handleCheckBoxSelected}
          recoveredCheckBoxRoles={recoveredCheckBoxRoles}
          checkboxRecover={recoveredCheckBox}
          resource={resourceContext}
          parentFile={parentFile}
          propsParentID={props.parentID}
        />
      </Box>
      {openModalPediatric && (
        <Modal open={openModalPediatric} onClose={handleCloseModalPediatric}>
          <>
            <CustomModal
              handleClose={handleCloseModalPediatric}
              handleSave={saveForm}
              title={t('modalPediatric')}
              warningText={t('irreversibleModalPediatricAction')}
            />
          </>
        </Modal>
      )}
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<UserQuery>[]
): QueryParam<UserQuery>[] | QueryParamN<UserQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
