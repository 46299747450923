import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'

type DownloadAlertProps = {
  type: 'success' | 'error'
  open: boolean
}

const DownloadAlert: React.FC<DownloadAlertProps> = ({ type, open }) => {
  const { t } = useTranslation()

  if (!open) return null

  return (
    <Alert
      severity={type}
      sx={{
        width: 'fit-content',
        marginLeft: 'auto',
        marginBottom: 1,
      }}
    >
      {type === 'success' ? t('fileDownloadSuccess') : t('fileDownloadError')}
    </Alert>
  )
}

export default DownloadAlert
