import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardComponent, GenericChartDashboardComponent } from './EpilepsyCard'
import { UserForm, UserFormQuery } from '../../../../modules/forms/models/UserForm'
import { getFormContainer } from 'container/form-module'
import { UserFormService } from 'modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from 'modules/forms'
import { Query, QueryParam } from 'common/api/Query'
import { Alert, Skeleton } from '@mui/material'

type PsychologicalHealthStateProps = {
  userForms: UserForm[]
}

export const PsychologicalHealthState: React.FC<PsychologicalHealthStateProps> = ({
  userForms,
}) => {
  const { t } = useTranslation()
  const [depressionCounts, setDepressionCounts] = useState<number[]>([0, 0, 0, 0])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)

  const memoizedUserForms = useMemo(() => userForms, [JSON.stringify(userForms)])

  useEffect(() => {
    if (userForms.length === 0) return

    const fetchData = async () => {
      setIsLoading(true)
      setError(null)

      const depressionGroups = [0, 0, 0, 0] // [mínima, leve, moderada, grave]

      try {
        const responses = await Promise.all(
          userForms.map(
            async (form) =>
              await userFormService
                .getPromsBeckItems(
                  new Query<UserFormQuery>({
                    query: [new QueryParam('ids', [form.id ?? ''])],
                  })
                )
                .toPromise()
                .then((res) => ({ res, form }))
          )
        )

        responses.forEach(({ res, form }) => {
          if (!res) return

          res.forEach((values) => {
            const totalScore = form.totalScore ?? 0
            if (totalScore <= 13) {
              depressionGroups[0]++
            } else if (totalScore <= 19) {
              depressionGroups[1]++
            } else if (totalScore <= 28) {
              depressionGroups[2]++
            } else {
              depressionGroups[3]++
            }
          })
        })

        setDepressionCounts(depressionGroups)
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred')
        console.error('Error fetching data:', err)
      } finally {
        setIsLoading(false)
      }
    }

    void fetchData()
  }, [memoizedUserForms, userFormService])

  const datasets = useMemo(
    () => [
      {
        label: t('numberOfPatients'),
        data: depressionCounts,
        backgroundColor: ['#e48f3f', '#2663a1', '#b6b5b5', '#eedc52'],
      },
    ],
    [depressionCounts, t]
  )

  if (error) {
    return (
      <CardComponent title={t('psychologicalHealthState')}>
        <Alert severity="error">{error}</Alert>
      </CardComponent>
    )
  }

  return (
    <CardComponent title={t('psychologicalHealthState')}>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={200} />
      ) : (
        <GenericChartDashboardComponent
          datasets={datasets}
          labels={[
            t('minimalDepression'),
            t('mildDepression'),
            t('moderateDepression'),
            t('severeDepression'),
          ]}
          showXAxis={false}
          showYAxis={false}
          title=""
          typeChart="pie"
          legendPosition="right"
        />
      )}
    </CardComponent>
  )
}
