/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { Query, QueryParam } from '../../common/api/Query'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Card, Typography, Table, TableFooter, TableRow } from '@mui/material'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { File, FileQuery } from 'modules/files/models/File'
import { downloadFile, viewImageFile, viewImageFileBase64 } from 'common/utils/file'
import style from './Search.module.css'
import { Article } from '../../modules/content/models/Article'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { VisualizationService } from '../../modules/content/services/VisualizationService'
import { VISUALIZATIONS_SERVICE_KEY } from '../../modules/content/container'
import { Pager } from '../../components/table_type/types'
import { getContentContainer } from '../../container/content-module'
import esferaLogo from '../../assets/brand_logo/smsBlue.svg'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '../../components/table_AP/Pagination'

const contentContainer = getContentContainer()
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const visualizationsService = contentContainer.get<VisualizationService>(VISUALIZATIONS_SERVICE_KEY)

type TutorialProps = {
  search?: string
  mostViewed?: boolean
  categoryList: string[]
  circles: number[]
}

const imageList = new Map()

export function TableGrid(props: TutorialProps) {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tutorials, setTutorials] = useState<ItemList<File>>(emptyList<File>())
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [articlesInit, setInitArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [articlesPerPage, setArticlesPerPage] = useState<number>(4)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
  }, [props.categoryList])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
      rowsPerPageOption: [4, 8, 16],
    })
  }, [page, count, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setArticlesPerPage(4)
      return
    }
    setArticlesPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setIsLoading(true)
  }, [page, count, articlesPerPage])

  useEffect(() => {
    const articlesQuery = [
      new QueryParam<Article>('type', 'tutorial'),
      new QueryParam<Article>('titleCoincidences', props.search ? props.search : ''),
    ]
    if (props.categoryList.length === 1 && !props.mostViewed) {
      articlesQuery.push(new QueryParam<Article>('circleID', props.categoryList[0]))
    } else if (props.categoryList.length > 1 && !props.mostViewed) {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', props.categoryList))
    } else if (props.categoryList.length === 1 && props.mostViewed) {
      articlesQuery.push(new QueryParam<Article>('circleID', props.categoryList[0]))
    } else {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', props.categoryList))
    }
    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          articleList.items.push(articleItem)
          articleList.count++

          if (props.mostViewed) {
            new Promise((resolve) => {
              visualizationsService
                .getVisualizationsByArticleID(articleItem.id)
                .subscribe((response) => {
                  if (response && response.count >= 0) {
                    articleItem.visualizations = response.count
                  }
                  if (i === 0) {
                    resolve(true)
                  }
                })
            }).then((resolve) => {
              articleList.items = articleList.items.sort(
                (a, b) => b.visualizations - a.visualizations
              )
              setArticles(articleList)
              setInitArticles(articleList)
              setCount(res.count)
            })
          }

          if (articleItem.slug !== 'slug') {
            // Download the image if exists
            filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
              if (res) {
                const image = await viewImageFileBase64(res)
                imageList.set(articleItem.slug, image)
              }
            })
          }
        }

        if (!props.mostViewed) {
          setArticles(articleList)
          setInitArticles(articleList)
          setCount(res.count)
        }
      })
  }, [props.search, props.categoryList, isLoading])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    const articlesQuery = [new QueryParam<Article>('type', 'tutorial')]
    if (props.categoryList.length === 1) {
      articlesQuery.push(new QueryParam<Article>('circleID', props.categoryList[0]))
    } else if (props.categoryList.length > 1) {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', props.categoryList))
    } else {
      if (props.circles.length === 1) {
        articlesQuery.push(new QueryParam<Article>('circleID', props.circles[0]))
      } else if (props.circles.length > 1) {
        articlesQuery.push(new QueryParam<Article>('circlesIDs', props.categoryList))
      } else return
    }
    articlesService
      .getFilteredList(
        new Query({
          query: articlesQuery,
          sort: [{ field: 'title' }],
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        const articleList = emptyList<Article>()

        articleList.count = 0
        for (let i = res.items.length - 1; i >= 0; i--) {
          const articleItem = res.items[i]
          if (props.search !== undefined)
            if (articleItem.title.toLowerCase().includes(props.search.toLowerCase())) {
              articleList.items.push(articleItem)
              articleList.count++
            }

          if (props.mostViewed) {
            new Promise((resolve) => {
              visualizationsService
                .getVisualizationsByArticleID(articleItem.id)
                .subscribe((response) => {
                  if (response && response.count >= 0) {
                    articleItem.visualizations = response.count
                  }
                  if (i === 0) {
                    resolve(true)
                  }
                })
            }).then((resolve) => {
              articleList.items = articleList.items.sort(
                (a, b) => b.visualizations - a.visualizations
              )
              setArticles(articleList)
              setInitArticles(articleList)
              setCount(res.count)
            })
          }

          if (articleItem.slug !== 'slug') {
            // Download the image if exists
            filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
              if (res) {
                const image = await viewImageFileBase64(res)
                imageList.set(articleItem.slug, image)
              }
            })
          }
        }
        if (!props.mostViewed) {
          setArticles(articleList)
          setInitArticles(articleList)
          setCount(res.count)
        }
      })
  }, [])

  const handleSee = (event: any, article: Article) => {
    if (
      !event.target.className.includes('like_button') &&
      !event.target.className.includes('comment_button')
    ) {
      navigate(`${ROUTE_ARTICLES}/${article.id}`)
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {/*  eslint-disable-next-line array-callback-return */}
        {articles.items.map((item, index) => {
          let articleImage = null
          if (item.slug !== 'slug') {
            articleImage = imageList.get(item.slug)
          }
          // eslint-disable-next-line
          if ((props.mostViewed && index < 4) || !props.mostViewed) {
            return (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  className={style.card}
                  sx={{ borderRadius: 5, borderColor: '#68b3e0' }}
                  variant="outlined"
                  onClick={(event) => handleSee(event, item)}
                >
                  <div className={style.imageContainer}>
                    <img
                      className={style.thumbnail}
                      src={!articleImage ? esferaLogo : `data:image/jpeg;base64,${articleImage}`}
                    />
                    <div>
                      <Typography variant="h5" color="silver" className={style.title}>
                        {t('tutorial')}
                      </Typography>
                      <Typography variant="h6" color="textPrimary" className={style.title}>
                        {item.title}
                      </Typography>
                    </div>
                  </div>
                </Card>
              </Grid>
            )
          }
        })}
      </Grid>
      {!props.mostViewed && (
        <Table>
          <TableFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TableRow sx={{ borderBottom: 0 }}>{pager && <Pagination {...pager} />}</TableRow>
          </TableFooter>
        </Table>
      )}
    </Box>
  )
}
