import { Box, Tooltip, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTE_PATIENT_FORMS } from 'routes/routes-constants'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from 'modules/forms'
import { UserFormService } from 'modules/forms/services/UserFormService'
import { getFormContainer } from 'container/form-module'
import { FormService } from 'modules/forms/services/FormService'
import { getUserContainer } from 'container/user-module'
import { Query, QueryParam } from 'common/api/Query'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from 'modules/users'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { UserForm, UserFormQuery } from 'modules/forms/models/UserForm'
import genericStyle from 'common/utils/generic.module.css'
import { emptyList, ItemList } from 'common/models/ItemList'
import { ReadingStatus } from 'modules/forms/enums/ReadingStatus'
import { Form } from 'modules/forms/models/Form'
import { AppTable, Field } from 'components/table'
import { Actions, Pager, Search, SearchValue } from 'components/table_type/types'
import styles from './Table.module.css'
import seeIcon from 'assets/table_icons/ico-ver.svg'
import editIcon from 'assets/table_icons/ico-edit.svg'
import { reduceString } from 'common/utils/strings'
import seeIconResponsive from 'assets/resource_icons/ico-eye.svg'
import editIconResponsive from 'assets/resource_icons/ico-editar.svg'
import { Roles } from 'modules/users/enums/Roles'
import { RolesService } from 'modules/users/services/RolesServices'
import patient from 'assets/role_icons/ico-rol-paciente.svg'
import patientGreen from 'assets/role_icons/icon-rol-pacienteGreen.svg'
import familyGreen from 'assets/role_icons/ico-rol-familiarVerde.svg'
import familyRed from 'assets/role_icons/ico-rol-familiarRojo.svg'
import professionalSMS from 'assets/role_icons/ico-rol-profesional.svg'
import externProfessional from 'assets/role_icons/ico-rol-externo.svg'
import externalProfessionalGreen from 'assets/role_icons/icon-rol-profesionalExterno.svg'
import career from 'assets/role_icons/ico-rol-cuidador.svg'
import careerGreen from 'assets/role_icons/icon-rol-cuidadorGreen.svg'
import { Chip } from '@mui/material'
import { User } from 'modules/users/models/User'
import { useLocation, useNavigate } from 'react-router-dom'
import { addCero } from 'common/utils/strings'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const formService = getFormContainer().get<FormService>(FORM_SERVICE_KEY)
const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const roleService = getUserContainer().get<RolesService>(ROLES_SERVICE_KEY)

type RowItem = {
  id: string
  title: string
  assigned: Date
  description?: string
  status: ReadingStatus
  formUserID: string
  response: Date
  user: string
  role: string
  specialty?: string
  pathology?: string[]
}

export function Table(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const loggedUser = loggedUserService.get()
  const isDoctor = loggedUser?.roles.includes(Roles.Professional)
  const isExtern = loggedUser?.roles.includes(Roles.ProfessionalExtern)
  const { selectedUserCircle } = useCircleConfiguration()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userForms, setUserForms] = useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [filteredUserForms, setFilteredUserForms] =
    useState<ItemList<RowItem>>(emptyList<RowItem>())
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [roles, setRoles] = useState<Map<string, string>>(new Map())
  const imgRef = useRef<HTMLImageElement>(null)
  const [searcher, setSearcher] = useState<SearchValue<UserFormQuery>[]>([
    {
      name: 'allFields',
      label: t('search') + '...',
      value: '',
    },
  ])
  const location = useLocation()
  const isXS = useMediaQuery('(max-width: 599px)')

  const loadData = async () => {
    setIsLoading(true)

    if (!loggedUser || !selectedUserCircle) {
      return
    }

    try {
      const rolesResponse = await roleService
        .getAll(
          new Query({
            sort: [{ field: 'name' }],
            pager: { offset: 0, limit: -1 },
          })
        )
        .toPromise()

      const rolesMap = new Map<string, string>()
      rolesResponse.forEach((role) => rolesMap.set(role.id, role.name))
      setRoles(rolesMap)

      const auxList = emptyList<RowItem>()
      let query: QueryParam<UserFormQuery>[]

      if (isExtern) {
        query = [
          new QueryParam<UserFormQuery>('userID', loggedUser?.id),
          new QueryParam<UserFormQuery>('userCircleID', selectedUserCircle.id),
          new QueryParam<UserFormQuery>('external', 'true'),
        ]
      } else if (isDoctor) {
        query = [
          new QueryParam<UserFormQuery>('userCircleID', selectedUserCircle.id),
          new QueryParam<UserFormQuery>('readingStatus', ReadingStatus.Completed),
        ]
      } else {
        query = [
          new QueryParam<UserFormQuery>('userID', loggedUser.id),
          new QueryParam<UserFormQuery>('userCircleID', selectedUserCircle.id),
        ]
      }

      const ufl = await userFormService
        .getFilteredListWithCustomOrder(
          new Query({
            pager: { offset: page * rowsPerPage, limit: rowsPerPage },
            query,
            sort: [{ field: 'assigned', desc: true }],
          })
        )
        .toPromise()

      const userforms: UserForm[] = []
      const formPromises: Promise<Form | undefined>[] = []
      const userPromises: Promise<User | undefined>[] = []

      ufl.items.forEach((uf) => {
        userforms.push(uf)
        formPromises.push(formService.getByID(uf.formID || '').toPromise())
        userPromises.push(userService.getByID(uf.userID).toPromise())
      })

      const formResults = await Promise.all(formPromises)
      const userResults = await Promise.all(userPromises)

      formResults.forEach((form, index) => {
        const uf = userforms[index]
        const user = userResults[index]

        if (!form || !uf || !user) {
          return
        }

        const name = user.name || ''
        let role
        const rolesArray = [...roles.entries()]

        if (uf.circleID === user?.id) {
          const key = rolesArray.find(([_, value]) => value === Roles.Patient)
          role = key ? key[0] : ''
        } else {
          role = user.roles[0] || ''
        }
        const response = uf.userFormValues[uf.userFormValues.length - 1]?.date || ''

        const item: RowItem = {
          id: form.id ?? '',
          title: form.title,
          description: form.description,
          assigned: form.created,
          status: uf.readingStatus,
          formUserID: uf.id ?? '',
          response,
          user: name,
          role,
          pathology: form.circles.split(','),
          specialty: form.specialities,
        }

        auxList.items.push(item)
      })

      setUserForms(auxList)
      setCount(ufl.count)
    } catch (error) {
      // Manejar el error de manera adecuada
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if ((location as any).state?.update) {
      loadData().then()
    }
  }, [location])

  useEffect(() => {
    if (!isLoading) return
    loadData().then()
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, rowsPerPage, count, searcher])

  useEffect(() => {
    const aux: ItemList<RowItem> = emptyList<RowItem>()

    if (searcher[0].value) {
      if (userForms) {
        userForms.items.forEach((item) => {
          const searcherString = searcher[0].value ?? '---@#'

          if (
            item.title?.toLowerCase().includes(searcherString.toLocaleLowerCase()) ||
            item.user?.toLowerCase().includes(searcherString.toLocaleLowerCase()) ||
            item.pathology?.join(';').toLowerCase().includes(searcherString.toLocaleLowerCase())
          ) {
            aux.items.push(item)
          }
        })
      }
      setFilteredUserForms(aux)
    }
    // en caso de borrar el filtro vacio el array filtrado.
    else {
      setFilteredUserForms(emptyList<RowItem>())
    }
  }, [searcher])

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  const viewPatientForm = (r: RowItem) =>
    navigate(`${ROUTE_PATIENT_FORMS}/${r.formUserID}`, { state: { edit: false } })

  const editPatientForm = (r: RowItem) => {
    navigate(`${ROUTE_PATIENT_FORMS}/${r.formUserID}`, {
      state: { edit: true, userForm: r },
    })
  }

  const handlePaginationChange = (e: unknown, value: number) => setPage(value)

  const isCompleted = (r: RowItem): boolean => {
    return r.status === ReadingStatus.Completed
  }

  const getUserRole = (r: RowItem): string => roles.get(r.role) ?? ''

  const search: Search<UserFormQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserFormQuery>[]) => {
      // setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        date.setHours(1, 0, 0, 0)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const fields: Field<RowItem>[] = [
    {
      name: 'title',
      label: t('title'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'description',
      label: t('description'),
      renderFunc: (f, i) => reduceString(i.description ? i.description : '', 60),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'assigned',
      label: t('date'),
      renderFunc: (f, i) => {
        if (i.status === ReadingStatus.Pending) {
          return ''
        }
        return i.response ? new Date(i.response).toLocaleDateString('es-ES') : ''
      },
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(ReadingStatus[i.status].toLowerCase()),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatusText : styles.completedStatusText,
    },
  ]

  const goEditView = (value: RowItem) => {
    return value.status === ReadingStatus.Completed
      ? viewPatientForm(value)
      : editPatientForm(value)
  }

  const getTableMobileElemnt = (i: RowItem) => {
    return (
      <Box className={styles.containerTableMobile} onClick={() => goEditView(i)}>
        <Box className={styles.containerTitleMobile}>
          <Box className={styles.title}>{i.title}</Box>
          <Box>
            {addCero(new Date(i.assigned).getDate()) +
              '/' +
              addCero(new Date(i.assigned).getMonth()) +
              '/' +
              new Date(i.assigned).getFullYear()}
          </Box>
        </Box>
        <Box className={styles.containerColumn}>
          <Box className={styles.containerDescription}>{t('description')}</Box>
          <Box>{i.description}</Box>
        </Box>
      </Box>
    )
  }

  const fieldsMobile: Field<RowItem>[] = [
    {
      name: 'id',
      label: '',
      renderFunc: (f, i) => getTableMobileElemnt(i),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending
          ? styles.pendingStatusMobile
          : styles.completedStatusMobile,
    },
  ]

  const profesionalfields: Field<RowItem>[] = [
    {
      name: 'title',
      label: t('title'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'user',
      label: t('user'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'role',
      label: t('role'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
      renderFunc: (f, i) => {
        const role = getUserRole(i)
        let img = ''
        switch (role) {
          case Roles.Patient:
            img = i.status === ReadingStatus.Pending ? patient : patientGreen
            break
          case Roles.FamilyOrTutor:
            img = i.status === ReadingStatus.Pending ? familyRed : familyGreen
            break
          case Roles.Professional:
            img = professionalSMS
            break
          case Roles.ProfessionalExtern:
            img =
              i.status === ReadingStatus.Pending ? externProfessional : externalProfessionalGreen
            break
          case Roles.CarerOrTeacher:
            img = i.status === ReadingStatus.Pending ? career : careerGreen
            break
          case Roles.ManagerHealthChild:
          case Roles.ManagerEpileptic:
          case Roles.ManagerActivePlan:
            img = professionalSMS
            break
        }
        const tooltipContent = t(role) || role // Contenido para el tooltip

        return (
          <div>
            {isXS ? (
              <Box display={'flex'} alignItems={'center'}>
                <img
                  style={{ width: '15px', height: '15px', margin: '4px', marginLeft: '8px' }}
                  src={img}
                  alt={t(role)}
                />
                <h5 style={{ margin: '0' }}>{t(role)}</h5>
              </Box>
            ) : (
              <Tooltip title={tooltipContent}>
                <img src={img} alt={tooltipContent} ref={imgRef} />
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      name: 'specialty',
      label: t('specialty'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
      renderFunc: (f, i) => {
        if (i.specialty !== ' ') {
          return (
            <Chip
              style={{
                backgroundColor: '#dddedf',
                borderRadius: '9px',
                marginLeft: '8px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{i.specialty}</span>}
            />
          )
        }
        return ''
      },
    },
    {
      name: 'pathology',
      label: t('circle'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
      renderFunc: (f, i) => {
        if (i.pathology?.length === 0) return ''
        if (i.pathology && i.pathology[0] !== ' ') {
          return i.pathology.map((p) => (
            <Chip
              key={i.id + p}
              style={{
                backgroundColor: '#d1dee6',
                marginLeft: '8px',
                marginBottom: window.innerWidth <= 1782 ? '8px' : '0px',
              }}
              label={<span style={{ fontFamily: 'Poppins', color: '#000' }}>{p}</span>}
            ></Chip>
          ))
        }
        return ''
      },
    },
    {
      name: 'assigned',
      label: t('date'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
      renderFunc: (f, i) => {
        if (i.status === ReadingStatus.Pending) {
          return ''
        }
        return i.response ? new Date(i.response).toLocaleDateString('es-ES') : ''
      },
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(ReadingStatus[i.status].toLowerCase()),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatusText : styles.completedStatusText,
    },
  ]

  const externalProfessionalfields: Field<RowItem>[] = [
    {
      name: 'title',
      label: t('title'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'user',
      label: t('user'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
    },
    {
      name: 'role',
      label: t('role'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
      renderFunc: (f, i) => {
        const role = getUserRole(i)
        let img = ''
        switch (role) {
          case Roles.Patient:
            img = i.status === ReadingStatus.Pending ? patient : patientGreen
            break
          case Roles.FamilyOrTutor:
            img = i.status === ReadingStatus.Pending ? familyRed : familyGreen
            break
          case Roles.Professional:
            img = professionalSMS
            break
          case Roles.ProfessionalExtern:
            img =
              i.status === ReadingStatus.Pending ? externProfessional : externalProfessionalGreen
            break
          case Roles.CarerOrTeacher:
            img = i.status === ReadingStatus.Pending ? career : careerGreen
            break
          case Roles.ManagerHealthChild:
          case Roles.ManagerEpileptic:
          case Roles.ManagerActivePlan:
            img = professionalSMS
            break
        }
        const tooltipContent = t(role) || role // Contenido para el tooltip

        return (
          <div>
            {isXS ? (
              <Box display={'flex'} alignItems={'center'}>
                <img
                  style={{ width: '15px', height: '15px', margin: '4px', marginLeft: '8px' }}
                  src={img}
                  alt={t(role)}
                />
                <h5 style={{ margin: '0' }}>{t(role)}</h5>
              </Box>
            ) : (
              <Tooltip title={tooltipContent}>
                <img src={img} alt={tooltipContent} ref={imgRef} />
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      name: 'assigned',
      label: t('date'),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatus : styles.completedStatus,
      renderFunc: (f, i) => {
        if (i.status === ReadingStatus.Pending) {
          return ''
        }
        return i.response ? new Date(i.response).toLocaleDateString('es-ES') : ''
      },
    },
    {
      name: 'status',
      label: t('status'),
      renderFunc: (f, i) => t(ReadingStatus[i.status].toLowerCase()),
      styleFunc: (f, i) =>
        i.status === ReadingStatus.Pending ? styles.pendingStatusText : styles.completedStatusText,
    },
  ]

  const actions: Actions<RowItem> = {
    actionsColumn: !isXS ? t('action') : '',
    styleFunc: (r) => (isCompleted(r) ? styles.completedStatus : styles.pendingStatus),
    items: !isXS
      ? [
          {
            handler: viewPatientForm,
            icon: !isXS ? seeIcon : seeIconResponsive,
            label: 'see',
            hidden: (r) => !isCompleted(r),
          },
          {
            handler: editPatientForm,
            icon: !isXS ? editIcon : editIconResponsive,
            label: 'edit',
            hidden: (r) => isCompleted(r),
          },
        ]
      : [],
  }

  // TODO add searcher by user
  return (
    <Box className={genericStyle.pageContainer}>
      {isDoctor && (
        <AppTable
          isLoading={isLoading}
          fields={profesionalfields}
          items={filteredUserForms.items.length > 0 ? filteredUserForms.items : userForms.items}
          rowKeyField="id"
          actions={actions}
          pager={pager}
          search={search}
          isPreventDefault={true}
        />
      )}
      {isExtern && (
        <AppTable
          isLoading={isLoading}
          fields={externalProfessionalfields}
          items={filteredUserForms.items.length > 0 ? filteredUserForms.items : userForms.items}
          rowKeyField="id"
          actions={actions}
          pager={pager}
          search={search}
          isPreventDefault={true}
        />
      )}
      {!isDoctor && !isExtern && (
        <AppTable
          isLoading={isLoading}
          fields={isXS ? fieldsMobile : fields}
          items={filteredUserForms.items.length > 0 ? filteredUserForms.items : userForms.items}
          rowKeyField="id"
          actions={actions}
          pager={pager}
        />
      )}
    </Box>
  )
}
