import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import { List, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Query, QueryParam } from 'common/api/Query'
import { TransportType } from 'common/enums/TransportType'
import { getNotificationContainer } from 'container/notification-module'
import { NotificationService } from 'modules/notifications/services/NotificationService'
import { NOTIFICATION_SERVICE_KEY } from 'modules/notifications'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { SourceType } from 'modules/notifications/enums/SourceType'
import trainingIcon from 'assets/dashboard/trainingIcon.svg'
import formIcon from 'assets/dashboard/formIcon.svg'
import resourcesIcon from 'assets/dashboard/resourceIcon.svg'
import calendarIcon from 'assets/dashboard/calendarIcon.svg'
import chatIcon from 'assets/dashboard/chatIcon.svg'
import libraryIcon from 'assets/dashboard/libraryIcon.svg'
import symptomIcon from 'assets/dashboard/symptomIcon.svg'
import treatmentIcon from 'assets/dashboard/treatmentIcon.svg'
import teleconsultationIcon from 'assets/dashboard/teleconsulta.png'
import reportIcon from 'assets/dashboard/reportIcon.png'
import style from './NotificationCard.module.scss'
import { listItemTextStyle } from './NotificationsCardStyleMui'
import { useNavigate } from 'react-router-dom'
import {
  ROUTE_ACTIVE_PLAN_DATA,
  ROUTE_ARTICLES,
  ROUTE_CALENDAR,
  ROUTE_MESSENGER,
  ROUTE_PATIENT_FORMS,
  ROUTE_PATIENT_SYMPTOMS,
  ROUTE_QUERY,
  ROUTE_RESOURCES,
  ROUTE_SEGMENTED_NOTIFICATIONS,
  ROUTE_TELECONSULTATION,
  ROUTE_TREATMENTS,
  ROUTE_USERS,
} from 'routes/routes-constants'
import { DeliveryStatus } from 'modules/notifications/enums/DeliveryStatus'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'
import { NotificationWithMessage } from 'modules/notifications/models/NotificationWithMessage'
import { finalize } from 'rxjs/operators'

const FILTER_SOURCE_TYPES = [
  SourceType.CalendarEventCreated,
  SourceType.FilesNewCreated,
  SourceType.ContentNewArticle,
  SourceType.ContentArticleValidated,
  SourceType.FormsNewUserForm,
  SourceType.PatientSymptomCreated,
  SourceType.TreatmentCreated,
  SourceType.NewRegisteredUser,
  SourceType.NewReport,
  SourceType.NewSegmentedNotification,
  SourceType.QueryMessageCreated,
  SourceType.NewOnlineConsultation,
]

type NotificationsCardProps = {
  ProfessionalNotifications: boolean
  SetNumberNewResources: (number: number) => void
}
const notificationsPage = 0
const notificationsPerPage = 50
const notificationContainer = getNotificationContainer()
const notificationService = notificationContainer.get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const NotificationsCardPatient: React.FC<NotificationsCardProps> = (props): JSX.Element => {
  const { selectedUserCircle, setUserCircleID } = useCircleConfiguration()
  const loggedUser = loggedUserService.get()
  const [notifications, setNotifications] = useState<NotificationWithMessage[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!loggedUser) return

    setIsLoading(true)
    // Si no hay un selectedUserCircle, no haces nada o puedes usar un valor por defecto
    const recipientID = selectedUserCircle ? selectedUserCircle.user.id : loggedUser.id

    const query = [
      new QueryParam<NotificationWithMessage>('recipientID', recipientID),
      new QueryParam<NotificationWithMessage>('userCircleID', selectedUserCircle?.id ?? ''),
      new QueryParam<NotificationWithMessage>('transportType', TransportType.App),
      new QueryParam<NotificationWithMessage>('sourcesType', FILTER_SOURCE_TYPES),
    ]
    notificationService
      .getFilteredListWithMessage(
        new Query({
          pager: { offset: notificationsPage * notificationsPerPage, limit: notificationsPerPage },
          query,
          sort: [{ field: 'sendAt', desc: true }],
        })
      )
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        // Filtrar notificaciones de nuevos recursos
        const newFiles = res.items.filter((n) => n.sourceType === SourceType.FilesNewCreated)
        props.SetNumberNewResources(newFiles.length)
        setNotifications(res.items)
      })
  }, [selectedUserCircle])

  const handleOnClick = (notification: NotificationWithMessage) => {
    if (notification && notification.deliveryStatus !== DeliveryStatus.Viewed) {
      notificationService.updateStatus(notification.id as string, DeliveryStatus.Viewed).subscribe()
      setUserCircleID(notification.userCircleID)
    }
    setIsLoading(true)

    setTimeout(() => {
      switch (notification.sourceType) {
        case SourceType.CalendarEventCreated:
        case SourceType.CalendarEventUpdated:
          navigate(ROUTE_CALENDAR)
          break
        case SourceType.FilesNewCreated:
          navigate(ROUTE_RESOURCES)
          break

        case SourceType.ContentNewArticle:
          if (!notification.segmentedID) {
            navigate(`${ROUTE_ARTICLES}/${notification.message.url}`)
          } else {
            navigate(`${ROUTE_SEGMENTED_NOTIFICATIONS}/${notification.segmentedID}`)
          }
          break
        case SourceType.FormsNewUserForm:
          navigate(ROUTE_PATIENT_FORMS)
          break
        case SourceType.FormsUserFormFilled:
          navigate(ROUTE_PATIENT_FORMS)
          break
        case SourceType.PatientSymptomCreated:
          navigate(ROUTE_PATIENT_SYMPTOMS)
          break
        case SourceType.TreatmentCreated:
          navigate(ROUTE_TREATMENTS)
          break
        case SourceType.TreatmentReminder:
          navigate(ROUTE_TREATMENTS)
          break
        case SourceType.NewRegisteredUser:
          navigate(ROUTE_USERS)
          break
        case SourceType.NewReport:
          // cuando se clica en la notification del cuadro de mandos seteamos la ventana 2 de local que es informes
          localStorage.setItem('selectedTab', '2')
          navigate(ROUTE_ACTIVE_PLAN_DATA)
          break
        case SourceType.QueryMessageCreated:
          navigate(ROUTE_QUERY)
          break
        case SourceType.MessengerMessageCreated:
          navigate(ROUTE_MESSENGER)
          break
        case SourceType.NewOnlineConsultation:
          navigate(ROUTE_TELECONSULTATION)
          break
      }
      setIsLoading(false)
    }, 500)
  }

  const getIcon = (type: SourceType, id: string): string | null => {
    switch (type) {
      case SourceType.CalendarEventCreated:
      case SourceType.CalendarEventUpdated:
        return calendarIcon
      case SourceType.FilesNewCreated:
        return resourcesIcon
      case SourceType.ContentNewArticle:
        if (!id.includes('Una nueva notificación')) {
          return libraryIcon
        } else {
          return chatIcon
        }
      case SourceType.FormsNewUserForm:
      case SourceType.FormsUserFormFilled:
        return formIcon
      case SourceType.PatientSymptomCreated:
        return symptomIcon
      case SourceType.TreatmentCreated:
      case SourceType.TreatmentReminder:
        return treatmentIcon
      case SourceType.NewRegisteredUser:
        return libraryIcon
      case SourceType.QueryMessageCreated:
        return chatIcon
      case SourceType.ActivePlanCreated:
        return trainingIcon
      case SourceType.NewOnlineConsultation:
        return teleconsultationIcon
      case SourceType.NewReport:
        return reportIcon
      default:
        return null
    }
  }

  return (
    <DashboardSectionCard label={'notifications'}>
      <List className={style.containerList}>
        {isLoading &&
          Array.from({ length: 5 }).map((n, i) => {
            return (
              <ListItem className={style.notificationContainer} key={i}>
                <ListItemIcon>
                  <Skeleton
                    width={24}
                    height={24}
                    style={{ borderRadius: '5px' }}
                    variant="rectangular"
                  />
                </ListItemIcon>
                <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                  <Skeleton width={'100%'} height={'100%'} style={{ borderRadius: '5px' }} />
                </ListItemText>
              </ListItem>
            )
          })}
        {!isLoading &&
          notifications
            // .filter((f) => f.message.id && f.message.id.length > 0)
            .map((n) => {
              const icon = getIcon(n.message.sourceType, n.message.title)
              return (
                <ListItem
                  className={style.notificationContainer}
                  key={n.id}
                  onClick={() => handleOnClick(n)}
                >
                  {icon && (
                    <ListItemIcon>
                      <img className={style.icon} src={icon} alt={icon} />
                    </ListItemIcon>
                  )}
                  <ListItemText sx={listItemTextStyle} className={style.listItemText}>
                    {n.message.title}
                  </ListItemText>
                </ListItem>
              )
            })}
      </List>
    </DashboardSectionCard>
  )
}
