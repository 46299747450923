import GenericChartDashboard from 'components/dashboard/generic-chart-dashboard/GenericChartDashboard'
import { ChartType } from 'chart.js'

interface Dataset {
  label?: string
  data: number[]
  backgroundColor?: string | string[]
  borderColor?: string
}
interface GenericChartDashboardComponentProps {
  datasets: Dataset[]
  labels: string[]
  title: string
  typeChart: ChartType
  legendPosition: 'top' | 'bottom' | 'left' | 'right'
  showXAxis?: boolean
  showYAxis?: boolean
  yAxisConfig?: {
    min?: number
    max?: number
    stepSize?: number
  }
}
export const GenericChartDashboardComponent: React.FC<GenericChartDashboardComponentProps> = ({
  datasets,
  labels,
  title,
  typeChart,
  legendPosition,
  showXAxis,
  showYAxis,
  yAxisConfig,
}) => (
  <GenericChartDashboard
    datasets={datasets}
    labels={labels}
    title={title}
    typeChart={typeChart}
    legendPosition={legendPosition}
    showXAxis={showXAxis}
    showYAxis={showYAxis}
    yAxisConfig={yAxisConfig}
  />
)
