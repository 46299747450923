export type CalendarConfigProps = {
  monthly: boolean
  weekly: boolean
  diary: boolean
  vaccines: boolean
  appointments: boolean
  symptoms: boolean
  treatments: boolean
  others: boolean
  teleconsultation: boolean
}

export class CalendarConfig {
  private _monthly: boolean
  private _weekly: boolean
  private _diary: boolean
  private _vaccines: boolean
  private _appointments: boolean
  private _symptoms: boolean
  private _treatments: boolean
  private _others: boolean
  _teleconsultation: boolean

  constructor(p: CalendarConfigProps) {
    this._monthly = p.monthly
    this._weekly = p.weekly
    this._diary = p.diary

    this._vaccines = p.vaccines
    this._appointments = p.appointments
    this._symptoms = p.symptoms
    this._treatments = p.treatments
    this._others = p.others
    this._teleconsultation = p.teleconsultation
  }

  get monthly(): boolean {
    return this._monthly
  }

  set monthly(value: boolean) {
    this._monthly = value
  }

  get weekly(): boolean {
    return this._weekly
  }

  set weekly(value: boolean) {
    this._weekly = value
  }

  get diary(): boolean {
    return this._diary
  }

  set diary(value: boolean) {
    this._diary = value
  }

  get vaccines(): boolean {
    return this._vaccines
  }

  set vaccines(value: boolean) {
    this._vaccines = value
  }

  get appointments(): boolean {
    return this._appointments
  }

  set appointments(value: boolean) {
    this._appointments = value
  }

  get symptoms(): boolean {
    return this._symptoms
  }

  set symptoms(value: boolean) {
    this._symptoms = value
  }

  get treatments(): boolean {
    return this._treatments
  }

  set treatments(value: boolean) {
    this._treatments = value
  }

  get others(): boolean {
    return this._others
  }

  set others(value: boolean) {
    this._others = value
  }

  // juan parche no borrar comentario que necesita refactor 11/02/2024
  get teleconsultation(): boolean {
    return this._teleconsultation
  }

  set teleconsultation(value: boolean) {
    this._teleconsultation = true // just for esfera-1110
  }
}
