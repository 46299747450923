export type PROMS_beckItemsProps = {
  userFormID: string
  sadness: number
  pessimism: number
  failure: number
  lossOfPleasure: number
  guilt: number
  punishment: number
  disconformityWithSelf: number
  selfCriticism: number
  suicidalThoughts: number
  crying: number
  agitation: number
  lossOfInterest: number
  indecision: number
  devaluation: number
  lossOfEnergy: number
  sleepHabits: number
  irritability: number
  appetite: number
  difficultyConcentrating: number
  fatigue: number
  lossOfInterestInSex: number
}

export class PROMS_beckItems {
  private readonly _userFormID: string
  private _sadness: number
  private _pessimism: number
  private _failure: number
  private _lossOfPleasure: number
  private _guilt: number
  private _punishment: number
  private _disconformityWithSelf: number
  private _selfCriticism: number
  private _suicidalThoughts: number
  private _crying: number
  private _agitation: number
  private _lossOfInterest: number
  private _indecision: number
  private _devaluation: number
  private _lossOfEnergy: number
  private _sleepHabits: number
  private _irritability: number
  private _appetite: number
  private _difficultyConcentrating: number
  private _fatigue: number
  private _lossOfInterestInSex: number

  constructor(p: PROMS_beckItemsProps) {
    this._userFormID = p.userFormID
    this._sadness = p.sadness
    this._pessimism = p.pessimism
    this._failure = p.failure
    this._lossOfPleasure = p.lossOfPleasure
    this._guilt = p.guilt
    this._punishment = p.punishment
    this._disconformityWithSelf = p.disconformityWithSelf
    this._selfCriticism = p.selfCriticism
    this._suicidalThoughts = p.suicidalThoughts
    this._crying = p.crying
    this._agitation = p.agitation
    this._lossOfInterest = p.lossOfInterest
    this._indecision = p.indecision
    this._devaluation = p.devaluation
    this._lossOfEnergy = p.lossOfEnergy
    this._sleepHabits = p.sleepHabits
    this._irritability = p.irritability
    this._appetite = p.appetite
    this._difficultyConcentrating = p.difficultyConcentrating
    this._fatigue = p.fatigue
    this._lossOfInterestInSex = p.lossOfInterestInSex
  }

  get userFormID(): string {
    return this._userFormID
  }

  get sadness(): number {
    return this._sadness
  }

  get pessimism(): number {
    return this._pessimism
  }

  get failure(): number {
    return this._failure
  }

  get lossOfPleasure(): number {
    return this._lossOfPleasure
  }

  get guilt(): number {
    return this._guilt
  }

  get punishment(): number {
    return this._punishment
  }

  get disconformityWithSelf(): number {
    return this._disconformityWithSelf
  }

  get selfCriticism(): number {
    return this._selfCriticism
  }

  get suicidalThoughts(): number {
    return this._suicidalThoughts
  }

  get crying(): number {
    return this._crying
  }

  get agitation(): number {
    return this._agitation
  }

  get lossOfInterest(): number {
    return this._lossOfInterest
  }

  get indecision(): number {
    return this._indecision
  }

  get devaluation(): number {
    return this._devaluation
  }

  get lossOfEnergy(): number {
    return this._lossOfEnergy
  }

  get sleepHabits(): number {
    return this._sleepHabits
  }

  get irritability(): number {
    return this._irritability
  }

  get appetite(): number {
    return this._appetite
  }

  get difficultyConcentrating(): number {
    return this._difficultyConcentrating
  }

  get fatigue(): number {
    return this._fatigue
  }

  get lossOfInterestInSex(): number {
    return this._lossOfInterestInSex
  }
}
