/* eslint-disable @typescript-eslint/dot-notation */ // Única manera de acceder a esos datos
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { WearableService } from '../../../modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from '../../../modules/patient-data/container'
import { getPatientDataContainer } from '../../../container/patient-data-module'
import { Query, QueryParam } from '../../../common/api/Query'
import { WearableQuery } from '../../../modules/patient-data/models/Wearable'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { BarChart, LineChart } from '@mui/x-charts'
import { Header } from '../../../components/header/Header'
import { useCircleConfiguration } from '../../../common/utils/circle-config-context/CircleConfigContext'

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

const chartSetting = {
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-13px, 0)',
    },
  },
}

const chartSetting2 = {
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-11px, 0)',
    },
  },
}

function secondsToHms(d: number) {
  d = Number(d)
  const hours = Math.floor(d / 60)
  const minutes = d % 60
  const min = Math.trunc(minutes)
  const aux = min.toString().length === 1 ? '0' + min.toString() : min.toString()
  return hours + ':' + aux
}

function labelFormatter(value: any, type: string) {
  if (type !== 'sleepHours') {
    return value
  }
  if (value) {
    return secondsToHms(value * 60)
  }
  return value
}

export const Graphs = ({ returnToWearables, type }: any) => {
  const { t } = useTranslation()
  const { selectedUserCircle } = useCircleConfiguration()
  const [fromDate, setFromDate] = useState<Date>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  )
  const [toDate, setToDate] = useState<Date>(new Date())
  const [wearableResult, setWearableResult] = useState<any>([])
  const [dataGraphX, setDataGraphX] = useState<any>([])
  const [dataGraphY, setDataGraphY] = useState<any>([])
  const [yAxisLabel, setYAxisLabel] = useState<string>('Y')
  const [xAxisLabel, setXAxisLabel] = useState<string>('X')
  const [measureType, setMeasureType] = useState<number>(0)
  const [isBarChart, setGraphType] = useState<boolean>(true)

  useEffect(() => {
    switch (type) {
      case 'steps':
        setYAxisLabel(t('steps'))
        setXAxisLabel(t('date'))
        setMeasureType(1)
        break
      case 'calories':
        setYAxisLabel('KCal')
        setXAxisLabel(t('date'))
        setMeasureType(11)
        break
      case 'distance':
        setYAxisLabel('Km')
        setXAxisLabel(t('date'))
        setMeasureType(3)
        break
      case 'sleepHours':
        setYAxisLabel(t('hours'))
        setXAxisLabel(t('date'))
        setMeasureType(4)
        break
      case 'pulse2':
        setYAxisLabel('Lat/min')
        setXAxisLabel(t('date'))
        setMeasureType(5)
        setGraphType(false)
        break
      case 'bloodPreasure':
        setYAxisLabel('%')
        setXAxisLabel(t('date'))
        setGraphType(false)
        setMeasureType(9)
        break
      case 'fat':
        setYAxisLabel('% Grasa corporal')
        setXAxisLabel(t('date'))
        setMeasureType(8)
        break
      case 'weight':
        setYAxisLabel('Kg')
        setXAxisLabel(t('date'))
        setMeasureType(7)
        break
      case 'temperature':
        setYAxisLabel('ºC')
        setXAxisLabel(t('date'))
        setGraphType(false)
        setMeasureType(10)
        break
    }
  }, [])

  useEffect(() => {
    if (
      wearableResult.fitbitResponse ||
      wearableResult['activities-heart'] ||
      wearableResult['tempCore']
    ) {
      switch (type) {
        case 'steps':
          parseData(wearableResult.fitbitResponse['activities-steps'])
          break
        case 'calories':
          parseData(wearableResult.fitbitResponse['activities-calories'])
          break
        case 'distance':
          parseData(wearableResult.fitbitResponse['activities-distance'])
          break
        case 'sleepHours':
          parseDataSleep(wearableResult.fitbitResponse['sleep'])
          break
        case 'pulse2':
          parseDataHeart(wearableResult['activities-heart'])
          break
        case 'bloodPreasure':
          parseData(wearableResult.fitbitResponse['activities-heart'])
          break
        case 'fat':
          parseData(wearableResult.fitbitResponse['body-fat'])
          break
        case 'weight':
          parseData(wearableResult.fitbitResponse['body-weight'])
          break
        case 'temperature':
          parseDataTemp(wearableResult['tempCore'])
          break
      }
    }
  }, [wearableResult])

  useEffect(() => {
    if (selectedUserCircle) {
      if (type !== 'pulse2' && type !== 'temperature') {
        wearableService
          .getFilteredItems(
            new Query({
              query: [
                new QueryParam<WearableQuery>('userID', selectedUserCircle.user.id),
                new QueryParam<WearableQuery>('startDate', fromDate.toISOString()),
                new QueryParam<WearableQuery>('finishDate', toDate.toISOString()),
                new QueryParam<WearableQuery>('type', measureType),
              ],
            })
          )
          .subscribe((res) => {
            setWearableResult(res)
          })
      } else if (type === 'temperature' && type !== 'pulse2') {
        wearableService
          .getTemperatureByDate(
            new Query({
              query: [
                new QueryParam<WearableQuery>('userID', selectedUserCircle.user.id),
                new QueryParam<WearableQuery>('startDate', fromDate.toISOString()),
                new QueryParam<WearableQuery>('finishDate', toDate.toISOString()),
                new QueryParam<WearableQuery>('type', measureType),
              ],
            })
          )
          .subscribe((res) => {
            setWearableResult(res)
          })
      } else {
        wearableService
          .getHeartRateByDate(
            new Query({
              query: [
                new QueryParam<WearableQuery>('userID', selectedUserCircle.user.id),
                new QueryParam<WearableQuery>('startDate', fromDate.toISOString()),
                new QueryParam<WearableQuery>('finishDate', toDate.toISOString()),
              ],
            })
          )
          .subscribe((res) => {
            console.log(res)
            setWearableResult(res)
          })
      }
    }
  }, [measureType, fromDate, toDate])

  const parseData = (data: any) => {
    let array1: any[] = []
    let array2: any[] = []
    let display = false
    data.forEach((obj: any) => {
      array1.push(obj.dateTime)
      array2.push(obj.value)
      if (obj.value !== 0) display = true
    })
    if (!display) {
      array1 = []
      array2 = []
    }
    setDataGraphX(array1)
    setDataGraphY(array2)
  }

  const parseDataTemp = (data: any) => {
    // debugger;
    const array1: any[] = []
    const array2: any[] = []
    data.forEach((obj: any) => {
      array1.push(new Date(obj.dateTime).toLocaleDateString('es-ES'))
      array2.push(obj.value)
    })
    setDataGraphX(array1)
    setDataGraphY(array2)
  }

  const parseDate = (data: any) => {
    if (!data) return [];

    // Sort by date
    return [...data].sort((a, b) =>
      new Date(a.dateOfSleep).getTime() - new Date(b.dateOfSleep).getTime()
    );
  }

  const parseDataSleep = (data: any) => {
    const sortedData = parseDate(data);
    const dates: string[] = [];
    const hoursSlept: number[] = [];
    const formattedHours = [];

    // Group sleep data by date
    const sleepByDate = {};

    sortedData.forEach((sleep) => {
      // @ts-ignore
      if (!sleepByDate[sleep.dateOfSleep]) {
        // @ts-ignore
        sleepByDate[sleep.dateOfSleep] = [];
      }
      // @ts-ignore
      sleepByDate[sleep.dateOfSleep].push(sleep);
    });

    // Calculate total sleep time for each date
    Object.keys(sleepByDate).forEach((date) => {
      // @ts-ignore
      const sleeps = sleepByDate[date];

      // Sum all sleep minutes for this date
      const totalMinutes = sleeps.reduce((total: any, sleep: { minutesAsleep: any }) => total + sleep.minutesAsleep, 0);

      // Only include days with substantial sleep (still using your 350 minutes threshold)
      if (totalMinutes > 350) {
        dates.push(date);
        hoursSlept.push(totalMinutes / 60);
        formattedHours.push(secondsToHms(totalMinutes));
      }
    });

    setDataGraphX(dates);
    setDataGraphY(hoursSlept);
  }

  const parseDataHeart = (data: any) => {
    const array1: any[] = []
    const array2: any[] = []
    data.forEach((obj: any) => {
      array1.push(obj.dateTime)
      array2.push(obj.value.restingHeartRate)
    })
    setDataGraphX(array1)
    setDataGraphY(array2)
  }

  const handleFromDateChange = (date: any) => {
    if (date) {
      setFromDate(date.toDate())
    }
  }

  const handleToDateChange = (date: any) => {
    if (date) {
      setToDate(date.toDate())
    }
  }

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flex: 1,
          position: 'sticky',
          top: '0',
          backgroundColor: '#ffffff',
          zIndex: 100,
          borderBottom: '3px solid #f18b24',
        }}
      >
        <Header divider={true} label={t(type)} />
        <>
          <p>{t('from')}:</p>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              key={'date'}
              PopperProps={{
                sx: {
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              renderInput={(props) => (
                <TextField
                  style={{ width: '20%' }}
                  sx={{
                    '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  variant={'outlined'}
                  {...props}
                />
              )}
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              onChange={handleFromDateChange}
            />
          </LocalizationProvider>
          <p>{t('to')}:</p>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              key={'date'}
              PopperProps={{
                sx: {
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Poppins',
                  },
                },
              }}
              // onError={(reason, value) => {
              //   switch (reason) {
              //     case 'invalidDate':
              //       setDateTimePickerError(t('invalidDateMessage'))
              //       break
              //     case 'minDate':
              //       setDateTimePickerError(t('minDateMessage'))
              //       break
              //   }
              // }}
              renderInput={(props) => (
                <TextField
                  style={{ width: '20%' }}
                  sx={{
                    '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  }}
                  variant={'outlined'}
                  // helperText={props.error && DateTimePickerError}
                  {...props}
                />
              )}
              inputFormat="DD/MM/YYYY"
              value={toDate}
              onChange={handleToDateChange}
            />
          </LocalizationProvider>
          <Grid item xs={3} display={'flex'} paddingLeft={'30px'} justifyContent={'end'}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={t('back')}
              handler={returnToWearables}
            />
          </Grid>
        </>
      </Box>
      <Box paddingTop={'50px'}>
        <Grid
          item
          display={'flex'}
          padding={'15px'}
          flexDirection={'column'}
          border={'1px solid rgb(104 213 231)'}
          borderRadius={'17px'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {isBarChart ? (
            <BarChart
              slotProps={{
                noDataOverlay: { message: 'No hay datos que mostrar' },
              }}
              margin={{
                left: 100,
                right: 80,
                top: 75,
                bottom: 80,
              }}
              colors={['#f18b24']}
              grid={{ horizontal: true }}
              xAxis={[
                {
                  scaleType: 'band',
                  data: dataGraphX,
                  label: xAxisLabel,
                },
              ]}
              yAxis={[
                {
                  label: yAxisLabel,
                  valueFormatter: (v) => (type !== 'sleepHours' ? v : secondsToHms(v * 60)),
                },
              ]}
              series={[{ data: dataGraphY, valueFormatter: (v) => labelFormatter(v, type) }]}
              width={1100}
              height={400}
              {...chartSetting}
            />
          ) : (
            <LineChart
              colors={['#f18b24']}
              slotProps={{
                noDataOverlay: { message: 'No hay datos que mostrar' },
              }}
              disableLineItemHighlight={false}
              grid={{ horizontal: true }}
              xAxis={[{ scaleType: 'band', data: dataGraphX, label: xAxisLabel }]}
              yAxis={[{ label: yAxisLabel }]}
              series={[{ curve: 'linear', data: dataGraphY }]}
              width={1000}
              height={400}
              {...chartSetting2}
            />
          )}
        </Grid>
      </Box>
      {type === 'bloodPreasure' && (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '50px',
              flex: 1,
              position: 'sticky',
              top: '0',
              backgroundColor: '#ffffff',
              zIndex: 100,
              borderBottom: '3px solid #f18b24',
            }}
          >
            <Header divider={true} label={t('spo2-title')} />
          </Box>
          <Box paddingTop={'50px'}>
            <Grid
              item
              display={'flex'}
              padding={'15px'}
              flexDirection={'column'}
              border={'1px solid rgb(104 213 231)'}
              borderRadius={'17px'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <LineChart
                colors={['#f18b24']}
                disableLineItemHighlight={false}
                slotProps={{
                  noDataOverlay: { message: 'No hay datos que mostrar' },
                }}
                grid={{ horizontal: true }}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: [],
                    label: xAxisLabel,
                  },
                ]}
                yAxis={[{ label: yAxisLabel }]}
                series={[{ curve: 'linear', data: [] }]}
                width={1000}
                height={400}
              />
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}
