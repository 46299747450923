import { CalendarFilter } from 'features/calendar/Calendar'
import i18n from '../../../i18n'

export enum EventCategory {
  Appointment = 1,
  Medication,
  Landmarks,
  Vaccines,
  Reviews,
  Food,
  OralHealth,
  Advices,
  Others,
  EpilepticCrisis,
  Symptoms,
  TrainingPending,
  TrainingAssisted,
  TrainingAbsent,
  PersonalEvent,
  Treatments,
  Teleconsultation,
}

export const EventCategoryArray = [
  EventCategory.Medication,
  EventCategory.Appointment,
  EventCategory.Landmarks,
  EventCategory.Vaccines,
  EventCategory.Reviews,
  EventCategory.Food,
  EventCategory.OralHealth,
  EventCategory.Advices,
  EventCategory.Others,
  EventCategory.EpilepticCrisis,
  EventCategory.Symptoms,
  EventCategory.TrainingPending,
  EventCategory.TrainingAssisted,
  EventCategory.TrainingAbsent,
  EventCategory.PersonalEvent,
  EventCategory.Treatments,
  EventCategory.Teleconsultation,
]

export const eventCategoryTypes = (): Record<EventCategory, string> => ({
  [EventCategory.Medication]: i18n.t('medication'),
  [EventCategory.Appointment]: i18n.t('appointment'),
  [EventCategory.Landmarks]: i18n.t('landmarks'),
  [EventCategory.Vaccines]: i18n.t('vaccines'),
  [EventCategory.Reviews]: i18n.t('reviews'),
  [EventCategory.Food]: i18n.t('food'),
  [EventCategory.OralHealth]: i18n.t('oralHealth'),
  [EventCategory.Advices]: i18n.t('advices'),
  [EventCategory.Others]: i18n.t('others'),
  [EventCategory.EpilepticCrisis]: i18n.t('epilepticCrisis'),
  [EventCategory.Symptoms]: i18n.t('symptoms'),
  [EventCategory.TrainingPending]: i18n.t('trainingPending'),
  [EventCategory.TrainingAssisted]: i18n.t('trainingAssisted'),
  [EventCategory.TrainingAbsent]: i18n.t('trainingAbsent'),
  [EventCategory.PersonalEvent]: i18n.t('personalEvent'),
  [EventCategory.Treatments]: i18n.t('treatments'),
  [EventCategory.Teleconsultation]: i18n.t('teleconsultation'),
})

export const checkFilterEvents = (
  category: EventCategory,
  filter: CalendarFilter | undefined
): boolean => {
  switch (category) {
    case EventCategory.Medication:
      return !filter?.tratamientos
    case EventCategory.Symptoms:
      return !filter?.sintomas
    case EventCategory.Vaccines:
      return !filter?.vacunas
    case EventCategory.EpilepticCrisis:
      return !filter?.crisisEpilepticas
    case EventCategory.Others:
      return !filter?.otros
    case EventCategory.Appointment:
      return !filter?.citaMedica
    case EventCategory.Teleconsultation:
      return !filter?.teleconsulta
  }
  return false
}
