/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, MenuItem, Select, InputLabel, FormControl, TextField, Modal } from '@mui/material'
import { Header } from '../../components/header/Header'
import style from './CustomModal.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import close from '../../assets/table_icons/ico-eliminar.svg'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  SegmentedNotificationDTO,
  EmptySegmentedNotificationDTO,
  toModel,
} from '../../modules/notifications/models/SegmentedNotificationDTO'
import { SegmentedNotificationService } from '../../modules/notifications/services/SegmentedNotificationService'
import { SEGMENTED_NOTIFICATION_API_KEY } from '../../modules/notifications'
import { getNotificationContainer } from '../../container/notification-module'
import { CustomModal } from '../../components/modal/CustomModal'
import { NotificationModal } from './NotificationModal'
import { CircleDTO } from '../../modules/circle/models/CircleDTO'
import { Query, QueryParam } from '../../common/api/Query'
import { CircleQuery } from '../../modules/circle/models/Circle'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'
import { getUserCircleContainer } from '../../container/user-circle-module'
import { UserCircleService } from '../../modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from '../../modules/user-circle'
import { TreatmentQuery } from '../../modules/patient-data/models/Treatment'
import { TreatmentService } from '../../modules/patient-data/services/TreatmentService'
import { TREATMENT_SERVICE_KEY } from '../../modules/patient-data'
import { getPatientDataContainer } from '../../container/patient-data-module'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'

const notificationContainer = getNotificationContainer()
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const patientDataContainer = getPatientDataContainer()
const treatmentService = patientDataContainer.get<TreatmentService>(TREATMENT_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const segmentedNotificationService = notificationContainer.get<SegmentedNotificationService>(
  SEGMENTED_NOTIFICATION_API_KEY
)
const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type modalProps = {
  title?: string
  author?: string
  name?: string
  data?: any
  description?: string
  segmentedId?: string
  userId?: string
  userCircleId?: string
  handleCreateVariable?: (name: string, description: string) => void
  handleClose: () => void
  deleted: () => void
  handleSave: (name: string) => void
}

export const GroupModal = (props: modalProps): JSX.Element => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [segmentedGroup, setSegmentedGroup] = useState<SegmentedNotificationDTO>(
    EmptySegmentedNotificationDTO()
  )
  const [segmentationId, setSegmentationId] = useState<string>('')
  const [userCircles, setUserCircles] = useState<any>([])
  const [circle, setCircle] = useState<CircleDTO[]>([])
  const [openCreatedModal, setOpenCreatedModal] = useState<boolean>(false)
  const [openCreateNotification, setOpenCreateNotification] = useState<boolean>(false)
  const [openDeleteGroup, setOpenDeleteGroup] = useState<boolean>(false)
  const [errorAge, setErrorAge] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<boolean>(false)
  const [lockButton, setLockButton] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [fromAge, setFromAge] = useState<number>(0)
  const [toAge, setToAge] = useState<number>(0)
  const [pathology, setPathology] = useState<string>('')
  const [treatment, setTreatment] = useState<string>('')
  const [diagnostic, setDiagnostic] = useState<string>('')
  const [treats, setTreat] = useState<any[]>([''])

  useEffect(() => {
    treatmentService.getAll().subscribe((res: any) => {
      setTreat(res.items)
    })
    if (props.data && props.data.data) {
      setSegmentedGroup(props.data.data)
      if (props.data) {
        setName(props.data.data.name)
        setFromAge(props.data.data.fromAge)
        setToAge(props.data.data.toAge)
        setTreatment(props.data.data.treatment)
        setPathology(props.data.data.pathology)
        setDiagnostic(props.data.data.diagnostic)
      }
    }

    const userCircles: any[] = []
    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          const uniqueCircles: CircleDTO[] = []
          res.forEach((userCircle) => {
            if (!uniqueCircles.find((circle) => circle.id === userCircle.circle.id)) {
              uniqueCircles.push(userCircle.circle)
              userCircles.push(userCircle)
            }
          })
          setUserCircles(userCircles)
        }
      })

    circleService.getFilteredList(new Query<CircleQuery>({})).subscribe((res) => {
      if (!res) return
      setCircle(res.items)
    })
  }, [])

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(e.target.value)
    // setSegmentedGroup({ ...segmentedGroup , [e.target.name]: e.target.value})
  }
  const handleInputFromAge = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setErrorAge(false)
    if (!isNaN(Number(e.target.value))) {
      setFromAge(Number(e.target.value))
    }
  }
  const handleInputToAge = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setErrorAge(false)
    if (!isNaN(Number(e.target.value))) {
      setToAge(Number(e.target.value))
    }
  }
  const handlePathology = (event: any) => {
    setPathology(event.target.value)
  }
  const handleTreatments = (event: any) => {
    setTreatment(event.target.value)
  }
  const handleDiagnosis = (event: any) => {
    setDiagnostic(event.target.value)
  }

  const validate = () => {
    if (!name || !pathology || !toAge) {
      setErrorModal(true)
      return false
    }
    return true
  }

  const handleSave = (event: any) => {
    if (!validate()) {
      return
    }
    if (fromAge <= toAge) {
      const logged = JSON.parse(localStorage.getItem('logged user') ?? 'null')
      const circleId = pathology
      const userCircleId = userCircles.find((obj: any) => obj.circle.id === pathology).id
      const userID = logged.id
      segmentedGroup.name = name
      segmentedGroup.treatment = treatment
      segmentedGroup.pathology = pathology
      segmentedGroup.diagnostic = diagnostic
      segmentedGroup.userId = userID
      segmentedGroup.circleId = circleId
      segmentedGroup.userCircleId = userCircleId
      segmentedGroup.fromAge = fromAge
      segmentedGroup.toAge = toAge
      event.preventDefault()
      if (props.data && props.data.id) {
        segmentedGroup.id = props.data.id
        segmentedNotificationService.update(toModel(segmentedGroup)).subscribe((resp) => {
          setSegmentationId(props.data.id)
        })
      } else {
        segmentedNotificationService.add(toModel(segmentedGroup)).subscribe((resp) => {
          if (resp && resp.id) {
            setSegmentationId(resp.id)
            setSegmentedGroup(resp)
          }
        })
      }
      props.handleSave(name)
      setOpenCreatedModal(true)
    } else {
      setErrorAge(true)
    }
  }

  const handleDelete = (event: any) => {
    setOpenCreatedModal(true)
  }

  const handleCloseCreatedModal = () => {
    props.handleClose()
  }
  const handleCloseDeleteModal = () => {
    setOpenDeleteGroup(false)
  }
  const handleCloseError = () => {
    setErrorModal(false)
  }
  const handleCloseCreatedNotification = () => {
    props.handleClose()
  }

  const openCreateMessage = () => {
    setOpenCreateNotification(true)
  }

  const handleDeleteModal = () => {
    if (segmentedGroup.id) {
      segmentedNotificationService.delete(segmentedGroup.id).subscribe((resp) => {
        props.deleted()
        props.handleClose()
      })
    }
    props.handleClose()
  }

  return (
    <div>
      <Modal open={openCreateNotification} onClose={handleCloseCreatedNotification}>
        <NotificationModal
          handleClose={handleCloseCreatedModal}
          handleSave={handleCloseCreatedNotification}
          notiTitle={name}
          data={props}
          segmentedId={segmentationId}
          userId={props.userId}
          userCircleId={props.userCircleId}
          segmentedGroup={segmentedGroup}
          closeModal={handleCloseCreatedNotification}
          newNotification={true}
        />
      </Modal>
      <Modal open={openCreatedModal} onClose={handleCloseCreatedModal}>
        <CustomModal
          handleClose={handleCloseCreatedModal}
          handleSave={openCreateMessage}
          title={props.data && props.data.id ? t('groupEdited') : t('newGroupCreated')}
          warningText={
            props.data && props.data.id
              ? '¿Quieres enviar una notifiación?'
              : '¿Quieres enviar la primera notificación?'
          }
        />
      </Modal>
      <Modal open={errorModal} onClose={handleCloseError}>
        <div className={style.customModal}>
          <CustomModal
            handleClose={handleCloseError}
            handleSave={handleCloseError}
            title={'Error'}
            warningText={'Es necesario rellenar todos los campos marcados con *'}
          />
        </div>
      </Modal>
      <Modal open={openDeleteGroup} onClose={handleCloseCreatedModal}>
        <CustomModal
          handleClose={handleCloseDeleteModal}
          handleSave={handleDeleteModal}
          title={props.data && props.data.id ? t('groupEdited') : t('newGroupCreated')}
          warningText={'¿Quieres borrar el grupo?'}
        />
      </Modal>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header
            label={props.data && props.data.id ? t('editGroup') : t('newGroup')}
            icon={close}
            onClick={props.handleClose}
          />
          <form>
            <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
              <p className={style.label}>{t('groupName')}*</p>
              <TextFieldItem
                key={'name'}
                name={'name'}
                value={name}
                handleChange={handleInput}
                required={true}
                fullWidth={true}
                field={'groupName'}
                label={''}
                maxLength={199}
              />
            </Box>

            <Box mt={3} mb={3} display={'flex'}>
              <h4>{t('defineGroup')}</h4>
            </Box>

            <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
              <p className={style.label}>{t('pathology')}*</p>
              <Select
                key={'pathology'}
                id={'pathology'}
                variant={'outlined'}
                value={pathology}
                onChange={handlePathology}
                size={'small'}
                required
                fullWidth={true}
              >
                {userCircles.map((k: any) => {
                  const keySelect = k.circle.id
                  const labelSelect = k.circle.name

                  return (
                    <MenuItem key={keySelect} value={keySelect}>
                      {labelSelect}
                    </MenuItem>
                  )
                })}
              </Select>
            </Box>

            <Box mt={3} mb={3} display={'flex'}>
              <p className={style.label}>{t('age')}*</p>

              <p className={style.label}>{t('from')}</p>
              <TextField
                error={errorAge}
                key={'fromAge'}
                id={'fromAge'}
                name={'fromAge'}
                inputMode={'numeric'}
                value={fromAge}
                onChange={handleInputFromAge}
                size={'small'}
                variant={'outlined'}
              />
              <p className={style.label}>{t('years')}</p>
              <p className={style.label}>{t('to')}</p>
              <TextField
                error={errorAge}
                helperText={
                  errorAge && 'Edad mayor o igual que ' + segmentedGroup.fromAge + ' años'
                }
                key={'toAge'}
                id={'toAge'}
                name={'toAge'}
                value={toAge}
                onChange={handleInputToAge}
                size={'small'}
                variant={'outlined'}
              />
              <p className={style.label}>{t('years')}</p>
            </Box>

            {/* <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}> */}
            {/*  <p className={style.label}>{t('treatments')}</p> */}
            {/*  <TextField */}
            {/*    key={'treatment'} */}
            {/*    id={'treatment'} */}
            {/*    name={'treatment'} */}
            {/*    value={treatment} */}
            {/*    onChange={handleTreatments} */}
            {/*    required={true} */}
            {/*    size={'small'} */}
            {/*    variant={'outlined'} */}
            {/*    fullWidth={true} */}
            {/*  /> */}
            {/* </Box> */}
            <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
              <p className={style.label}>Tratamiento</p>
              <Select
                value={treatment}
                key={'treatment'}
                size={'small'}
                onChange={handleTreatments}
                id={'treatment'}
                fullWidth={true}
                variant={'outlined'}
              >
                {treats.map((k: any) => {
                  if (k !== '') {
                    return (
                      <MenuItem key={k.id} value={k.id}>
                        {k.name}
                      </MenuItem>
                    )
                  } else {
                    return (
                      <MenuItem key={'0'} value={''}>
                        {'No hay tratamientos disponibles'}
                      </MenuItem>
                    )
                  }
                })}
              </Select>
            </Box>

            <Box mt={3} mb={3} display={'flex'} justifyContent={'space-between'}>
              <p className={style.label}>Tipo de diagnóstico</p>
              <TextField
                key={'diagnostic'}
                id={'diagnostic'}
                name={'diagnostic'}
                value={diagnostic}
                onChange={handleDiagnosis}
                required={true}
                size={'small'}
                variant={'outlined'}
                fullWidth={true}
              />
            </Box>
            <Box mt={1} mb={1} className={style.buttons}>
              <Box style={{ marginRight: 4 }}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('delete')}
                  handler={() => setOpenDeleteGroup(true)}
                />
              </Box>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('submit')}
                handler={handleSave}
              />
            </Box>
          </form>
        </Box>
      </div>
    </div>
  )
}
