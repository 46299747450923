import React, { useState, useRef } from 'react'
import { Button } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { Pie, Line, Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
} from 'chart.js'

// Registrar los componentes de Chart.js
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement
)

// Interfaces TypeScript
interface DatosGenerales {
  nombre: string
  edad: string
  centroSalud: string
  profesional: string
  categoria: string
  tipoPrograma: string
  fechaInicio: string
  fechaFin: string
  periodoInforme: string
}

interface DatosEspecificos {
  centroDeportivo: string
  direccion: string
  ayuntamiento: string
  periodo: string
  grupo: string
  aforo: string
  nombreEFD: string
  correoEFD: string
  diasEntrenamiento: string
}

interface ValoracionRCV {
  riesgoCardiovascular: string
}

interface Sesiones {
  totalSesiones: number
  sesionesRealizadas: number
  sesionesNoAsistidas: number
  asistenciaPromedio: number
}

interface DatosPorMes {
  labels: string[]
  datos: number[]
}

interface DatosAntropometricos {
  fechas: string[]
  peso: number[]
  imc: number[]
}

interface TestMilla {
  fechas: string[]
  valores: number[]
}

interface TestCajon {
  fechas: string[]
  cajon: number[]
  lanzamiento: number[]
}

interface TestFlamenco {
  fechas: string[]
  intentos: number[]
}

interface CondicionFisica {
  testMilla: TestMilla
  testCajon: TestCajon
  testFlamenco: TestFlamenco
}

interface CuestionarioSalud {
  fechas: string[]
  porcentajeOptimo: number[]
  saludFisica: number[]
  saludMental: number[]
}

interface InformeData {
  titulo?: string
  periodo?: string
  datosGenerales?: Partial<DatosGenerales>
  datosEspecificos?: Partial<DatosEspecificos>
  valoracionRCV?: Partial<ValoracionRCV>
  sesiones?: Partial<Sesiones>
  asistenciaMensual?: Partial<DatosPorMes>
  intensidadMensual?: Partial<DatosPorMes>
  datosAntropometricos?: Partial<DatosAntropometricos>
  condicionFisica?: Partial<CondicionFisica>
  cuestionarioSalud?: Partial<CuestionarioSalud>
  comentarios?: string
}

interface ChartImages {
  pie?: string
  asistencia?: string
  intensidad?: string
  antropometricos?: string
  aerobica?: string
  flexibilidad?: string
  equilibrio?: string
  salud?: string
  [key: string]: string | undefined
}

interface ChartConfig {
  data: any
  options: any
}

interface ChartConfigs {
  pieConfig: ChartConfig
  asistenciaConfig: ChartConfig
  intensidadConfig: ChartConfig
  antropometricosConfig: ChartConfig
  aerobicaConfig: ChartConfig
  flexibilidadConfig: ChartConfig
  equilibrioConfig: ChartConfig
  saludConfig: ChartConfig
  [key: string]: ChartConfig
}

interface InformePlanActivaPDFProps {
  fileName?: string
  buttonText?: string
  buttonProps?: any
  informeData?: InformeData
}

/**
 * Componente para generar un informe PDF del Plan Activa
 * @param props - Propiedades del componente
 * @returns Botón para generar PDF
 */
const PDFViewerWithCanvas: React.FC<InformePlanActivaPDFProps> = ({
  fileName = 'informe_plan_activa.pdf',
  buttonText = 'Exportar a PDF',
  buttonProps = {},
  informeData = {},
}) => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  // Referencias para los gráficos
  const pieChartRef = useRef<HTMLDivElement>(null)
  const asistenciaChartRef = useRef<HTMLDivElement>(null)
  const intensidadChartRef = useRef<HTMLDivElement>(null)
  const antropometricosChartRef = useRef<HTMLDivElement>(null)
  const aerobicaChartRef = useRef<HTMLDivElement>(null)
  const flexibilidadChartRef = useRef<HTMLDivElement>(null)
  const equilibrioChartRef = useRef<HTMLDivElement>(null)
  const saludChartRef = useRef<HTMLDivElement>(null)

  // Contenedor oculto para renderizar los gráficos temporalmente
  const hiddenChartsContainerRef = useRef<HTMLDivElement>(null)

  // Función auxiliar para manejar textos seguros en jsPDF (evita undefined/null)
  const safeText = (text: any): string => {
    if (text === undefined || text === null) return ''
    return String(text)
  }

  // Datos del informe con valores por defecto
  const data = {
    titulo: informeData.titulo ?? 'Informe Plan Activa',
    periodo: informeData.periodo ?? '10 Febrero 2024 - 09 Junio 2024',

    // Datos generales
    datosGenerales: {
      nombre: informeData.datosGenerales?.nombre ?? '',
      edad: informeData.datosGenerales?.edad ?? '',
      centroSalud: informeData.datosGenerales?.centroSalud ?? '',
      profesional: informeData.datosGenerales?.profesional ?? '',
      categoria: informeData.datosGenerales?.categoria ?? '',
      tipoPrograma: informeData.datosGenerales?.tipoPrograma ?? '',
      fechaInicio: informeData.datosGenerales?.fechaInicio ?? '',
      fechaFin: informeData.datosGenerales?.fechaFin ?? '',
      periodoInforme:
        informeData.datosGenerales?.periodoInforme ?? '10 Febrero 2024 - 09 Junio 2024',
    },

    // Datos específicos
    datosEspecificos: {
      centroDeportivo: informeData.datosEspecificos?.centroDeportivo ?? '',
      direccion: informeData.datosEspecificos?.direccion ?? '',
      ayuntamiento: informeData.datosEspecificos?.ayuntamiento ?? '',
      periodo: informeData.datosEspecificos?.periodo ?? '',
      grupo: informeData.datosEspecificos?.grupo ?? '',
      aforo: informeData.datosEspecificos?.aforo ?? '',
      nombreEFD: informeData.datosEspecificos?.nombreEFD ?? '',
      correoEFD: informeData.datosEspecificos?.correoEFD ?? '',
      diasEntrenamiento: informeData.datosEspecificos?.diasEntrenamiento ?? '',
    },

    // Valoración RCV
    valoracionRCV: {
      riesgoCardiovascular: informeData.valoracionRCV?.riesgoCardiovascular ?? 'Bajo',
    },

    // Sesiones y asistencia
    sesiones: {
      totalSesiones: informeData.sesiones?.totalSesiones ?? 28,
      sesionesRealizadas: informeData.sesiones?.sesionesRealizadas ?? 22,
      sesionesNoAsistidas: informeData.sesiones?.sesionesNoAsistidas ?? 6,
      asistenciaPromedio: informeData.sesiones?.asistenciaPromedio ?? 78.6,
    },

    // Asistencia media por mes
    asistenciaMensual: {
      labels: informeData.asistenciaMensual?.labels ?? [
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
      ],
      datos: informeData.asistenciaMensual?.datos ?? [80, 70, 75, 80, 82],
    },

    // Intensidad media del entrenamiento
    intensidadMensual: {
      labels: informeData.intensidadMensual?.labels ?? [
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
      ],
      datos: informeData.intensidadMensual?.datos ?? [2, 2, 3, 3, 4],
    },

    // Datos antropométricos
    datosAntropometricos: {
      fechas: informeData.datosAntropometricos?.fechas ?? [
        '2/10/2024',
        '3/11/2024',
        '4/10/2024',
        '5/10/2024',
        '6/9/2024',
      ],
      peso: informeData.datosAntropometricos?.peso ?? [70.15, 69.2, 67.11, 66.0, 65.21],
      imc: informeData.datosAntropometricos?.imc ?? [23, 22, 20, 20, 19],
    },

    // Condición física
    condicionFisica: {
      // Capacidad aeróbica
      testMilla: {
        fechas: informeData.condicionFisica?.testMilla?.fechas ?? [
          '2/10/2024',
          '3/11/2024',
          '4/10/2024',
          '5/10/2024',
          '6/9/2024',
        ],
        valores: informeData.condicionFisica?.testMilla?.valores ?? [44, 46, 45, 48, 46],
      },
      // Flexibilidad y fuerza
      testCajon: {
        fechas: informeData.condicionFisica?.testCajon?.fechas ?? [
          '01/02/2024',
          '01/03/2024',
          '01/04/2024',
          '01/05/2024',
          '01/06/2024',
        ],
        cajon: informeData.condicionFisica?.testCajon?.cajon ?? [8, 9, 9, 10, 10],
        lanzamiento: informeData.condicionFisica?.testCajon?.lanzamiento ?? [10, 8, 11, 9, 10],
      },
      // Equilibrio
      testFlamenco: {
        fechas: informeData.condicionFisica?.testFlamenco?.fechas ?? [
          '2/10/2024',
          '3/11/2024',
          '4/10/2024',
          '5/10/2024',
          '6/9/2024',
        ],
        intentos: informeData.condicionFisica?.testFlamenco?.intentos ?? [2, 1, 1, 2, 1],
      },
    },

    // Cuestionario de salud
    cuestionarioSalud: {
      fechas: informeData.cuestionarioSalud?.fechas ?? [
        '2/10/2024',
        '3/11/2024',
        '4/10/2024',
        '5/10/2024',
        '6/9/2024',
      ],
      porcentajeOptimo: informeData.cuestionarioSalud?.porcentajeOptimo ?? [
        55.95, 64.05, 64.05, 75.85, 94.45,
      ],
      saludFisica: informeData.cuestionarioSalud?.saludFisica ?? [12, 14.5, 14.5, 17, 20],
      saludMental: informeData.cuestionarioSalud?.saludMental ?? [14, 15, 15, 18, 24],
    },

    // Comentarios
    comentarios: informeData.comentarios ?? '',
  }

  // Configuración de los gráficos
  const chartConfigs: ChartConfigs = {
    // Gráfico circular de asistencia
    pieConfig: {
      data: {
        labels: ['Sesiones realizadas', 'Sesiones no asistidas'],
        datasets: [
          {
            data: [data.sesiones.sesionesRealizadas, data.sesiones.sesionesNoAsistidas],
            backgroundColor: ['#3498db', '#f39c12'],
            borderColor: ['#2980b9', '#e67e22'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Porcentaje de Asistencia',
            font: {
              size: 16,
            },
          },
        },
      },
    },

    // Gráfico de barras de asistencia mensual
    asistenciaConfig: {
      data: {
        labels: data.asistenciaMensual.labels,
        datasets: [
          {
            label: 'Asistencia (%)',
            data: data.asistenciaMensual.datos,
            backgroundColor: '#3498db',
            borderColor: '#2980b9',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Asistencia',
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
            title: {
              display: true,
              text: '%',
            },
          },
        },
      },
    },

    // Gráfico de línea de intensidad
    intensidadConfig: {
      data: {
        labels: data.intensidadMensual.labels,
        datasets: [
          {
            label: 'Intensidad',
            data: data.intensidadMensual.datos,
            backgroundColor: 'rgba(52, 152, 219, 0.2)',
            borderColor: '#3498db',
            borderWidth: 2,
            pointBackgroundColor: '#3498db',
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointRadius: 5,
            tension: 0.1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Intensidad',
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 5,
            title: {
              display: true,
              text: 'Valor',
            },
          },
        },
      },
    },

    // Gráfico combinado de datos antropométricos
    antropometricosConfig: {
      data: {
        labels: data.datosAntropometricos.fechas,
        datasets: [
          {
            type: 'bar',
            label: 'Peso',
            data: data.datosAntropometricos.peso,
            backgroundColor: '#3498db',
            borderColor: '#2980b9',
            borderWidth: 1,
            yAxisID: 'y',
          },
          {
            type: 'line',
            label: 'IMC',
            data: data.datosAntropometricos.imc,
            borderColor: '#e74c3c',
            backgroundColor: 'rgba(231, 76, 60, 0.2)',
            borderWidth: 2,
            pointBackgroundColor: '#e74c3c',
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointRadius: 5,
            fill: false,
            yAxisID: 'y1',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Peso e IMC',
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            beginAtZero: false,
            min: Math.min(...data.datosAntropometricos.peso) - 5,
            max: Math.max(...data.datosAntropometricos.peso) + 5,
            title: {
              display: true,
              text: 'Peso',
            },
          },
          y1: {
            type: 'linear',
            position: 'right',
            beginAtZero: false,
            min: Math.min(...data.datosAntropometricos.imc) - 2,
            max: Math.max(...data.datosAntropometricos.imc) + 2,
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'IMC',
            },
          },
        },
      },
    },

    // Gráfico de capacidad aeróbica
    aerobicaConfig: {
      data: {
        labels: data.condicionFisica.testMilla.fechas,
        datasets: [
          {
            label: 'Consumo de O2 (ml/kg/min)',
            data: data.condicionFisica.testMilla.valores,
            backgroundColor: '#3498db',
            borderColor: '#2980b9',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Test de la milla: Consumo de O2',
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            min: 42,
            max: 50,
            title: {
              display: true,
              text: 'ml/kg/min',
            },
          },
        },
      },
    },

    // Gráfico de flexibilidad y fuerza
    flexibilidadConfig: {
      data: {
        labels: data.condicionFisica.testCajon.fechas,
        datasets: [
          {
            label: 'Test del cajón (cm)',
            data: data.condicionFisica.testCajon.cajon,
            backgroundColor: '#3498db',
            borderColor: '#2980b9',
            borderWidth: 1,
            yAxisID: 'y',
          },
          {
            label: 'Lanzamiento de balón (m)',
            data: data.condicionFisica.testCajon.lanzamiento,
            backgroundColor: '#e74c3c',
            borderColor: '#c0392b',
            borderWidth: 1,
            yAxisID: 'y1',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Test del cajón & Lanzamiento de balón',
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            min: 0,
            max: 12,
            title: {
              display: true,
              text: 'cm',
            },
          },
          y1: {
            type: 'linear',
            position: 'right',
            min: 0,
            max: 12,
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'm',
            },
          },
        },
      },
    },

    // Gráfico de equilibrio
    equilibrioConfig: {
      data: {
        labels: data.condicionFisica.testFlamenco.fechas,
        datasets: [
          {
            label: 'Intentos',
            data: data.condicionFisica.testFlamenco.intentos,
            backgroundColor: '#3498db',
            borderColor: '#2980b9',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Test del flamenco',
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 3,
            ticks: {
              stepSize: 1,
            },
            title: {
              display: true,
              text: 'N.º intentos',
            },
          },
        },
      },
    },

    // Gráfico de cuestionario de salud
    saludConfig: {
      data: {
        labels: data.cuestionarioSalud.fechas,
        datasets: [
          {
            label: 'Porcentaje óptimo total',
            data: data.cuestionarioSalud.porcentajeOptimo,
            borderColor: '#3498db',
            backgroundColor: 'rgba(52, 152, 219, 0.1)',
            borderWidth: 2,
            pointBackgroundColor: '#3498db',
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointRadius: 5,
            fill: false,
            yAxisID: 'y',
          },
          {
            label: 'Salud física',
            data: data.cuestionarioSalud.saludFisica,
            borderColor: '#e74c3c',
            backgroundColor: 'rgba(231, 76, 60, 0.1)',
            borderWidth: 2,
            pointBackgroundColor: '#e74c3c',
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointRadius: 5,
            fill: false,
            yAxisID: 'y1',
          },
          {
            label: 'Salud mental',
            data: data.cuestionarioSalud.saludMental,
            borderColor: '#2ecc71',
            backgroundColor: 'rgba(46, 204, 113, 0.1)',
            borderWidth: 2,
            pointBackgroundColor: '#2ecc71',
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointRadius: 5,
            fill: false,
            yAxisID: 'y1',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Cuestionario de salud',
            font: {
              size: 16,
            },
          },
        },
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            beginAtZero: true,
            max: 100,
            title: {
              display: true,
              text: '%',
            },
          },
          y1: {
            type: 'linear',
            position: 'right',
            beginAtZero: true,
            max: 30,
            grid: {
              drawOnChartArea: false,
            },
            title: {
              display: true,
              text: 'Puntuación',
            },
          },
        },
      },
    },
  }

  // Función para generar el PDF
  const generatePDF = async (): Promise<void> => {
    setIsGenerating(true)

    try {
      // 1. Primero, renderizar todos los gráficos y capturarlos como imágenes
      const chartImages = await renderChartImages()

      // 2. Crear un nuevo documento PDF
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4')
      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()
      const margin = 10
      const contentWidth = pageWidth - 2 * margin

      // 3. Generar las páginas
      // PÁGINA 1
      renderPage1(pdf, pageWidth, pageHeight, margin, contentWidth, chartImages)

      // PÁGINA 2
      pdf.addPage()
      renderPage2(pdf, pageWidth, pageHeight, margin, contentWidth, chartImages)

      // PÁGINA 3
      pdf.addPage()
      renderPage3(pdf, pageWidth, pageHeight, margin, contentWidth, chartImages)

      // PÁGINA 4
      pdf.addPage()
      renderPage4(pdf, pageWidth, pageHeight, margin, contentWidth, chartImages)

      // 4. Guardar el PDF
      pdf.save(fileName)
    } catch (error) {
      console.error('Error al generar el PDF:', error)
    } finally {
      setIsGenerating(false)
    }
  }

  // Función para renderizar y capturar todos los gráficos como imágenes
  const renderChartImages = async (): Promise<ChartImages> => {
    // Asegurarse de que el contenedor esté visible
    const container = hiddenChartsContainerRef.current
    if (!container) return {}

    // Hacer visible el contenedor
    container.style.display = 'block'

    // Objeto para almacenar las imágenes de los gráficos
    const chartImages: ChartImages = {}

    // Función auxiliar para capturar un gráfico como imagen
    const captureChart = async (
      chartRef: React.RefObject<HTMLDivElement>,
      chartId: string
    ): Promise<string | null> => {
      if (!chartRef.current) return null

      try {
        const canvas = await html2canvas(chartRef.current, {
          scale: 2,
          useCORS: true,
          logging: false,
        })

        return canvas.toDataURL('image/png')
      } catch (error) {
        console.error(`Error capturando gráfico ${chartId}:`, error)
        return null
      }
    }

    // Capturar cada gráfico
    const pieImage = await captureChart(pieChartRef, 'pie')
    if (pieImage) chartImages.pie = pieImage

    const asistenciaImage = await captureChart(asistenciaChartRef, 'asistencia')
    if (asistenciaImage) chartImages.asistencia = asistenciaImage

    const intensidadImage = await captureChart(intensidadChartRef, 'intensidad')
    if (intensidadImage) chartImages.intensidad = intensidadImage

    const antropometricosImage = await captureChart(antropometricosChartRef, 'antropometricos')
    if (antropometricosImage) chartImages.antropometricos = antropometricosImage

    const aerobicaImage = await captureChart(aerobicaChartRef, 'aerobica')
    if (aerobicaImage) chartImages.aerobica = aerobicaImage

    const flexibilidadImage = await captureChart(flexibilidadChartRef, 'flexibilidad')
    if (flexibilidadImage) chartImages.flexibilidad = flexibilidadImage

    const equilibrioImage = await captureChart(equilibrioChartRef, 'equilibrio')
    if (equilibrioImage) chartImages.equilibrio = equilibrioImage

    const saludImage = await captureChart(saludChartRef, 'salud')
    if (saludImage) chartImages.salud = saludImage

    // Ocultar nuevamente el contenedor
    container.style.display = 'none'

    return chartImages
  }

  // Función para renderizar el encabezado
  const renderHeader = (pdf: jsPDF, pageWidth: number, title: string, margin: number): void => {
    // Título y fecha en el encabezado
    pdf.setFontSize(16)
    pdf.setTextColor(68, 68, 68)
    pdf.text(safeText(title), margin, 20)
    pdf.setFontSize(14)
    pdf.text(
      safeText(data.periodo),
      pageWidth - margin - pdf.getTextWidth(safeText(data.periodo)),
      20
    )

    // Línea naranja debajo del encabezado
    pdf.setDrawColor(243, 156, 18)
    pdf.setLineWidth(0.5)
    pdf.line(margin, 22, pageWidth - margin, 22)
  }

  // Renderizar título de sección con línea azul
  const renderSectionTitle = (
    pdf: jsPDF,
    title: string,
    y: number,
    margin: number,
    pageWidth: number
  ): number => {
    pdf.setFontSize(14)
    pdf.setTextColor(68, 68, 68)
    pdf.text(safeText(title), margin, y)

    // Línea azul debajo del título
    pdf.setDrawColor(52, 152, 219)
    pdf.setLineWidth(0.5)
    pdf.line(margin, y + 2, pageWidth - margin, y + 2)

    return y + 10 // Retornar la siguiente posición Y
  }

  // PÁGINA 1: Título, Datos generales, Datos específicos, Valoración RCV, Sesiones y asistencia total
  const renderPage1 = (
    pdf: jsPDF,
    pageWidth: number,
    pageHeight: number,
    margin: number,
    contentWidth: number,
    chartImages: ChartImages
  ): void => {
    let yPos = 15

    // Encabezado
    renderHeader(pdf, pageWidth, data.titulo, margin)
    yPos = 35

    // Datos generales
    yPos = renderSectionTitle(pdf, 'Datos generales', yPos, margin, pageWidth)

    // Recuadro para datos generales
    pdf.setDrawColor(221, 221, 221)
    pdf.setLineWidth(0.3)
    pdf.roundedRect(margin, yPos, contentWidth, 70, 3, 3)

    // Columna izquierda
    pdf.setFontSize(10)
    pdf.setTextColor(68, 68, 68)
    const leftCol = yPos + 8

    // Nombre
    pdf.text('Nombre del paciente', margin + 5, leftCol)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.nombre), margin + 5, leftCol + 5)
    pdf.setTextColor(68, 68, 68)

    // Edad
    pdf.text('Edad', margin + 5, leftCol + 15)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.edad), margin + 5, leftCol + 20)
    pdf.setTextColor(68, 68, 68)

    // Centro de salud
    pdf.text('Centro de salud', margin + 5, leftCol + 30)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.centroSalud), margin + 5, leftCol + 35)
    pdf.setTextColor(68, 68, 68)

    // Profesional
    pdf.text('Profesional que prescribe', margin + 5, leftCol + 45)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.profesional), margin + 5, leftCol + 50)
    pdf.setTextColor(68, 68, 68)

    // Categoría
    pdf.text('Categoría del profesional', margin + 5, leftCol + 60)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.categoria), margin + 5, leftCol + 65)
    pdf.setTextColor(68, 68, 68)

    // Columna derecha
    const middlePoint = pageWidth / 2

    // Tipo de programa
    pdf.text('Tipo de programa', middlePoint, leftCol)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.tipoPrograma), middlePoint, leftCol + 5)
    pdf.setTextColor(68, 68, 68)

    // Fecha inicio
    pdf.text('Fecha de inicio del programa', middlePoint, leftCol + 15)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.fechaInicio), middlePoint, leftCol + 20)
    pdf.setTextColor(68, 68, 68)

    // Fecha fin
    pdf.text('Fecha de finalización del programa', middlePoint, leftCol + 30)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.fechaFin), middlePoint, leftCol + 35)
    pdf.setTextColor(68, 68, 68)

    // Período
    pdf.text('Período del informe', middlePoint, leftCol + 45)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosGenerales.periodoInforme), middlePoint, leftCol + 50)
    pdf.setTextColor(68, 68, 68)

    yPos += 80 // Avanzar después del recuadro

    // Datos específicos
    yPos = renderSectionTitle(pdf, 'Datos específicos', yPos, margin, pageWidth)

    // Recuadro para datos específicos
    pdf.setDrawColor(221, 221, 221)
    pdf.setLineWidth(0.3)
    pdf.roundedRect(margin, yPos, contentWidth, 70, 3, 3)

    // Columna izquierda
    const leftColEsp = yPos + 8

    // Centro deportivo
    pdf.text('Centro deportivo', margin + 5, leftColEsp)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.centroDeportivo), margin + 5, leftColEsp + 5)
    pdf.setTextColor(68, 68, 68)

    // Dirección
    pdf.text('Dirección', margin + 5, leftColEsp + 15)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.direccion), margin + 5, leftColEsp + 20)
    pdf.setTextColor(68, 68, 68)

    // Ayuntamiento
    pdf.text('Ayuntamiento asociado', margin + 5, leftColEsp + 30)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.ayuntamiento), margin + 5, leftColEsp + 35)
    pdf.setTextColor(68, 68, 68)

    // Período
    pdf.text('Período', margin + 5, leftColEsp + 45)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.periodo), margin + 5, leftColEsp + 50)
    pdf.setTextColor(68, 68, 68)

    // Grupo
    pdf.text('Grupo al que pertenece', margin + 5, leftColEsp + 60)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.grupo), margin + 5, leftColEsp + 65)
    pdf.setTextColor(68, 68, 68)

    // Columna derecha
    // Aforo
    pdf.text('Aforo', middlePoint, leftColEsp)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.aforo), middlePoint, leftColEsp + 5)
    pdf.setTextColor(68, 68, 68)

    // Nombre EFD
    pdf.text('Nombre EFD', middlePoint, leftColEsp + 15)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.nombreEFD), middlePoint, leftColEsp + 20)
    pdf.setTextColor(68, 68, 68)

    // Correo EFD
    pdf.text('Correo EFD', middlePoint, leftColEsp + 30)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.correoEFD), middlePoint, leftColEsp + 35)
    pdf.setTextColor(68, 68, 68)

    // Días de entrenamiento
    pdf.text('Días de entrenamiento y horario', middlePoint, leftColEsp + 45)
    pdf.setTextColor(119, 119, 119)
    pdf.text(safeText(data.datosEspecificos.diasEntrenamiento), middlePoint, leftColEsp + 50)
    pdf.setTextColor(68, 68, 68)

    yPos += 80 // Avanzar después del recuadro

    // Valoración RCV
    yPos = renderSectionTitle(pdf, 'Valoración RCV', yPos, margin, pageWidth)

    pdf.setFontSize(10)
    pdf.text('Riesgo cardiovascular', margin + 5, yPos + 10)

    // Color según nivel de riesgo
    const riesgoColor =
      data.valoracionRCV.riesgoCardiovascular.toLowerCase() === 'bajo'
        ? [39, 174, 96] // verde
        : data.valoracionRCV.riesgoCardiovascular.toLowerCase() === 'medio'
          ? [243, 156, 18] // naranja
          : [231, 76, 60] // rojo

    pdf.setTextColor(riesgoColor[0], riesgoColor[1], riesgoColor[2])
    pdf.setFontSize(11)
    pdf.text(safeText(data.valoracionRCV.riesgoCardiovascular), margin + 120, yPos + 10)
    pdf.setTextColor(68, 68, 68)

    yPos += 20 // Avanzar después de la valoración RCV

    // Sesiones y asistencia total
    yPos = renderSectionTitle(pdf, 'Sesiones y asistencia total', yPos, margin, pageWidth)

    // Información de sesiones
    pdf.setFontSize(10)
    pdf.text(
      `• Número total de sesiones durante el programa: ${data.sesiones.totalSesiones} sesiones`,
      margin + 5,
      yPos + 10
    )
    pdf.text(
      `• Sesiones realizadas totales: ${data.sesiones.sesionesRealizadas} sesiones`,
      margin + 5,
      yPos + 17
    )
    pdf.text(
      `• Sesiones marcadas como no asistidas: ${data.sesiones.sesionesNoAsistidas} sesiones`,
      margin + 5,
      yPos + 24
    )
    pdf.text(
      `• Asistencia promedio total: ${data.sesiones.asistenciaPromedio}%`,
      margin + 5,
      yPos + 31
    )

    // Gráfico de asistencia (pastel)
    if (chartImages.pie) {
      pdf.addImage(chartImages.pie, 'PNG', margin + 20, yPos + 40, contentWidth - 40, 70)
    }
  }

  // PÁGINA 2: Asistencia media por mes, Intensidad media por mes, Datos antropométricos
  const renderPage2 = (
    pdf: jsPDF,
    pageWidth: number,
    pageHeight: number,
    margin: number,
    contentWidth: number,
    chartImages: ChartImages
  ): void => {
    let yPos = 15

    // Encabezado
    // yPos = 35;

    // Asistencia media por mes
    yPos = renderSectionTitle(pdf, 'Asistencia media por mes', yPos, margin, pageWidth)

    // Gráfico de asistencia mensual
    if (chartImages.asistencia) {
      pdf.addImage(chartImages.asistencia, 'PNG', margin, yPos + 5, contentWidth, 70)
    }

    yPos += 85 // Avanzar después del gráfico

    // Intensidad media del entrenamiento por mes
    yPos = renderSectionTitle(
      pdf,
      'Intensidad media del entrenamiento por mes',
      yPos,
      margin,
      pageWidth
    )

    // Gráfico de intensidad
    if (chartImages.intensidad) {
      pdf.addImage(chartImages.intensidad, 'PNG', margin, yPos + 5, contentWidth, 70)
    }

    yPos += 80 // Avanzar después del gráfico

    // Datos antropométricos
    yPos = renderSectionTitle(pdf, 'Datos antropométricos', yPos, margin, pageWidth)

    // Subtítulo peso e IMC
    pdf.setFontSize(12)
    pdf.text('Peso e IMC', margin + 5, yPos + 10)

    // Gráfico de datos antropométricos
    if (chartImages.antropometricos) {
      pdf.addImage(chartImages.antropometricos, 'PNG', margin, yPos + 15, contentWidth, 70)
    }
  }

  // PÁGINA 3: Condición física (Capacidad aeróbica, Flexibilidad y fuerza, Equilibrio)
  const renderPage3 = (
    pdf: jsPDF,
    pageWidth: number,
    pageHeight: number,
    margin: number,
    contentWidth: number,
    chartImages: ChartImages
  ): void => {
    let yPos = 15

    // Encabezado
    // yPos = 35;

    // Condición física
    yPos = renderSectionTitle(pdf, 'Condición física', yPos, margin, pageWidth)

    // Capacidad aeróbica
    pdf.setFontSize(12)
    pdf.text('Capacidad aeróbica', margin + 5, yPos + 10)

    // Gráfico capacidad aeróbica
    if (chartImages.aerobica) {
      pdf.addImage(chartImages.aerobica, 'PNG', margin, yPos + 15, contentWidth, 70)
    }

    yPos += 95 // Avanzar después del gráfico

    // Flexibilidad y fuerza
    pdf.setFontSize(12)
    pdf.text('Flexibilidad y fuerza', margin + 5, yPos)

    // Gráfico flexibilidad y fuerza
    if (chartImages.flexibilidad) {
      pdf.addImage(chartImages.flexibilidad, 'PNG', margin, yPos + 5, contentWidth, 70)
    }

    yPos += 85 // Avanzar después del gráfico

    // Equilibrio
    pdf.setFontSize(12)
    pdf.text('Equilibrio', margin + 5, yPos)

    // Gráfico equilibrio
    if (chartImages.equilibrio) {
      pdf.addImage(chartImages.equilibrio, 'PNG', margin, yPos + 5, contentWidth, 70)
    }
  }

  // PÁGINA 4: Cuestionario de salud, Comentarios EFD
  const renderPage4 = (
    pdf: jsPDF,
    pageWidth: number,
    pageHeight: number,
    margin: number,
    contentWidth: number,
    chartImages: ChartImages
  ): void => {
    let yPos = 15

    // Encabezado
    yPos = 35

    // Cuestionario de salud
    yPos = renderSectionTitle(pdf, 'Cuestionario de salud SF-12', yPos, margin, pageWidth)

    // Gráfico cuestionario de salud
    if (chartImages.salud) {
      pdf.addImage(chartImages.salud, 'PNG', margin, yPos + 5, contentWidth, 100)
    }

    yPos += 115 // Avanzar después del gráfico

    // Comentarios EFD
    yPos = renderSectionTitle(pdf, 'Comentarios del EFD', yPos, margin, pageWidth)

    // Recuadro para comentarios
    pdf.setDrawColor(221, 221, 221)
    pdf.setLineWidth(0.3)
    pdf.roundedRect(margin, yPos + 5, contentWidth, 80, 3, 3)

    // Texto de comentarios
    pdf.setFontSize(10)
    pdf.setTextColor(119, 119, 119)

    // Dividir el texto en líneas para mostrarlo correctamente
    const comentarios = safeText(data.comentarios || 'Sin comentarios registrados.')
    const textLines = pdf.splitTextToSize(comentarios, contentWidth - 10)
    pdf.text(textLines, margin + 5, yPos + 15)

    pdf.setTextColor(68, 68, 68)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<PictureAsPdfIcon />}
        onClick={generatePDF}
        disabled={isGenerating}
        {...buttonProps}
      >
        {isGenerating ? 'Generando...' : buttonText}
      </Button>

      {/* Contenedor oculto para renderizar los gráficos temporalmente */}
      <div
        ref={hiddenChartsContainerRef}
        style={{ display: 'none', position: 'absolute', left: '-9999px' }}
      >
        {/* Gráfico circular de asistencia */}
        <div style={{ width: '400px', height: '300px' }} ref={pieChartRef}>
          <Pie data={chartConfigs.pieConfig.data} options={chartConfigs.pieConfig.options} />
        </div>

        {/* Gráfico de barras de asistencia */}
        <div style={{ width: '600px', height: '300px' }} ref={asistenciaChartRef}>
          <Bar
            data={chartConfigs.asistenciaConfig.data}
            options={chartConfigs.asistenciaConfig.options}
          />
        </div>

        {/* Gráfico de línea de intensidad */}
        <div style={{ width: '600px', height: '300px' }} ref={intensidadChartRef}>
          <Line
            data={chartConfigs.intensidadConfig.data}
            options={chartConfigs.intensidadConfig.options}
          />
        </div>

        {/* Gráfico combinado de datos antropométricos */}
        <div style={{ width: '600px', height: '300px' }} ref={antropometricosChartRef}>
          <Bar
            data={chartConfigs.antropometricosConfig.data}
            options={chartConfigs.antropometricosConfig.options}
          />
        </div>

        {/* Gráfico de capacidad aeróbica */}
        <div style={{ width: '600px', height: '300px' }} ref={aerobicaChartRef}>
          <Bar
            data={chartConfigs.aerobicaConfig.data}
            options={chartConfigs.aerobicaConfig.options}
          />
        </div>

        {/* Gráfico de flexibilidad y fuerza */}
        <div style={{ width: '600px', height: '300px' }} ref={flexibilidadChartRef}>
          <Bar
            data={chartConfigs.flexibilidadConfig.data}
            options={chartConfigs.flexibilidadConfig.options}
          />
        </div>

        {/* Gráfico de equilibrio */}
        <div style={{ width: '600px', height: '300px' }} ref={equilibrioChartRef}>
          <Bar
            data={chartConfigs.equilibrioConfig.data}
            options={chartConfigs.equilibrioConfig.options}
          />
        </div>

        {/* Gráfico de cuestionario de salud */}
        <div style={{ width: '600px', height: '300px' }} ref={saludChartRef}>
          <Line data={chartConfigs.saludConfig.data} options={chartConfigs.saludConfig.options} />
        </div>
      </div>
    </>
  )
}

export default PDFViewerWithCanvas
