import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import stepIcon from 'assets/dashboard/stepsIcon.svg'
import caloriesIcon from 'assets/dashboard/caloriesIcon.svg'
import style from './MonitoredActivityCard.module.scss'
import { useTranslation } from 'react-i18next'
import {
  avatarStyle,
  dividerStyle,
  TypographyProfileText,
  TypographyProfileTitleInformation,
} from './MonitoredActivityCardStyleMui'
import ProgressCircle from 'components/dashboard/circle-progress/CircleProgress'
import ProgressSemiCircle from 'components/dashboard/circle-progress/SemiCircleProgress'
import { dateToYYYYMMDD } from 'common/utils/date'
import { getPatientDataContainer } from 'container/patient-data-module'
import { WearableService } from 'modules/patient-data/services/WearableService'
import { WEARABLE_SERVICE_KEY } from 'modules/patient-data/container'
import styles from '../../../patient-data/wearables/Wearables.module.css'
import genericStyle from 'common/utils/generic.module.css'
import { Graphs } from '../../../patient-data/wearables/Graphs'
import { finalize } from 'rxjs/operators'
import { MontioredActivityCardText } from './MonitoredActivityCardText'

type MonitoredActivityCardProps = {}

const patientDataContainer = getPatientDataContainer()
const wearableService = patientDataContainer.get<WearableService>(WEARABLE_SERVICE_KEY)

export const MonitoredActivityCard: React.FC<MonitoredActivityCardProps> = () => {
  const { t } = useTranslation()
  const isXS = useMediaQuery('max-width: 599px')
  const [wearablesData, setWearablesData] = useState<any>({})
  const [visibleCard, setVisibleCard] = useState<boolean>(false)
  const [dataType, setType] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    // let date = new Date()
    setIsLoading(true)
    const newDate = dateToYYYYMMDD(new Date())
    wearableService
      .getData(newDate)
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        // alert(res)
        if (res) {
          setWearablesData(res)
        }
        // console.log(res)
      })
  }, [])

  const getDistances = (): any => {
    if (
      wearablesData.dailyActivitySummaryResponse &&
      wearablesData.dailyActivitySummaryResponse.summary.distances &&
      wearablesData.dailyActivitySummaryResponse.summary.distances.length > 0
    ) {
      let aux = 0
      aux = wearablesData.dailyActivitySummaryResponse.summary.distances
        .find((obj: { activity: string }) => obj.activity === 'total')
        .distance.toFixed(2)
      return aux
    }
    return '0 '
  }

  const getGoalDistance = (): any => {
    if (wearablesData.dailyActivitySummaryResponse) {
      const aux = wearablesData.dailyActivitySummaryResponse.goals.distance
      if (aux === 0) return '0'
      const total = (getDistances() / aux) * 100
      return total === Infinity ? 0 : Math.trunc(total)
    }
    return '0 '
  }

  const getSleepEfficiency = (): any => {
    if (!wearablesData?.sleep?.length) {
      return '0 '
    }

    // Find the main sleep entry
    const mainSleep = wearablesData.sleep.find((sleep: { isMainSleep: any }) => sleep.isMainSleep)

    // If there's a main sleep, return its efficiency, otherwise return 0
    return mainSleep ? mainSleep.efficiency : 0
  }

  const parseSleepMinutes = (): string => {
    if (!wearablesData?.sleep?.length) {
      return '0 '
    }

    // Sum up all minutesAsleep across all sleep entries
    const totalMinutes = wearablesData.sleep.reduce(
      (total: any, sleep: { minutesAsleep: any }) => total + (sleep.minutesAsleep || 0),
      0
    )

    // Convert to hours and minutes format
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    // Format with leading zeros
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

    return `${formattedHours}:${formattedMinutes}`
  }

  const changeToGraphic = (type: string): any => {
    setType(type)
    setVisibleCard(true)
  }

  const returnToWearables = (): any => {
    setVisibleCard(false)
  }

  if (visibleCard) {
    return (
      <Box className={genericStyle.pageContainer}>
        <Box
          className={styles.chartContainer}
          flexGrow={'1'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Graphs returnToWearables={returnToWearables} type={dataType} />
        </Box>
      </Box>
    )
  }

  return (
    <Grid container direction={isXS ? 'column' : 'row'} spacing={2} mt={1}>
      <Grid
        display={'flex'}
        flexWrap={'wrap'}
        flexDirection={'row'}
        mt={4}
        item
        xs={12}
        sm={6}
        md={12}
      >
        <Grid item xs={12} sm={6} md={3} className={styles.marginCard}>
          <Card className={style.cardProfile}>
            <CardContent className={style.containerCard}>
              <Avatar
                sx={avatarStyle}
                src={wearablesData.userResponse && wearablesData.userResponse.user.avatar640}
              />
              <MontioredActivityCardText isLoading={isLoading}>
                <Typography variant="h6" gutterBottom>
                  {`${wearablesData.userResponse && wearablesData.userResponse.user.displayName}`}
                </Typography>
              </MontioredActivityCardText>
              <Box display={'flex'} mt={4}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography sx={TypographyProfileTitleInformation}>{t('age')}</Typography>
                    <MontioredActivityCardText isLoading={isLoading}>
                      <Typography sx={TypographyProfileText}>
                        {wearablesData.userResponse && wearablesData.userResponse.user.age} años
                      </Typography>
                    </MontioredActivityCardText>
                  </Box>
                  <Divider orientation={'vertical'} sx={dividerStyle} />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography sx={TypographyProfileTitleInformation}>{t('height')}</Typography>
                    <MontioredActivityCardText isLoading={isLoading}>
                      <Typography sx={TypographyProfileText}>
                        {wearablesData.userResponse && wearablesData.userResponse.user.height} cm
                      </Typography>
                    </MontioredActivityCardText>
                  </Box>
                  <Divider orientation={'vertical'} sx={dividerStyle} />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography sx={TypographyProfileTitleInformation}>{t('weight')}</Typography>
                    <MontioredActivityCardText isLoading={isLoading}>
                      <Typography sx={TypographyProfileText}>
                        {wearablesData.userResponse && wearablesData.userResponse.user.weight} Kg
                      </Typography>
                    </MontioredActivityCardText>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={6}
          sm={2}
          md={2}
          className={styles.marginCard}
          onClick={() => changeToGraphic('steps')}
        >
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <Avatar src={stepIcon} sx={avatarStyle} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('steps')}
                </Typography>
                <MontioredActivityCardText isLoading={isLoading}>
                  <Typography sx={TypographyProfileText}>
                    {wearablesData.dailyActivitySummaryResponse &&
                      wearablesData.dailyActivitySummaryResponse.summary.steps}{' '}
                    {t('today')}
                  </Typography>
                </MontioredActivityCardText>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          className={styles.marginCard}
          onClick={() => changeToGraphic('calories')}
        >
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <Avatar src={caloriesIcon} sx={avatarStyle} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('calories')}
                </Typography>
                <MontioredActivityCardText isLoading={isLoading}>
                  <Typography sx={TypographyProfileText}>
                    {wearablesData.dailyActivitySummaryResponse &&
                      wearablesData.dailyActivitySummaryResponse.summary.caloriesOut}{' '}
                    {t('today')}
                  </Typography>
                </MontioredActivityCardText>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          className={styles.marginCard}
          onClick={() => changeToGraphic('distance')}
        >
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <ProgressSemiCircle percentage={getGoalDistance()} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('distance')}
                </Typography>
                <MontioredActivityCardText isLoading={isLoading}>
                  <Typography sx={TypographyProfileText}>
                    {getDistances() + 'km ' + t('today')}
                  </Typography>
                </MontioredActivityCardText>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          className={styles.marginCard}
          onClick={() => changeToGraphic('sleepHours')}
        >
          <Card className={styles.card}>
            <CardContent className={styles.containerCard}>
              <ProgressCircle progress={getSleepEfficiency()} />
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  {t('sleepHours')}
                </Typography>
                <MontioredActivityCardText isLoading={isLoading}>
                  <Typography sx={TypographyProfileText}>
                    {' '}
                    {parseSleepMinutes() + ' h ' + t('today')}
                  </Typography>
                </MontioredActivityCardText>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}
