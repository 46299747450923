/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  MenuItem,
  Modal,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { Header } from '../../../components/header/Header'
import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { TextField, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Actions, Field, SearchValue } from '../../../components/table_type/types'
import editIcon from '../../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../../assets/table_icons/ico-eliminar.svg'
import close from '../../../assets/table_icons/ico-eliminar.svg'
import viewIcon from '../../../assets/table_icons/ico-ver.svg'
import printIcon from '../../../assets/table_icons/ico-download.svg'
import { ReportModal, ReportProps } from './ReportModal'
import { ReportPreview, ReportToPrint } from './ReportPreview'
import { getReportContainer } from '../../../container/report-module'
import { ReportService } from '../../../modules/report/services/ReportService'
import { REPORT_SERVICE_KEY } from '../../../modules/report'
import { useGlobalContext } from '../../../common/utils/GlobalRoleContext'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import styles from './ActivePlan.module.css'
import styleModal from './CustomModal.module.css'
import { Body } from '../../../components/table/Body'
// import { PrintPDFComponent } from './PrintPDF'
import { getUserContainer } from '../../../container/user-module'
import { LoggedUserService } from '../../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../../modules/users'
import { useCircleConfiguration } from '../../../common/utils/circle-config-context/CircleConfigContext'
// ts juan
import { Roles } from '../../../modules/users/enums/Roles'
import { ReportQuery } from 'modules/report/models/Report'
import { Query, QueryParam, QueryParamN } from 'common/api/Query'
import { SelectChangeEvent } from '@mui/material/Select'
import { finalize } from 'rxjs/operators'

import PDFViewerWithCanvas from './PrintPDF'
import { dateDateToAge } from 'common/utils/date'
import { getCityHallContainer } from 'container/cityHall-module'
import { CityHallService } from 'modules/cityHall/services/CityHallService'
import { CITY_HALL_SERVICE_KEY } from 'modules/cityHall'
import { CityHallDTO } from 'modules/cityHall/models/CityHallDTO'

function EmptyReportProps() {
  return {
    generalData: true,
    attendanceMonth: true,
    startDate: new Date(),
    finishDate: new Date(),
    SF12: true,
    specificData: true,
    sessionsAttendance: false,
    intensityMonth: true,
    efdComments: true,
    RCV: true,
    antroData: true,
    totalSessions: true,
    condition: true,
  }
}

type Order = 'asc' | 'desc'

type ReportType = {
  createdAt: string
  createdByUserID: string
  createdByUserString: string
  period?: string
  finishDate: Date
  generalInfoID: string
  id: string
  rcvValoration: string
  sessionsAttendance: string
  specificInfoID: string
  startDate: Date
  userCircleID: string
  // new addition pay attention later on in case something else is needed
  healthFormulary?: string
  anthropometric?: string
  averageAttendancePerMonth?: string
  physicalCondition?: string
  edfComments?: string
  averageIntensityPerMonth?: string
  [key: string]: any
}

type ReportResponse = {
  items: ReportType[]
  count: number
}

function EmptyReport() {
  return {
    createdAt: '',
    createdByUserID: '',
    createdByUserString: '',
    finishDate: new Date(),
    generalInfoID: '',
    id: '',
    period: '',
    rcvValoration: '',
    sessionsAttendance: '',
    specificInfoID: '',
    startDate: new Date(),
    userCircleID: '',
  }
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const reportContainer = getReportContainer()
const reportService = reportContainer.get<ReportService>(REPORT_SERVICE_KEY)
// pdf info
const cityHallService = getCityHallContainer().get<CityHallService>(CITY_HALL_SERVICE_KEY)
// profesional
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

// report props for the momment
type ReportPropsType = {
  reportData: any // check this later
}

export function Reports({ reportData }: ReportPropsType) {
  const [showNewReport, setShowNewReport] = useState<boolean>(false)
  const [reports, setReports] = useState<{ items: ReportResponse[]; count: number }>({
    items: [],
    count: 0,
  })
  const [initReports, setInitReports] = useState<ReportResponse[]>([])
  const [dataToPrint, setDataToPrint] = useState({})
  const [reportSelected, setReportSelected] = useState<ReportType>(EmptyReport())
  const [reportProps, setReportProps] = useState<ReportProps | {}>({})

  const [cityHallSelectedUser, setcityHallSelectedUser] = useState<CityHallDTO>()

  const [refreshList, setRefresList] = useState(false)
  const [professionalPrescription, setProfPrescription] = useState<string>('')

  const [previewReportModal, setPreviewReportModal] = useState<boolean>(false)
  const [showRemoveReport, setShowRemoveReport] = useState<boolean>(false)
  const [showDeleted, setShowDeleted] = useState<boolean>(false)
  const [generate, setDisabledGenerate] = useState<boolean>(false)
  const [modalLabel, setModalLabel] = useState<string>('')
  const [createdBy, setCreatedBy] = useState<string>('')
  const [minValue, setMinValue] = useState()
  const [maxValue, setMaxValue] = useState()
  const [selectedReportId, setSelectedReportId] = useState<string>('')
  const [selectedReport, setSelectedReport] = useState<object>({})
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const { selectedUserCircle } = useCircleConfiguration()

  // const [toDate, setToDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() + 365)))
  // @ts-ignore
  const [toDate, setToDate] = useState<Date>(null)
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  // @ts-ignore
  const [fromDate, setFromDate] = useState<Date>(null)
  // const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 8)))
  const { role } = useGlobalContext()
  const rolesForNewReportBtn = [
    Roles.ProfessionalExtern,
    Roles.Professional,
    Roles.FamilyOrTutor,
    Roles.Patient,
    Roles.ManagerActivePlan,
  ]
  const containsRoleToAllow = role.map((role) =>
    rolesForNewReportBtn.includes(role.name as Roles)
  )[0]

  // temporary state for general data
  interface GeneralData {
    patientName: string
    age: string
    programType: string | undefined
    period: string
    professional: string | undefined
    center: string
    professionalCategory: string | undefined
    dateStartProgram: string
    dateFinishProgram: string
  }
  const [generalData, setGeneralData] = useState<GeneralData | undefined>(undefined)

  //

  useEffect(() => {
    if (!selectedUserCircle) return

    cityHallService.getByID(sportCenter.cityHallID).subscribe((res) => {
      if (!res) return
      setcityHallSelectedUser(res)
    })
  }, [selectedUserCircle]) // add / check deps later on

  const [searcher, setSearcher] = useState<SearchValue<ReportQuery>[]>([
    {
      name: 'createdBy',
      label: t('createdBy'),
    },
    { name: 'startDate', label: t('Start Date'), type: 'date' },
    { name: 'finishDate', label: t('Finish Date'), type: 'date' },
  ])

  // DATA PARA EL PDF
  const { activePlan, sportCenter, group } = reportData

  useEffect(() => {
    userService.getByDoctorID(activePlan.userProfessionalID).subscribe((res) => {
      if (!res) return
      const fullNameProfPrescription = res.name + '-' + res.lastname
      setProfPrescription(fullNameProfPrescription)
    })
  }, [activePlan.userProfessionalID])

  const weekDaysNames = [
    t('sunday'),
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
  ]
  const formatTime = (timeStr: Date) => {
    const date = new Date(timeStr)
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }

  const rcvValoration = ['Bajo', 'Medio', 'Alto']

  const rcvPDF = rcvValoration[activePlan.rcv]

  const diasEntrenamiento = group.timeTable.map((entrenamiento: any) => {
    return weekDaysNames[entrenamiento._weekDay]
  })

  const diasUnicos = [...new Set(diasEntrenamiento)]
  const startTime =
    group.timeTable[0] && group.timeTable[0].startTime
      ? formatTime(group.timeTable[0].startTime)
      : 'Hora no disponible'

  const finishTime =
    group.timeTable[0] && group.timeTable[0].finishTime
      ? formatTime(group.timeTable[0].finishTime)
      : 'Hora no disponible'

  const diasStr = diasUnicos.join(', ').replace(/,([^,]*)$/, ' y$1')
  const resultado = `${diasStr} - ${startTime} - ${finishTime}`

  const informeData = {
    titulo: 'Informe Plan Activa',
    periodo:
      new Date(activePlan.startDate).toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }) +
      ' - ' +
      new Date(activePlan.finishDate).toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),

    // Datos generales
    datosGenerales: {
      nombre: selectedUserCircle?.user.name + ' - ' + selectedUserCircle?.user.lastname,
      edad:
        selectedUserCircle &&
        dateDateToAge(new Date(selectedUserCircle?.user.birthDate)).toString(), // check this line later on
      centroSalud: sportCenter.name,
      profesional: professionalPrescription,
      categoria: generalData?.professionalCategory, // ??
      tipoPrograma: activePlan.protocol,
      fechaInicio: new Date(activePlan.startDate).toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
      fechaFin: new Date(activePlan.finishDate).toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
      periodoInforme: generalData?.period, // estas fechas vienen del modal a la hora de crear el informe / Report  , los dos selectores de fechas, llamar a ese endpoint y extraer esos datos
    },

    // Datos específicos
    datosEspecificos: {
      centroDeportivo: sportCenter.name,
      direccion: sportCenter.location.split(',', 2),
      ayuntamiento: cityHallSelectedUser?.name,
      periodo: 'Febrero - Junio 2024', // ?? estas fechas vienen del modal a la hora de crear el informe , los dos selectores de fechas, llamar a ese endpoint y extraer esos datos
      grupo: 'Rehabilitación cardiovascular - Nivel 2', // ?? de donde saco esto ?
      aforo: group.capacity,
      nombreEFD: group.trainerName,
      correoEFD: group.trainerEmail,
      diasEntrenamiento: resultado,
    },

    // Valoración RCV
    valoracionRCV: {
      riesgoCardiovascular: rcvPDF,
    },

    // Sesiones y asistencia
    sesiones: {
      totalSesiones: 28,
      sesionesRealizadas: 22,
      sesionesNoAsistidas: 6,
      asistenciaPromedio: 78.6,
    },

    // Asistencia media por mes
    asistenciaMensual: {
      labels: ['Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
      datos: [80, 70, 75, 80, 82],
    },

    // Intensidad media del entrenamiento
    intensidadMensual: {
      labels: ['Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
      datos: [2, 2, 3, 3, 4],
    },

    // Datos antropométricos
    datosAntropometricos: {
      fechas: ['2/10/2024', '3/11/2024', '4/10/2024', '5/10/2024', '6/9/2024'],
      peso: [70.15, 69.2, 67.11, 66.0, 65.21],
      imc: [23, 22, 20, 20, 19],
    },

    // Condición física
    condicionFisica: {
      // Capacidad aeróbica
      testMilla: {
        fechas: ['2/10/2024', '3/11/2024', '4/10/2024', '5/10/2024', '6/9/2024'],
        valores: [44, 46, 45, 48, 46],
      },
      // Flexibilidad y fuerza
      testCajon: {
        fechas: ['01/02/2024', '01/03/2024', '01/04/2024', '01/05/2024', '01/06/2024'],
        cajon: [8, 9, 9, 10, 10],
        lanzamiento: [10, 8, 11, 9, 10],
      },
      // Equilibrio
      testFlamenco: {
        fechas: ['2/10/2024', '3/11/2024', '4/10/2024', '5/10/2024', '6/9/2024'],
        intentos: [2, 1, 1, 2, 1],
      },
    },

    // Cuestionario de salud
    cuestionarioSalud: {
      fechas: ['2/10/2024', '3/11/2024', '4/10/2024', '5/10/2024', '6/9/2024'],
      porcentajeOptimo: [55.95, 64.05, 64.05, 75.85, 94.45],
      saludFisica: [12, 14.5, 14.5, 17, 20],
      saludMental: [14, 15, 15, 18, 24],
    },

    // Comentarios
    comentarios:
      'El paciente ha mostrado una excelente evolución durante todo el programa. Ha mejorado su capacidad aeróbica, su fuerza y flexibilidad, y sobre todo su equilibrio. Su peso e IMC han descendido de forma significativa, situándose en valores saludables. La adherencia al programa ha sido muy buena, con un porcentaje de asistencia global del 78.6%. Recomendamos que continúe realizando actividad física regular 3 veces por semana.',
  }

  //

  const [searchValues, setSearchValues] = useState<SearchValue<ReportQuery>[]>(searcher)

  const editReport = (report: ReportType) => {
    setSelectedReportId(report.id)

    setReportProps({
      generalData: true,
      RCV: report.rcvValoration === 'true',
      SF12: report.healthFormulary === 'true',
      antroData: report.anthropometric === 'true',
      sessionsAttendance: report.sessionsAttendance === 'true',
      attendanceMonth: report.averageAttendancePerMonth === 'true',
      condition: report.physicalCondition === 'true',
      efdComments: report.edfComments === 'true',
      intensityMonth: report.averageIntensityPerMonth === 'true',
      specificData: true,
      startDate: report.startDate,
      finishDate: report.finishDate,
      totalSessions: false,
    })
    setShowNewReport(true)
    setModalLabel('editReport')
  }

  const viewReport = (report: any) => {
    setSelectedReportId(report.id)
    setSelectedReport(report)
    setReportProps({
      generalData: true,
      RCV: report.rcvValoration === 'true',
      SF12: report.healthFormulary === 'true',
      antroData: report.anthropometric === 'true',
      sessionsAttendance: report.sessionsAttendance === 'true',
      attendanceMonth: report.averageAttendancePerMonth === 'true',
      condition: report.physicalCondition === 'true',
      efdComments: report.edfComments === 'true',
      intensityMonth: report.averageIntensityPerMonth === 'true',
      specificData: true,
      startDate: report.startDate,
      finishDate: report.finishDate,
      totalSessions: false,
    })
    setPreviewReportModal(true)
  }

  const handleClosePreview = () => {
    setPreviewReportModal(false)
  }

  const printReport = (report: any) => {
    // for the record
    if (!selectedUserCircle) return

    setDisabledGenerate(true)
    const printData = {
      // generalData: {}, specificData: {}, RCV: {}, sessionsAttendance:{}, anthropometric: {}, averageAttendancePerMonth: {}, averageIntensityPerMonth: {}, averageConditionPerMonth:{}, condition:{}
    } as any

    // Obtener información general si existe
    if (report.generalInfoID) {
      reportService.getGeneralInformationbyId(report.id).subscribe({
        next: (resp) => {
          console.log('Información general obtenida:', resp)

          if (localStorage.getItem('selected user circle') && resp) {
            const userCircle = JSON.parse(
              localStorage.getItem('selected user circle') ?? 'null'
            ).user

            const generalData = {
              patientName: userCircle.name + ' ' + userCircle.lastname,
              age: '45',
              programType: resp?.programType,
              period:
                new Date(resp.reportStartDate).toLocaleDateString('es-ES', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }) +
                ' - ' +
                new Date(resp.reportFinishDate).toLocaleDateString('es-ES', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }),
              professional: resp?.prescribingProfessional,
              center: resp?.healthCentre === 'not asigned' ? 'Sin asignar' : resp?.healthCentre,
              professionalCategory: resp?.professionalCategory,
              dateStartProgram: new Date(resp.programStartDate).toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }),
              dateFinishProgram: new Date(resp.programFinishDate).toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }),
            }

            setGeneralData(generalData)
            printData.generalData = generalData

            // Actualizar el objeto informeData
            informeData.periodo = generalData.period
            informeData.datosGenerales = {
              nombre: generalData.patientName,
              edad: generalData.age,
              centroSalud: generalData.center,
              profesional: generalData.professional,
              categoria: generalData.professionalCategory,
              tipoPrograma: generalData.programType,
              fechaInicio: generalData.dateStartProgram,
              fechaFin: generalData.dateFinishProgram,
              periodoInforme: generalData.period,
            }
          }
        },
        error: (error) => {
          console.error('Error obteniendo información general:', error)
        },
      })
    }

    // if (report.generalInfoID) {
    //   reportService.getGeneralInformationbyId(report.id).subscribe((resp) => {
    //     if (localStorage.getItem('selected user circle') && resp) {
    //       const userCircle = JSON.parse(localStorage.getItem('selected user circle') ?? 'null').user

    //       setGeneralData({
    //         patientName: userCircle.name + ' ' + userCircle.lastname,
    //         age: '45',
    //         programType: resp?.programType,
    //         period:
    //           new Date(resp.reportStartDate).toLocaleDateString('es-ES', {
    //             day: 'numeric',
    //             month: 'long',
    //             year: 'numeric',
    //           }) +
    //           ' - ' +
    //           new Date(resp.reportFinishDate).toLocaleDateString('es-ES', {
    //             day: 'numeric',
    //             month: 'long',
    //             year: 'numeric',
    //           }),
    //         professional: resp?.prescribingProfessional,
    //         center: resp?.healthCentre === 'not asigned' ? 'Sin asignar' : resp?.healthCentre,
    //         professionalCategory: resp?.professionalCategory,
    //         dateStartProgram: new Date(resp.programStartDate).toLocaleDateString('es-ES', {
    //           day: 'numeric',
    //           month: 'numeric',
    //           year: 'numeric',
    //         }),

    //         dateFinishProgram: new Date(resp.programFinishDate).toLocaleDateString('es-ES', {
    //           day: 'numeric',
    //           month: 'numeric',
    //           year: 'numeric',
    //         }),
    //       });

    //       // @ts-ignore
    //       printData.generalData = setGeneralData
    //     }
    //   })
    // }

    // Obtener información específica si existe
    // if (report.specificInfoID) {
    //   reportService.getCompleteSpecificInfo(report.id, selectedUserCircle.id).subscribe({
    //     next: (resp) => {
    //       console.log('Información específica completa obtenida:', resp);

    //       if (resp) {
    //         // Formatear la fecha si es necesario
    //         const formatPeriod = (startDate: string | number | Date | undefined, endDate: string | number | Date | undefined) => {
    //           if (!startDate || !endDate) return '';

    //           return new Date(startDate).toLocaleDateString('es-ES', {
    //             day: 'numeric',
    //             month: 'long',
    //             year: 'numeric',
    //           }) +
    //             ' - ' +
    //             new Date(endDate).toLocaleDateString('es-ES', {
    //               day: 'numeric',
    //               month: 'long',
    //               year: 'numeric',
    //             });
    //         };

    //         const period = formatPeriod(resp.programStartDate, resp.programFinishDate);

    //         const specificData = {
    //           sportCenter: resp.sportCentre ?? 'Centro no asignado',
    //           capacity: resp.capacity ?? 0,
    //           address: resp.address ?? 'Dirección no asignada',
    //           EFDName: resp.efdName ?? '',
    //           center: resp.cityHall ?? 'Ayuntamiento no encontrado',
    //           EFDEmail: resp.efdEmail ?? '',
    //           period,
    //           trainingDays: resp.trainingDays ?? 'No disponible',
    //           group: resp.group ?? 'Grupo no asignado',
    //         };

    //         printData.specificData = specificData;

    //         // Actualizar el objeto informeData
    //         informeData.datosEspecificos = {
    //           centroDeportivo: specificData.sportCenter,
    //           direccion: specificData.address,
    //           ayuntamiento: specificData.center,
    //           periodo: specificData.period,
    //           grupo: specificData.group,
    //           aforo: specificData.capacity.toString(),
    //           nombreEFD: specificData.EFDName,
    //           correoEFD: specificData.EFDEmail,
    //           diasEntrenamiento: specificData.trainingDays
    //         };

    //         console.log('Datos específicos actualizados:', informeData.datosEspecificos);
    //       }
    //     },
    //     error: (error) => {
    //       console.error('Error obteniendo información específica:', error);
    //     }
    //   });
    // }

    // if (report.specificInfoID) { // al crear el iforme este campo existe: specificInfoID
    //   reportService.getSpecificInformationbyId(report.id).subscribe((resp) => {
    //     // aqui se tiene que ir a la tabla de building / training group
    //     console.log(resp , "resp specific")
    //     if (!resp) return
    //     const setSpecificData = {
    //       sportCenter: resp?.sportCentre,
    //       capacity: resp?.capacity,
    //       address: resp?.address,
    //       EFDName: resp?.efdName,
    //       center: resp?.cityHall,
    //       EFDEmail: resp?.efdEmail,
    //       period:
    //         new Date(resp.programStartDate).toLocaleDateString('es-ES', {
    //           day: 'numeric',
    //           month: 'long',
    //           year: 'numeric',
    //         }) +
    //         ' - ' +
    //         new Date(resp.programFinishDate).toLocaleDateString('es-ES', {
    //           day: 'numeric',
    //           month: 'long',
    //           year: 'numeric',
    //         }),
    //       trainingDays: 'test', // TODO
    //       group: resp.group,
    //     }
    //     // @ts-ignore
    //     printData.specificData = setSpecificData
    //   })
    // }
    // if (report.rcvValoration) {
    //   reportService.getRcvValorationById(report.id).subscribe((resp) => {
    //     // @ts-ignore
    //     printData.RCV = resp
    //   })
    // }
    // if (report.sessionsAttendance) {
    //   reportService.getSessionsAttendancebyId(report.id).subscribe((resp) => {
    //     // @ts-ignore
    //     printData.totalSessions = resp
    //   })
    // }
    // if (report.averageAttendancePerMonth) {
    //   reportService.getAvgAttendanceMonthById(report.id).subscribe((resp) => {
    //     if (resp) {
    //       // @ts-ignore
    //       printData.attendance = resp
    //     }
    //   })
    // }
    // if (report.anthropometric) {
    //   reportService.getAnthropometricById(report.id).subscribe((resp) => {
    //     // @ts-ignore
    //     printData.antroData = resp
    //   })
    // }

    // if (report.averageIntensityPerMonth) {
    //   reportService.getAvgIntensityMonthById(report.id).subscribe((resp) => {
    //     // @ts-ignore
    //     printData.intensityMonth = resp
    //   })
    // }
    // //
    // if (report.averageConditionPerMonth) {
    //   reportService.getConditionById(report.id).subscribe((resp) => {
    //     // @ts-ignore
    //     printData.condition = resp
    //   })
    // }
    // if (report.healthFormulary) {
    //   reportService.getHealthReportById(report.id).subscribe((resp) => {
    //     // @ts-ignore
    //     printData.sf12 = resp
    //   })
    // }
    // if (report.edfComments) {
    //   reportService.getEFDCommentsById(report.id).subscribe((resp) => {
    //     // @ts-ignore
    //     printData.efdComments = resp
    //   })
    // }
    // setTimeout(() => {
    //   PrintPDFComponent(
    //     <ReportToPrint dataPrint={printData} header={t('planActivaReport')} reportId={report.id} />
    //   ).then((resp) => setDisabledGenerate(false))
    // }, 5000)
  }

  const deleteReport = (report: any) => {
    setReportSelected(report)
    handleShowRemoveReport()
  }

  useEffect(() => {
    // ESTE USEEFFECT SE EJECUTA EN EL PRIMER RENDER PARA TRAER LOS DATOS
    if (selectedUserCircle) {
      // FIX type later on , dont know why this is use for
      reportService.getByUserCircleID(selectedUserCircle.id).subscribe((resp: any) => {
        if (resp?.items?.length) {
          parseReports(resp)
          setInitReports(resp.items)
        }
      })
    }
  }, [refreshList])

  // search

  useEffect(() => {
    try {
      getSearchedData()
    } catch (error) {
      console.error(error)
    }
  }, [searcher])

  const getSearchedData = () => {
    reportService
      .getFilteredList(
        new Query({
          query: [...searcherQuery(searcher)],
        })
      )
      .pipe(finalize(() => console.log('finalizamos')))
      .subscribe((resp: any) => {
        parseReports(resp)
      })
  }

  //

  // creo que esto lo tendre que borrar, de momento lo dejamos aqui
  // useEffect(() => {

  //   if (!selectedUserCircle) return

  //   reportService.getByUserCircleID(selectedUserCircle.id).subscribe((resp: any) => {
  //     if (!resp) return
  //     let result = []
  //     if (createdBy === 'patient') {
  //       result = resp.items.filter(
  //         (item: any) => item.createdByUserID === selectedUserCircle?.user.id
  //       )
  //     } else if (createdBy === 'professional') {
  //       result = resp.items.filter(
  //         (item: any) => item.createdByUserID !== selectedUserCircle?.user.id
  //       )
  //     } else {
  //       result = resp.items
  //     }

  //     parseReports(resp)
  //   })
  // }, [createdBy])

  const parseReports = (reports: ReportResponse) => {
    const newReports = reports.items?.map((report: any) => {
      const startDate = new Date(report.startDate)
      const finishDate = new Date(report.finishDate)

      // Verificar si selectedUser no es null
      if (selectedUserCircle) {
        const selectedUserID = selectedUserCircle?.user.id
        report.createdByUserString =
          report.createdByUserID === selectedUserID ? 'Paciente' : 'Profesional SMS'
      } else {
        //  caso en que selectedUser sea null
        console.error('No se encontró ningún usuario seleccionado en localStorage')
      }

      report.period =
        startDate.toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' }) +
        ' - ' +
        finishDate.toLocaleDateString('es-ES', { day: 'numeric', month: 'long', year: 'numeric' })

      return report
    })

    newReports.sort(function (a: ReportType, b: ReportType) {
      return new Date(b[orderBy]).getTime() - new Date(a[orderBy]).getTime()
    })

    setReports({
      items: newReports,
      count: newReports?.length,
    })
  }

  const fields: Field<any>[] = [
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) =>
        new Date(i.createdAt).toLocaleDateString('es-ES', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      name: 'period',
      label: t('reportPeriod'),
      renderFunc: (f, i) => <span style={{ margin: '0px', cursor: 'pointer' }}>{i.period}</span>,
    },
    {
      name: 'creator',
      label: t('creator'),
      renderFunc: (f, i) => (
        <span style={{ margin: '0px', cursor: 'pointer' }}>{i.createdByUserString}</span>
      ),
    },
  ]

  const fieldsPatient: Field<any>[] = [
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) =>
        new Date(i.createdAt).toLocaleDateString('es-ES', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      name: 'period',
      label: t('reportPeriod'),
      renderFunc: (f, i) => <span style={{ margin: '0px', cursor: 'pointer' }}>{i.period}</span>,
    },
    {
      name: 'creator',
      label: t('creator'),
      renderFunc: (f, i) => (
        <span style={{ margin: '0px', cursor: 'pointer' }}>{i.createdByUserString}</span>
      ),
    },
  ]

  const actions: Actions<any> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editReport,
        icon: editIcon,
        label: 'edit',
        hidden: (item) => item.createdByUserID !== loggedUser?.id,
      },
      {
        handler: viewReport,
        icon: viewIcon,
        label: 'view',
      },
      {
        handler: printReport,
        icon: printIcon,
        label: 'Descargar',
      },
      {
        handler: deleteReport,
        icon: deleteIcon,
        label: 'delete',
        hidden: (item) => item.createdByUserID !== loggedUser?.id,
      },
    ],
  }

  const deleteSelectedReport = () => {
    if (reportSelected) {
      reportService.delete(reportSelected.id).subscribe((resp) => {
        updateReports()
      })
    }
    handleShowDeleted()
  }

  const updateReports = () => {
    // change this logic
    if (localStorage.getItem('selected user circle')) {
      const userCircle = JSON.parse(localStorage.getItem('selected user circle') ?? '')

      reportService.getByUserCircleID(userCircle.id).subscribe(
        (resp: any | undefined) => {
          let result = []
          if (resp) {
            if (createdBy === 'patient') {
              result = resp.filter(
                (item: any) => item.createdByUserID === selectedUserCircle?.user.id
              )
            } else if (createdBy === 'professional') {
              result = resp.filter(
                (item: any) => item.createdByUserID !== selectedUserCircle?.user.id
              )
            } else {
              result = resp
            }
            // for for the sake of the function fix later on
            const extractResultItems = result.items
            // parseReports(result)
            parseReports({
              items: extractResultItems,
              count: extractResultItems.length,
            })
          } else {
            console.error('No reports found')
          }
        },
        (error) => {
          console.error('Error fetching reports:', error)
        }
      )
    }
  }

  // linea 840
  const handleFromDateChange = (date: any) => {
    let reports
    let result: any[]
    if (date) {
      setMinValue(date.format('MM-DD-YYYY'))
      setFromDate(date.toDate())
    } else {
      // @ts-ignore
      setFromDate(null)
    }
    if (!date && toDate) {
      reports = initReports.filter(
        // @ts-ignore
        (report: any) => new Date(report.finishDate) <= toDate.setDate(toDate.getDate() + 1)
      )
    } else if (toDate) {
      // @ts-ignore
      reports = initReports.filter(
        (report: any) =>
          // @ts-ignore
          new Date(report.startDate) >= date.toDate() &&
          // @ts-ignore
          new Date(report.finishDate) <= toDate.setDate(toDate.getDate() + 1)
      )
    } else if (!date && !toDate) {
      reports = initReports
    } else {
      reports = initReports.filter((report: any) => new Date(report.startDate) >= date.toDate())
    }
    if (createdBy === 'patient') {
      result = reports.filter((item: any) => item.createdByUserID === selectedUserCircle?.user.id)
    } else if (createdBy === 'professional') {
      result = reports.filter((item: any) => item.createdByUserID !== selectedUserCircle?.user.id)
    } else {
      result = reports
    }
    // parseReports(result)
  }

  const handleToDateChange = (date: any) => {
    let reports
    let result: any[]
    if (date) {
      setMaxValue(date.format('MM-DD-YYYY'))
      setToDate(date.toDate())
    } else {
      // @ts-ignore
      setToDate(null)
    }
    if (!date && fromDate) {
      // @ts-ignore
      reports = initReports.filter((report: any) => new Date(report.startDate) >= fromDate)
    } else if (fromDate) {
      // const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 8)))
      reports = initReports.filter(
        (report: any) =>
          new Date(report.finishDate) <= date.toDate().setDate(date.toDate().getDate() + 1) &&
          new Date(report.startDate) >= fromDate
      )
    } else if (!date && !fromDate) {
      reports = initReports
    } else {
      reports = initReports.filter(
        (report: any) =>
          // @ts-ignore
          new Date(report.finishDate) <= date.toDate().setDate(date.toDate().getDate() + 1)
      )
    }
    if (createdBy === 'patient') {
      result = reports.filter((item: any) => item.createdByUserID === selectedUserCircle?.user.id)
    } else if (createdBy === 'professional') {
      result = reports.filter((item: any) => item.createdByUserID !== selectedUserCircle?.user.id)
    } else {
      result = reports
    }
    //   parseReports(result)
  }

  const handleShowNewReport = (report: any) => {
    setReportProps({
      generalData: true,
      specificData: true,
      startDate: new Date(),
      finishDate: new Date(),
      RCV: false,
      SF12: false,
      antroData: false,
      attendanceMonth: false,
      sessionsAttendance: false,
      condition: false,
      efdComments: false,
      intensityMonth: false,
      totalSessions: false,
    })
    setShowNewReport(true)
    setModalLabel('')
  }

  const handleCloseNewReport = () => {
    setSelectedReportId('')
    setShowNewReport(false)
  }

  const handleCloseRemoveReport = () => {
    setSelectedReportId('')
    setShowRemoveReport(false)
  }

  const handleShowRemoveReport = () => {
    setShowRemoveReport(true)
  }

  const handleCreateNewReport = () => {}

  const handleShowDeleted = () => {
    handleCloseRemoveReport()
    setShowDeleted(true)
  }

  const handleCloseDeleted = () => {
    setShowDeleted(false)
  }

  const handleCreatedBy = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value
    setCreatedBy(newValue)
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value
    const updatedSearcher = searcher.map((sv) => {
      if (sv.name === 'createdBy') {
        return { ...sv, value: selectedValue }
      }
      return sv
    })
    setSearcher(updatedSearcher)
    setSearchValues(updatedSearcher)
  }

  const handleDateChange = (name: 'startDate' | 'finishDate', date: Date | null) => {
    if (!date) {
      const updatedSearcher = searcher.map((sv) => {
        if (sv.name === name) {
          return { ...sv, value: undefined }
        }
        return sv
      })

      setSearcher(updatedSearcher)
      setRefresList(true)
      return
    }

    const localDate = new Date(date)

    const updatedSearcher = searcher.map((sv) => {
      if (sv.name === name) {
        return { ...sv, value: localDate.toISOString() }
      }
      return sv
    })

    setSearcher(updatedSearcher)
  }

  const createSortHandler = (props: any) => (event: React.MouseEvent<unknown>) => {
    const isAsc = orderBy === props && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(props)
  }

  useEffect(() => {
    const aux: ReportResponse[] = [...reports.items]
    aux.sort((a: any, b: any) => {
      if (order === 'asc') {
        return new Date(a[orderBy]).getTime() - new Date(b[orderBy]).getTime()
      } else {
        return new Date(b[orderBy]).getTime() - new Date(a[orderBy]).getTime()
      }
    })

    setReports({
      items: aux,
      count: aux.length,
    })
  }, [order, orderBy])

  const searcherQuery = (svs: SearchValue<ReportQuery>[]): QueryParam<ReportQuery>[] =>
    svs
      .filter((sv) => sv.value)
      .map((sv) => {
        if (['startDate', 'finishDate'].includes(sv.name)) {
          const date = new Date(sv.value as string)
          const dateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
          return new QueryParam(sv.name, dateUTC.toISOString())
        }
        return new QueryParam(sv.name, sv.value!.toLowerCase())
      })

  return (
    <>
      <Modal open={showRemoveReport} onClose={handleCloseRemoveReport}>
        <div className={styleModal.agoraContainer}>
          <Box
            className={styleModal.containerInside}
            style={{ height: '500px', overflow: 'auto', justifyContent: 'space-between' }}
          >
            <Box style={{ overflow: 'auto' }}>
              <Header label={t('removeReport')} icon={close} onClick={handleCloseRemoveReport} />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <h1 style={{ color: '#f18b24' }}>ELIMINAR INFORME</h1>
              <h4>¿Está seguro de eliminar este informe?</h4>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="20px"
              alignItems="center"
              justifyContent="center"
            >
              <Box style={{ paddingTop: '20px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('no')}
                  handler={handleCloseRemoveReport}
                />
              </Box>
              <Box style={{ paddingTop: '20px' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('yes')}
                  handler={deleteSelectedReport}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Modal>

      <Box className={styles.test1}>
        <Box display={'flex'} flexDirection={'column'} className={styles.notificationContainer}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: 1,
              position: 'sticky',
              top: '0',
              backgroundColor: '#ffffff',
              borderBottom: '3px solid #f18b24',
            }}
          >
            <Header divider={true} label={'Informes'} />
            <>
              <>
                <p>{t('Creado por')}:</p>
                <Select
                  style={{ width: '500px' }}
                  key={'createdBy'}
                  id={'createdBy'}
                  placeholder={'Creado por'}
                  name={'createdBy'}
                  value={searcher.find((sv) => sv.name === 'createdBy')?.value ?? ''}
                  // onChange={handleCreatedBy}
                  onChange={handleSelectChange}
                  required={true}
                  size={'small'}
                  variant={'outlined'}
                  fullWidth={true}
                >
                  <MenuItem value={'todos'}>
                    <div>Todos</div>
                  </MenuItem>

                  <MenuItem value={'professional'}>
                    <div>Profesional SMS</div>
                  </MenuItem>
                  <MenuItem value={'professionalExtern'}>
                    <div>Profesional externo</div>
                  </MenuItem>
                  <MenuItem value={'patient'}>
                    <div>Paciente</div>
                  </MenuItem>
                </Select>
              </>

              <p>{t('from')}:</p>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  key={'date'}
                  disableFuture={true}
                  maxDate={maxValue}
                  PopperProps={{
                    sx: {
                      '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                      },
                      '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-3eghsz-PrivatePickersYear-button': {
                        fontFamily: 'Poppins',
                      },
                    },
                  }}
                  // onError={(reason, value) => {
                  //   switch (reason) {
                  //     case 'invalidDate':
                  //       setDateTimePickerError(t('invalidDateMessage'))
                  //       break
                  //     case 'minDate':
                  //       setDateTimePickerError(t('minDateMessage'))
                  //       break
                  //   }
                  // }}
                  renderInput={(props) => (
                    <TextField
                      style={{ width: '20%' }}
                      sx={{
                        '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                        '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                          fontFamily: 'Poppins',
                        },
                      }}
                      variant={'outlined'}
                      // helperText={props.error && DateTimePickerError}
                      {...props}
                    />
                  )}
                  inputFormat="DD/MM/YYYY"
                  value={searcher.find((sv) => sv.name === 'startDate')?.value ?? null}
                  onChange={(date) => {
                    if (date) {
                      handleDateChange('startDate', date)
                    } else {
                      handleDateChange('startDate', null)
                    }
                  }}
                />
              </LocalizationProvider>
              <p>{t('to')}:</p>

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  key={'date'}
                  minDate={minValue}
                  disableFuture={true}
                  PopperProps={{
                    sx: {
                      '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                      },
                      '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                        fontFamily: 'Poppins',
                      },
                      '& .css-3eghsz-PrivatePickersYear-button': {
                        fontFamily: 'Poppins',
                      },
                    },
                  }}
                  // onError={(reason, value) => {
                  //   switch (reason) {
                  //     case 'invalidDate':
                  //       setDateTimePickerError(t('invalidDateMessage'))
                  //       break
                  //     case 'minDate':
                  //       setDateTimePickerError(t('minDateMessage'))
                  //       break
                  //   }
                  // }}
                  renderInput={(props) => (
                    <TextField
                      style={{ width: '20%' }}
                      sx={{
                        '.css-1ptx2yq-MuiInputBase-root-MuiInput-root': { paddingRight: '17px' },
                        '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                          fontFamily: 'Poppins',
                        },
                      }}
                      variant={'outlined'}
                      // helperText={props.error && DateTimePickerError}
                      {...props}
                    />
                  )}
                  inputFormat="DD/MM/YYYY"
                  value={searcher.find((sv) => sv.name === 'finishDate')?.value ?? null}
                  // onChange={handleToDateChange}
                  onChange={(date) => {
                    if (date) {
                      handleDateChange('finishDate', date)
                    } else {
                      handleDateChange('finishDate', null)
                    }
                  }}
                />
              </LocalizationProvider>
              {containsRoleToAllow && (
                <Button
                  onClick={handleShowNewReport}
                  style={{
                    backgroundColor: '#68b3e0',
                    marginLeft: '20px',
                    float: 'right',
                    width: '25%',
                    color: 'white',
                  }}
                >
                  Generar nuevo informe
                </Button>
              )}
            </>
          </Box>

          <Box alignItems={'center'} className={styles.horizontalTimeline}>
            {
              <>
                <Modal open={showNewReport} onClose={handleCloseNewReport}>
                  <ReportModal
                    handleClose={handleCloseNewReport}
                    updateReports={updateReports}
                    label={modalLabel}
                    reportsFromView={reportProps}
                    reportId={selectedReportId}
                  />
                </Modal>
                <Modal open={previewReportModal} onClose={handleClosePreview}>
                  <ReportPreview
                    handleClose={handleClosePreview}
                    reportData={selectedReport}
                    reportId={selectedReportId}
                    dataToPrint={reportProps}
                  />
                </Modal>
                <Table
                  style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 20px',
                    borderColor: '#DBDADA',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {fieldsPatient.map((headCell, index) => (
                        <TableCell
                          key={index}
                          align={'left'}
                          padding={'normal'}
                          sortDirection={orderBy === headCell.name ? order : false}
                        >
                          {headCell.name === 'createdAt' ? (
                            <TableSortLabel
                              active={orderBy === headCell.name}
                              direction={orderBy === headCell.name ? order : 'desc'}
                              onClick={createSortHandler(headCell.name)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          ) : (
                            headCell.label
                          )}
                        </TableCell>
                      ))}
                      <TableCell align={'center'} padding={'normal'}>
                        {t('Actions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <Body
                    actions={actions}
                    fields={
                      JSON.parse(localStorage.getItem('logged user') ?? 'null').roles.includes(
                        'professionalSMS'
                      )
                        ? fields
                        : fieldsPatient
                    }
                    items={reports.items || []}
                    rowKeyField={'id'}
                  />
                </Table>
              </>
            }
          </Box>
        </Box>
      </Box>

      <>
        <PDFViewerWithCanvas
          fileName="informe_plan_activa.pdf"
          buttonText="Informe a PDF"
          informeData={informeData}
        />
      </>
    </>
  )
}
