import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import style from './Header.module.css'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { User } from '../../modules/users/models/User'
import { GenericTooltip } from '../../components/generic-tooltip'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { SwitchCloseQuery } from './SwitchStyle'
import { TeleconsultaDTO } from '../../modules/teleconsultation/models/TeleconsultaDTO'
import { ClosedStatus } from 'modules/teleconsultation/enums/ClosedStatus'

const userContainer = getUserContainer()
const usersService = userContainer.get<UserService>(USER_SERVICE_KEY)

export type ConversationHeaderProps = {
  headerName: string
  avatarUrl?: string
  creatorID: string
  description: string
  currentConversation: TeleconsultaDTO
  handleEdit: () => void
  handleDelete: () => void
  handleCloseQuery: () => void
  conversationUsers: string[]
}
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Header(props: ConversationHeaderProps): JSX.Element {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()

  const [conversationCreator, setConversationCreator] = useState<User>()

  useEffect(() => {
    usersService.getByID(props.creatorID).subscribe((res) => {
      setConversationCreator(res)
    })
  }, [props.creatorID, props.conversationUsers, props.currentConversation])

  return (
    <>
      <Box className={style.divHeader}>
        <Box className={style.contactFullName}>{props.headerName}</Box>
        <Box className={style.conversationLeft}>
          <Box className={style.conversationOptions}>
            <Box component="p">{t(`consultationOpenedBy`) + ' ' + conversationCreator?.name}</Box>
            <div>
              <GenericTooltip
                values={[conversationCreator?.name ? conversationCreator?.name : '']}
                icon={<PersonIcon color={'primary'} />}
                noUseFab={true}
              />
            </div>
            {loggedUser && loggedUser?.roles.includes('professionalSMS') && (
              <>
                {props.currentConversation.closed === ClosedStatus.Closed
                  ? 'Reabrir consulta'
                  : ' Cerrar consulta'}
                <SwitchCloseQuery
                  size="small"
                  checked={props.currentConversation.closed === ClosedStatus.Closed}
                  onClick={() => {
                    props.handleCloseQuery()
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Box className={style.creatorResponsive}>
        {t(`consultationOpenedBy`) + ' ' + conversationCreator?.name}
      </Box>
    </>
  )
}
