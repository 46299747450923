export enum Roles {
  Admin = 'admin',
  Patient = 'patient',
  FamilyOrTutor = 'family/Tutor',
  Professional = 'professionalSMS',
  CarerOrTeacher = 'carer/Teacher',
  ProfessionalExtern = 'externProfessional',
  CaseManager = 'caseManager',
  Manager = 'manager',
  ManagerEpileptic = 'managerEpileptic',
  ManagerHealthChild = 'managerHealthChild',
  ManagerActivePlan = 'managerActivePlan',
}

export const RoleName = (): Record<string, Roles> => ({
  admin: Roles.Admin,
  // contentManager: Roles.ContentManager,
  patient: Roles.Patient,
  'family-Tutor': Roles.FamilyOrTutor,
  professionalSMS: Roles.Professional,
  'carer-Teacher': Roles.CarerOrTeacher,
  externProfessional: Roles.ProfessionalExtern,
  caseManager: Roles.CaseManager,
  managerHealthChild: Roles.ManagerHealthChild,
  managerEpileptic: Roles.ManagerEpileptic,
  managerActivePlan: Roles.ManagerActivePlan,
})
