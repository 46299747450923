import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import React, { useEffect, useState } from 'react'
import { UserQuery } from 'modules/users/models/User'
import { emptyList, ItemList } from 'common/models/ItemList'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { AppTable, Field } from 'components/table'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Pager, Search, SearchValue } from 'components/table_type/types'
import { ROUTE_DASHBOARD } from 'routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import genericStyle from 'common/utils/generic.module.css'
import dayjs from 'dayjs'
import { getUserCircleContainer } from 'container/user-circle-module'
import { IUserCircleService, USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { UserCircleWithCircleAndUser } from 'modules/user-circle/models/UserCircleWithCircleAndUser'
import { UserCircleQuery } from 'modules/user-circle/models/UserCircle'
import { Query, QueryParam } from 'common/api/Query'
import Button from '@mui/material/Button'
import { NewUserModal } from './NewUserModal'
import userIconTable from 'assets/responsive_icons/userIconTable.svg'
import style from './Circle.module.css'
import { useGlobalContext } from 'common/utils/GlobalRoleContext'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { ROUTE_CIRCLE_REGISTER } from 'routes/routes-constants'
import { finalize } from 'rxjs/operators'
import { useCircleConfiguration } from 'common/utils/circle-config-context/CircleConfigContext'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<IUserCircleService>(USER_CIRCLE_SERVICE_KEY)

const roleCircleMap: Record<string, string> = {
  managerHealthChild: '1999',
  managerActivePlan: '1113',
  managerEpileptic: '1111',
  // agregar más roles aquí según sea necesario
}
export function Table() {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()
  const { role } = useGlobalContext()
  const { setSelectedUserCircle } = useCircleConfiguration()
  const [userCircles, setUserCircles] =
    useState<ItemList<UserCircleWithCircleAndUser>>(emptyList<UserCircleWithCircleAndUser>())
  const [pager, setPager] = useState<Pager>()
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filtering, setFiltering] = useState<boolean>(false)
  const [circlesPerPage, setCirclesPerPage] = useState<number>(10)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'allFields',
      label: t('search'),
    },
    {
      name: 'birthDate',
      label: '',
      type: 'date',
    },
  ])

  const isMobile = window.innerWidth < 768

  // const { render, setRender } = useGlobalRenderHeaderContext()
  const navigate = useNavigate()
  const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false)
  const isCaseManager = Object.keys(roleCircleMap).some((role) => loggedUser?.roles?.includes(role))
  const isProfessionalSMS = loggedUser?.roles.includes('professionalSMS')

  useEffect(() => {
    if (!loggedUser?.id) return

    if (!isCaseManager) {
      const query = new Query<UserCircleQuery>({
        pager: { offset: page * circlesPerPage, limit: circlesPerPage },
        query: [
          new QueryParam('includesUserID', loggedUser.id),
          new QueryParam('searchByCircleOrUser', searcher[0]?.value ?? ''),
          new QueryParam('searchByDate', searcher[1]?.value?.split('T')[0] ?? ''),
          new QueryParam('mainCircleView', true),
        ],
        sort: [{ field: 'userID', desc: true }],
      })
      fetchUserCircles(query)
    } else {
      loggedUser.roles.forEach((role) => {
        const circleID = roleCircleMap[role]
        if (circleID) {
          fetchCustomUserCircles(circleID)
        }
      })
    }
  }, [pager, searcher])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: circlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, circlesPerPage])

  const filterCirclesByRole = (
    res: ItemList<UserCircleWithCircleAndUser>
  ): ItemList<UserCircleWithCircleAndUser> => {
    const data = role.some((r) => r.name === 'patient')
      ? res.items.filter((item) => item.user.id === loggedUser?.id)
      : res.items.filter((item) => item.user.id !== loggedUser?.id)
    return {
      count: res.count,
      items: data,
      raw: res,
    }
  }

  const fetchUserCircles = (query: Query<UserCircleQuery>) => {
    setIsLoading(true)
    userCircleService
      .getFilteredListWithCircleAndUser(query)
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        if (!res) return
        const newRes = filterCirclesByRole(res)
        setUserCircles(newRes)
        setCount(newRes.count)
      })
  }

  const fetchCustomUserCircles = (circleID: string) => {
    setIsLoading(true)
    const query = new Query<UserCircleQuery>({
      pager: { offset: page * circlesPerPage, limit: circlesPerPage },
      query: [
        new QueryParam('roles', loggedUser?.roles ?? ''),
        new QueryParam('searchByCircleOrUser', searcher[0]?.value ?? ''),
        new QueryParam('searchByDate', searcher[1]?.value?.split('T')[0] ?? ''),
      ],
      sort: [{ field: 'userID', desc: true }],
    })
    userCircleService
      .getFilteredListWithCircleAndUserCustom(query)
      .pipe(finalize(() => setIsLoading(false)))
      .subscribe((res) => {
        if (!res) return
        const newRes = filterCirclesByRole(res)
        setUserCircles(newRes)
        setCount(newRes.count)
      })
  }

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0)
    if (Number.isNaN(event.target.value)) {
      setCirclesPerPage(10)
      return
    }
    setCirclesPerPage(Number.parseInt(event.target.value))
  }

  const handleSelect = (userCircle: UserCircleWithCircleAndUser) => {
    setSelectedUserCircle(new UserCircleWithCircleAndUser(userCircle).toDTO())
  }

  const fields: Field<UserCircleWithCircleAndUser>[] = [
    {
      name: 'user',
      label: t('fullName'),
      renderFunc: (f, i) => (
        <div
          className={style.cell}
          onClick={() => {
            handleSelect(i)
            navigate(ROUTE_DASHBOARD)
          }}
        >
          <p>
            {i.user.name} {i.user.lastname}
          </p>
        </div>
      ),
    },
    {
      name: 'circle',
      label: t('circle'),
      renderFunc: (f, i) => i.circle.name,
    },
    {
      name: 'user',
      label: t('birthdate'),
      renderFunc: (f, i) => dayjs(i.user.birthDate).format('DD/MM/YYYY'),
    },
    {
      name: 'user',
      label: t('CIP'),
      renderFunc: (f, i) => i.user.cip,
    },
    {
      name: 'user',
      label: t('DNI'),
      renderFunc: (f, i) => i.user.dni,
    },
  ]
  const fieldsResponsive: Field<UserCircleWithCircleAndUser>[] = [
    {
      name: 'user',
      label: t(''),
      styleFunc: (f, i) => style.borderTable,
      renderFunc: (f, i) => (
        <div
          className={style.userBoxResponsive}
          onClick={() => {
            handleSelect(i)
            navigate(ROUTE_DASHBOARD)
          }}
        >
          <div>
            <p className={style.bold}>{i.user.name + ' ' + i.user.lastname}</p>
            <p>{i.circle.name}</p>
            <p>{i.user.dni}</p>
          </div>
          <img src={userIconTable} alt="userIconTable" style={{ marginLeft: '10px' }} />
        </div>
      ),
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      setFiltering(!!svs[0].value)

      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        date.setDate(date.getDate() + 1)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  return (
    <Box data-testid="component" className={genericStyle.pageContainer}>
      {isProfessionalSMS ? (
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type="button"
          label={t('newCircle')}
          handler={() => navigate(ROUTE_CIRCLE_REGISTER)}
        />
      ) : null}
      {process.env.NODE_ENV === 'development' && (
        <Button onClick={() => setShowNewUserModal(true)}>Nuevo usuario</Button>
      )}

      <AppTable
        isLoading={isLoading}
        items={userCircles.items}
        rowKeyField="id"
        fields={isMobile ? fieldsResponsive : fields}
        search={search}
        fieldsInDraggable={true}
        pager={pager}
      />
      {showNewUserModal && (
        <NewUserModal open={showNewUserModal} onClose={() => setShowNewUserModal(false)} />
      )}
    </Box>
  )
}
