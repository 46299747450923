/* eslint-disable @typescript-eslint/no-unused-vars */
import { DashboardSectionCard } from 'components/section-card/DashboardSectionCard'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_ARTICLES } from 'routes/routes-constants'
import { emptyList, ItemList } from 'common/models/ItemList'
import { Article } from 'modules/content/models/Article'
import { Query, QueryParam } from 'common/api/Query'
import { ArticleStatus } from 'modules/content/enums/ArticleStatus'
import { viewImageFileBase64 } from 'common/utils/file'
import { getContentContainer } from 'container/content-module'
import { ArticleService } from 'modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from 'modules/content'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { getUserCircleContainer } from 'container/user-circle-module'
import { UserCircleService } from 'modules/user-circle/services/UserCircleConfigService'
import { USER_CIRCLE_SERVICE_KEY } from 'modules/user-circle'
import { Box, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import style from '../chats-card/ChatsCard.module.css'
import { listItemTextStyle } from '../notification-card/NotificationsCardStyleMui'
import esferaLogo from 'assets/brand_logo/smsBlue.svg'
import styleNoti from '../notification-card/NotificationCard.module.scss'
import { RichText } from 'components/text-editor/RichText'
import { CircleDTO } from '../../../../modules/circle/models/CircleDTO'
import { getUserContainer } from '../../../../container/user-module'
import { LoggedUserService } from '../../../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../../../modules/users'
import { useProfCircle } from '../../../../common/utils/prof-circle-context/ProfCircleContext'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)
const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)
const userCircleService = getUserCircleContainer().get<UserCircleService>(USER_CIRCLE_SERVICE_KEY)
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type EducationalContentCardProps = {}
const imageList = new Map()

export const EducationalContentCard: React.FC<EducationalContentCardProps> = () => {
  const loggedUser = loggedUserService.get()
  const [articles, setArticles] = useState<ItemList<Article>>(emptyList<Article>())
  const [circles, setUserCircles] = useState<any>([])
  const navigate = useNavigate()
  const { selectedProfCircle } = useProfCircle()

  const handlerGotoEducationalContent = () => {
    navigate(ROUTE_ARTICLES)
  }

  useEffect(() => {
    const circles: any[] = []
    userCircleService
      .getWichIncludesUserIDWithRelatersAndCircle(loggedUser?.id ?? '')
      .subscribe((res) => {
        if (res) {
          const uniqueCircles: CircleDTO[] = []
          res.forEach((userCircle) => {
            if (!uniqueCircles.find((circle) => circle.id === userCircle.circle.id)) {
              uniqueCircles.push(userCircle.circle)
              circles.push(userCircle.circle.id)
            }
          })
          setUserCircles(circles)
        }
      })
  }, [])

  useEffect(() => {
    const circleID = selectedProfCircle?.id

    if (circles.length === 0) {
      return
    }
    const articleList = emptyList<Article>()
    const articlesQuery = [
      new QueryParam<Article>('status', [ArticleStatus[ArticleStatus.publicado]]),
      new QueryParam<Article>('type', 'article'),
    ]

    if (circleID) {
      articlesQuery.push(new QueryParam<Article>('circleID', circleID))
    } else if (circles.length === 1) {
      articlesQuery.push(new QueryParam<Article>('circleID', circles[0]))
    } else if (circles.length > 1) {
      articlesQuery.push(new QueryParam<Article>('circlesIDs', circles))
    } else {
      return
    }

    new Promise((resolve) => {
      articlesService
        .getFilteredList(
          new Query({
            query: articlesQuery,
            sort: [{ field: 'createdAt', desc: true }],
          })
        )
        .subscribe((res) => {
          res.items = res.items.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          })
          articleList.count = 0
          for (let i = 0; i < res.items.length && i < 2; i++) {
            const articleItem = res.items[i]

            articleList.items.push(articleItem)
            articleList.count++

            if (articleItem.slug !== 'slug') {
              filesService.downloadByID(articleItem.slug).subscribe(async (res) => {
                if (res) {
                  const image = await viewImageFileBase64(res)
                  imageList.set(articleItem.slug, image)
                }
                if (i === 0) {
                  resolve(true)
                }
              })
            } else {
              if (i === 0) {
                resolve(true)
              }
            }
          }
          if (res.items.length === 0) {
            resolve(true)
          }
        })
    }).then(() => {
      setArticles(articleList)
    })
  }, [circles, selectedProfCircle])

  const handleSee = (event: any, article: Article) => {
    navigate(`${ROUTE_ARTICLES}/${article.id}`)
  }

  return (
    <DashboardSectionCard label={'library'} showMore={true} onClick={handlerGotoEducationalContent}>
      {articles.items.map((article) => {
        let content = <div></div>
        let articleImage = null

        if (article.slug !== 'slug') {
          articleImage = imageList.get(article.slug) ?? null
        }
        if (article && article.content && article.content.charAt(0) === '[') {
          content = <RichText content={article.content} edit={true} view={true} />
        }
        return (
          <ListItem
            key={article.id}
            onClick={(event) => handleSee(event, article)}
            sx={{
              hover: 'none',
              cursor: 'pointer',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Box width={'100%'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                height={'100px'}
                mr={1}
                ml={1}
                className={style.chatContainer}
              >
                <ListItemAvatar>
                  <img
                    className={!articleImage ? styleNoti.image : styleNoti.imageFormat}
                    src={!articleImage ? esferaLogo : `data:image/jpeg;base64,${articleImage}`}
                  />
                </ListItemAvatar>
                <ListItemText sx={listItemTextStyle} className={styleNoti.listItemText}>
                  <div className={styleNoti.title}>{article.title}</div>
                  <div className={styleNoti.titleEditorEducational}>{content}</div>
                </ListItemText>
              </Box>
            </Box>
          </ListItem>
        )
      })}
    </DashboardSectionCard>
  )
}
