export const ACTIVE_PLAN_CIRCLE_ID = '1113'
export const EPILEPSY_CIRCLE_ID = '1111'
export const HEALTH_CHILD_CIRCLE_ID = '0000'

export const ROWS_PER_PAGE_OPTIONS =
  process.env.NODE_ENV === 'development' ? [10, 20, 50, 100] : [10, 20, 50, 100]

export const MANAGER_TYPES = ['managerHealthChild', 'managerActivePlan', 'managerEpileptic']

export const ARRAY_OF_LETTERS = ['A-C', 'D-F', 'G-I', 'J-L', 'M-O', 'P-R', 'S-U', 'V-X', 'Y-Z']

export const FILE_VALID_EXTENSIONS =
  '.pdf, .xml, .txt, .html, .doc, .docx, .odt, .ods, .odp, .odg,' +
  ' .dotx, .docm, .dotm, .xls, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb, .ppt, .pptx, .potx,' +
  ' .ppsx, .ppam, .pptm, .potm, .ppsm,' +
  '.mpp, .vsd, .rtf, jpg, .jpeg, .JPG, .JPEG, .PNG, .png, .gif, .tif,' +
  ' .svf, .svg, .p12, .cert, .ps, .bz, .bz2, .zip, .gzip, .rar, .rm,' +
  ' .aif, .au, .midi, .mpg, .mp3,' +
  ' .wmv, .mkv, .avi, .mp4,' +
  '.dvi, .avi, .mov, .bin, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, ' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template, application/vnd.ms-word.document.macroEnabled.12,' +
  'application/vnd.ms-word.template.macroEnabled.12, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template, application/vnd.ms-excel.sheet.macroEnabled.12,' +
  'application/vnd.ms-excel.template.macroEnabled.12, application/vnd.ms-excel.addin.macroEnabled.12,' +
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-powerpoint, ' +
  'application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.presentationml.template,' +
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.ms-powerpoint.addin.macroEnabled.12,' +
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12, application/vnd.ms-powerpoint.template.macroEnabled.12,' +
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12'

export const TODAY = new Date()

export const UNITS_SIZE = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const WEEK_DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
