import React from 'react'
import { Box, Chip } from '@mui/material'
import styles from './Header.module.css'
import style from '../current-nav-header-esfera/CurrentNavHeaderEsfera.module.css'
import { getFirstWord } from '../../common/utils/strings'
import { useTranslation } from 'react-i18next'
import showMoreIcon from '../../assets/dashboard/showMoreIcon.svg'
import clsx from 'clsx'

type HeaderProps = {
  label: string
  icon?: string
  onClick?: () => void
  circle?: string
  userName?: string
  subtitle?: string
  divider?: boolean
  showMore?: boolean
  borderBottom?: string
  noTranslate?: boolean
}

export function Header({
  label,
  icon,
  onClick,
  circle,
  userName,
  subtitle,
  divider,
  showMore,
  borderBottom,
  noTranslate,
}: HeaderProps) {
  const { t } = useTranslation()

  return (
    <Box
      sx={{ borderBottom: borderBottom ?? '' }}
      className={clsx(styles.container, divider && styles.containerNoBorder)}
    >
      <p className={styles.header}>{noTranslate ? label : t(label)}</p>
      {icon && <img className={styles.icon} src={icon} alt={label} onClick={onClick} />}

      {(circle ?? userName) && (
        <Box className={styles.circleUser}>
          {circle && <Chip label={circle} className={style.pathologies} />}
          {userName && (
            <div className={style.circleContainer}>
              <p>{getFirstWord(userName)}</p>
            </div>
          )}
        </Box>
      )}

      {showMore && (
        <Box className={style.showMore} onClick={onClick}>
          <p>{t('showMore')}</p>
          <img src={showMoreIcon} alt={'arrow-right'} />
        </Box>
      )}
    </Box>
  )
}
